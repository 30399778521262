//#region IMPORT

import { ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { BankAccountModel } from "./bankaccount.model";
import { SubmissionModel } from "./submission.model";
import { SubmissionVideModel } from "./submissionvide.model";
import { BaseModel } from "./bases/base.model";
import { SubmissionCostModel } from "./submissioncost.model";
import { ResponseModel } from "./response.model";

//#endregion


//#region CLASS

export class SubmissionDetailModel extends BaseModel
{
	ID?: number;
	SubmissionID?: number;
	Title?: string;
	Description?: string;
	BeneficiaryName?: string;
	BankAccountToken?: string;
	PaidOn?: Date;

	modelBankAccount?: BankAccountModel;
	modelSubmission?: SubmissionModel;
	listModelSubmissionVide?: Array<SubmissionVideModel>;
	listModelSubmissionCost?: Array<SubmissionCostModel>;

	//#region FRONT END ONLY MODEL

	TotalAmount?: number;
	TotalCost?: number;
	TotalAmountWithCost?: number;
	modelSubmissionCostInsert?: SubmissionCostModel;
	BooleanBankAccountVerification?: boolean;

	//#endregion

	//#region CONSTRUCTOR

	constructor()
	{
		super();
		this.TotalAmount = 0;
		this.TotalCost = 0;
		this.TotalAmountWithCost = 0;
		this.modelSubmissionCostInsert = new SubmissionCostModel();
		this.listModelSubmissionVide = [];
		this.listModelSubmissionCost = [];
		this.BooleanBankAccountVerification = false;
	}

	//#endregion


	//#region SETTER

	setModelBankAccount(): void
	{
		const stringModelBankAccount: string = JSON.stringify(this.modelBankAccount);
		this.modelBankAccount = new BankAccountModel();
		this.modelBankAccount.setModelFromString(stringModelBankAccount);
	}

	setListModelSubmissionVide(): void
	{
		if (this.listModelSubmissionVide == null || this.listModelSubmissionVide === undefined)
		{

		}
		else
		{
			if (this.listModelSubmissionVide.length > 0 && this.TotalAmount !== undefined)
			{
				const arrayModelSubmissionVideTemporary: Array<SubmissionVideModel> = this.listModelSubmissionVide;
				this.listModelSubmissionVide = [];

				let modelSubmissionVide: SubmissionVideModel;

				for (const modelSubmissionVideTemporary of arrayModelSubmissionVideTemporary)
				{
					modelSubmissionVide = new SubmissionVideModel();
					modelSubmissionVide.setModelFromObject(modelSubmissionVideTemporary);
					this.TotalAmount += modelSubmissionVide.Amount ?? 0;
					this.TotalAmountWithCost = this.TotalAmount;
					this.listModelSubmissionVide.push(modelSubmissionVide);
				}
			}
			else
			{

			}
		}
	}

	setListModelSubmissionCost(): void
	{
		if (this.listModelSubmissionCost == null || this.listModelSubmissionCost === undefined)
		{

		}
		else
		{
			if (this.listModelSubmissionCost.length > 0)
			{
				const arrayModelSubmissionCostTemporary: Array<SubmissionCostModel> = this.listModelSubmissionCost;
				this.listModelSubmissionCost = [];

				let modelSubmissionCost: SubmissionCostModel;

				for (const modelSubmissionCostTemporary of arrayModelSubmissionCostTemporary)
				{
					modelSubmissionCost = new SubmissionCostModel();
					modelSubmissionCost.setModelFromObject(modelSubmissionCostTemporary);
					this.listModelSubmissionCost.push(modelSubmissionCost);
				}
			}
			else
			{

			}
		}
	}

	setAmountAdjustment(modelSubmissionCost: SubmissionCostModel): void
	{
		if(modelSubmissionCost !== undefined && modelSubmissionCost.Amount !== undefined && this.TotalAmount !== undefined && this.TotalCost !== undefined)
		{
			this.TotalCost += modelSubmissionCost.Amount;
			this.TotalAmountWithCost = this.TotalAmount + this.TotalCost;
		}
		else
		{

		}
	}

	setDecreaseAmountAdjustment(modelSubmissionCost: SubmissionCostModel): void
	{
		if(modelSubmissionCost !== undefined && modelSubmissionCost.Amount !== undefined && this.TotalAmount !== undefined && this.TotalCost !== undefined)
		{
			this.TotalCost -= modelSubmissionCost.Amount;
			this.TotalAmountWithCost = this.TotalAmount + this.TotalCost;
		}
		else
		{

		}
	}

	//#endregion


	//#region VALIDATE

	validateCheck(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Bank Account Verification");

		if (this.BooleanBankAccountVerification == null || this.BooleanBankAccountVerification === undefined || this.BooleanBankAccountVerification === false)
		{
			modelResponse.MessageContent = "Please check bank account for verification before approve.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	//#endregion


	//#region CLEAN

	cleanFootprintForPaymentRequest(): void
	{
		this.cleanAllFootprint();
		this.BeneficiaryName = undefined;
		this.BankAccountToken = undefined;
		this.Description = undefined;
		this.Title = undefined;
		this.TotalAmount = undefined;
		this.TotalAmountWithCost = undefined;
		this.TotalCost = undefined;
		this.modelBankAccount = undefined;
		this.modelSubmissionCostInsert = undefined;
		this.listModelSubmissionVide = undefined;
	}

	//#endregion
}

//#endregion