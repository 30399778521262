//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { TableModel } from "../models/bases/table.model";
import { LetterGeneralModel } from "../models/lettergeneral.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class LettergeneralService extends BaseService
{
	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	//#region SELECT

	selectLetterGeneralSignedByAttributes(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable) ,WebAddressConstant.STRING_URL_LETTERGENERAL_SELECTLETTERGENERALSIGNEDBYATTRIBUTES);
	}

	selectLetterGeneralRejectedByAttributes(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable) ,WebAddressConstant.STRING_URL_LETTERGENERAL_SELECTLETTERGENERALREJECTEDBYATTRIBUTES);
	}

	selectLetterGeneralApprovedByAttributes(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable) ,WebAddressConstant.STRING_URL_LETTERGENERAL_SELECTLETTERGENERALAPPROVEDBYATTRIBUTES);
	}

	selectLetterGeneralByAttributes(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable) ,WebAddressConstant.STRING_URL_LETTERGENERAL_SELECTLETTERGENERALBYATTRIBUTES);
	}

	selectLetterGeneralPendingForCheckerAKUByAttributes(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable) ,WebAddressConstant.STRING_URL_LETTERGENERAL_SELECTLETTERGENERALPENDINGFORCHECKERAKUBYATTRIBUTES);
	}

	selectLetterGeneralPendingForSignerAKUByAttributes(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable) ,WebAddressConstant.STRING_URL_LETTERGENERAL_SELECTLETTERGENERALPENDINGFORSIGNERAKUBYATTRIBUTES);
	}

	selectLetterGeneralByAttributesForReport(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral) ,WebAddressConstant.STRING_URL_LETTERGENERAL_SELECTLETTERGENERALBYATTRIBUTESFORREPORT);
	}

	//#endregion


	//#region UPDATE

	//#endregion


	//#region APPROVAL

	approveLetterGeneralSignedByToken(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral) ,WebAddressConstant.STRING_URL_LETTERGENERAL_APPROVELETTERGENERALSIGNEDBYTOKEN);
	}

	rejectLetterGeneralSignedByToken(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral) ,WebAddressConstant.STRING_URL_LETTERGENERAL_REJECTLETTERGENERALSIGNEDBYTOKEN);
	}

	approveLetterGeneralPendingForCheckerAKUByToken(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral) ,WebAddressConstant.STRING_URL_LETTERGENERAL_APPROVELETTERGENERALPENDINGFORCHECKERAKUBYTOKEN);
	}

	rejectLetterGeneralPendingForCheckerAKUByToken(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral) ,WebAddressConstant.STRING_URL_LETTERGENERAL_REJECTLETTERGENERALPENDINGFORCHECKERAKUBYTOKEN);
	}

	rejectLetterGeneralPendingForSignerAKUByToken(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral) ,WebAddressConstant.STRING_URL_LETTERGENERAL_REJECTLETTERGENERALPENDINGFORSIGNERAKUBYTOKEN);
	}

	rejectLetterGeneralPaymentRequestByToken(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral) ,WebAddressConstant.STRING_URL_LETTERGENERAL_REJECTLETTERGENERALPAYMENTREQUESTBYTOKEN);
	}

	//#endregion


	//#region OTP

	sendOTPForSignerAKU(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral) ,WebAddressConstant.STRING_URL_LETTERGENERAL_SENDOTPFORSIGNERAKU);
	}

	verifyOTPForSignerAKU(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral) ,WebAddressConstant.STRING_URL_LETTERGENERAL_VERIFYOTPFORSIGNERAKU);
	}

	//#endregion


	//#region DOWNLOAD

	//#endregion
}

//#endregion
