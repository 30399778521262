//#region IMPORT

import { AccountChargeModel } from "./accountcharge.model";
import { BaseModel } from "./bases/base.model";
import { CurrencyModel } from "./currency.model";
import { SubmissionDetailModel } from "./submissiondetail.model";

//#endregion


//#region CLASS

export class SubmissionVideModel extends BaseModel
{
	ID?: number;
	SubmissionDetailID?: number;
	AccountChargeID?: number;
	Name?: string;
	Description?: string;
	Amount?: number;
	CurrencyID?: number;
	CurrencyCode?: string;
	modelSubmissionDetail?: SubmissionDetailModel;
	modelAccountCharge?: AccountChargeModel;
	modelCurrency?: CurrencyModel;
}

//#endregion