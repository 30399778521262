//#region IMPORT

import { ENUM_PHOTO_TYPE, ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { BaseModel } from "./bases/base.model";
import { DentalReimbursementModel } from "./dentalreimbursement.model";
import { ResponseModel } from "./response.model";

//#endregion


//#region CLASS

export class DentalReimbursementGalleryModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for dental reimbursement gallery.
		Author: Andryana Baros.
		Created on : Friday, 05 November 2021. 				Updated on : -.
		Created by : Andryana Baros.							Updated by : -.
		Version : 1.0:2.
	*/

	ID?: number;
	Tokens?: string;
	DentalReimbursementID?: number;
	DentalReimbursementToken?: string;
	Name?: string;
	URL?: string;
	Type?: ENUM_PHOTO_TYPE;
	Order?: number;
	Width?: number;
	Height?: number;
	Format?: string;
	Note?: string;
	modelDentalReimbursement?: DentalReimbursementModel;

	Downloaded?: boolean;

	/* Attribute - END */


	constructor()
	{
		super();
	}


	//#region  VALIDATION

	validateCheck(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Dental document");

		if (this.Downloaded == null || this.Downloaded === undefined || this.Downloaded === false)
		{
			modelResponse.MessageContent = "Harap periksa file attachment terlebih dahulu sebelum melakukan persetujuan.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		return modelResponse;
	}


	validateDownload(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Dental	 document");

		if (this.URL == null || this.URL === undefined && this.URL === "")
		{
			modelResponse.MessageContent = "URL document tidak ditemukan.";
		}
		else if (this.Name == null || this.Name === undefined || this.Name === "")
		{
			modelResponse.MessageContent = "Nama document tidak ditemukan.";
		}
		else if (this.Extension == null || this.Extension === undefined || this.Extension === "")
		{
			modelResponse.MessageContent = "Nama extension tidak ditemukan.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	//#endregion
}

//#endregion