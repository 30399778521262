//#region IMPORT

import { ENUM_CHILD_NUMBER, ENUM_GENDER_TYPE, ENUM_RESPONSE_STATE, ENUM_MATERNITY_TYPE } from "../constants/enum.constant";
import { BaseModel } from "./bases/base.model";
import { InpatientRoomPlafondModel } from "./inpatientroomplafond.model";
import { MaternityReimbursementGalleryModel } from "./maternityreimbursementgallery.model";
import { ReimbursementGeneralModel } from "./reimbursementgeneral.model";
import { ResponseModel } from "./response.model";

//#endregion


//#region CLASS

export class MaternityReimbursementModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for maternity reimbursement.
		Author: Andryana Baros.
		Created on : Friday, 29 November 2021. 					Updated on : -.
		Created by : Andryana Baros.							Updated by : -.
		Version : 1.1:2.
	*/

	ID?: number;
	ReimbursementGeneralID?: number;
	Token?: string;
	InpatientRoomPlafondID?: number;
	InpatientDuration?: number;
	MaternityNumber?: ENUM_CHILD_NUMBER;
	ChildBirthDate?: Date;
	ChildGender?: ENUM_GENDER_TYPE;
	MaternityType?: ENUM_MATERNITY_TYPE;
	ReceiptDate?: Date;
	ReceiptInpatientRoomCost?: number;
	CoveredInpatientRoomCost?: number;
	ReceiptTreatmentCost?: number;
	CoveredTreatmentCost?: number;
	modelReimbursementGeneral?: ReimbursementGeneralModel;
	modelInpatientRoomPlafond?: InpatientRoomPlafondModel;
	listModelMaternityReimbursementGallery?: Array<MaternityReimbursementGalleryModel>;

	/* Attribute - END */


	constructor()
	{
		super();
		this.listModelMaternityReimbursementGallery = [];
	}


	//#region  VALIDATION

	validateAddModelMaternityReimbursementDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Maternity Reimbursement");

		if(this.listModelMaternityReimbursementGallery == null || this.listModelMaternityReimbursementGallery === undefined)
		{
			modelResponse.MessageContent = "Terjadi kesalahan pada daftar attachment.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}


	validateCheckListModelMaternityReimbursementDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Maternity Reimbursement");

		if(this.listModelMaternityReimbursementGallery == null || this.listModelMaternityReimbursementGallery === undefined)
		{
			modelResponse.MessageContent = "Terjadi kesalahan pada daftar attachment.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}



	validateCheckModelMaternityReimbursementDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = this.validateCheckListModelMaternityReimbursementDocument();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			let modelResponseMaternityReimbursementDocument: ResponseModel;
			let modelMaternityReimbursementDocumentCheck: MaternityReimbursementGalleryModel;

			for (const modelMaternityReimbursementDocument of this.listModelMaternityReimbursementGallery ?? [])
			{
				modelMaternityReimbursementDocumentCheck = new MaternityReimbursementGalleryModel();
				modelMaternityReimbursementDocumentCheck.setModelFromObject(modelMaternityReimbursementDocument);
				modelResponseMaternityReimbursementDocument = modelMaternityReimbursementDocumentCheck.validateCheck();

				if (modelResponseMaternityReimbursementDocument.State === ENUM_RESPONSE_STATE.Fail)
				{
					return modelResponseMaternityReimbursementDocument;
				}
				else
				{

				}
			}

			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{

		}

		return modelResponse;
	}

	//#endregion
}

//#endregion