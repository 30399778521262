//#region IMPORT

import { ENUM_CHILD_NUMBER, ENUM_RESPONSE_STATE, ENUM_USG_TYPE } from "../constants/enum.constant";
import { BaseModel } from "./bases/base.model";
import { PreMaternityReimbursementGalleryModel } from "./prematernityreimbursementgallery.model";
import { ReimbursementGeneralModel } from "./reimbursementgeneral.model";
import { ResponseModel } from "./response.model";

//#endregion


//#region CLASS

export class PreMaternityReimbursementModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for pre maternity reimbursement.
		Author: Andryana Baros.
		Created on : Wednesday, 17 November 2021. 				Updated on : -.
		Created by : Andryana Baros.							Updated by : -.
		Version : 1.0:2.
	*/

	ID?: number;
	ReimbursementGeneralID?: number;
	MaternityNumber?: ENUM_CHILD_NUMBER;
	USGType?: ENUM_USG_TYPE;
	ReceiptDate?: Date;
	TreatmentCost?: number;
	modelReimbursementGeneral?: ReimbursementGeneralModel;
	listModelPreMaternityReimbursementGallery?: Array<PreMaternityReimbursementGalleryModel>;

	/* Attribute - END */


	//#region  VALIDATION

	validateAddModelPreMaternityReimbursementDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("PreMaternity Reimbursement");

		if(this.listModelPreMaternityReimbursementGallery == null || this.listModelPreMaternityReimbursementGallery === undefined)
		{
			modelResponse.MessageContent = "Terjadi kesalahan pada daftar attachment.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}


	validateCheckListModelPreMaternityReimbursementDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Dental Reimbursement");

		if(this.listModelPreMaternityReimbursementGallery == null || this.listModelPreMaternityReimbursementGallery === undefined)
		{
			modelResponse.MessageContent = "Terjadi kesalahan pada daftar attachment.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		return modelResponse;
	}


	validateCheckModelPreMaternityReimbursementDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = this.validateCheckListModelPreMaternityReimbursementDocument();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			let modelResponsePreMaternityReimbursementDocument: ResponseModel;
			let modelPreMaternityReimbursementDocumentCheck: PreMaternityReimbursementGalleryModel;

			for (const modelPreMaternityReimbursementDocument of this.listModelPreMaternityReimbursementGallery ?? [])
			{
				modelPreMaternityReimbursementDocumentCheck = new PreMaternityReimbursementGalleryModel();
				modelPreMaternityReimbursementDocumentCheck.setModelFromObject(modelPreMaternityReimbursementDocument);
				modelResponsePreMaternityReimbursementDocument = modelPreMaternityReimbursementDocumentCheck.validateCheck();

				if (modelResponsePreMaternityReimbursementDocument.State === ENUM_RESPONSE_STATE.Fail)
				{
					return modelResponsePreMaternityReimbursementDocument;
				}
				else
				{

				}
			}
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{

		}

		return modelResponse;
	}

	//#endregion
}

//#endregion