<div id="divBodySignIn" appCheckDevTools>
	<header id="headerSignIn">
		<h1>AKU</h1>

		<span id="spanSubTitle">Sistem Integrasi Akuntansi dan Keuangan</span>
	</header>

	<form>
		<div class="DivForm TextCenter">
			<h3>Lampiran anda akan di unduh sebentar lagi</h3>
		</div>
		<input type="button" value="Coba lagi" (click)="callDownload();">
		<input type="button" value="Kembali ke Halaman Utama" (click)="goToHome();">
	</form>
</div>