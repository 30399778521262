//#region IMPORT

import { ENUM_VOUCHER_TYPE } from "../constants/enum.constant";
import { BaseModel } from "./bases/base.model";
import { CurrencyModel } from "./currency.model";
import { LetterGeneralModel } from "./lettergeneral.model";

//#endregion


//#region CLASS

export class VoucherModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for package model, used for package information and link to product.
		Author: Andri Septiawan and Ibrahim Aziz.
		Created on : Tuesday, 29 December 2020. 			Updated on : Wednesday, 6 January 2021.
		Created by : Andri Septiawan.						Updated by : Ibrahim Aziz.
		Version : 1.0:1.
	*/

	ID?: number;
	LetterGeneralID?: number;
	PaymentNumber?: string;
	PaymentRequestNumber?: string;
	Voucher?: ENUM_VOUCHER_TYPE;
	BranchCode?: string;
	BranchName?: string;
	DivisionID?: number;
	DivisionCode?: string;
	DivisionName?: string;
	ReferenceNumber?: string;
	ReferenceDate?: Date;
	ReferenceType?: string;
	CurrencyID?: number;
	FirstCheckerBy?: number;
	FirstCheckerOn?: Date;
	FirstCheckerURL?: string;
	SecondCheckerBy?: number;
	SecondCheckerOn?: Date;
	SecondCheckerURL?: string;
	FirstSignerBy?: number;
	FirstSignerOn?: Date;
	FirstSignerURL?: string;
	SecondSignerBy?: number;
	SecondSignerOn?: Date;
	SecondSignerURL?: string;
	RejectedBy?: number;
	RejectedOn?: Date;
	RejectedNote?: string;

	/* Attribute - END */

	modelLetterGeneral?: LetterGeneralModel;
	modelCurrency?: CurrencyModel;
}

//#endregion