//#region IMPORT

import { Location } from "@angular/common";
import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ENUM_PAYMENT_TYPE, ENUM_RESPONSE_STATE } from "src/app/constants/enum.constant";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { LetterGeneralModel } from "src/app/models/lettergeneral.model";
import { ResponseModel } from "src/app/models/response.model";
import { DeclarationService } from "src/app/services/declaration.service";
import { SessionService } from "src/app/services/session.service";
import { BaseAuthourizeDetailComponent } from "../../bases/baseauthourizedetail.component";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-declarationverifier",
		templateUrl: "./declarationverifier.component.html",
		styleUrls: ["./declarationverifier.component.sass"]
	}
)

//#endregion


//#region CLASS

export class DeclarationverifierComponent extends BaseAuthourizeDetailComponent
{
	//#region DECLARATION

	public _modelLetterGeneral: LetterGeneralModel;
	public _enumPaymentType = ENUM_PAYMENT_TYPE;

	public _stringCheckerFullName: string = "";
	public _stringSignerFullName: string = "";
	public _stringFinalSignerFullName: string = "";

	//#endregion


	//#region CONSTRUCTOR

	constructor(routeActivated: ActivatedRoute, location: Location, private _serviceDeclaration: DeclarationService, serviceSession: SessionService, public router: Router)
	{
		super(routeActivated, location, serviceSession, router);
		this._modelLetterGeneral = new LetterGeneralModel();
		this._modelLetterGeneral.modelSubmission = undefined;
	}

	//#endregion


	//#region INITIALIZE

	ngOnInit(): void
	{
		const stringToken: string | null = this.getParameterFromRouter();

		if (stringToken != null)
		{
			this._modelLetterGeneral.Token = stringToken;
			this.callSelectDeclarationQRByToken();
		}
		else
		{

		}
	}

	//#endregion


	//#region WEB SERVICE

	callSelectDeclarationQRByToken(): void
	{
		const componentCurrent: DeclarationverifierComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(3);
		this._modelLetterGeneral.clearDeclarationForInsert();
		componentCurrent._functionUserInterface.updateLoadingProgress();
		const modelResponseRequest: ResponseModel = this._modelLetterGeneral.validateTokenForUpdate();

		if (modelResponseRequest.State === ENUM_RESPONSE_STATE.Success)
		{
			this._serviceDeclaration.selectDeclarationQRByToken
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						componentCurrent._functionUserInterface.updateLoadingProgress();

						if (modelResponse.Data !== undefined)
						{
							componentCurrent._modelLetterGeneral.setModelFromString(modelResponse.Data);
							componentCurrent._modelLetterGeneral.setModelUserFirstChecker();
							componentCurrent._modelLetterGeneral.setModelUserFirstSigner();
							componentCurrent._modelLetterGeneral.setModelUserSecondSigner();

							if (componentCurrent._modelLetterGeneral.modelUserFirstChecker === undefined || componentCurrent._modelLetterGeneral.modelUserFirstSigner === undefined || componentCurrent._modelLetterGeneral.modelUserSecondSigner === undefined)
							{

							}
							else
							{
								componentCurrent._stringCheckerFullName = componentCurrent._modelLetterGeneral.modelUserFirstChecker.getName();
								componentCurrent._stringSignerFullName = componentCurrent._modelLetterGeneral.modelUserFirstSigner.getName();
								componentCurrent._stringFinalSignerFullName = componentCurrent._modelLetterGeneral.modelUserSecondSigner.getName();
							}

							componentCurrent._functionUserInterface.updateLoadingProgress();
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectDeclarationQRByToken(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelLetterGeneral);
		}
		else
		{
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseRequest, () => { });
		}
	};

	//#endregion
}

//#endregion