//#region IMPORT

import { Component } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { SessionService } from "src/app/services/session.service";
import { UserService } from "src/app/services/user.service";
import { UserModel } from "src/app/models/user.model";
import { ResponseModel } from "src/app/models/response.model";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { AccountingAndFinanceModel } from "src/app/models/accountingandmodel.model";
import { LogisticModel } from "src/app/models/logistic.model";
import { BaseAuthourizeDetailComponent } from "../bases/baseauthourizedetail.component";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-userverifier",
		templateUrl: "./userverifier.component.html",
		styleUrls: ["./userverifier.component.sass"]
	}
)

//#endregion


//#region CLASS

export class UserverifierComponent extends BaseAuthourizeDetailComponent
{
	//#region DECLARATION

	public _modelUserRequest: UserModel;
	public _modelUserResult: UserModel;


	//#endregion


	//#region CONSTRUCTOR

	constructor(routeActivated: ActivatedRoute, location: Location, private _serviceUser: UserService, serviceSession: SessionService, public router: Router)
	{
		super(routeActivated, location, serviceSession, router);
		this._modelUserRequest = new UserModel();
		this._modelUserResult = new UserModel();
	}

	//#endregion


	//#region INITIALIZE

	ngOnInit(): void
	{
		const stringToken: string | null = this.getParameterFromRouter();
		this.getParameterTokenAndStringCategoryFromURLParameter();
		const stringUserCategory: string = this._stringCategory;

		if (stringToken != null)
		{
			this._modelUserRequest.Token = stringToken;

			if (stringUserCategory.includes("LOGISTIC"))
			{
				this.callSelectUserLogisticQRByToken();
			}
			else if (stringUserCategory.includes("AKU"))
			{
				this.callSelectUserAccountingAndFinanceQRByToken();
			}
		}
		else
		{

		}
	}

	//#endregion


	//#region WEB SERVICE

	callSelectUserAccountingAndFinanceQRByToken(): void
	{
		const componentCurrent: UserverifierComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		this._serviceUser.selectUserAccountingAndFinanceByQuickResponse
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelUserResult.setModelFromString(modelResponse.Data);
						componentCurrent._modelUserResult.modelAccountingAndFinance = new AccountingAndFinanceModel();
						componentCurrent._modelUserResult.modelAccountingAndFinance.setModelPosition();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
					componentCurrent._functionUserInterface.updateLoadingProgress();
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectUserAccountingAndFinanceQRByToken(); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, this._modelUserRequest);
	};

	callSelectUserLogisticQRByToken(): void
	{
		const componentCurrent: UserverifierComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		this._serviceUser.selectUserLogisticByQuickResponse
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelUserResult.setModelFromString(modelResponse.Data);
						componentCurrent._modelUserResult.modelLogistic = new LogisticModel();
						componentCurrent._modelUserResult.modelLogistic.setModelPosition();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
					componentCurrent._functionUserInterface.updateLoadingProgress();
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectUserLogisticQRByToken(); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, this._modelUserRequest);
	};

	//#endregion

}

//#endregion
