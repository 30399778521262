//#region IMPORT

import { ENUM_BRANCH_TYPE } from "../constants/enum.constant";
import { BaseModel } from "./bases/base.model";

//#endregion


//#region CLASS

export class BranchModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for coverage model, used for package information and link to product.
		Author: Andri Septiawan and Ibrahim Aziz.
		Created on : Tuesday, 29 December 2020. 			Updated on : Wednesday, 6 January 2021.
		Created by : Andri Septiawan.						Updated by : Ibrahim Aziz.
		Version : 1.0:2.
	*/

	ID?: number;
	Code?: string;
	Name?: string;
	Token?: string;
	Longitude?: any;
	Latitude?: any;
	Distance?: number;
	Type?: ENUM_BRANCH_TYPE;


	/* Attribute - END */
}

//#endregion