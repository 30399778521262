<div id="divBodyVerifierSubmission" class="DivVerifierGeneral">
	<header class="HeaderGeneral">
		<h2>Submission Information</h2>
		<p>Display and verify submission letter that has been made.</p>
	</header>

	<img id="imageLogoHeader" src="../../assets/logos/sakina/logo_briinsurance_header.svg" alt="BRI Insurance - Logo Header">

	<div class="DivMainAsideSearch">
		<form>
			<div class="DivForm">
				<h3>Letter General</h3>

				<fieldset>
					<label for="spanSubmissionNumber">Submission Number</label>
					<span>{{ _modelLetterGeneral.modelSubmission!.SubmissionNumber | convertEmptyToDash}}</span>

					<label for="spanLetterType">Letter Type</label>
					<span>{{ _modelLetterGeneral.getLetterTypeName() | convertEmptyToDash}}</span>

					<label for="spanPaymentPriority">Payment Priority</label>
					<span>{{ _modelLetterGeneral.getPaymentPriorityName() }}</span>

					<label for="spanPaymentType">Payment Type</label>
					<span>{{ _enumPaymentType[_modelLetterGeneral.PaymentType!] | convertEmptyToDash }}</span>

					<label for="spanBudgetType">Budget Type</label>
					<span>{{ _modelLetterGeneral.BudgetType | convertEmptyToDash }}</span>

					<label for="spanBudgetYear">Budget Year</label>
					<span>{{ _modelLetterGeneral.BudgetYear | convertEmptyToDash }}</span>

					<label for="spanProposedName">Branch Name Responsibility</label>
					<span>{{ _modelLetterGeneral.modelSubmission!.ProposedName | convertEmptyToDash }}</span>
				</fieldset>
			</div>
		</form>

		<div class="DivDynamicContainer">
			<form>
				<div  class="DivSubDynamicContainer"  *ngFor="let modelSubmissionDetail of _modelLetterGeneral.modelSubmission!.listModelSubmissionDetail; let numberIndex = index">
					<div class="DivForm">
						<h3>Transaction - {{ numberIndex + 1 }} </h3>

						<fieldset>
							<label for="spanDescription">Transaction Description</label>
							<p>{{ modelSubmissionDetail.Description | convertEmptyToDash}}</p>

							<div class="DivFormHorizontalContainer">
								<div class="DivForm2Column">
									<label for="spanBeneficiaryName">Penerima</label>
									<span *ngIf="_modelLetterGeneral.PaymentType === _enumPaymentType.Transfer">{{ modelSubmissionDetail.modelBankAccount?.BeneficiaryName | convertEmptyToDash }}</span>
									<span *ngIf="_modelLetterGeneral.PaymentType === _enumPaymentType.Cash">{{ modelSubmissionDetail.BeneficiaryName | convertEmptyToDash }}</span>

									<label for="spanAccountNumber">Nomor Rekening</label>
									<span>{{ modelSubmissionDetail.modelBankAccount?.AccountNumber | convertEmptyToDash }}</span>
								</div>

								<div class="DivForm2Column">
									<label for="spanBankName">Nama Bank</label>
									<span>{{ modelSubmissionDetail.modelBankAccount?.BankName | convertEmptyToDash }}</span>
								</div>
							</div>
						</fieldset>
					</div>

					<div class="DivTableContainer">
						<table>
							<thead>
								<tr>
									<th>Keterangan</th>
									<th>Jumlah</th>
									<th>Nomor dan Nama Akun</th>
								</tr>
							</thead>
			
							<tbody>
								<tr *ngFor="let modelSubmissionVide of modelSubmissionDetail.listModelSubmissionVide">
									<td>{{ modelSubmissionVide.Name | convertEmptyToDash }}</td>
									<td>{{ modelSubmissionVide.Amount | currencyValue | convertEmptyToDash}}</td>
									<td>{{ modelSubmissionVide.modelAccountCharge?.Code | convertEmptyToDash }} | {{ modelSubmissionVide.modelAccountCharge?.Name | convertEmptyToDash }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div class="DivForm">
					<h3>Approver</h3>

					<fieldset>
						<div class="DivFormHorizontalContainer">
							<div class="DivForm2Column">
								<label for="spanModelUserFirstChecker">Checker</label>
								<span>{{ _stringCheckerFullName }}</span>
							</div>

							<div class="DivForm2Column">
								<label for="spanModelUserFirstSigner">Signer</label>
								<span>{{ _stringSignerFullName }}</span>

								<label for="spanModelUserSecondSigner">Final Signer</label>
								<span>{{ _stringFinalSignerFullName }}</span>
							</div>
						</div>
					</fieldset>
				</div>
			</form>
		</div>
	</div>
</div>