<div id="divBodyVerifierUser" class="DivVerifierGeneral">
	<header class="HeaderGeneral">
		<h2>User Information</h2>
		<p>Displaying associated user information.</p>
	</header>

	<img id="imageLogoHeader" src="../../assets/logos/sakina/logo_briinsurance_header.svg" alt="BRI Insurance - Logo Header">

	<div id="divMainDashboard">
		<div class="DivContainer">
			<form>
				<div class="DivForm">
					<label for="spanFirstName">First Name</label>
					<span>{{ _modelUserResult.FirstName | convertEmptyToDash }}</span>

					<label for="spanMiddleName">Middle Name</label>
					<span>{{ _modelUserResult.MiddleName | convertEmptyToDash }}</span>

					<label for="spanLastName">Last Name</label>
					<span>{{ _modelUserResult.LastName | convertEmptyToDash }}</span>

					<label for="spanPosition">Position Name</label>
					<span>{{ _modelUserResult.PositionName | convertEmptyToDash }}</span>
				</div>
			</form>
		</div>
	</div>
</div>