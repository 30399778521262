//#region IMPORT

import { AccountChargeModel } from "./accountcharge.model";
import { BaseModel } from "./bases/base.model";
import { CurrencyModel } from "./currency.model";
import { DeclarationDetailModel } from "./declarationdetail.model";

//#endregion


//#region CLASS

export class DeclarationVideModel extends BaseModel
{
	ID?: number;
	DeclarationDetailID?: number;
	AccountChargeID?: number;
	Name?: string;
	Description?: string;
	Amount?: number;
	RealizationAmount?: number;
	CurrencyID?: number;
	CurrencyCode?: string;
	modelDeclarationDetail?: DeclarationDetailModel;
	modelAccountCharge?: AccountChargeModel;
	modelCurrency?: CurrencyModel;
}

//#endregion