//#region IMPORT

import { ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { LetterGeneralModel } from "./lettergeneral.model";
import { SubmissionDocumentModel } from "./submissiomdocument.model";
import { SubmissionDetailModel } from "./submissiondetail.model";
import { BaseModel } from "./bases/base.model";
import { ResponseModel } from "./response.model";

//#endregion


//#region CLASS

export class SubmissionModel extends BaseModel
{
	ID?: number;
	LetterGeneralID?: number;
	SubmissionNumber?: string;
	ProposedName?: string;
	modelLetterGeneral?: LetterGeneralModel;
	listModelSubmissionDetail?: Array<SubmissionDetailModel>;
	listModelSubmissionDocument?: Array<SubmissionDocumentModel>;

	constructor()
	{
		super();
		this.listModelSubmissionDetail = [];
		this.listModelSubmissionDocument = [];
	}


	//#region  SETTER

	setListModelSubmissionDetail(): void
	{
		if (this.listModelSubmissionDetail == null || this.listModelSubmissionDetail === undefined)
		{

		}
		else
		{
			if (this.listModelSubmissionDetail.length > 0)
			{
				const arrayModelSubmissionDetailTemporary: Array<SubmissionDetailModel> = this.listModelSubmissionDetail;
				this.listModelSubmissionDetail = [];

				let modelSubmissionDetail: SubmissionDetailModel;

				for (const modelSubmissionDetailTemporary of arrayModelSubmissionDetailTemporary)
				{
					modelSubmissionDetail = new SubmissionDetailModel();
					modelSubmissionDetail.setModelFromObject(modelSubmissionDetailTemporary);
					modelSubmissionDetail.setListModelSubmissionVide();
					this.listModelSubmissionDetail.push(modelSubmissionDetail);
				}
			}
			else
			{

			}
		}
	}

	//#endregion


	//#region VALIDATE

	validateCheckListModelSubmissionDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Submission");

		if(this.listModelSubmissionDocument == null || this.listModelSubmissionDocument === undefined)
		{
			modelResponse.MessageContent = "Terjadi kesalahan pada daftar attachment.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateCheckModelSubmissionDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = this.validateCheckListModelSubmissionDocument();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			let modelResponseSubmissionDocument: ResponseModel;
			let modelSubmissionDocumentCheck: SubmissionDocumentModel;

			for (const modelSubmissionDocument of this.listModelSubmissionDocument ?? [])
			{
				modelSubmissionDocumentCheck = new SubmissionDocumentModel();
				modelSubmissionDocumentCheck.setModelFromObject(modelSubmissionDocument);
				modelResponseSubmissionDocument = modelSubmissionDocumentCheck.validateCheck();

				if (modelResponseSubmissionDocument.State === ENUM_RESPONSE_STATE.Fail)
				{
					return modelResponseSubmissionDocument;
				}
				else
				{

				}
			}

			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{

		}

		return modelResponse;
	}

	validateAddModelSubmissionDetail(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Submission");

		if(this.listModelSubmissionDetail == null || this.listModelSubmissionDetail === undefined)
		{
			modelResponse.MessageContent = "Daftar transaksi tidak boleh kosong.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateAddModelSubmissionDocument(arrayFile: Array<File>): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Submission");

		if(arrayFile == null || arrayFile === undefined)
		{
			modelResponse.MessageContent = "Terjadi kesalahan pada daftar attachment.";
		}
		else
		{
			for (let numberIndexRow = 0; numberIndexRow < arrayFile.length; numberIndexRow++)
			{
				for (let numberIndexColumn = 0; numberIndexColumn < arrayFile.length; numberIndexColumn++)
				{
					if (numberIndexRow !== numberIndexColumn)
					{
						if (arrayFile[numberIndexRow].name === arrayFile[numberIndexColumn].name)
						{
							modelResponse.MessageContent = "Nama lampiran yang dipilih antara satu dengan yang lainnya memiliki kesamaan! Silahkan pilih kembali!";
							return modelResponse;
						}
						else
						{

						}
					}
					else
					{

					}
				}
			}

			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;

			for (const file of arrayFile)
			{
				if (this.listModelSubmissionDocument == null || this.listModelSubmissionDocument === undefined)
				{

				}
				else
				{
					for (const modelSubmissionDocumentSelected of this.listModelSubmissionDocument)
					{
						if (file.name === (modelSubmissionDocumentSelected.Name ?? "") + "." + (modelSubmissionDocumentSelected.Extension ?? ""))
						{
							modelResponse.MessageContent = "Nama lampiran yang dipilih antara yang sudah ada dan yang di upload memiliki kesamaan! Silahkan pilih kembali!";
							return modelResponse;
						}
						else
						{

						}
					}
				}
			}
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{

		}

		return modelResponse;
	}

	validateCheckBankAccountVerification(): ResponseModel
	{
		const modelResponse: ResponseModel = this.validateAddModelSubmissionDetail();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			let modelResponseSubmissionDetail: ResponseModel;
			let modelSubmissionDetailCheck: SubmissionDetailModel;

			for (const modelSubmissionDetail of this.listModelSubmissionDetail ?? [])
			{
				modelSubmissionDetailCheck = new SubmissionDetailModel();
				modelSubmissionDetailCheck.setModelFromObject(modelSubmissionDetail);
				modelResponseSubmissionDetail = modelSubmissionDetailCheck.validateCheck();

				if (modelResponseSubmissionDetail.State === ENUM_RESPONSE_STATE.Fail)
				{
					return modelResponseSubmissionDetail;
				}
				else
				{
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
			}
		}
		else
		{

		}

		return modelResponse;
	}

	//#endregion


	//#region CLEAN

	cleanFootprintForInsert(): void
	{
		this.SubmissionNumber = undefined;
		this.LetterGeneralID = undefined;
		this.listModelSubmissionDocument = undefined;
		this.cleanAllFootprint();
	}

	clearForValidateBankAccount(): void
	{
		this.cleanFootprintForInsert();
		this.ID = undefined;

		if(this.listModelSubmissionDetail !== undefined)
		{
			for(const modelSubmissionDetail of this.listModelSubmissionDetail)
			{
				modelSubmissionDetail.CreatedBy = undefined;
				modelSubmissionDetail.CreatedOn = undefined;
				modelSubmissionDetail.UpdatedBy = undefined;
				modelSubmissionDetail.UpdatedOn = undefined;
				modelSubmissionDetail.Amount = undefined;
				modelSubmissionDetail.BankAccountToken = undefined;
				modelSubmissionDetail.SubmissionID = undefined;
				modelSubmissionDetail.Description = undefined;
				modelSubmissionDetail.ID = undefined;
				modelSubmissionDetail.Title = undefined;
				modelSubmissionDetail.TotalAmount = undefined;
				modelSubmissionDetail.TotalAmountWithCost = undefined;
				modelSubmissionDetail.TotalCost = undefined;
				modelSubmissionDetail.listModelSubmissionCost = undefined;
				modelSubmissionDetail.listModelSubmissionVide = undefined;
				modelSubmissionDetail.modelBankAccount = undefined;
				modelSubmissionDetail.modelSubmission = undefined;
				modelSubmissionDetail.modelSubmissionCostInsert = undefined;
			}
		}
	}

	//#endregion

}

//#endregion