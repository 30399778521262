//#region IMPORT

import { Injectable } from "@angular/core";
import { Router, CanActivate } from "@angular/router";
import "../../functions/extension.function";
import { Observable, of } from "rxjs";
import { ResponseModel } from "src/app/models/response.model";
import { UserSessionModel } from "src/app/models/usersession.model";
import { SessionService } from "../session.service";
import { HandshakeService } from "../handshake.service";
import { BaseauthorizedGuard } from "./baseauthorized.guard";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class VerifyauthorizedGuard extends BaseauthorizedGuard implements CanActivate
{
	//#region DECLARATION

	public _stringUserToken: string = "";
	public _stringVerifyCategory: string = "";
	public _arrayStringURL: Array<string> = [];

	//#endregion


	//#region CONSTRUCTOR

	constructor(protected _serviceHandshake: HandshakeService, protected _router: Router, protected _serviceSession: SessionService)
	{
		super(_serviceHandshake, _router, _serviceSession);
		this._stringUserToken = this.getTokenFromURLParameter();
	}

	//#endregion


	//#region CAN ACTIVATE

	canActivate(): Observable<boolean>
	{
		try
		{
			let booleanSignIn: boolean = false;

			if (this.validateModelHandshake(this._modelHandshakeSignIn))
			{
				booleanSignIn = true;
			}
			else
			{
				this._modelHandshakeSignIn = this._serviceSession.getModelHandshakeSignInFromLocalStorage();

				if (this.validateModelHandshake(this._modelHandshakeSignIn))
				{
					if (this._modelHandshakeSignIn != null)
					{
						this._serviceSession.setModelHandshakeSignInToLocalStorage(this._modelHandshakeSignIn);

						booleanSignIn = true;
					}
					else
					{

					}
				}
				else
				{

				}
			}

			if (booleanSignIn)
			{
				const modelUserSession: UserSessionModel | null = this._serviceSession.getUserSession();
				if (modelUserSession != null)
				{
					if (modelUserSession.UserToken != null || modelUserSession.UserToken === undefined || modelUserSession.UserToken === "" )
					{
						return this.callRefreshTokenForAccountingAndFinance();
					}
					else
					{
						this.signOut(this._stringVerifyCategory);
						return of(false);
					}
				}
				else
				{
					this.signOut(this._stringVerifyCategory);
					return of(false);
				}
			}
			else
			{
				const modelResponse: ResponseModel = new ResponseModel();
				modelResponse.setSessionExpired();

				this._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { this.signOut(this._stringVerifyCategory); });

				return of(false);
			}
		}
		catch (error: any)
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.setSessionExpired();
			modelResponse.MessageContent = error.error;

			this._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { this.signOut(this._stringVerifyCategory); });

			return of(false);
		}
	}

	//#endregion


	//#region FUNCTION

	signOut(stringURL: string): void
	{
		this.clearSession();
		this._router.navigate(["signin", "verify", stringURL, this._stringUserToken], { queryParamsHandling: "preserve" });
	}

	getTokenFromURLParameter(): string
	{
		const stringURL: string = window.location.pathname;
		this._arrayStringURL = stringURL.split("/");
		const stringToken: string = this._arrayStringURL[(this._arrayStringURL.length - 1)];
		this._stringVerifyCategory = this._arrayStringURL[(this._arrayStringURL.length - 2)];
		return stringToken;
	}

	//#endregion

}
