//#region IMPORT

import { ENUM_CHILD_NUMBER, ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { BaseModel } from "./bases/base.model";
import { PostMaternityReimbursementGalleryModel } from "./postmaternityreimbursementgallerymodel";
import { ReimbursementGeneralModel } from "./reimbursementgeneral.model";
import { ResponseModel } from "./response.model";

//#endregion


//#region CLASS

export class PostMaternityReimbursementModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for post maternity reimbursement.
		Author: Andryana Baros.
		Created on : Wednesday, 24 November 2021. 				Updated on : -.
		Created by : Andryana Baros.							Updated by : -.
		Version : 1.1:2.
	*/

	ID?: number;
	ReimbursementGeneralID?: number;
	Token?: string;
	MaternityNumber?: ENUM_CHILD_NUMBER;
	ReceiptDate?: Date;
	TreatmentCost?: number;
	ChildBirthDate?: Date;
	modelReimbursementGeneral?: ReimbursementGeneralModel;
	listModelPostMaternityReimbursementGallery?: Array<PostMaternityReimbursementGalleryModel>;

	/* Attribute - END */


	//#region  VALIDATION

	validateAddModelPostMaternityReimbursementDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("PostMaternity Reimbursement");

		if(this.listModelPostMaternityReimbursementGallery == null || this.listModelPostMaternityReimbursementGallery === undefined)
		{
			modelResponse.MessageContent = "Terjadi kesalahan pada daftar attachment.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}


	validateCheckListModelPostMaternityReimbursementDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Dental Reimbursement");

		if(this.listModelPostMaternityReimbursementGallery == null || this.listModelPostMaternityReimbursementGallery === undefined)
		{
			modelResponse.MessageContent = "Terjadi kesalahan pada daftar attachment.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		return modelResponse;
	}


	validateCheckModelPostMaternityReimbursementDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = this.validateCheckListModelPostMaternityReimbursementDocument();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			let modelResponsePostMaternityReimbursementDocument: ResponseModel;
			let modelPostMaternityReimbursementDocumentCheck: PostMaternityReimbursementGalleryModel;

			for (const modelPostMaternityReimbursementDocument of this.listModelPostMaternityReimbursementGallery ?? [])
			{
				modelPostMaternityReimbursementDocumentCheck = new PostMaternityReimbursementGalleryModel();
				modelPostMaternityReimbursementDocumentCheck.setModelFromObject(modelPostMaternityReimbursementDocument);
				modelResponsePostMaternityReimbursementDocument = modelPostMaternityReimbursementDocumentCheck.validateCheck();

				if (modelResponsePostMaternityReimbursementDocument.State === ENUM_RESPONSE_STATE.Fail)
				{
					return modelResponsePostMaternityReimbursementDocument;
				}
				else
				{

				}
			}
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{

		}

		return modelResponse;
	}

	//#endregion
}

//#endregion