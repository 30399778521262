<div id="divBodyHome" appCheckDevTools>
	<nav [ngClass]="{ 'NavShow' : _booleanNavigation, 'NavHide' : !_booleanNavigation, 'NavNone' : _booleanNavigation === null }">
		<figure>
			<div>
				<img *ngIf="_modelAccountingAndFinanceSignIn.PhotoURL" id="imageProfilePicture">
				<span *ngIf="!_modelAccountingAndFinanceSignIn.PhotoURL" id="spanProfileNameInitial">{{ _modelUserSignIn.getNameInitial() }}</span>
			</div>

			<figcaption>
				<span id="spanProfileName">{{ _modelUserSignIn.getName() }}</span>
				<span id="spanProfilePosition">{{ _modelAccountingAndFinanceSignIn.getDivisionName() }}</span>
			</figcaption>

			<a class="Underline SignOut" (click)="callSignOut();">Sign out</a>
		</figure>

		<ul class="ListNavigation">
			<li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerTicket">Ticket</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerTicket" [checked]="this._booleanNavigationItem" [(ngModel)]="_booleanNavigationItem">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation">
						<a (click)="goToTicketReceived();">Received</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToTicketSent();">Create</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToTicketReport();">Report Ticket</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToTicketSatisfactionReport();">Report  Satisfaction Ticket</a>
					</li>
				</ul>
			</li>
			<li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerDashboard">Dashboard</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerDashboard" [checked]="this._booleanNavigationDashboard" [(ngModel)]="_booleanNavigationDashboard">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Maker) || _modelUserSignIn.checkLetterRole(_enumLetterRole.Checker) || _modelUserSignIn.checkLetterRole(_enumLetterRole.Signer)">
						<a (click)="goToDashboardEmaterai();">e-Materai</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToDashboardEbudgeting();">e-Budgeting</a>
					</li>
				</ul>
			</li>
			<li class="ListItemNavigation">
				<a (click)="goToDeclaration();">Declaration Document</a>
			</li>
			<li class="ListItemNavigation">
				<a (click)="goToSubmission();">Submission Document</a>
			</li>
			<li class="ListItemNavigation" *ngIf="(_modelUserSignIn.checkLetterRole(_enumLetterRole.Maker) && _modelUserSignIn.checkGroupAccessAKUEDeclaration(_stringConstant.STRING_ACCESS_USER_AKUEDECLARATIONMAKER)) || _modelUserSignIn.checkLetterRole(_enumLetterRole.Checker) || _modelUserSignIn.checkLetterRole(_enumLetterRole.Signer)">
				<label for="checkBoxNavigationTriggerPaymentSubmission">Payment Submission</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerPaymentSubmission" [checked]="this._booleanNavigationPaymentSubmission" [(ngModel)]="_booleanNavigationPaymentSubmission">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Maker) && _modelUserSignIn.checkGroupAccessAKUEDeclaration(_stringConstant.STRING_ACCESS_USER_AKUEDECLARATIONMAKER)">
						<a (click)="goToPaymentSubmission();">Approval</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Maker) || _modelUserSignIn.checkLetterRole(_enumLetterRole.Checker) || _modelUserSignIn.checkLetterRole(_enumLetterRole.Signer)">
						<a (click)="goToRejectedPaymentSubmission();">Rejected</a>
					</li>
				</ul>
			</li>
			<li class="ListItemNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Maker) || _modelUserSignIn.checkLetterRole(_enumLetterRole.Checker) || _modelUserSignIn.checkLetterRole(_enumLetterRole.Signer)">
				<label for="checkBoxNavigationTriggerPaymentRequest">Payment Request</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerPaymentRequest" [checked]="this._booleanNavigationPaymentRequest" [(ngModel)]="_booleanNavigationPaymentRequest">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Maker)">
						<a (click)="goToEmaterai();">e-Materai</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Maker) && _modelUserSignIn.checkGroupAccessAKUEDeclaration(_stringConstant.STRING_ACCESS_USER_AKUEDECLARATIONMAKER)">
						<a (click)="goToPaymentRequestMaker();">Maker</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Checker)">
						<a (click)="goToPaymentRequestChecker();">Checker</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Signer)">
						<a (click)="goToPaymentRequestSigner();">Signer</a>
					</li>
				</ul>
			</li>
			<!-- <li class="ListItemNavigation">
				<label for="checkBoxNavigationEmeterei">e-Materai</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationEmeterei" [checked]="this._booleanNavigationEmaterai" [(ngModel)]="_booleanNavigationEmaterai">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation">
						<a (click)="goToEmaterai();">Stamping</a>
					</li> 
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Maker)">
						<a (click)="goToEmateraiForMaker();">Maker</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Checker)">
						<a (click)="goToEmateraiForChecker();">Checker</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Signer)">
						<a (click)="goToEmateraiForSigner();">Signer</a>
					</li>
				</ul>
			</li> -->
			<!-- <li class="ListItemNavigation" *ngIf="_modelUserSignIn.checkGroupAccessEBudgetingRole(_stringConstant.STRING_ACCESS_USER_EBUDGETING_AFNMAKER) || _modelUserSignIn.checkGroupAccessEBudgetingRole(_stringConstant.STRING_ACCESS_USER_EBUDGETING_AFNCHECKER) || _modelUserSignIn.checkGroupAccessEBudgetingRole(_stringConstant.STRING_ACCESS_USER_EBUDGETING_AFNSIGNER)">
				<label for="checkBoxNavigationEbudgeting">e-Budgeting</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationEbudgeting" [checked]="this._booleanNavigationEbudgeting" [(ngModel)]="_booleanNavigationEbudgeting">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessEBudgetingRole(_stringConstant.STRING_ACCESS_USER_EBUDGETING_AFNMAKER)">
						<a (click)="goToEbudgetingMaster();">Master</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessEBudgetingRole(_stringConstant.STRING_ACCESS_USER_EBUDGETING_AFNCHECKER) || _modelUserSignIn.checkGroupAccessEBudgetingRole(_stringConstant.STRING_ACCESS_USER_EBUDGETING_AFNSIGNER)">
						<a (click)="goToEbudgetingApproval();">Approval Master Budget</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessEBudgetingRole(_stringConstant.STRING_ACCESS_USER_EBUDGETING_AFNCHECKER) || _modelUserSignIn.checkGroupAccessEBudgetingRole(_stringConstant.STRING_ACCESS_USER_EBUDGETING_AFNSIGNER)">
						<a (click)="goToApprovalEBudgetingKuotaCheckerSigner();">Approval Penambahan Kuota</a>
					</li>
				</ul>
			</li> -->
			<li class="ListItemNavigation">
				<label for="checkBoxNavigationReport">Report</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationReport" [checked]="this._booleanNavigationReport" [(ngModel)]="_booleanNavigationReport">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation">
						<a (click)="goToReportEmaterai();">e-Meterai</a>
					</li>
					<!-- <li class="ListItemSubNavigation">
						<a (click)="goToReportEbudgeting();">e-Budgeting</a>
					</li> -->
					<li class="ListItemSubNavigation">
						<a (click)="goToCashflow();">Cashflow Report</a>
					</li>
					<li class="ListItemSubNavigation">
						<div class="DivListItemSubNavigationTwoLine" (click)="goToReportMonitoringDigitalChannel();">
							<span>Monitoring Digital</span><br>
							<span>Channel</span>
						</div>
					</li>
				</ul>
			</li>
			<!-- <li class="ListItemNavigation" *ngIf="_modelUserSignIn.checkGroupAccessBrinsaveUser([_enumUserGroupID.BrinsaveWorkingUnitMaker, _enumUserGroupID.BrinsaveWorkingUnitChecker, _enumUserGroupID.BrinsaveWorkingUnitSigner])">
				<label for="checkBoxNavigationTriggerBRINSAVE">BRINSAVE</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerBRINSAVE" [checked]="this._booleanNavigationBRINSAVE" [(ngModel)]="_booleanNavigationBRINSAVE">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation">
						<a (click)="goToBrinsaveDashboard();">Dashboard</a>
					</li>
					<li class="ListItemSubNavigation">
						<label for="checkBoxNavigationTriggerManagement">Management</label>
						<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerManagement" [checked]="this._booleanNavigationManagement" [(ngModel)]="_booleanNavigationManagement">
						<ul class="ListSubNavigation">
							<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessBrinsaveUser([_enumUserGroupID.BrinsaveWorkingUnitMaker])">
								<a (click)="goToManagementUploadDocument();">Upload Dokumen</a>
							</li>
							<li class="ListItemSubNavigation">
								<a (click)="goToManagementAllocation();">Pengalokasian Arsip</a>
							</li>
							<li class="ListItemSubNavigation">
								<a (click)="goToManagementArchiveData();">Data Arsip</a>
							</li>
							<li class="ListItemSubNavigation">
								<a (click)="goToManagementArchiveLoan();">Pengambilan Arsip</a>
							</li>
							<li class="ListItemSubNavigation">
								<label for="checkBoxNavigationTriggerReallocation">Pemindahan</label>
								<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerReallocation" [checked]="this._booleanNavigationReallocation" [(ngModel)]="_booleanNavigationReallocation">
								<ul class="ListSubNavigation">
									<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkIsBranch()">
										<a (click)="goToReallocationArchiveShelf();">Rak</a>
									</li>
									<li class="ListItemSubNavigation">
										<a (click)="goToReallocationArchive();">Arsip</a>
									</li>
								</ul>
							</li>
						</ul>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToReportBrinsave();">Report</a>
					</li>
				</ul>
			</li> -->
			<!-- <li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerApuPPT">APU PPT</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerApuPPT" [checked]="this._booleanNavigationApuPPT" [(ngModel)]="_booleanNavigationApuPPT">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation">
						<a (click)="goToAPUPPTCustomer();">Customer Data</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToRiskBasedAssessment();">Risk Based Assessment (RBA)</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToTransaction();">Transaction</a>
					</li>
				</ul>
			</li> -->
			<ng-container *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Checker) || _modelUserSignIn.checkLetterRole(_enumLetterRole.Signer)">
				<li class="ListItemNavigation">
					<label for="checkBoxNavigationTriggerReimbursement">Reimbursement</label>
					<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerReimbursement" [checked]="this._booleanNavigationReimbursement" [(ngModel)]="_booleanNavigationReimbursement">
					<ul class="ListSubNavigation" style="font-size: 15px;">
						<li class="ListItemNavigation">
							<label for="checkBoxNavigationTriggerApproval">Approval</label>
							<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerApproval" [checked]="this._booleanNavigationApproval" [(ngModel)]="_booleanNavigationApproval">
							<ul class="ListSubNavigation">
								<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Checker)">
									<a (click)="goToReimbursementGeneralChecker();">Checker</a>
								</li>
								<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Signer)">
									<a (click)="goToReimbursementGeneralSigner();">Signer</a>
								</li>
							</ul>
						</li>
					</ul>
				</li>
			</ng-container>
			<li class="ListItemNavigation" *ngIf="_modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKCHAMPION) || _modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKAPPROVER)">
				<label for="checkBoxNavigationTriggerRcsa">RCSA</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerRcsa" [checked]="this._booleanNavigationItemRCSA" [(ngModel)]="_booleanNavigationItemRCSA">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKCHAMPION);">
						<a (click)="goToAssessment();">Assessment</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKAPPROVER);">
						<a (click)="goToApproval();">Approval</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKCHAMPION) || _modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKAPPROVER)">
						<a (click)="goToRCSAReport();">Report</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKCHAMPION) || _modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKAPPROVER)">
						<a (click)="goToRiskIssueRequest();">Risk Issue Request</a>
					</li>
				</ul>
			</li>
			<li class="ListItemNavigation" *ngIf="(_modelUserSignIn.BranchID === undefined && (_modelUserSignIn.checkGroupAccessComplienceAndRiskManagement(_stringConstant.STRING_ACCESS_USER_MANAGEMENTRISK) || _modelUserSignIn.checkGroupAccessComplienceAndRiskManagement(_stringConstant.STRING_ACCESS_USER_RISKCHAMPION) || _modelUserSignIn.checkGroupAccessComplienceAndRiskManagement(_stringConstant.STRING_ACCESS_USER_RISKAPPROVER))) || (_modelUserSignIn.BranchID === null && (_modelUserSignIn.checkGroupAccessComplienceAndRiskManagement(_stringConstant.STRING_ACCESS_USER_MANAGEMENTRISK) || _modelUserSignIn.checkGroupAccessComplienceAndRiskManagement(_stringConstant.STRING_ACCESS_USER_RISKCHAMPION) || _modelUserSignIn.checkGroupAccessComplienceAndRiskManagement(_stringConstant.STRING_ACCESS_USER_RISKAPPROVER)))">
				<label for="checkBoxNavigationTriggerRAS">RAS</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerRAS" [checked]="this._booleanNavigationItemRAS" [(ngModel)]="_booleanNavigationItemRAS">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessComplienceAndRiskManagement(_stringConstant.STRING_ACCESS_USER_RISKAPPROVER)">
						<a (click)="goToRASDashboard();">Dashboard</a>
					</li>
					<li class="ListItemSubNavigation"  *ngIf="_modelUserSignIn.checkGroupAccessComplienceAndRiskManagement(_stringConstant.STRING_ACCESS_USER_MANAGEMENTRISK) || _modelUserSignIn.checkGroupAccessComplienceAndRiskManagement(_stringConstant.STRING_ACCESS_USER_RISKCHAMPION) || _modelUserSignIn.checkGroupAccessComplienceAndRiskManagement(_stringConstant.STRING_ACCESS_USER_RISKAPPROVER)">
						<label for="checkBoxNavigationTriggerRealization">Realization</label>
						<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerRealization" [checked]="this._booleanNavigationRealization" [(ngModel)]="_booleanNavigationRealization">
						<ul class="ListSubNavigation">
							<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKCHAMPION);">
								<a (click)="goToRASRealization();">Adjustment</a>
							</li>
							<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKCHAMPION);">
								<a (click)="goToRASMonitoring();">Monitoring</a>
							</li>
							<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKAPPROVER);">
								<a (click)="goToRASRealizationApproval();">Approval</a>
							</li>
						</ul>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKAPPROVER) || _modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKCHAMPION);">
						<a (click)="goToRASFollowUpPlan();">Follow Up Plan</a>
					</li>
				</ul>
			</li>
			<li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerGCGOnline">GCG Online</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerGCGOnline" [checked]="this._booleanNavigationItemGCGOnline" [(ngModel)]="_booleanNavigationItemGCGOnline">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation" *ngIf="!_modelUserSignIn.checkGroupAccessGCGRole(_stringConstant.STRING_ACCESS_USER_GCGADMIN);">
						<a (click)="goToGCGOnlineProfile();">Profile</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToGCGOnlineGratification();">Gratification</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToGCGOnlineIntegrityPact();">Integrity Pact</a>
					</li>
					<li class="ListItemSubNavigation">
						<label for="checkBoxNavigationTriggerCodeOfEthics">Code of Ethics</label>
						<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerCodeOfEthics" [checked]="this._booleanNavigationCodeOfEthics" [(ngModel)]="_booleanNavigationCodeOfEthics">
						<ul class="ListSubNavigation">
							<li class="ListItemSubNavigation">
								<a (click)="goToGCGOnlineCodeOfEthics();">Submission</a>
							</li>
							<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessGCGRole(_stringConstant.STRING_ACCESS_USER_GCGCHECKER) || _modelUserSignIn.checkGroupAccessGCGRole(_stringConstant.STRING_ACCESS_USER_GCGSIGNER);">
								<a (click)="goToGCGOnlineCodeOfEthicsApproval();">Approval</a>
							</li>
						</ul>
					</li>
					<li class="ListItemSubNavigation">
						<label for="checkBoxNavigationTriggerAnnualDisclosure">Annual Disclosure</label>
						<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerAnnualDisclosure" [checked]="this._booleanNavigationAnnualDisclosure" [(ngModel)]="_booleanNavigationAnnualDisclosure">
						<ul class="ListSubNavigation">
							<li class="ListItemSubNavigation">
								<a (click)="goToGCGOnlineAnnualDisclosure();">Submission</a>
							</li>
							<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessGCGRole(_stringConstant.STRING_ACCESS_USER_GCGCHECKER) || _modelUserSignIn.checkGroupAccessGCGRole(_stringConstant.STRING_ACCESS_USER_GCGSIGNER);">
								<a (click)="goToGCGOnlineAnnualDisclosureApproval();">Approval</a>
							</li>
						</ul>
					</li>
				</ul>
			</li>
			<li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerLegalDocumentRequest">Legal Document Request</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerLegalDocumentRequest" [checked]="this._booleanNavigationItemDocumentRequest" [(ngModel)]="_booleanNavigationItemDocumentRequest">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation"*ngIf="!_modelUserSignIn.checkLetterRole(_enumLetterRole.Signer) && !_modelUserSignIn.checkLetterRole(_enumLetterRole.Checker)">
						<a (click)="goToDigitalizationMaker();">Maker</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Checker)">
						<a (click)="goToDigitalizationChecker();">Checker</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Signer)">
						<a (click)="goToDigitalizationSigner();">Signer</a>
					</li>
				</ul>
			</li>
			<li class="ListItemNavigation">
			<!-- <li class="ListItemNavigation" (click)="callSelectAnomalyNotificationCount(this)"> -->
                <label for="checkBoxNavigationTriggerPROTECT">PROTECT</label>
                <input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerPROTECT" [checked]="this._booleanNavigationPROTECT" [(ngModel)]="_booleanNavigationPROTECT">
                <ul class="ListSubNavigation">
                    <li class="ListItemSubNavigation">
                        <a (click)="goToAnomaly();">Anomaly</a>
                    </li>
					<!-- <li class="ListItemSubNavigation" [ngClass]="{'ListItemSubNavigationRelative' : _numberAnomalyNotification }">
						<a (click)="goToAnomalyNotification();"> Notification </a>
						<div *ngIf="_numberAnomalyNotification" class="DivNotificationAlert">
							<p>{{ _numberAnomalyNotification }}</p>
						</div>
					</li> -->
                </ul>
            </li>

			<li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerTransactioMonitoring">Transaction Monitoring</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerTransactioMonitoring" [checked]="_booleanNavigationTransactionMonitoring" [(ngModel)]="_booleanNavigationTransactionMonitoring">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation">
						<a (click)="goToPegadaianTransactionMonitoring();">Pegadaian</a>
					</li>
				</ul>
			</li>

			<!-- <li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerBrinspro">BRINSPRO</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerBrinspro" [checked]="_booleanNavigationBrinspro" [(ngModel)]="_booleanNavigationBrinspro">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation">
						<a (click)="goProjectManagementDashboard()">Dashboard</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToProjectManagementProject()">Project</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToProjectManagementDocument()">Document</a>
					</li>
				</ul>
			</li> -->
			<!-- <li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerEWS">Early Warning Signal</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerEWS" [checked]="this._booleanNavigationEWS" [(ngModel)]="_booleanNavigationEWS">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation">
						<a (click)="goToEWSDashboard();">Dashboard</a>
					</li>
					<li class="ListItemSubNavigation">
						<label for="checkBoxNavigationTriggerEWSFollowUp">Follow Up Plan</label>
						<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerEWSFollowUp" [checked]="this._booleanNavigationEWSFollowUp" [(ngModel)]="_booleanNavigationEWSFollowUp">
						<ul class="ListSubNavigation">
							<li class="ListItemSubNavigation">
								<a (click)="goToEWSFollowUpPlan();">List</a>
							</li>
							<li class="ListItemSubNavigation">
								<a (click)="goToEWSFollowUpPlanMonitoring();">Monitoring</a>
							</li>
						</ul>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToEWSReport();">Report</a>
					</li>
				</ul>
			</li> -->
			<li class="ListItemNavigation">
				<div class="DivListItemNavigationTwoLine" (click)="booleanNavigationCMS()">
					<span for="checkBoxNavigationTriggerCMS">Content Management</span><br>
					<span>System</span>
				</div>
				<ul class="ListSubNavigation" *ngIf="_booleanNavigationCMS">
					<li class="ListSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessGeneral([_enumUserGroupID.AKUMaker])">
						<label for="checkBoxNavigationTriggerFinancialReport">Financial Report</label>
						<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerFinancialReport" [checked]="this._booleanNavigationFinancialReport" [(ngModel)]="_booleanNavigationFinancialReport">
						<ul class="ListSubNavigation">
							<li class="ListItemSubNavigation">
								<a (click)="goToFinancialReport();">Maker</a>
							</li>
						</ul>
					</li>
				</ul>
			</li>
			<li class="ListItemNavigation">
				<a (click)="goToAbout();">About</a>
			</li>
		</ul>

		<div id="divNotificationInstallList" *ngIf="!this._servicePWA._booleanInstalled">
			<div>
				<span>AKU</span>
				<p class="ParagraphNotificationInstallOthers" *ngIf="!this._servicePWA._booleanIOS">Avoid any hassle to return this app and gives you better experience!</p>
				<p class="ParagraphNotificationInstallIOS" *ngIf="this._servicePWA._booleanIOS">To install this web app on your device tap the 'Share' button and then 'Add to Home Screen' button.</p>
			</div>

			<input type="button" value="Install" id="buttonNotificationInstallList" *ngIf="!this._servicePWA._booleanIOS" (click)="this._servicePWA.installWebsite();">
		</div>

		<a href="https://www.ssltrust.com.au/security-report?domain=aku.web.brinesia.app" rel="nofollow" target="new"><img src="https://seals.ssltrust.com.au/report_large.png" style="border: 0px;"></a>

		<iframe src="https://securityscorecard.com/security-rating/badge/brinesia.app" width="256" height="100" frameBorder="0"></iframe>
	</nav>

	<main [ngClass]="{ 'MainHide' : _booleanNavigation, 'MainShow' : !_booleanNavigation }">
		<router-outlet></router-outlet>
	</main>

	<input type="button" name="buttonNavigation" (click)="toggleNavigation();" id="buttonNavigation">

	<img id="imageLogoHeader" [ngClass]="{'DisplayNone': this._booleanNavigation}" src="../../assets/logos/sakina/logo_briinsurance_header.svg" alt="BRI Insurance - Logo Header">
</div>