//#region IMPORT

import { ENUM_COST_TYPE } from "../constants/enum.constant";
import { AccountChargeModel } from "./accountcharge.model";
import { BaseModel } from "./bases/base.model";
import { BranchModel } from "./branch.model";
import { CurrencyModel } from "./currency.model";

//#endregion


//#region CLASS

export class SubmissionCostModel extends BaseModel
{
	ID?: number;
	SubmissionDetailID?: number;
	Type?: ENUM_COST_TYPE;
	Remarks?: string;
	Amount?: number;
	BranchCodeResponsibility?: string;
	BranchNameResponsibility?: string;
	AccountChargeID?: number;
	CurrencyID?: number;

	modelBranch?: BranchModel;
	modelAccountCharge?: AccountChargeModel;
	modelCurrency?: CurrencyModel;

	//#region CONSTRUCTOR

	constructor()
	{
		super();
		this.modelBranch = new BranchModel();
		this.modelAccountCharge = new AccountChargeModel();
	}

	//#endregion


	//#region CLEAN

	cleanForInsert(): void
	{
		this.modelBranch = undefined;
		this.modelAccountCharge = undefined;
		this.modelCurrency = undefined;
		this.cleanAllFootprint();
	}

	//#endregion
}

//#endregion