//#region IMPORT

import { ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { RuleConstant } from "../constants/rule.constant";
import { ConvertByteForPreview } from "../functions/pipes/converter.pipe";
import { BaseModel } from "./bases/base.model";
import { DeclarationDetailModel } from "./declarationdetail.model";
import { DeclarationDocumentModel } from "./declarationdocument.model";
import { ResponseModel } from "./response.model";

//#endregion


//#region CLASS

export class DeclarationModel extends BaseModel
{
	ID?: number;
	LetterGeneralID?: number;
	LetterInquiryID?: number;
	DeclarationNumber?: string;
	BranchCodeResponsibility?: string;
	BranchNameResponsibility?: string;
	listModelDeclarationDetail?: Array<DeclarationDetailModel>;
	listModelDeclarationDocument?: Array<DeclarationDocumentModel>;

	constructor()
	{
		super();
		this.listModelDeclarationDetail = [];
		this.listModelDeclarationDocument = [];
	}


	//#region VALIDATION

	validateCheckListModelDeclarationDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Declaration");

		if(this.listModelDeclarationDocument == null || this.listModelDeclarationDocument === undefined)
		{
			modelResponse.MessageContent = "Terjadi kesalahan pada daftar attachment.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateAddModelDeclarationDetail(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Declaration");

		if(this.listModelDeclarationDetail == null || this.listModelDeclarationDetail === undefined)
		{
			modelResponse.MessageContent = "Daftar transaksi tidak boleh kosong.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateAddModelDeclarationDocument(arrayFile: Array<File>): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Declaration");

		if(arrayFile == null || arrayFile === undefined)
		{
			modelResponse.MessageContent = "Terjadi kesalahan pada daftar attachment.";
		}
		else
		{
			for (let numberIndexRow = 0; numberIndexRow < arrayFile.length; numberIndexRow++)
			{
				for (let numberIndexColumn = 0; numberIndexColumn < arrayFile.length; numberIndexColumn++)
				{
					if (numberIndexRow !== numberIndexColumn)
					{
						if (arrayFile[numberIndexRow].name === arrayFile[numberIndexColumn].name)
						{
							modelResponse.MessageContent = "Nama lampiran yang dipilih antara satu dengan yang lainnya memiliki kesamaan! Silahkan pilih kembali!";
							return modelResponse;
						}
						else
						{

						}
					}
					else
					{

					}
				}
			}

			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;

			for (const file of arrayFile)
			{
				if (this.listModelDeclarationDocument == null || this.listModelDeclarationDocument === undefined)
				{

				}
				else
				{
					for (const modelDeclarationDocumentSelected of this.listModelDeclarationDocument)
					{
						if (file.name === (modelDeclarationDocumentSelected.Name ?? "") + "." + (modelDeclarationDocumentSelected.Extension ?? ""))
						{
							modelResponse.MessageContent = "Nama lampiran yang dipilih antara yang sudah ada dan yang di upload memiliki kesamaan! Silahkan pilih kembali!";
							return modelResponse;
						}
						else
						{

						}
					}
				}
			}
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{

		}

		return modelResponse;
	}

	validateUpload(): ResponseModel
	{
		const modelResponse: ResponseModel = this.validateCheckListModelDeclarationDocument();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			let numberSize: number = 0;

			if (this.listModelDeclarationDocument == null || this.listModelDeclarationDocument === undefined || this.listModelDeclarationDocument.length <= 0)
			{
				modelResponse.MessageContent = "Terjadi kesalahan pada daftar attachment.";
			}
			else
			{
				let modelResponseDeclarationDocument: ResponseModel = new ResponseModel();

				for (const modelDeclarationDocument of this.listModelDeclarationDocument)
				{
					modelResponseDeclarationDocument = modelDeclarationDocument.validateAdd();

					if (modelResponseDeclarationDocument.State === ENUM_RESPONSE_STATE.Fail)
					{
						return modelResponseDeclarationDocument;
					}
					else
					{
						numberSize += modelDeclarationDocument.Size ?? 0;
					}
				}
			}

			if (numberSize > RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM)
			{
				const pipeConvertByteForPreview: ConvertByteForPreview = new ConvertByteForPreview();

				modelResponse.MessageContent = "Maksimum total size attachment adalah " + pipeConvertByteForPreview.transform(RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM);
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else
		{

		}

		return modelResponse;
	}

	validateCheckModelDeclarationDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = this.validateCheckListModelDeclarationDocument();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			let modelResponseDeclarationDocument: ResponseModel;
			let modelDeclarationDocumentCheck: DeclarationDocumentModel;

			for (const modelDeclarationDocument of this.listModelDeclarationDocument ?? [])
			{
				modelDeclarationDocumentCheck = new DeclarationDocumentModel();
				modelDeclarationDocumentCheck.setModelFromObject(modelDeclarationDocument);
				modelResponseDeclarationDocument = modelDeclarationDocumentCheck.validateCheck();

				if (modelResponseDeclarationDocument.State === ENUM_RESPONSE_STATE.Fail)
				{
					return modelResponseDeclarationDocument;
				}
				else
				{

				}
			}

			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{

		}

		return modelResponse;
	}

	validateCheckBankAccountVerification(): ResponseModel
	{
		const modelResponse: ResponseModel = this.validateAddModelDeclarationDetail();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			let modelResponseDeclarationDetail: ResponseModel;
			let modelDeclarationDetailCheck: DeclarationDetailModel;

			for (const modelDeclarationDetail of this.listModelDeclarationDetail ?? [])
			{
				modelDeclarationDetailCheck = new DeclarationDetailModel();
				modelDeclarationDetailCheck.setModelFromObject(modelDeclarationDetail);
				modelResponseDeclarationDetail = modelDeclarationDetailCheck.validateCheck();

				if (modelResponseDeclarationDetail.State === ENUM_RESPONSE_STATE.Fail)
				{
					return modelResponseDeclarationDetail;
				}
				else
				{
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
			}
		}
		else
		{

		}

		return modelResponse;
	}

	//#endregion


	//#region SETTER

	setListModelDeclarationDetail(): void
	{
		if (this.listModelDeclarationDetail == null || this.listModelDeclarationDetail === undefined)
		{

		}
		else
		{
			if (this.listModelDeclarationDetail.length > 0)
			{
				const arrayModelDeclarationDetailTemporary: Array<DeclarationDetailModel> = this.listModelDeclarationDetail;
				this.listModelDeclarationDetail = [];

				let modelDeclarationDetail: DeclarationDetailModel;

				for (const modelDeclarationDetailTemporary of arrayModelDeclarationDetailTemporary)
				{
					modelDeclarationDetail = new DeclarationDetailModel();
					modelDeclarationDetail.setModelFromObject(modelDeclarationDetailTemporary);
					modelDeclarationDetail.setListModelDeclarationVide();
					modelDeclarationDetail.setRealizationAmount();
					this.listModelDeclarationDetail.push(modelDeclarationDetail);
				}
			}
			else
			{

			}
		}
	}

	//#endregion


	//#region CLEAN

	cleanFootprintForInsert(): void
	{
		this.DeclarationNumber = undefined;
		this.LetterGeneralID = undefined;
		this.listModelDeclarationDocument = undefined;
		this.cleanAllFootprint();
	}

	clearForValidateBankAccount(): void
	{
		this.cleanFootprintForInsert();
		this.ID = undefined;

		if(this.listModelDeclarationDetail !== undefined)
		{
			for(const modelDeclarationDetail of this.listModelDeclarationDetail)
			{
				modelDeclarationDetail.CreatedBy = undefined;
				modelDeclarationDetail.CreatedOn = undefined;
				modelDeclarationDetail.UpdatedBy = undefined;
				modelDeclarationDetail.UpdatedOn = undefined;
				modelDeclarationDetail.State = undefined;
				modelDeclarationDetail.Amount = undefined;
				modelDeclarationDetail.BankAccountToken = undefined;
				modelDeclarationDetail.DeclarationID = undefined;
				modelDeclarationDetail.Description = undefined;
				modelDeclarationDetail.ID = undefined;
				modelDeclarationDetail.RealizationAmount = undefined;
				modelDeclarationDetail.Title = undefined;
				modelDeclarationDetail.TotalAmount = undefined;
				modelDeclarationDetail.TotalAmountWithCost = undefined;
				modelDeclarationDetail.TotalCost = undefined;
				modelDeclarationDetail.listModelDeclarationCost = undefined;
				modelDeclarationDetail.listModelDeclarationVide = undefined;
				modelDeclarationDetail.modelBankAccount = undefined;
				modelDeclarationDetail.modelCurrency = undefined;
				modelDeclarationDetail.modelDeclarationCostInsert = undefined;
			}
		}
	}

	//#endregion

}

//#endregion