//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { TableModel } from "../models/bases/table.model";
import { DeclarationDocumentModel } from "../models/declarationdocument.model";
import { LetterGeneralModel } from "../models/lettergeneral.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class DeclarationService extends BaseService
{
	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	//#region SELECT DECLARATION

	selectDeclarationByToken(interfaceGeneralService: GeneralServiceInterface, modelLeterGeneral: LetterGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLeterGeneral), WebAddressConstant.STRING_URL_DECLARATION_SELECTDECLARATIONBYTOKEN);
	}

	selectDeclarationForPaymentRequestAccounting(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_DECLARATION_SELECTDECLARATIONFORPAYMENTREQUESTACCOUNTINGBYTOKEN);
	}

	selectDeclarationByAttributesForAccounting(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_DECLARATION_SELECTDECLARATIONBYATTRIBUTESFORACCOUNTING);
	}

	selectDeclarationForAccountingByToken(interfaceGeneralService: GeneralServiceInterface, modelLeterGeneral: LetterGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLeterGeneral), WebAddressConstant.STRING_URL_DECLARATION_SELECTDECLARATIONFORACCOUNTINGBYTOKEN);
	}

	selectDeclarationQRByToken(interfaceGeneralService: GeneralServiceInterface, modelLeterGeneral: LetterGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLeterGeneral), WebAddressConstant.STRING_URL_DECLARATION_SELECTDECLARATIONQRBYTOKEN);
	}

	approveDeclarationByTokenForMaker(interfaceGeneralService: GeneralServiceInterface, modelLeterGeneral: LetterGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLeterGeneral), WebAddressConstant.STRING_URL_DECLARATION_APPROVEDECLARATIONBYTOKENFORMAKER);
	}

	approveDeclarationForPaymentRequestAccounting(interfaceGeneralService: GeneralServiceInterface, modelLeterGeneral: LetterGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLeterGeneral), WebAddressConstant.STRING_URL_DECLARATION_APPROVEDECLARATIONFORPAYMENTREQUESTACCOUNTING);
	}

	rejectDeclarationByToken(interfaceGeneralService: GeneralServiceInterface, modelLeterGeneral: LetterGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLeterGeneral), WebAddressConstant.STRING_URL_DECLARATION_REJECTECLARATIONBYTOKEN);
	}

	selectChecker(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_LOGISTIC_SELECTCHECKER);
	}

	selectSigner(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_LOGISTIC_SELECTSIGNER);
	}

	updateDeclarationPaymentRequestByLetterGeneralToken(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral) ,WebAddressConstant.STRING_URL_DECLARATION_UPDATEDECLARATIONPAYMENTREQUESTBYLETTERGENERALTOKEN);
	}

	updateDeclarationPendingForPaymentAKUByLetterGeneralToken(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral) ,WebAddressConstant.STRING_URL_DECLARATION_UPDATEDECLARATIONPENDINGFORPAYMENTAKUBYLETTERGENERALTOKEN);
	}

	approveDeclarationPendingForSignerAKUByLetterGeneralToken(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral) ,WebAddressConstant.STRING_URL_DECLARATION_APPROVEDECLARATIONPENDINGFORSIGNERAKUBYLETTERGENERALTOKEN);
	}

	downloadDeclarationTransferReceiptByLetterGeneralToken(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral) ,WebAddressConstant.STRING_URL_DECLARATION_DOWNLOADDECLARATIONTRANSFERRECEIPTBYLETTERGENERALTOKEN);
	}


	//#region DOWNLOAD

	downloadDeclarationAttachment(interfaceGeneralService: GeneralServiceInterface, modelDeclarationDocument: DeclarationDocumentModel): void
	{
		this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelDeclarationDocument), WebAddressConstant.STRING_URL_DECLARATION_DOWNLOADDECLARATIONDOCUMENT);
	}

	downloadDeclarationLetterAKU(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel): void
	{
		this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral), WebAddressConstant.STRING_URL_DECLARATION_DOWNLOADDECLARATION);
	}

	downloadDeclarationLetterBranchHead(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel): void
	{
		this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral), WebAddressConstant.STRING_URL_DECLARATION_DOWNLOADDECLARATIONBRANCHHEAD);
	}

	downloadDeclarationLetterHumanResource(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel): void
	{
		this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral), WebAddressConstant.STRING_URL_DECLARATION_DOWNLOADDECLARATIONHUMANRESOURCE);
	}

	downloadDeclarationLetterLogistic(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel): void
	{
		this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral), WebAddressConstant.STRING_URL_DECLARATION_DOWNLOADDECLARATIONLOGISTIC);
	}

	downloadDeclarationLetterCompanySecretariat(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel): void
	{
		this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral), WebAddressConstant.STRING_URL_DECLARATION_DOWNLOADDECLARATIONCOMPANYSECRETARIAT);
	}

	//#endregion
}

//#endregion
