//#region IMPORT

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { QuicklinkModule, QuicklinkStrategy } from "ngx-quicklink";
import { AboutComponent } from "./components/about/about.component";
import { DeclarationverifierComponent } from "./components/declarations/declarationverifier/declarationverifier.component";
import { DownloadComponent } from "./components/download/download.component";
import { HomeComponent } from "./components/home/home.component";
import { SigninComponent } from "./components/signin/signin.component";
import { SubmissionverifierComponent } from "./components/submissions/submissionverifier/submissionverifier.component";
import { UserverifierComponent } from "./components/userverifier/userverifier.component";
import { StringConstant } from "./constants/string.constant";
import { GeneralAuthorizedGuardService } from "./services/guards/generalauthorized.guard";
import { PublicGuardService } from "./services/guards/public.guard.service";
import { VerifyauthorizedGuard } from "./services/guards/verifyauthorized.guard";
import { AnomalyDownloadComponent } from "./components/protect/anomaly/anomalydownload/anomalydownload.component";
import { ReportMonitoringDigitalChannelComponent } from "./components/reportmonitoringdigitalchannel/reportmonitoringdigitalchannel.component";


//#endregion


//#region DECLARATION

const arrayRoute: Routes =
[
	{
		path: "",
		redirectTo: "/",
		pathMatch: "full"
	},
	{
		path: "",
		component: SigninComponent,
		canActivate: [PublicGuardService]
	},
	{
		path: "signin/downloadDeclarationLetter/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: SigninComponent,
		canActivate: [PublicGuardService],
	},
	{
		path: "signin/downloadSubmissionLetter/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: SigninComponent,
		canActivate: [PublicGuardService],
	},
	{
		path: "signin/downloadDeclarationAttachment/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: SigninComponent,
		canActivate: [PublicGuardService],
	},
	{
		path: "signin/downloadSubmissionAttachment/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: SigninComponent,
		canActivate: [PublicGuardService],
	},
	{
		path: "signin/downloadTicketAttachment/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: SigninComponent,
		canActivate: [PublicGuardService],
	},
	{
		path: "downloadTicketAttachment/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: DownloadComponent,
		canActivate: [GeneralAuthorizedGuardService]
	},
	{
		path: "signin/verify/AKU/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: SigninComponent,
		canActivate: [PublicGuardService],
	},
	{
		path: "signin/verify/LOGISTIC/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: SigninComponent,
		canActivate: [PublicGuardService],
	},
	{
		path: "signin/verify/declaration/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: SigninComponent,
		canActivate: [PublicGuardService],
	},
	{
		path: "signin/verify/submission/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: SigninComponent,
		canActivate: [PublicGuardService],
	},

	{
		path: "signin/downloadOpticReimbursementAttachment/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: SigninComponent,
		canActivate: [PublicGuardService],
	},
	{
		path: "signin/downloadPreMaternityReimbursementAttachment/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: SigninComponent,
		canActivate: [PublicGuardService],
	},
	{
		path: "signin/downloadMaternityReimbursementAttachment/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: SigninComponent,
		canActivate: [PublicGuardService],
	},
	{
		path: "signin/downloadPostMaternityReimbursementAttachment/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: SigninComponent,
		canActivate: [PublicGuardService],
	},
	{
		path: "signin/downloadTransferReceipt/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: SigninComponent,
		canActivate: [PublicGuardService],
	},
	{
		path: "downloadOpticReimbursementAttachment/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: DownloadComponent,
		canActivate: [GeneralAuthorizedGuardService]
	},
	{
		path: "downloadMaternityReimbursementAttachment/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: DownloadComponent,
		canActivate: [GeneralAuthorizedGuardService]
	},
	{
		path: "downloadPreMaternityReimbursementAttachment/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: DownloadComponent,
		canActivate: [GeneralAuthorizedGuardService]
	},
	{
		path: "downloadPostMaternityReimbursementAttachment/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: DownloadComponent,
		canActivate: [GeneralAuthorizedGuardService]
	},

	{
		path: "downloadDeclarationLetter/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: DownloadComponent,
		canActivate: [GeneralAuthorizedGuardService]
	},
	{
		path: "downloadSubmissionLetter/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: DownloadComponent,
		canActivate: [GeneralAuthorizedGuardService]
	},
	{
		path: "downloadDeclarationAttachment/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: DownloadComponent,
		canActivate: [GeneralAuthorizedGuardService]
	},
	{
		path: "downloadSubmissionAttachment/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: DownloadComponent,
		canActivate: [GeneralAuthorizedGuardService]
	},
	{
		path: "downloadTransferReceipt/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: DownloadComponent,
		canActivate: [GeneralAuthorizedGuardService]
	},
	{
		path: "signin",
		component: SigninComponent,
		canActivate: [PublicGuardService]
	},
	// {
	// 	path: "home",
	// 	redirectTo: "home/dashboard/ematerai",
	// },
	{
		path: "home",
		redirectTo: "home/dashboard",
	},
	{
		path: "home",
		component: HomeComponent,
		canActivate: [GeneralAuthorizedGuardService],
		children:
		[
			{
				path: "dashboard",
				loadChildren: () => import("./components/dashboards/dashboard.module").then(pageModule => pageModule.DashboardModule)
			},
			// {
			// 	path: "dashboard",
			// 	component: DashboardComponent,
			// },
			{
				path: "about",
				component: AboutComponent,
			},
			{
				path: "submission",
				loadChildren: () => import("./components/submissions/submissions.module").then(pageModule => pageModule.SubmissionsModule)
			},
			{
				path: "declaration",
				loadChildren: () => import("./components/declarations/declarations.module").then(pageModule => pageModule.DeclarationsModule)
			},
			{
				path: "ematerai",
				loadChildren: () => import("./components/ematerai/ematerai.module").then(pageModule => pageModule.EmateraiModule)
			},
			{
				path: "ebudgeting",
				children:
				[
					{
						path: "",
						loadChildren: () => import("./components/ebudgeting/ebudgeting.module").then(pageModule => pageModule.EbudgetingModule),
					},
					{
						path: "approval",
						loadChildren: () => import("./components/ebudgeting/approval/approvalcheckersigner.module").then(pageModule => pageModule.ApprovalcheckersignerModule)
					},
				],
			},
			{
				path: "report",
				loadChildren: () => import("./components/reports/report.module").then(pageModule => pageModule.ReportModule)
			},
			{
				path: "voucherpayment",
				loadChildren: () => import("./components/vouchers/vouchers.module").then(pageModule => pageModule.VouchersModule)
			},
			{
				path: "ticket",
				loadChildren: () => import("./components/tickets/tickets.module").then(pageModule => pageModule.TicketsModule)
			},
			{
				path: "reimbursement",
				children:
				[
					{
						path: "approval",
						loadChildren: () => import("./components/approvalreimbursement/approval.module").then(pageModule => pageModule.ApprovalModule)
					},
				]
			},
			{
				path: "rcsa",
				children:
				[
					{
						path: "assessment",
						loadChildren: () => import("./components/rcsa/assessment/assessment.module").then(pageModule => pageModule.AssessmentModule)
					},
					{
						path: "approval",
						loadChildren: () => import("./components/rcsa/approval/approval.module").then(pageModule => pageModule.ApprovalModule)
					},
					{
						path: "report",
						loadChildren: () => import("./components/rcsa/report/report.module").then(pageModule => pageModule.ReportModule)
					},
					{
						path: "riskissuerequest",
						loadChildren: () => import("./components/rcsa/riskissuerequest/riskissuerequest.module").then(pageModule => pageModule.RiskissuerequestModule)
					},
				],
			},
			{
				path: "ras",
				children:
				[
					{
						path: "dashboard",
						loadChildren: () => import("./components/ras/dashboardras/dashboardras.module").then(pageModule => pageModule.DashboardrasModule)
					},
					{
						path: "realization",
						loadChildren: () => import("./components/ras/realization/realization.module").then(pageModule => pageModule.RealizationModule)
					},
					{
						path: "followupplan",
						loadChildren: () => import("./components/ras/followupplan/followupplan.module").then(pageModule => pageModule.FollowupplanModule)
					},
				],
			},
			{
				path: "digitalization",
				loadChildren: () => import("./components/digitalization/submissions/submission.module").then(pageModule => pageModule.SubmissionModule)
			},
			{
				path: "paymentrequest",
				loadChildren: () => import("./components/paymentrequest/paymentrequest.module").then(pageModule => pageModule.PaymentrequestModule)
			},
			{
				path: "paymentsubmission",
				loadChildren: () => import("./components/paymentsubmission/paymentsubmission.module").then(pageModule => pageModule.PaymentsubmissionModule)
			},
			{
				path: StringConstant.STRING_ROUTING_MENU_PROTECT,
				loadChildren: () => import("./components/protect/protect.module").then(pageModule => pageModule.ProtectModule)
			},
			{
				path: "reportmonitoringdigitalchannel",
				component: ReportMonitoringDigitalChannelComponent
			},
			{
				path: "gcgonline",
				loadChildren: () => import("./components/gcgonline/gcgonline.module").then(pageModule => pageModule.GcgOnlineModule)
			},
			{
				path: "brinsave",
				loadChildren: () => import("./components/brinsave/brinsave.module").then(pageModule => pageModule.BrinsaveModule)
			},
			{
				path: StringConstant.STRING_PATH_ROUTING_APUPPT,
				loadChildren: () => import("./components/apu-ppt/apu-ppt.module").then(pageModule => pageModule.APUPPTModule)
			},
			{
				path: "pegadaian",
				loadChildren: () => import("./components/pegadaian/pegadaian.module").then(pageModule => pageModule.PegadaianModule)
			},
			{
				path: "brinspro",
				loadChildren: () => import("./components/brinspro/brinspro.module").then(pageModule => pageModule.BrinsproModule)
			},
			{
				path: StringConstant.STRING_PATH_ROUTING_EWS,
				loadChildren: () => import("./components/earlywarningsignal/earlywarningsignal.module").then(pageModule => pageModule.EarlyWarningSignalModule)
			},
			{
				path: "contentmanagementsystem",
				children:
				[
					{
						path: "financialreport",
						loadChildren: () => import("./components/contentmanagementsystem/financialreport/financialreport.module").then(pageModule => pageModule.FinancialReportModule)
					},
				],
			},
		]
	},
	{
		path: "verify",
		canActivate: [VerifyauthorizedGuard],
		children:
		[
			{
				path: "user",
				children:
				[
					{
						path: "LOGISTIC/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
						component: UserverifierComponent
					},
					{
						path: "AKU/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
						component: UserverifierComponent
					}
				]
			},
			{
				path: "submission/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
				component: SubmissionverifierComponent
			},
			{
				path: "declaration/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
				component: DeclarationverifierComponent
			}
		]
	},
	{
		path: StringConstant.STRING_ROUTING_MENU_DOWNLOADANOMALYATTACHMENT_PARAM + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: AnomalyDownloadComponent,
		canActivate: [GeneralAuthorizedGuardService]
	}
];

//#endregion


//#region MODULE

@NgModule
(
	{
		imports:
		[
			QuicklinkModule,
			RouterModule.forRoot(arrayRoute,
				{
					preloadingStrategy: QuicklinkStrategy
				})
		],
		exports: [RouterModule]
	}
)

//#endregion


//#region CLASS

export class AppRoutingModule { }

//#endregion
