//#region IMPORT

import { ENUM_RATINGPRODUCT_TYPE, ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { StringConstant } from "../constants/string.constant";
import { BaseModel } from "./bases/base.model";
import { ResponseModel } from "./response.model";

//#endregion


//#region CLASS

export class PurchaseRatingReviewsModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for purchase rating review.
		Author: Dimas Dandy Aryarajendra Suprapto.
		Created on : Monday, 9 October 2023.	 					Updated on : .
		Created by : Dimas Dandy Aryarajendra Suprapto.				Updated by : .
		Version : 1.0:1.
	*/

	Token?: string;
	PolicyGeneralID?: number;
	CoverageCode?: string;
	ProductPurchasingExperience?: ENUM_RATINGPRODUCT_TYPE;
	Message?: string;
	OrderDateStartRange?: Date;
	OrderDateEndRange?: Date;
	ProductName?: string;
	Channel?: string;
	BranchName?: string;
	ReferenceNumber?: string;

	/* Attribute - END */


	//#region VALIDATE

	validatePeriod(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Product General Report");

		if (this.OrderDateStartRange == null || this.OrderDateStartRange === undefined)
		{
			modelResponse.MessageContent = "Start date can't be empty.";
		}
		else if (this.OrderDateEndRange == null || this.OrderDateEndRange === undefined)
		{
			modelResponse.MessageContent = "End date can't be empty.";
		}
		else if (this.OrderDateEndRange < this.OrderDateStartRange)
		{
			modelResponse.MessageContent = "End date can't be less than Start date.";
		}
		else if (this.Channel === StringConstant.STRING_CHANNEL_BRIMO && this.ProductName === StringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_PERSONALACCIDENT || this.Channel === StringConstant.STRING_CHANNEL_BRIMO && this.ProductName === StringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_DAMAGEOFBUSINESSPLACE || this.Channel === StringConstant.STRING_CHANNEL_BRIMO && this.ProductName === StringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_MYHOUSE || this.Channel === StringConstant.STRING_CHANNEL_BRIMO && this.ProductName === StringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_MYMOTORCYCLE || this.Channel === StringConstant.STRING_CHANNEL_NDSUB && this.ProductName === StringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_PERSONALACCIDENT)
		{
			modelResponse.MessageContent = "Please Choose Product.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
		}

		return modelResponse;
	}

	//#endregion

}

//#endregion