/* eslint-disable no-unused-vars */
//#region IMPORT

import { ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { BaseModel } from "./bases/base.model";
import { DeclarationDetailModel } from "./declarationdetail.model";
import { ResponseModel } from "./response.model";

//#endregion


//#region CLASS

export class BankAccountModel extends BaseModel
{
	ID?: number;
	BeneficiaryName?: string;
	AccountNumber?: string;
	AccountHolder?: string;
	BankName?: string;
	BankCode?: string;
	Token?: string;
	modelDeclarationDetail?: DeclarationDetailModel;
	modelSubmissionDetail?: DeclarationDetailModel;

	SourceAccount?: string;
	SourceAccountName?: string;
	SourceAccountStatus?: string;
	SourceAccountBalance?: number;
	BeneficiaryAccount?: string;
	BeneficiaryAccountName?: string;
	BeneficiaryAccountStatus?: string;


	//#region VALIDATE

	validateBankAccountAttributes(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Reimbursement general");

		if (this.BeneficiaryAccountName == null || this.BeneficiaryAccountName === undefined)
		{
			modelResponse.MessageContent = "Beneficiary Account Name can't be empty!";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		return modelResponse;
	}

	//#endregion


	//#region SETTER

	setForBankAccountRequest(modelBankAccount: BankAccountModel, stringSelectedSourceAccount: string): void
	{
		this.BeneficiaryAccountName = modelBankAccount.AccountHolder;
		this.BeneficiaryAccount = modelBankAccount.AccountNumber;
		this.SourceAccount = stringSelectedSourceAccount;

		//#region DUMMY

		// this.BeneficiaryAccount = "888809999999918"; // Account Number
		// this.SourceAccount = "888801000157508"; // Bank Account Source

		//#endregion
	}

	setForBankAccountRequestDeclarationDetail(modelDeclarationDetail: DeclarationDetailModel, stringSelectedSourceAccount: string): void
	{
		this.BeneficiaryAccountName = modelDeclarationDetail.BeneficiaryName;
		this.BeneficiaryAccount = modelDeclarationDetail.EmployeeAccountNumber;
		this.SourceAccount = stringSelectedSourceAccount;

		//#region DUMMY

		// this.BeneficiaryAccount = "888809999999918";
		// this.SourceAccount = "888801000157508";

		//#endregion
	}

	setFromBankAccountResponse(modelBankAccount: BankAccountModel): void
	{
		this.BeneficiaryAccount = modelBankAccount.BeneficiaryAccount;
		this.BeneficiaryAccountName = modelBankAccount.BeneficiaryAccountName;
		this.BeneficiaryAccountStatus = modelBankAccount.BeneficiaryAccountStatus;
	}

	//#endregion
}

//#endregion