/* eslint-disable no-unused-vars */
//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { TableModel } from "../models/bases/table.model";
import { ReimbursementGeneralModel } from "../models/reimbursementgeneral.model";
import { OpticReimbursementModel } from "../models/opticreimbursement.model";
import { OpticReimbursementGalleryModel } from "../models/opticreimbursementgallery.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class OpticreimbursementService extends BaseService
{
	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	// #region SELECT

	selectOpticReimbursementGeneralByAttributesForChecker(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_OPTICREIMBURSEMENT_SELECTOPTICREIMBURSEMENTBYATTRIBUTESFORCHECKER);
	}

	selectOpticReimbursementByAttributesForSigner(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_OPTICREIMBURSEMENT_SELECTOPTICREIMBURSEMENTBYATTRIBUTESFORSIGNER);
	}

	selectOpticReimbursementByToken(interfaceGeneralService: GeneralServiceInterface, modelReimbursementGeneral: ReimbursementGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelReimbursementGeneral), WebAddressConstant.STRING_URL_OPTICREIMBURSEMENT_SELECTOPTICREIMBURSEMENTBYTOKEN);
	}

	// #endregion


	//#region UPDATE

	updateOpticReimbursementByToken(interfaceGeneralService: GeneralServiceInterface, modelReimbursementGeneral: ReimbursementGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelReimbursementGeneral), WebAddressConstant.STRING_URL_OPTICREIMBURSEMENT_UPDATEOPTICREIMBURSEMENTBYTOKEN);
	}

	//#endregion


	//#region APPROVAL

	checkOpticReimbursementByToken(interfaceGeneralService: GeneralServiceInterface, modelReimbursementGeneral: ReimbursementGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelReimbursementGeneral), WebAddressConstant.STRING_URL_OPTICREIMBURSEMENT_CHECKOPTICREIMBURSEMENTBYTOKEN);
	}

	signOpticReimbursementByToken(interfaceGeneralService: GeneralServiceInterface, modelReimbursementGeneral: ReimbursementGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelReimbursementGeneral), WebAddressConstant.STRING_URL_OPTICREIMBURSEMENT_SIGNOPTICREIMBURSEMENTBYTOKEN);
	}

	rejectOpticReimbursementByToken(interfaceGeneralService: GeneralServiceInterface, modelReimbursementGeneral: ReimbursementGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelReimbursementGeneral), WebAddressConstant.STRING_URL_OPTICREIMBURSEMENT_REJECTOPTICREIMBURSEMENTBYTOKEN);
	}

	reviseOpticReimbursementByToken(interfaceGeneralService: GeneralServiceInterface, modelReimbursementGeneral: ReimbursementGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelReimbursementGeneral), WebAddressConstant.STRING_URL_OPTICREIMBURSEMENT_REVISEOPTICREIMBURSEMENTBYTOKEN);
	}

	//#endregion


	//#region DOWNLOAD

	downloadOpticReimbursementGalleryByToken(interfaceGeneralService: GeneralServiceInterface, modelOpticReimbursementGallery: OpticReimbursementGalleryModel): void
	{
		this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelOpticReimbursementGallery), WebAddressConstant.STRING_URL_OPTICREIMBURSEMENT_DOWNLOADOPTICREIMBURSEMENTGALLERYBYTOKEN);
	}

	//#endregion

}

//#endregion