/* eslint-disable @typescript-eslint/ban-types */
//#region IMPORT

import { Location } from "@angular/common";
import { ActivatedRoute, ActivatedRouteSnapshot, Router, UrlSegment } from "@angular/router";
import { StringConstant } from "src/app/constants/string.constant";
import { SessionService } from "src/app/services/session.service";
import { Platform } from "@angular/cdk/platform";
import { Injectable, Inject, LOCALE_ID } from "@angular/core";
import { MatDateFormats, NativeDateAdapter } from "@angular/material/core";
import { BaseAuthourizeComponent } from "./baseauthourize.component";

//#endregion


//#region CONST

export const appDateFormats: MatDateFormats =
{
	parse:
	{
		dateInput: { month: "short", year: "numeric", day: "numeric" },
	},
	display:
	{
		dateInput: "input",
		monthYearLabel: { year: "numeric", month: "numeric" },
		dateA11yLabel: { year: "numeric", month: "long", day: "numeric"
		},
		monthYearA11yLabel: { year: "numeric", month: "long" },
	},
};

//#endregion


//#region CLASS DECLARATION

@Injectable() export class AppYearAdapter extends NativeDateAdapter
{
	constructor(@Inject(LOCALE_ID) public locale: string)
	{
		super(locale, new Platform(locale));
	}

	format(date: Date, displayFormat: Object): string
	{
		if (displayFormat === "input")
		{
			const numberYear = date.getFullYear();
			return `${numberYear}`;
		}
		return date.toDateString();
	}
}

@Injectable() export class AppDateAdapter extends NativeDateAdapter
{
	// eslint-disable-next-line @typescript-eslint/ban-types
	format(date: Date, displayFormat: Object): string
	{
		if (displayFormat === "input")
		{
			let stringDay: string = date.getUTCDate().toString();
			stringDay = +stringDay < 10 ? "0" + stringDay : stringDay;
			let stringMonth: string = (date.getUTCMonth() + 1).toString();
			stringMonth = +stringMonth < 10 ? "0" + stringMonth : stringMonth;
			const numberYear = date.getFullYear();
			return `${stringDay}/${stringMonth}/${numberYear}`;
		}
		return date.toDateString();
	}
}

//#endregion


//#region CLASS

export class BaseAuthourizeDetailComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	public _booleanReadOnly: boolean;

	public _stringDocumentToken: string = "";
	public _stringCategory: string = "";
	public _arrayStringURL: Array<string> = [];

	protected _routeActivated: ActivatedRoute;
	protected _location: Location;

	//#endregion


	//#region CONSTRUCTOR

	constructor(routeActivated: ActivatedRoute, location: Location, serviceSession: SessionService, router: Router)
	{
		super(serviceSession, router);

		this._routeActivated = routeActivated;
		this._location = location;
		this._booleanReadOnly = true;
	}

	//#endregion


	//#region GETTER

	protected getParameterFromRouter(stringKey: string = StringConstant.STRING_ROUTING_KEY_TOKEN): string | null
	{
		const stringParameter: string | null = this._routeActivated.snapshot.paramMap.get(stringKey);
		return stringParameter;
	}

	protected getSecondTokenFromRouter(stringKey: string = StringConstant.STRING_ROUTING_KEY_TOKEN2): string | null
	{
		const stringParameter: string | null = this._routeActivated.snapshot.paramMap.get(stringKey);
		return stringParameter;
	}

	protected getParameterTokenAndStringCategoryFromURLParameter(): void
	{
		const stringURL: string = window.location.pathname;
		this._arrayStringURL = stringURL.split("/");
		this._stringDocumentToken = this._arrayStringURL[(this._arrayStringURL.length - 1)];
		this._stringCategory = this._arrayStringURL[(this._arrayStringURL.length - 2)];
	}

	protected getParameterCategoryFromRouter(stringKey: string): any | null
	{
		const stringParameter: any | null = this._routeActivated.snapshot.paramMap.get(stringKey);
		return stringParameter;
	}

	protected getCategoryFromURL(numberCategory: number): void
	{
		const stringURL: string = window.location.pathname;
		this._arrayStringURL = stringURL.split("/");
		this._stringCategory = this._arrayStringURL[(this._arrayStringURL.length - numberCategory)];
	}

	protected getActiveUrl(): string | undefined
	{
		const snapshot: ActivatedRouteSnapshot = this._routeActivated.snapshot;
		const arrayStringSegments: Array<string> = snapshot.url.map((segment: UrlSegment) => segment.path);

		if (arrayStringSegments.length > 1)
		{
			return arrayStringSegments[0];
		}
		else
		{
			return undefined;
		}
	}

	//#endregion


	//#region CHECK

	protected checkInsert(): boolean
	{
		return this._router.url.indexOf("insert") > -1;
	}

	protected confirmationAction(stringText: string): boolean
	{
		const booleanResult: boolean = confirm(stringText);
		return booleanResult;
	}

	//#endregion


	//#region NAVIGATION

	public goToBack(): void
	{
		this._location.back();
	}

	public goToHome(): void
	{
		this._router.navigate([""]);
	}

	//#endregion
}

//#endregion