//#region IMPORT

import { Router } from "@angular/router";
import { PartialObserver } from "rxjs";
import { AccountingAndFinanceModel } from "src/app/models/accountingandmodel.model";
import { UserModel } from "src/app/models/user.model";
import { SessionService } from "src/app/services/session.service";
import { NumberConstant } from "src/app/constants/number.constant";
import { EnumGeneralModel } from "src/app/models/enumgeneral.model";
import { ENUM_ANOMALY_ANALYSISMETHOD, ENUM_ANOMALY_CATEGORY, ENUM_ANOMALY_QUARTER } from "src/app/constants/enum.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { ConvertSeparateEnumSpace } from "src/app/functions/pipes/converter.pipe";
import { TableModel } from "src/app/models/bases/table.model";
import { BasePublicComponent } from "./basepublic.component";

//#endregion


//#region CLASS

export class BaseAuthourizeComponent extends BasePublicComponent
{
	//#region DECLARATION

	public _booleanReadOnly: boolean;

	public _modelUserSignIn: UserModel;
	public _modelAccountingAndFinanceSignIn: AccountingAndFinanceModel;

	public _modelNull: any = null;

	//#endregion


	//#region CONSTRUCTOR

	constructor(serviceSession: SessionService, router: Router)
	{
		super(serviceSession, router);

		this._modelUserSignIn = new UserModel();

		this._booleanReadOnly = true;

		this._modelAccountingAndFinanceSignIn = new AccountingAndFinanceModel();

		const observerPartialModelUserSignIn: PartialObserver<UserModel> =
		{
			next: (modelUser: UserModel) => { this._modelUserSignIn = modelUser; },
			error: () => { },
			complete: () => { }
		};

		const observerPartialModelAccountingAndFinanceSignIn: PartialObserver<AccountingAndFinanceModel> =
		{
			next: (modelAccountingAndFinance: AccountingAndFinanceModel) => { this._modelAccountingAndFinanceSignIn = modelAccountingAndFinance; },
			error: () => { },
			complete: () => { }
		};

		this._serviceSession._modelUserSignIn.subscribe(observerPartialModelUserSignIn);
		this._serviceSession._modelAccountingAndFinanceSignIn.subscribe(observerPartialModelAccountingAndFinanceSignIn);
	}

	//#endregion


	//#region FUNCTION

	protected signOut(): void
	{
		this._serviceSession.clearKey();
		this._serviceSession.clearSession();
		this._serviceSession.clearSessionQuery();

		this._router.navigate(["signin"]);
	}

	protected signOutPublic(): void
	{
		this._serviceSession.clearKey();
		this._serviceSession.clearSession();
		this._serviceSession.clearSessionQuery();
		window.location.reload();
	}

	protected signOutDownload(stringURL: string): void
	{
		this._serviceSession.clearKey();
		this._serviceSession.clearSession();
		this._serviceSession.clearSessionQuery();

		this._router.navigate(["signin", stringURL]);
	}

	protected confirmationAction(stringText: string): boolean
	{
		const booleanResult: boolean = confirm(stringText);
		return booleanResult;
	}

	//#endregion


	//#region GENERAL

	public generate5YearLater(numberYear: number): Array<number>
	{
		const arrayNumberYear: Array<number> = [];
		const numberMinimunYear: number = numberYear - NumberConstant.NUMBER_COMPARE_VALUE_FIVE;

		for (let numberYearCalc: number = numberYear; numberYearCalc >= numberMinimunYear; numberYearCalc--)
		{
			arrayNumberYear.push(numberYearCalc);
		}

		return arrayNumberYear;
	}

	public getListEnumByName(stringEnumName: string): Array<EnumGeneralModel>
	{
		let enumSelected: any;

		if(stringEnumName === StringConstant.STRING_ENUM_AUDIT_ANOMALYQUARTER)
		{
			enumSelected = ENUM_ANOMALY_QUARTER;
		}
		else if(stringEnumName === StringConstant.STRING_ENUM_ANOMALY_ANALYSISMETHOD)
		{
			enumSelected = ENUM_ANOMALY_ANALYSISMETHOD;
		}
		else if(stringEnumName === StringConstant.STRING_ENUM_ANOMALY_CATEGORY)
		{
			enumSelected = ENUM_ANOMALY_CATEGORY;
		}

		if(enumSelected)
		{
			const arrayObjectEnumGeneral = Object.keys(enumSelected)
				.filter(
					stringKey => !Number.isNaN(Number(enumSelected[stringKey])))
				.flatMap(
					stringKey => (
						[
							{ ID: enumSelected[stringKey], Name: stringKey }
						]
					)
				);

			const arrayModelEnumGeneral: Array<EnumGeneralModel> = [];
			arrayObjectEnumGeneral.forEach(
				objectEnumGeneralLoop =>
				{
					const pipeConvertSeparateEnumSpace: ConvertSeparateEnumSpace = new ConvertSeparateEnumSpace();
					const modelEnumGeneral: EnumGeneralModel = new EnumGeneralModel();
					modelEnumGeneral.ID = objectEnumGeneralLoop.ID;
					modelEnumGeneral.Name = pipeConvertSeparateEnumSpace.transform(objectEnumGeneralLoop.Name);

					arrayModelEnumGeneral.push(modelEnumGeneral);
				}
			);

			return arrayModelEnumGeneral;
		}
		else
		{
			return [];
		}

	}

	//#endregion



	//#region CHECK

	protected checkURL(stringURLPath: string): boolean
	{
		return this._router.url.indexOf(stringURLPath) > -1;
	}

	//#endregion

	//#region GETTER

	public getTableNumberIndex(modelTable: TableModel, numberIndex: number): number
	{
		return (((modelTable.Page ?? 0) - 1) * (modelTable.RowPerPage ?? 0) + numberIndex + 1);
	}

	//#endregion


	//#region SETTER

	protected setViewFromTop(): void
	{
		let top = document.getElementById("divBodyDashboard");
		if (top !== null)
		{
			top.scrollIntoView();
			top = null;
		}
	}

	//#endregion
}

//#endregion