//#region IMPORT

import { ENUM_LETTERCOMPONENT_TYPE, ENUM_LETTER_ROLE, ENUM_LETTER_STATUS, ENUM_LETTER_TYPE, ENUM_PAYMENTPRIORITY_TYPE, ENUM_PAYMENT_TYPE, ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { StringConstant } from "../constants/string.constant";
import { BankAccountSourceModel } from "./bankaccountsource.model";
import { BaseModel } from "./bases/base.model";
import { BranchModel } from "./branch.model";
import { DeclarationModel } from "./declaration.model";
import { DeclarationVideModel } from "./declarationvide.model";
import { DentalReimbursementModel } from "./dentalreimbursement.model";
import { DivisionModel } from "./division.model";
import { MaternityReimbursementModel } from "./maternityreimbursement.model";
import { OpticReimbursementModel } from "./opticreimbursement.model";
import { PostMaternityReimbursementModel } from "./postmaternityreimbursementmodel";
import { PreMaternityReimbursementModel } from "./prematernityreimbursement.model";
import { ReimbursementTreatmentModel } from "./reimbursementtreatment.model";
import { ResponseModel } from "./response.model";
import { SubmissionModel } from "./submission.model";
import { SubmissionVideModel } from "./submissionvide.model";
import { UserModel } from "./user.model";
import { VoucherModel } from "./voucher.model";

//#endregion


//#region CLASS

export class LetterGeneralModel extends BaseModel
{
	ID?: number;
	Status?: ENUM_LETTER_STATUS;
	Type?: ENUM_LETTER_TYPE;
	PaymentPriority?: ENUM_PAYMENTPRIORITY_TYPE;
	PaymentType?: ENUM_PAYMENT_TYPE;
	BudgetType?: string;
	BudgetYear?: number;
	TotalAmount?: number;
	DepositToDesk?: number;
	DivisionID?: number;
	FormationID?: number;
	Token?: string;
	Title?: string;
	Role?: ENUM_LETTER_ROLE;
	DueDate?: Date;
	ReferenceNumber?: string;
	ReferenceDate?: Date;
	WorkingUnit?: string;
	DivisionToken?: string;
	BranchToken?: string;
	RequestNo?: string;

	FirstCheckerBy?: number;
	FirstCheckerOn?: Date;
	FirstCheckerURL?: string;
	SecondCheckerBy?: number;
	SecondCheckerOn?: Date;
	SecondCheckerURL?: string;
	FirstSignerBy?: number;
	FirstSignerOn?: Date;
	FirstSignerURL?: string;
	SecondSignerBy?: number;
	SecondSignerOn?: Date;
	SecondSignerURL?: string;
	FirstCheckerAKUBy?: number;
	FirstCheckerAKUOn?: Date;
	FirstCheckerAKUURL?: string;
	SecondCheckerAKUBy?: number | null;
	SecondCheckerAKUOn?: Date;
	SecondCheckerAKUURL?: string;
	FirstSignerAKUBy?: number;
	FirstSignerAKUOn?: Date;
	FirstSignerAKUURL?: string;
	SecondSignerAKUBy?: number;
	SecondSignerAKUOn?: Date;
	SecondSignerAKUURL?: string;
	DocumentNo?: string;
	SourceAccount?: string;
	VoucherType?: string;
	SentTo?: number;
	SentOn?: Date;
	RejectedOn?: Date;
	RejectedBy?: number;
	RejectedNote?: string;;
	DeclarationNumber?: string;
	Subject?: string;
	LastUser?: string;

	Data?: string;
	CreatedURL?: string;
	FileName?: string;
	FileURL?: string;
	FileFormat?: string;
	FileSize?: string;

	SubmissionStartDate?: Date;
	SubmissionEndDate?: Date;

	SLAIndicator?: boolean;

	//#region OTP

	EmailToken?: string;
	EmailTokenPlain?: string;
	EmailTokenExpired?: Date;
	EmailTokenFailure?: number;
	EmailTokenResendOn?: Date;
	EmailTokenSubmitOn?: Date;

	//#endregion


	modelDeclaration?: DeclarationModel;
	modelSubmission?: SubmissionModel;
	modelUserReviewer?: UserModel;
	modelDivision?: DivisionModel;
	modelBranch?: BranchModel;

	modelUserMaker?: UserModel;
	modelUserFirstChecker?: UserModel;
	modelUserSecondChecker?: UserModel;
	modelUserFirstSigner?: UserModel;
	modelUserSecondSigner?: UserModel;
	modelUserMakerAKU?: UserModel;
	modelUserFirstCheckerAKU?: UserModel;
	modelUserSecondCheckerAKU?: UserModel;
	modelUserFirstSignerAKU?: UserModel;
	modelUserSecondSignerAKU?: UserModel;

	modelDentalReimbursement?: DentalReimbursementModel;
	modelOpticReimbursement?: OpticReimbursementModel;
	modelPreMaternityReimbursement?: PreMaternityReimbursementModel;
	modelMaternityReimbursement?: MaternityReimbursementModel;
	modelPostMaternityReimbursement?: PostMaternityReimbursementModel;
	listModelReimbursementTreatment?: Array<ReimbursementTreatmentModel>;
	modelVoucher?: VoucherModel;
	modelBankAccountSource?: BankAccountSourceModel;

	constructor()
	{
		super();
		this.modelDeclaration = new DeclarationModel();
		this.modelSubmission = new SubmissionModel();

		this.modelUserMaker = new UserModel();
		this.modelUserFirstChecker = new UserModel();
		this.modelUserSecondChecker = new UserModel();
		this.modelUserFirstSigner = new UserModel();
		this.modelUserSecondSigner = new UserModel();
		this.modelUserReviewer = new UserModel();
		this.modelUserMakerAKU = new UserModel();
		this.modelUserFirstCheckerAKU = new UserModel();
		this.modelUserSecondCheckerAKU = new UserModel();
		this.modelUserFirstSignerAKU = new UserModel();
		this.modelUserSecondSignerAKU = new UserModel();

		this.modelDentalReimbursement = new DentalReimbursementModel();
		this.modelOpticReimbursement = new OpticReimbursementModel();
		this.modelPreMaternityReimbursement = new PreMaternityReimbursementModel();
		this.modelPostMaternityReimbursement = new PostMaternityReimbursementModel();
		this.modelMaternityReimbursement = new MaternityReimbursementModel();
		this.modelVoucher = new VoucherModel();

		this.modelDivision = new DivisionModel();
		this.modelBranch = new BranchModel();

		this.modelBankAccountSource = new BankAccountSourceModel();
	}

	//#region GETTER

	getLetterTypeName(): string
	{
		if (this.Type != null && this.Type !== undefined)
		{
			if (this.Type === ENUM_LETTER_TYPE.DeclarationPayment)
			{
				return "Payment";
			}
			else if (this.Type === ENUM_LETTER_TYPE.DeclarationReimburse)
			{
				return "Reimburse";
			}
			else if (this.Type === ENUM_LETTER_TYPE.DeclarationRealizationDebt)
			{
				return "Debt Realization";
			}
			else if (this.Type === ENUM_LETTER_TYPE.DeclarationRealizationDownPayment)
			{
				return "Down Payment Realization";
			}
			else if (this.Type === ENUM_LETTER_TYPE.SubmissionDebt)
			{
				return "Debt Submission";
			}
			else if (this.Type === ENUM_LETTER_TYPE.SubmissionDownPayment)
			{
				return "Down Payment Submission";
			}
			else
			{
				return StringConstant.STRING_CHARACTER_EMPTY;
			}
		}
		else
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
	}

	getPaymentPriorityName(): string
	{
		if (this.PaymentPriority != null && this.PaymentPriority !== undefined)
		{
			if (this.PaymentPriority === ENUM_PAYMENTPRIORITY_TYPE.Normal)
			{
				return "Biasa";
			}
			else if (this.PaymentPriority === ENUM_PAYMENTPRIORITY_TYPE.Important)
			{
				return "Segera";
			}
			else if (this.PaymentPriority === ENUM_PAYMENTPRIORITY_TYPE.VeryImportant)
			{
				return "Sangat Segera";
			}
			else
			{
				return StringConstant.STRING_CHARACTER_EMPTY;
			}
		}
		else
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
	}

	getSLAPriorityName(): string
	{
		if (this.PaymentPriority != null && this.PaymentPriority !== undefined)
		{
			if (this.PaymentPriority === ENUM_PAYMENTPRIORITY_TYPE.Normal)
			{
				return "5 day(s)";
			}
			else if (this.PaymentPriority === ENUM_PAYMENTPRIORITY_TYPE.Important)
			{
				return "3 day(s)";
			}
			else if (this.PaymentPriority === ENUM_PAYMENTPRIORITY_TYPE.VeryImportant)
			{
				return "1 day(s)";
			}
			else
			{
				return StringConstant.STRING_CHARACTER_EMPTY;
			}
		}
		else
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
	}

	getStatusName(): string
	{
		if (this.Status != null && this.Status !== undefined)
		{
			if (this.Status === ENUM_LETTER_STATUS.OnProgress)
			{
				return "Sedang Dibuat";
			}
			else if (this.Status === ENUM_LETTER_STATUS.PendingForCheck)
			{
				return "Menunggu Pengecekan";
			}
			else if (this.Status === ENUM_LETTER_STATUS.PendingForSign)
			{
				return "Menunggu Disetujui";
			}
			else if (this.Status === ENUM_LETTER_STATUS.Rejected)
			{
				return "Ditolak";
			}
			else if (this.Status === ENUM_LETTER_STATUS.Signed)
			{
				return "Disetujui kepala divisi/cabang terkait";
			}
			else if (this.Status === ENUM_LETTER_STATUS.Printed)
			{
				return "Telah Terbit";
			}
			else if (this.Status === ENUM_LETTER_STATUS.Sent)
			{
				return "Terkirim";
			}
			else if (this.Status === ENUM_LETTER_STATUS.ApprovedByAKU)
			{
				return "Di Setujui Oleh AKU";
			}
			else if (this.Status === ENUM_LETTER_STATUS.PendingForMakerAKU)
			{
				return "Menunggu pembuatan payment request oleh AKU";
			}
			else if (this.Status === ENUM_LETTER_STATUS.PendingForCheckerAKU)
			{
				return "Menunggu DiSetujui Checker AKU";
			}
			else if (this.Status === ENUM_LETTER_STATUS.PendingForSignerAKU)
			{
				return "Menunggu DiSetujui Signer AKU";
			}
			else if (this.Status === ENUM_LETTER_STATUS.PendingForPaid)
			{
				return "Menunggu Dibayar";
			}
			else if (this.Status === ENUM_LETTER_STATUS.Paid)
			{
				return "Dibayarkan";
			}
			else if (this.Status === ENUM_LETTER_STATUS.Failed)
			{
				return "Gagal Bayar";
			}
			else if (this.Status === ENUM_LETTER_STATUS.SuccessfullyPaid)
			{
				return "Sepenuhnya Berhasil";
			}
			else
			{
				return StringConstant.STRING_CHARACTER_EMPTY;
			}
		}
		else
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
	}

	getStatusNameForPaymentRequest(): string
	{
		if(this.Status != null && this.Status !== undefined)
		{
			if (this.Status === ENUM_LETTER_STATUS.PendingForMakerAKU)
			{
				return "Need Payment Request";
			}
			else if (this.Status === ENUM_LETTER_STATUS.PendingForCheckerAKU)
			{
				return "Need Checker Approval";
			}
			else if (this.Status === ENUM_LETTER_STATUS.PendingForSignerAKU)
			{
				return "Need Signer Approval";
			}
			else if (this.Status === ENUM_LETTER_STATUS.PendingForPaid)
			{
				return "Pending For Paid";
			}
			else if (this.Status === ENUM_LETTER_STATUS.Paid)
			{
				return "Paid";
			}
			else if (this.Status >= ENUM_LETTER_STATUS.SuccessfullyPaid && this.PaymentType === ENUM_PAYMENT_TYPE.Transfer)
			{
				return "Transferred";
			}
			else if (this.Status >= ENUM_LETTER_STATUS.SuccessfullyPaid && this.PaymentType === ENUM_PAYMENT_TYPE.Cash)
			{
				return "Successfully Paid";
			}
			else
			{
				return "Need Payment Request";
			}
		}
		else
		{
			return "Need Payment Request";
		}
	}

	getPaymentType(): string
	{
		if (this.PaymentType != null && this.PaymentType !== undefined)
		{
			if (this.PaymentType === ENUM_PAYMENT_TYPE.Transfer)
			{
				return "Transfer";
			}
			else if (this.PaymentType === ENUM_PAYMENT_TYPE.Cash)
			{
				return "Cash";
			}
			else
			{
				return StringConstant.STRING_CHARACTER_EMPTY;
			}
		}
		else
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
	}

	getProgress(): number
	{
		let numberProgress: number = 0;

		if (this.FirstCheckerOn != null && this.FirstCheckerOn !== undefined)
		{
			numberProgress += 1;
		}
		if (this.FirstSignerOn != null && this.FirstSignerOn !== undefined)
		{
			numberProgress += 1;
		}
		if (this.SecondSignerOn != null && this.SecondSignerOn !== undefined)
		{
			numberProgress += 1;
		}

		return numberProgress;
	}

	getProgressPaymentRequest(): number
	{
		let numberProgress: number = 0;

		if(this.Status != null && this.Status !== undefined)
		{
			if (this.Status === ENUM_LETTER_STATUS.PendingForCheckerAKU)
			{
				numberProgress = 1;
			}
			else if (this.Status === ENUM_LETTER_STATUS.PendingForSignerAKU)
			{
				numberProgress = 2;
			}
			else if (this.Status === ENUM_LETTER_STATUS.PendingForPaid)
			{
				numberProgress = 3;
			}
			else if(this.Status === ENUM_LETTER_STATUS.Paid)
			{
				numberProgress = 4;
			}
			else if(this.Status >= ENUM_LETTER_STATUS.SuccessfullyPaid)
			{
				numberProgress = 5;
			}
		}
		else
		{

		}

		return numberProgress;
	}

	//#endregion


	//#region SETTER

	setModelUserReviewer(): void
	{
		const stringModelUserReviewer: string = JSON.stringify(this.modelUserReviewer);
		this.modelUserReviewer = new UserModel();
		this.modelUserReviewer.setModelFromString(stringModelUserReviewer);
	}

	setModelUserFirstChecker(): void
	{
		const stringModelUserFirstChecker: string = JSON.stringify(this.modelUserFirstChecker);
		this.modelUserFirstChecker = new UserModel();
		this.modelUserFirstChecker.setModelFromString(stringModelUserFirstChecker);
	}

	setModelUserSecondChecker(): void
	{
		const stringModelUserSecondChecker: string = JSON.stringify(this.modelUserSecondChecker);
		this.modelUserSecondChecker = new UserModel();
		this.modelUserSecondChecker.setModelFromString(stringModelUserSecondChecker);
	}

	setModelUserFirstSigner(): void
	{
		const stringModelUserFirstSigner: string = JSON.stringify(this.modelUserFirstSigner);
		this.modelUserFirstSigner = new UserModel();
		this.modelUserFirstSigner.setModelFromString(stringModelUserFirstSigner);
	}

	setModelUserSecondSigner(): void
	{
		const stringModelUserSecondSigner: string = JSON.stringify(this.modelUserSecondSigner);
		this.modelUserSecondSigner = new UserModel();
		this.modelUserSecondSigner.setModelFromString(stringModelUserSecondSigner);
	}

	setAndCleanForPaymentRequest(modelLetterGeneral: LetterGeneralModel, enumLetterTypeForComponent: ENUM_LETTERCOMPONENT_TYPE): void
	{
		this.FirstCheckerAKUBy = modelLetterGeneral.FirstCheckerAKUBy;

		if(modelLetterGeneral.SecondCheckerAKUBy !== undefined && modelLetterGeneral.SecondCheckerAKUBy !== null)
		{
			this.SecondCheckerAKUBy = modelLetterGeneral.SecondCheckerAKUBy;
		}
		else
		{
			modelLetterGeneral.SecondCheckerAKUBy = undefined;
		}

		this.FirstSignerAKUBy = modelLetterGeneral.FirstSignerAKUBy;
		this.DocumentNo = modelLetterGeneral.DocumentNo;
		this.SourceAccount = modelLetterGeneral.SourceAccount;
		this.VoucherType = modelLetterGeneral.VoucherType;
		this.Token = modelLetterGeneral.Token;

		if(enumLetterTypeForComponent === ENUM_LETTERCOMPONENT_TYPE.Declaration)
		{
			this.modelSubmission = undefined;

			if(this.modelDeclaration !== undefined && this.modelDeclaration.listModelDeclarationDetail !== undefined)
			{
				if(modelLetterGeneral.modelDeclaration !== undefined)
				{
					this.modelDeclaration.setModelFromObject(modelLetterGeneral.modelDeclaration);
					this.modelDeclaration.setListModelDeclarationDetail();
				}
				else
				{

				}

				this.modelDeclaration.cleanFootprintForInsert();

				for(const modelDeclarationDetailTemp of this.modelDeclaration.listModelDeclarationDetail)
				{
					modelDeclarationDetailTemp.setListModelDeclarationCost();
					modelDeclarationDetailTemp.cleanFootprintForPaymentRequest();

					if(modelDeclarationDetailTemp.listModelDeclarationCost !== undefined)
					{
						for(const modelDeclarationCostTemp of modelDeclarationDetailTemp.listModelDeclarationCost)
						{
							modelDeclarationCostTemp.cleanForInsert();
						}
					}
					else
					{

					}
				}
			}
			else
			{

			}
		}
		else if(enumLetterTypeForComponent === ENUM_LETTERCOMPONENT_TYPE.Submission)
		{
			this.modelDeclaration = undefined;

			if(this.modelSubmission !== undefined && this.modelSubmission.listModelSubmissionDetail !== undefined)
			{
				if(modelLetterGeneral.modelSubmission !== undefined)
				{
					this.modelSubmission.setModelFromObject(modelLetterGeneral.modelSubmission);
					this.modelSubmission.setListModelSubmissionDetail();
				}
				else
				{

				}

				this.modelSubmission.cleanFootprintForInsert();

				for(const modelSubmissionDetailTemp of this.modelSubmission.listModelSubmissionDetail)
				{
					modelSubmissionDetailTemp.setListModelSubmissionCost();
					modelSubmissionDetailTemp.cleanFootprintForPaymentRequest();

					if(modelSubmissionDetailTemp.listModelSubmissionCost !== undefined)
					{
						for(const modelSubmissionCostTemp of modelSubmissionDetailTemp.listModelSubmissionCost)
						{
							modelSubmissionCostTemp.cleanForInsert();
						}
					}
					else
					{

					}
				}
			}
			else
			{

			}
		}
		else
		{

		}

		this.modelUserMaker = undefined;
		this.modelUserFirstChecker = undefined;
		this.modelUserSecondChecker = undefined;
		this.modelUserFirstSigner = undefined;
		this.modelUserSecondSigner = undefined;
		this.modelUserReviewer = undefined;
		this.modelUserMakerAKU = undefined;
		this.modelUserFirstCheckerAKU = undefined;
		this.modelUserSecondCheckerAKU = undefined;
		this.modelUserFirstSignerAKU = undefined;
		this.modelUserSecondSignerAKU = undefined;
		this.modelDentalReimbursement = undefined;
		this.modelOpticReimbursement = undefined;
		this.modelPreMaternityReimbursement = undefined;
		this.modelPostMaternityReimbursement = undefined;
		this.modelMaternityReimbursement = undefined;
		this.modelDivision = undefined;
		this.modelBranch = undefined;
		this.modelVoucher = undefined;
		this.modelBankAccountSource = undefined;
	}

	setAfterParsingForPaymentRequest(): void
	{
		this.modelUserMaker = undefined;
		this.modelUserFirstChecker = undefined;
		this.modelUserSecondChecker = undefined;
		this.modelUserFirstSigner = undefined;
		this.modelUserSecondSigner = undefined;
		this.modelUserReviewer = undefined;
		this.modelUserMakerAKU = undefined;
		this.modelUserFirstCheckerAKU = undefined;
		this.modelUserSecondCheckerAKU = undefined;
		this.modelUserFirstSignerAKU = undefined;
		this.modelUserSecondSignerAKU = undefined;
		this.modelVoucher = undefined;
	}

	setAndClearForInsertVoucher(modelLetterGeneral: LetterGeneralModel): void
	{
		this.modelDeclaration = undefined;
		this.modelSubmission = undefined;
		this.modelUserFirstChecker = undefined;
		this.modelUserSecondChecker = undefined;
		this.modelUserFirstSigner = undefined;
		this.modelUserSecondSigner = undefined;
		this.modelUserReviewer = undefined;
		this.modelUserMakerAKU = undefined;
		this.modelUserFirstCheckerAKU = undefined;
		this.modelUserSecondCheckerAKU = undefined;
		this.modelUserFirstSignerAKU = undefined;
		this.modelUserSecondSignerAKU = undefined;
		this.modelDentalReimbursement = undefined;
		this.modelOpticReimbursement = undefined;
		this.modelPreMaternityReimbursement = undefined;
		this.modelPostMaternityReimbursement = undefined;
		this.modelMaternityReimbursement = undefined;
		this.modelBranch = undefined;
		this.modelDivision = undefined;
		this.modelVoucher = new VoucherModel();

		if(modelLetterGeneral.modelUserMaker !== undefined && modelLetterGeneral.modelUserMaker.modelDivision !== undefined && modelLetterGeneral.modelUserMaker.modelDivision.Code !== undefined && modelLetterGeneral.modelUserMaker.modelDivision.Code !== "")
		{
			this.modelVoucher.BranchCode = modelLetterGeneral.modelUserMaker.modelDivision.Code;
		}
		else if(modelLetterGeneral.modelUserMaker !== undefined && modelLetterGeneral.modelUserMaker.modelBranch !== undefined && modelLetterGeneral.modelUserMaker.modelBranch.Code !== undefined && modelLetterGeneral.modelUserMaker.modelBranch.Code !== "")
		{
			this.modelVoucher.BranchCode = modelLetterGeneral.modelUserMaker.modelBranch.Code;
		}
		else
		{

		}

		this.modelUserMaker = undefined;
		this.Token = modelLetterGeneral.Token;
		this.modelBankAccountSource = undefined;
	}

	//#endregion


	//#region VALIDATION

	validateData(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		const numberYear: number = new Date().getFullYear();
		modelResponse.setForValidation("Letter general");

		if (this.Title == null || this.Title === undefined || this.Title === "")
		{
			modelResponse.MessageContent = "Judul tidak boleh kosong!";
		}
		else if (this.Type == null || this.Type === undefined)
		{
			modelResponse.MessageContent = "Perihal tidak boleh kosong!";
		}
		else if (this.PaymentPriority == null || this.PaymentPriority === undefined)
		{
			modelResponse.MessageContent = "Prioritas pembayaran tidak boleh kosong!";
		}
		else if (this.PaymentType == null || this.PaymentType === undefined)
		{
			modelResponse.MessageContent = "Keterangan pembayaran tidak boleh kosong!";
		}
		else if (this.BudgetYear == null || this.BudgetYear === undefined)
		{
			modelResponse.MessageContent = "Tahun tidak boleh kosong";
		}
		else if (this.BudgetYear < numberYear - 1 || this.BudgetYear > numberYear)
		{
			modelResponse.MessageContent = `Tahun harus berjarak antara ${numberYear - 1} - ${numberYear}`;
		}
		else if (this.FirstCheckerBy == null || this.FirstCheckerBy === undefined)
		{
			modelResponse.MessageContent = "Checker tidak boleh kosong!";
		}
		else if (this.FirstSignerBy == null || this.FirstSignerBy === undefined)
		{
			modelResponse.MessageContent = "Signer tidak boleh kosong!";
		}
		else if (this.SecondSignerBy == null || this.SecondSignerBy === undefined)
		{
			modelResponse.MessageContent = "Final Signer tidak boleh kosong!";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateRejectedNote(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if (this.RejectedNote == null || this.RejectedNote === undefined || this.RejectedNote === "")
		{
			modelResponse.MessageContent = "Note cannot be empty!";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
		}

		return modelResponse;
	}

	validateDocumentToken(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Pengunduhan Lampiran.");
		const regularExpression: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABETNUMERIC);

		if (this.Token == null || this.Token === undefined)
		{
			modelResponse.MessageContent = "Token dokumen kosong! Silahkan hubungi developer!";
		}
		else if (!regularExpression.test(this.Token))
		{
			modelResponse.MessageContent = "Token tidak dalam format yang benar!";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateAddForDeclaration(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Letter general");

		if (this.modelDeclaration == null || this.modelDeclaration === undefined)
		{
			modelResponse.MessageContent = "Model declaration tidak boleh kosong!";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateAddForSubmission(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Letter general");

		if (this.modelSubmission == null || this.modelSubmission === undefined)
		{
			modelResponse.MessageContent = "Model declaration tidak boleh kosong!";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateTokenForUpdate(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Letter general");

		if (this.Token == null || this.Token === undefined || this.Token === "")
		{
			modelResponse.MessageContent = "Token can't be empty.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateDownloadForReimbursement(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Reimbursement general");

		if (this.modelOpticReimbursement == null || this.modelOpticReimbursement === undefined)
		{
			modelResponse.MessageContent = "Model optic reimbursement tidak boleh kosong!";
		}
		else if (this.modelPreMaternityReimbursement == null || this.modelPreMaternityReimbursement === undefined)
		{
			modelResponse.MessageContent = "Model prematernity reimbursement tidak boleh kosong!";
		}
		else if (this.modelPostMaternityReimbursement == null || this.modelPostMaternityReimbursement === undefined)
		{
			modelResponse.MessageContent = "Model post reimbursement tidak boleh kosong!";
		}
		else if (this.modelMaternityReimbursement == null || this.modelMaternityReimbursement === undefined)
		{
			modelResponse.MessageContent = "Model maternity reimbursement tidak boleh kosong!";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		return modelResponse;
	}

	validateCheckForDeclaration(): ResponseModel
	{
		let modelResponse: ResponseModel = this.validateTokenForUpdate();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateAddForDeclaration();
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.modelDeclaration?.validateCheckModelDeclarationDocument() ?? new ResponseModel();
		}
		else
		{

		}

		if(this.PaymentType === ENUM_PAYMENT_TYPE.Transfer)
		{
			if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
			{
				modelResponse = this.modelDeclaration?.validateCheckBankAccountVerification() ?? new ResponseModel();
			}
			else
			{

			}
		}
		else
		{

		}

		return modelResponse;
	}

	validateCheckForDeclarationCheckerSigner(): ResponseModel
	{
		let modelResponse: ResponseModel = this.validateTokenForUpdate();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateAddForDeclaration();
		}
		else
		{

		}

		return modelResponse;
	}

	validateCheckForSubmission(): ResponseModel
	{
		let modelResponse: ResponseModel = this.validateTokenForUpdate();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateAddForSubmission();
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.modelSubmission?.validateCheckModelSubmissionDocument() ?? new ResponseModel();
		}
		else
		{

		}

		if(this.PaymentType === ENUM_PAYMENT_TYPE.Transfer)
		{
			if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
			{
				modelResponse = this.modelSubmission?.validateCheckBankAccountVerification() ?? new ResponseModel();
			}
			else
			{

			}
		}
		else
		{

		}

		return modelResponse;
	}

	validateCheckForSubmissionCheckerSigner(): ResponseModel
	{
		let modelResponse: ResponseModel = this.validateTokenForUpdate();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateAddForSubmission();
		}
		else
		{

		}

		return modelResponse;
	}

	validateForPaymentRequest(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Payment Request");

		if (this.VoucherType == null || this.VoucherType === undefined)
		{
			modelResponse.MessageContent = "Please fill voucher type!";
		}
		else if (this.SourceAccount == null || this.SourceAccount === undefined)
		{
			modelResponse.MessageContent = "Please fill bank account!";
		}
		else if (this.FirstCheckerAKUBy == null || this.FirstCheckerAKUBy === undefined)
		{
			modelResponse.MessageContent = "Please select checker!";
		}
		else if (this.FirstSignerAKUBy == null || this.FirstSignerAKUBy === undefined)
		{
			modelResponse.MessageContent = "Please select signer!";
		}
		else if(this.SecondCheckerAKUBy !== undefined && this.SecondCheckerAKUBy !== null)
		{
			if(this.SecondCheckerAKUBy === this.FirstCheckerAKUBy)
			{
				modelResponse.MessageContent = "Second checker cannot be same with first checker!";
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateForBankAccountValidation(): ResponseModel
	{
		let modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Bank Account Validation");

		if(this.PaymentType === ENUM_PAYMENT_TYPE.Cash)
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{
			if (this.modelDeclaration !== null && this.modelDeclaration !== undefined && this.modelDeclaration.listModelDeclarationDetail !== undefined && this.modelDeclaration.listModelDeclarationDetail.length > 0)
			{
				modelResponse = this.modelDeclaration.validateCheckBankAccountVerification();
			}
			else if (this.modelSubmission !== null && this.modelSubmission !== undefined && this.modelSubmission.listModelSubmissionDetail !== undefined && this.modelSubmission.listModelSubmissionDetail.length > 0)
			{
				modelResponse = this.modelSubmission.validateCheckBankAccountVerification();
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}

		return modelResponse;
	}

	//#endregion


	//#region CLEAR

	private clearForInsert(): void
	{
		this.modelUserFirstChecker = undefined;
		this.modelUserSecondChecker = undefined;
		this.modelUserFirstSigner= undefined;
		this.modelUserSecondSigner = undefined;
		this.modelUserMaker = undefined;
		this.modelUserReviewer = undefined;
		this.modelVoucher = undefined;
		this.RejectedBy = undefined;
		this.RejectedOn = undefined;
		this.modelBranch = undefined;
		this.modelBankAccountSource = undefined;
		this.modelUserMakerAKU = undefined;
		this.modelUserFirstCheckerAKU = undefined;
		this.modelUserFirstSignerAKU = undefined;
		this.modelUserSecondCheckerAKU = undefined;
		this.modelUserSecondSignerAKU = undefined;
	}

	clearDeclarationForServer(clear: (listModelDeclarationVide?: Array<DeclarationVideModel>) => void): void
	{
		if (this.modelDeclaration !== undefined)
		{
			if(this.modelDeclaration.listModelDeclarationDetail !== undefined)
			{
				for(const modelDeclarationDetail of this.modelDeclaration.listModelDeclarationDetail)
				{
					modelDeclarationDetail.modelBankAccount = undefined;

					clear(modelDeclarationDetail.listModelDeclarationVide);
				}
			}
			else
			{

			}
		}
		else
		{

		}
	}

	clearDeclarationForInsert(): void
	{
		this.clearDeclarationForServer((arrayModelDeclarationVide) =>
		{
			if(arrayModelDeclarationVide !== undefined)
			{
				for (const modelDeclarationDetailVide of arrayModelDeclarationVide)
				{
					modelDeclarationDetailVide.modelAccountCharge = undefined;
				}
			}
			else
			{

			}
		});

		this.clearForInsert();
	}

	clearSubmissionForSearch(): void
	{
		this.clearSubmissionForServer((arrayModelSubmissionVide) =>
		{
			if (arrayModelSubmissionVide !== undefined)
			{
				arrayModelSubmissionVide = undefined;
			}
			else
			{

			}
		});

		this.clearForInsert();
	}

	clearSubmissionForServer(clear: (listModelSubmissionVide?: Array<SubmissionVideModel>) => void): void
	{
		if (this.modelSubmission !== undefined)
		{
			if(this.modelSubmission.listModelSubmissionDetail !== undefined)
			{
				for(const modelSubmissionDetail of this.modelSubmission.listModelSubmissionDetail)
				{
					modelSubmissionDetail.modelBankAccount = undefined;
					modelSubmissionDetail.modelCurrency = undefined;

					clear(modelSubmissionDetail.listModelSubmissionVide);
				}
			}
			else
			{

			}
		}
		else
		{

		}
	}

	clearDeclarationForSearch(): void
	{
		this.clearDeclarationForServer((arrayModelDeclarationVide) =>
		{
			if (arrayModelDeclarationVide !== undefined)
			{
				arrayModelDeclarationVide = undefined;
			}
			else
			{

			}
		});

		this.clearForInsert();
	}

	clearForDownload(): void
	{
		this.modelDeclaration = undefined;
		this.modelSubmission = undefined;
		this.modelUserFirstChecker = undefined;
		this.modelUserSecondChecker = undefined;
		this.modelUserFirstSigner = undefined;
		this.modelUserSecondSigner = undefined;
		this.modelVoucher = undefined;
		this.modelBankAccountSource = undefined;
	}

	clearAllForTokenOnly(): void
	{
		this.modelDeclaration = undefined;
		this.modelSubmission = undefined;
		this.modelUserMaker = undefined;
		this.modelUserFirstChecker = undefined;
		this.modelUserSecondChecker = undefined;
		this.modelUserFirstSigner = undefined;
		this.modelUserSecondSigner = undefined;
		this.modelUserReviewer = undefined;
		this.modelUserMakerAKU = undefined;
		this.modelUserFirstCheckerAKU = undefined;
		this.modelUserSecondCheckerAKU = undefined;
		this.modelUserFirstSignerAKU = undefined;
		this.modelUserSecondSignerAKU = undefined;
		this.modelDentalReimbursement = undefined;
		this.modelOpticReimbursement = undefined;
		this.modelPreMaternityReimbursement = undefined;
		this.modelPostMaternityReimbursement = undefined;
		this.modelMaternityReimbursement = undefined;
		this.listModelReimbursementTreatment = undefined;
		this.modelDivision = undefined;
		this.modelBranch = undefined;
		this.modelVoucher = undefined;
		this.modelBankAccountSource = undefined;
	}

	clearAfterParsingSelectDeclarationForPaymentRequest(): void
	{
		this.modelSubmission = undefined;
		this.modelUserFirstChecker = undefined;
		this.modelUserSecondChecker = undefined;
		this.modelUserFirstSigner = undefined;
		this.modelUserSecondSigner = undefined;
		this.modelUserReviewer = undefined;
		this.modelUserFirstCheckerAKU = undefined;
		this.modelUserSecondCheckerAKU = undefined;
		this.modelUserFirstSignerAKU = undefined;
		this.modelUserSecondSignerAKU = undefined;
		this.modelDentalReimbursement = undefined;
		this.modelOpticReimbursement = undefined;
		this.modelPreMaternityReimbursement = undefined;
		this.modelPostMaternityReimbursement = undefined;
		this.modelMaternityReimbursement = undefined;
		this.modelDivision = undefined;
		this.modelBranch = undefined;
		this.modelBankAccountSource = undefined;
	}

	clearAfterParsingSelectSubmissionForPaymentRequest(): void
	{
		this.modelDeclaration = undefined;
		this.modelUserFirstChecker = undefined;
		this.modelUserSecondChecker = undefined;
		this.modelUserFirstSigner = undefined;
		this.modelUserSecondSigner = undefined;
		this.modelUserReviewer = undefined;
		this.modelUserFirstCheckerAKU = undefined;
		this.modelUserSecondCheckerAKU = undefined;
		this.modelUserFirstSignerAKU = undefined;
		this.modelUserSecondSignerAKU = undefined;
		this.modelDentalReimbursement = undefined;
		this.modelOpticReimbursement = undefined;
		this.modelPreMaternityReimbursement = undefined;
		this.modelPostMaternityReimbursement = undefined;
		this.modelMaternityReimbursement = undefined;
		this.modelDivision = undefined;
		this.modelBranch = undefined;
		this.modelBankAccountSource = undefined;
	}

	//#endregion


	//#region GENERATE

	generateBudgetYear(): Array<number>
	{
		const arrayNumberBudgetYear: Array<number> = [];
		const numberYear: number = new Date().getFullYear();

		arrayNumberBudgetYear.push(numberYear - 1);
		arrayNumberBudgetYear.push(numberYear);

		return arrayNumberBudgetYear;
	}

	//#endregion
}

//#endregion