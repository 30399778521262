//#region IMPORT

import { NgModule } from "@angular/core";
import { ConvertToBroadcastURL, ConvertToProductURL, ConvertToUserURL } from "./hyperlink.pipe";
import { ConvertByteForPreview, ConvertCapitalizeEachWord, ConvertDateToDay, ConvertDateToMonth, ConvertEmptyNumberToDash, ConvertEmptyToDash, ConvertEmtpyNumberToZero, ConvertExtensionToIcon, ConvertNoDivisionToCustomer, ConvertSeparateEnumSpace, ConvertToDateMedium, ConvertToDateShort, ConvertToMaskingValue, ConvertToMonth, ConvertToTime, CurrencyValue, CurrencyValueWithNull, InitiateWithoutUTC } from "./converter.pipe";
import { TrimShort, TrimShortest } from "./trim.pipe";

//#endregion


//#region MODULE

@NgModule
(
	{
		declarations:
		[
			ConvertToProductURL,
			ConvertToDateMedium,
			ConvertToDateShort,
			ConvertEmptyToDash,
			ConvertEmptyNumberToDash,
			ConvertEmtpyNumberToZero,
			ConvertToMonth,
			InitiateWithoutUTC,
			CurrencyValue,
			CurrencyValueWithNull,
			ConvertSeparateEnumSpace,
			ConvertNoDivisionToCustomer,
			TrimShort,
			TrimShortest,
			ConvertByteForPreview,
			ConvertExtensionToIcon,
			ConvertToBroadcastURL,
			ConvertToUserURL,
			ConvertToTime,
			ConvertCapitalizeEachWord,
			ConvertDateToDay,
			ConvertToMaskingValue,
			ConvertDateToMonth,
		],
		exports:
		[
			ConvertToProductURL,
			ConvertToDateMedium,
			ConvertToDateShort,
			ConvertEmptyToDash,
			ConvertEmptyNumberToDash,
			ConvertEmtpyNumberToZero,
			ConvertToMonth,
			InitiateWithoutUTC,
			CurrencyValue,
			CurrencyValueWithNull,
			ConvertSeparateEnumSpace,
			ConvertNoDivisionToCustomer,
			TrimShort,
			TrimShortest,
			ConvertByteForPreview,
			ConvertExtensionToIcon,
			ConvertToBroadcastURL,
			ConvertToUserURL,
			ConvertToTime,
			ConvertCapitalizeEachWord,
			ConvertDateToDay,
			ConvertToMaskingValue,
			ConvertDateToMonth
		]
	}
)

//#endregion


//#region CLASS

export class PipeModule { }

//#endregion