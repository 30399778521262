//#region IMPORT

import { DragDropModule } from "@angular/cdk/drag-drop";
import { HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ServiceWorkerModule } from "@angular/service-worker";
import { NgSelectModule } from "@ng-select/ng-select";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { NgxEchartsModule } from "ngx-echarts";
import { ImageCropperModule } from "ngx-image-cropper";
import { IConfig, NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from "ngx-mask";
import { environment } from "src/environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { ApprovalReimbursementListComponent } from "./components/approvalreimbursement/approvalreimbursementlist/approvalreimbursementlist.component";
import { DentalreimbursementdetailComponent } from "./components/approvalreimbursement/dentalreimbursementdetail/dentalreimbursementdetail.component";
import { MaternityreimbursementdetailComponent } from "./components/approvalreimbursement/maternityreimbursementdetail/maternityreimbursementdetail.component";
import { OpticreimbursementdetailComponent } from "./components/approvalreimbursement/opticreimbursementdetail/opticreimbursementdetail.component";
import { PostmaternityreimbursementdetailComponent } from "./components/approvalreimbursement/postmaternityreimbursementdetail/postmaternityreimbursementdetail.component";
import { PrematernityreimbursementdetailComponent } from "./components/approvalreimbursement/prematernityreimbursementdetail/prematernityreimbursementdetail.component";
import { CheckDevToolsDirective } from "./components/bases/basecheckdevtools.directive";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { DashboardebudgetingComponent } from "./components/dashboards/dashboardebudgeting/dashboardebudgeting.component";
import { DashboardemateraiComponent } from "./components/dashboards/dashboardematerai/dashboardematerai.component";
import { DeclarationdetailComponent } from "./components/declarations/declarationdetail/declarationdetail.component";
import { DeclarationlistComponent } from "./components/declarations/declarationlist/declarationlist.component";
import { DeclarationverifierComponent } from "./components/declarations/declarationverifier/declarationverifier.component";
import { SubmissionDetailComponent } from "./components/digitalization/submissions/submissiondetail/submissiondetail.component";
import { SubmissionInsertComponent } from "./components/digitalization/submissions/submissioninsert/submissioninsert.component";
import { SubmissionListComponent } from "./components/digitalization/submissions/submissionlist/submissionlist.component";
import { DownloadComponent } from "./components/download/download.component";
import { ApprovalebudgetingdetailComponent } from "./components/ebudgeting/approval/approvalebudgetingdetail/approvalebudgetingdetail.component";
import { ApprovalebudgetinglistComponent } from "./components/ebudgeting/approval/approvalebudgetinglist/approvalebudgetinglist.component";
import { EbudgetingmasterdetailComponent } from "./components/ebudgeting/master/ebudgetingmasterdetail/ebudgetingmasterdetail.component";
import { EbudgetingmasterlistComponent } from "./components/ebudgeting/master/ebudgetingmasterlist/ebudgetingmasterlist.component";
import { EbudgetingmastersettingbudgetComponent } from "./components/ebudgeting/master/ebudgetingmastersettingbudget/ebudgetingmastersettingbudget.component";
import { EmateraicheckersignerdetailComponent } from "./components/ematerai/emateraicheckersignerdetail/emateraicheckersignerdetail.component";
import { EmateraicheckersignerlistComponent } from "./components/ematerai/emateraicheckersignerlist/emateraicheckersignerlist.component";
import { EmateraiinsertComponent } from "./components/ematerai/emateraiinsert/emateraiinsert.component";
import { EmaterailistComponent } from "./components/ematerai/ematerailist/ematerailist.component";
import { EmateraimakerdetailComponent } from "./components/ematerai/emateraimakerdetail/emateraimakerdetail.component";
import { EmateraimakerlistComponent } from "./components/ematerai/emateraimakerlist/emateraimakerlist.component";
import { AnnualdisclosurecheckersignerdetailComponent } from "./components/gcgonline/annualdisclosure/annualdisclosurecheckersignerdetail/annualdisclosurecheckersignerdetail.component";
import { AnnualdisclosurecheckersignerlistComponent } from "./components/gcgonline/annualdisclosure/annualdisclosurecheckersignerlist/annualdisclosurecheckersignerlist.component";
import { AnnualdisclosuredetailComponent } from "./components/gcgonline/annualdisclosure/annualdisclosuredetail/annualdisclosuredetail.component";
import { AnnualdisclosureinsertComponent } from "./components/gcgonline/annualdisclosure/annualdisclosureinsert/annualdisclosureinsert.component";
import { AnnualdisclosurelistComponent } from "./components/gcgonline/annualdisclosure/annualdisclosurelist/annualdisclosurelist.component";
import { CodeofethicscheckersignerdetailComponent } from "./components/gcgonline/codeofethics/codeofethicscheckersignerdetail/codeofethicscheckersignerdetail.component";
import { CodeofethicscheckersignerlistComponent } from "./components/gcgonline/codeofethics/codeofethicscheckersignerlist/codeofethicscheckersignerlist.component";
import { CodeofethicsdetailComponent } from "./components/gcgonline/codeofethics/codeofethicsdetail/codeofethicsdetail.component";
import { CodeofethicsinsertComponent } from "./components/gcgonline/codeofethics/codeofethicsinsert/codeofethicsinsert.component";
import { CodeofethicslistComponent } from "./components/gcgonline/codeofethics/codeofethicslist/codeofethicslist.component";
import { GratificationdetailComponent } from "./components/gcgonline/gratification/gratificationdetail/gratificationdetail.component";
import { GratificationlistComponent } from "./components/gcgonline/gratification/gratificationlist/gratificationlist.component";
import { IntegritypactdetailComponent } from "./components/gcgonline/integritypact/integritypactdetail/integritypactdetail.component";
import { IntegritypactinsertComponent } from "./components/gcgonline/integritypact/integritypactinsert/integritypactinsert.component";
import { IntegritypactlistComponent } from "./components/gcgonline/integritypact/integritypactlist/integritypactlist.component";
import { ProfilegcgonlineComponent } from "./components/gcgonline/profilegcgonline/profilegcgonline.component";
import { HomeComponent } from "./components/home/home.component";
import { BarchartIncludeComponent } from "./components/includes/barchart.include/barchart.include.component";
import { GroupbarchartIncludeComponent } from "./components/includes/groupbarchart.include/groupbarchart.include.component";
import { PiechartIncludeComponent } from "./components/includes/piechart.include/piechart.include.component";
import { TableControlIncludeComponent } from "./components/includes/tablecontrol.include/tablecontrol.include.component";
import { PaymentrequestcheckersignerdetailComponent } from "./components/paymentrequest/paymentrequestcheckersignerdetail/paymentrequestcheckersignerdetail.component";
import { PaymentrequestcheckersignerlistComponent } from "./components/paymentrequest/paymentrequestcheckersignerlist/paymentrequestcheckersignerlist.component";
import { PaymentrequestmakerdetailComponent } from "./components/paymentrequest/paymentrequestmakerdetail/paymentrequestmakerdetail.component";
import { PaymentrequestmakerlistComponent } from "./components/paymentrequest/paymentrequestmakerlist/paymentrequestmakerlist.component";
import { PaymentsubmissiondetailComponent } from "./components/paymentsubmission/paymentsubmissiondetail/paymentsubmissiondetail.component";
import { PaymentsubmissionlistComponent } from "./components/paymentsubmission/paymentsubmissionlist/paymentsubmissionlist.component";
import { PaymentsubmissionrejectedlistComponent } from "./components/paymentsubmission/paymentsubmissionrejectedlist/paymentsubmissionrejectedlist.component";
import { AnomalyDetailComponent } from "./components/protect/anomaly/anomalydetail/anomalydetail.component";
import { AnomalyDownloadComponent } from "./components/protect/anomaly/anomalydownload/anomalydownload.component";
import { AnomalyInsertComponent } from "./components/protect/anomaly/anomalyinsert/anomalyinsert.component";
import { AnomalyListComponent } from "./components/protect/anomaly/anomalylist/anomalylist.component";
import { AnomalyNotificationComponent } from "./components/protect/anomalynotification/anomalynotification.component";
import { DashboardrasdetailComponent } from "./components/ras/dashboardras/dashboardrasdetail/dashboardrasdetail.component";
import { DashboardraslistComponent } from "./components/ras/dashboardras/dashboardraslist/dashboardraslist.component";
import { FollowupplandetailComponent } from "./components/ras/followupplan/followupplandetail/followupplandetail.component";
import { FollowupplanlistComponent } from "./components/ras/followupplan/followupplanlist/followupplanlist.component";
import { RealizationapprovaldetailComponent } from "./components/ras/realization/realizationapprovaldetail/realizationapprovaldetail.component";
import { RealizationapprovallistComponent } from "./components/ras/realization/realizationapprovallist/realizationapprovallist.component";
import { RealizationlistComponent } from "./components/ras/realization/realizationlist/realizationlist.component";
import { RealizationmonitoringdetailComponent } from "./components/ras/realization/realizationmonitoringdetail/realizationmonitoringdetail.component";
import { RealizationmonitoringlistComponent } from "./components/ras/realization/realizationmonitoringlist/realizationmonitoringlist.component";
import { RealizationparameterdetailComponent } from "./components/ras/realization/realizationparameterdetail/realizationparameterdetail.component";
import { RealizationsignerapprovaldetailComponent } from "./components/ras/realization/realizationsignerapprovaldetail/realizationsignerapprovaldetail.component";
import { RealizationsignerapprovallistComponent } from "./components/ras/realization/realizationsignerapprovallist/realizationsignerapprovallist.component";
import { ApprovalrcsadetailComponent } from "./components/rcsa/approval/approvalrcsadetail/approvalrcsadetail.component";
import { ApprovalrcsalistComponent } from "./components/rcsa/approval/approvalrcsalist/approvalrcsalist.component";
import { AssessmentdetailComponent } from "./components/rcsa/assessment/assessmentdetail/assessmentdetail.component";
import { AssessmentlistComponent } from "./components/rcsa/assessment/assessmentlist/assessmentlist.component";
import { ReportdetailComponent } from "./components/rcsa/report/reportdetail/reportdetail.component";
import { ReportrcsalistComponent } from "./components/rcsa/report/reportrcsalist/reportrcsalist.component";
import { RiskissuerequestdetailComponent } from "./components/rcsa/riskissuerequest/riskissuerequestdetail/riskissuerequestdetail.component";
import { RiskissuerequestlistComponent } from "./components/rcsa/riskissuerequest/riskissuerequestlist/riskissuerequestlist.component";
import { ReportMonitoringDigitalChannelComponent } from "./components/reportmonitoringdigitalchannel/reportmonitoringdigitalchannel.component";
import { CashflowComponent } from "./components/reports/cashflow/cashflow.component";
import { ReportebudgetingComponent } from "./components/reports/reportebudgeting/reportebudgeting.component";
import { ReportemateraiComponent } from "./components/reports/reportematerai/reportematerai.component";
import { AppComponent } from "./components/root/app.component";
import { SigninComponent } from "./components/signin/signin.component";
import { SubmissiondetailComponent } from "./components/submissions/submissiondetail/submissiondetail.component";
import { SubmissionlistComponent } from "./components/submissions/submissionlist/submissionlist.component";
import { SubmissionverifierComponent } from "./components/submissions/submissionverifier/submissionverifier.component";
import { TicketdetailComponent } from "./components/tickets/ticketdetail/ticketdetail.component";
import { TicketlistinboxComponent } from "./components/tickets/ticketlistinbox/ticketlistinbox.component";
import { TicketlistoutboxComponent } from "./components/tickets/ticketlistoutbox/ticketlistoutbox.component";
import { TicketReportComponent } from "./components/tickets/ticketreport/ticketreport/ticketreport.component";
import { TicketReportSatisfactionComponent } from "./components/tickets/ticketreport/ticketreportsatisfaction/ticketreportsatisfaction.component";
import { TicketSatisfactionComponent } from "./components/tickets/ticketsatisfaction/ticketsatisfaction.component";
import { UserverifierComponent } from "./components/userverifier/userverifier.component";
import { VoucherpaymentcheckdetailComponent } from "./components/vouchers/voucherpaymentcheckdetail/voucherpaymentcheckdetail.component";
import { VoucherpaymentchecklistComponent } from "./components/vouchers/voucherpaymentchecklist/voucherpaymentchecklist.component";
import { VoucherpaymentmakerdetailComponent } from "./components/vouchers/voucherpaymentmakerdetail/voucherpaymentmakerdetail.component";
import { VoucherpaymentmakerlistComponent } from "./components/vouchers/voucherpaymentmakerlist/voucherpaymentmakerlist.component";
import { VoucherpaymentsignerdetailComponent } from "./components/vouchers/voucherpaymentsignerdetail/voucherpaymentsignerdetail.component";
import { VoucherpaymentsignerlistComponent } from "./components/vouchers/voucherpaymentsignerlist/voucherpaymentsignerlist.component";
import { PipeModule } from "./functions/pipes/pipe.module";
import { PwaService } from "./services/pwa.service";
import { TransactiondetailComponent } from "./components/apu-ppt/transaction/transactiondetail/transactiondetail.component";
import { TransactionlistComponent } from "./components/apu-ppt/transaction/transactionlist/transactionlist.component";
import { CustomerdataComponent } from "./components/apu-ppt/customerdata/customerdata.component";
import { RiskbasedassessmentComponent } from "./components/apu-ppt/riskbasedassessment/riskbasedassessment.component";
import { ApprovalsubmissioncheckersignerdetailComponent } from "./components/ebudgeting/approval/approvalsubmissioncheckersignerdetail/approvalsubmissioncheckersignerdetail.component";
import { ApprovalsubmissioncheckersignerlistComponent } from "./components/ebudgeting/approval/approvalsubmissioncheckersignerlist/approvalsubmissioncheckersignerlist.component";
import { DashboardbrinsaveComponent } from "./components/brinsave/dashboard/dashboardbrinsave.component";
import { AllocationdetailComponent } from "./components/brinsave/management/allocation/allocationdetail/allocationdetail.component";
import { AllocationlistComponent } from "./components/brinsave/management/allocation/allocationlist/allocationlist.component";
import { ArchivedatadetailComponent } from "./components/brinsave/management/archivedata/archivedatadetail/archivedatadetail.component";
import { ArchivedatalistComponent } from "./components/brinsave/management/archivedata/archivedatalist/archivedatalist.component";
import { ArchiveloandetailComponent } from "./components/brinsave/management/archiveloan/archiveloandetail/archiveloandetail.component";
import { ArchiveloanlistComponent } from "./components/brinsave/management/archiveloan/archiveloanlist/archiveloanlist.component";
import { ArchivereallocationdetailComponent } from "./components/brinsave/management/reallocation/archivereallocation/archivereallocationdetail/archivereallocationdetail.component";
import { ArchivereallocationlistComponent } from "./components/brinsave/management/reallocation/archivereallocation/archivereallocationlist/archivereallocationlist.component";
import { UploadbrinsavedocumentComponent } from "./components/brinsave/management/uploaddocument/uploadbrinsavedocument.component";
import { BrinsaveReportComponent } from "./components/brinsave/report/report.component";
import { ArchiveshelfreallocationdetailComponent } from "./components/brinsave/management/reallocation/archiveshelfreallocation/archiveshelfreallocationdetail/archiveshelfreallocationdetail.component";
import { ArchiveshelfreallocationlistComponent } from "./components/brinsave/management/reallocation/archiveshelfreallocation/archiveshelfreallocationlist/archiveshelfreallocationlist.component";
import { PegadaianTransactionMonitoringComponent } from "./components/pegadaian/pegadaiantransactionmonitoring/pegadaiantransactionmonitoring.component";
import { ProjectmanagementlistComponent } from "./components/brinspro/projectmanagement/projectmanagementlist/projectmanagementlist.component";
import { ProjectmanagementdetailComponent } from "./components/brinspro/projectmanagement/projectmanagementdetail/projectmanagementdetail.component";
import { ProjectmanagementdashboardComponent } from "./components/brinspro/projectmanagementdashboard/projectmanagementdashboard.component";
import { ProjectmanagementdocumentdetailComponent } from "./components/brinspro/projectmanagementdocument/projectmanagementdocumentdetail/projectmanagementdocumentdetail.component";
import { ProjectmanagementdocumentlistComponent } from "./components/brinspro/projectmanagementdocument/projectmanagementdocumentlist/projectmanagementdocumentlist.component";
import { DashboardewsdetailComponent } from "./components/earlywarningsignal/dashboardews/dashboardewsdetail/dashboardewsdetail.component";
import { DashboardewslistComponent } from "./components/earlywarningsignal/dashboardews/dashboardewslist/dashboardewslist.component";
import { FollowupplanewsdetailComponent } from "./components/earlywarningsignal/followupplanews/followupplanewsdetail/followupplanewsdetail.component";
import { FollowupplanewsinsertComponent } from "./components/earlywarningsignal/followupplanews/followupplanewsinsert/followupplanewsinsert.component";
import { FollowupplanewslistComponent } from "./components/earlywarningsignal/followupplanews/followupplanewslist/followupplanewslist.component";
import { FollowupplanmonitoringewsdetailComponent } from "./components/earlywarningsignal/followupplanews/followupplanmonitoringews/followupplanmonitoringewsdetail/followupplanmonitoringewsdetail.component";
import { FollowupplanmonitoringewslistComponent } from "./components/earlywarningsignal/followupplanews/followupplanmonitoringews/followupplanmonitoringewslist/followupplanmonitoringewslist.component";
import { ReportewsComponent } from "./components/earlywarningsignal/reportews/reportews.component";
import { FinancialReportListComponent } from "./components/contentmanagementsystem/financialreport/financialreportlist/financialreportlist.component";
import { FinancialReportInsertComponent } from "./components/contentmanagementsystem/financialreport/financialreportinsert/financialreportinsert.component";


//#endregion


//#region MODULE

const initializer = (pwaService: PwaService) => () => pwaService.setupInstaller();
const maskConfig: Partial<IConfig> = {
	validation: false,
};

@NgModule
(
	{
		declarations:
		[
			AppComponent,
			SigninComponent,
			HomeComponent,
			DashboardComponent,
			TableControlIncludeComponent,
			BarchartIncludeComponent,
			GroupbarchartIncludeComponent,
			PiechartIncludeComponent,
			TicketdetailComponent,
			TicketlistinboxComponent,
			TicketlistoutboxComponent,
			TicketReportComponent,
			TicketSatisfactionComponent,
			TicketReportSatisfactionComponent,
			CashflowComponent,
			VoucherpaymentmakerlistComponent,
			VoucherpaymentmakerdetailComponent,
			VoucherpaymentsignerlistComponent,
			VoucherpaymentsignerdetailComponent,
			VoucherpaymentchecklistComponent,
			VoucherpaymentcheckdetailComponent,
			DeclarationlistComponent,
			DeclarationdetailComponent,
			SubmissionlistComponent,
			SubmissiondetailComponent,
			DownloadComponent,
			UserverifierComponent,
			DeclarationverifierComponent,
			SubmissionverifierComponent,
			EmateraiinsertComponent,
			EmaterailistComponent,
			ReportemateraiComponent,
			ReportebudgetingComponent,
			ApprovalReimbursementListComponent,
			DentalreimbursementdetailComponent,
			OpticreimbursementdetailComponent,
			PostmaternityreimbursementdetailComponent,
			PrematernityreimbursementdetailComponent,
			MaternityreimbursementdetailComponent,
			AssessmentlistComponent,
			AssessmentdetailComponent,
			ApprovalrcsalistComponent,
			ApprovalrcsadetailComponent,
			ReportdetailComponent,
			ReportrcsalistComponent,
			RiskissuerequestlistComponent,
			RiskissuerequestdetailComponent,
			SubmissionInsertComponent,
			SubmissionListComponent,
			SubmissionDetailComponent,
			PaymentrequestmakerlistComponent,
			PaymentrequestmakerdetailComponent,
			PaymentrequestcheckersignerlistComponent,
			PaymentrequestcheckersignerdetailComponent,
			PaymentsubmissionlistComponent,
			PaymentsubmissiondetailComponent,
			PaymentsubmissionrejectedlistComponent,
			EmateraimakerlistComponent,
			EmateraimakerdetailComponent,
			EmateraicheckersignerlistComponent,
			EmateraicheckersignerdetailComponent,
			EbudgetingmasterlistComponent,
			EbudgetingmasterdetailComponent,
			EbudgetingmastersettingbudgetComponent,
			ApprovalebudgetinglistComponent,
			ApprovalebudgetingdetailComponent,
			DashboardebudgetingComponent,
			ApprovalsubmissioncheckersignerlistComponent,
			ApprovalsubmissioncheckersignerdetailComponent,
			DashboardemateraiComponent,
			RealizationlistComponent,
			RealizationapprovallistComponent,
			RealizationapprovaldetailComponent,
			RealizationparameterdetailComponent,
			RealizationsignerapprovallistComponent,
			RealizationsignerapprovaldetailComponent,
			FollowupplanlistComponent,
			FollowupplandetailComponent,
			AnomalyListComponent,
			AnomalyInsertComponent,
			AnomalyDetailComponent,
			AnomalyDownloadComponent,
			AnomalyNotificationComponent,
			CheckDevToolsDirective,
			RealizationmonitoringlistComponent,
			RealizationmonitoringdetailComponent,
			DashboardraslistComponent,
			DashboardrasdetailComponent,
			ReportMonitoringDigitalChannelComponent,
			ProfilegcgonlineComponent,
			GratificationlistComponent,
			GratificationdetailComponent,
			IntegritypactlistComponent,
			IntegritypactinsertComponent,
			IntegritypactdetailComponent,
			CodeofethicslistComponent,
			CodeofethicsinsertComponent,
			CodeofethicsdetailComponent,
			CodeofethicscheckersignerlistComponent,
			CodeofethicscheckersignerdetailComponent,
			AnnualdisclosurelistComponent,
			AnnualdisclosureinsertComponent,
			AnnualdisclosuredetailComponent,
			AnnualdisclosurecheckersignerlistComponent,
			AnnualdisclosurecheckersignerdetailComponent,
			TransactiondetailComponent,
			TransactionlistComponent,
			CustomerdataComponent,
			RiskbasedassessmentComponent,
			DashboardbrinsaveComponent,
			UploadbrinsavedocumentComponent,
			AllocationlistComponent,
			AllocationdetailComponent,
			ArchivedatalistComponent,
			ArchivedatadetailComponent,
			ArchiveloanlistComponent,
			ArchiveloandetailComponent,
			ArchivereallocationlistComponent,
			ArchivereallocationdetailComponent,
			ArchiveshelfreallocationlistComponent,
			ArchiveshelfreallocationdetailComponent,
			BrinsaveReportComponent,
			PegadaianTransactionMonitoringComponent,
			ProjectmanagementlistComponent,
			ProjectmanagementdetailComponent,
			ProjectmanagementdashboardComponent,
			ProjectmanagementdocumentdetailComponent,
			ProjectmanagementdocumentlistComponent,
			DashboardewslistComponent,
			DashboardewsdetailComponent,
			FollowupplanewslistComponent,
			FollowupplanewsinsertComponent,
			FollowupplanewsdetailComponent,
			FollowupplanmonitoringewslistComponent,
			FollowupplanmonitoringewsdetailComponent,
			ReportewsComponent,
			FinancialReportListComponent,
			FinancialReportInsertComponent,
		],
		imports:
		[
			BrowserModule,
			AppRoutingModule,
			FormsModule,
			HttpClientModule,
			PipeModule,
			BrowserAnimationsModule,
			MatNativeDateModule,
			MatInputModule,
			MatDatepickerModule,
			NgSelectModule,
			ImageCropperModule,
			PdfViewerModule,
			DragDropModule,
			NgxMaskDirective,
			NgxMaskPipe,
			NgxEchartsModule.forRoot
			(
				{
					echarts: () => import("echarts")
				}
			),
			ServiceWorkerModule.register("ngsw-worker.js", {
				enabled: environment.serviceWorker,
				// Register the ServiceWorker as soon as the app is stable
				// or after 30 seconds (whichever comes first).
				registrationStrategy: "registerWhenStable:30000"
			})
		],
		providers:
		[
			{
				provide: APP_INITIALIZER, useFactory: initializer, deps: [PwaService], multi: true
			},
			provideEnvironmentNgxMask(maskConfig),
		],
		bootstrap: [AppComponent]
	}
)

//#endregion


//#region CLASS

export class AppModule { }

//#endregion