/* eslint-disable no-unused-vars */
//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { TableModel } from "../models/bases/table.model";
import { ReimbursementGeneralModel } from "../models/reimbursementgeneral.model";
import { PreMaternityReimbursementModel } from "../models/prematernityreimbursement.model";
import { PreMaternityReimbursementGalleryModel } from "../models/prematernityreimbursementgallery.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class PrematernityreimbursementService extends BaseService
{
	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	// #region SELECT

	selectPreMaternityReimbursementGeneralByAttributesForChecker(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_PREMATERNITYREIMBURSEMENT_SELECTPREMATERNITYREIMBURSEMENTBYATTRIBUTESFORCHECKER);
	}

	selectPreMaternityReimbursementByAttributesForSigner(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_PREMATERNITYREIMBURSEMENT_SELECTPREMATERNITYREIMBURSEMENTBYATTRIBUTESFORSIGNER);
	}

	selectPreMaternityReimbursementByToken(interfaceGeneralService: GeneralServiceInterface, modelReimbursementGeneral: ReimbursementGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelReimbursementGeneral), WebAddressConstant.STRING_URL_PREMATERNITYREIMBURSEMENT_SELECTPREMATERNITYREIMBURSEMENTBYTOKEN);
	}

	// #endregion


	//#region APPROVAL

	checkPreMaternityReimbursementByToken(interfaceGeneralService: GeneralServiceInterface, modelReimbursementGeneral: ReimbursementGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelReimbursementGeneral), WebAddressConstant.STRING_URL_PREMATERNITYREIMBURSEMENT_CHECKPREMATERNITYREIMBURSEMENTBYTOKEN);
	}

	signPreMaternityReimbursementByToken(interfaceGeneralService: GeneralServiceInterface, modelReimbursementGeneral: ReimbursementGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelReimbursementGeneral), WebAddressConstant.STRING_URL_PREMATERNITYREIMBURSEMENT_SIGNPREMATERNITYREIMBURSEMENTBYTOKEN);
	}

	rejectPreMaternityReimbursementByToken(interfaceGeneralService: GeneralServiceInterface, modelReimbursementGeneral: ReimbursementGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelReimbursementGeneral), WebAddressConstant.STRING_URL_PREMATERNITYREIMBURSEMENT_REJECTPREMATERNITYREIMBURSEMENTBYTOKEN);
	}

	revisePreMaternityReimbursementByToken(interfaceGeneralService: GeneralServiceInterface, modelReimbursementGeneral: ReimbursementGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelReimbursementGeneral), WebAddressConstant.STRING_URL_PREMATERNITYREIMBURSEMENT_REVISEPREMATERNITYREIMBURSEMENTBYTOKEN);
	}

	//#endregion


	//#region DOWNLOAD

	downloadPreMaternityReimbursementGalleryByToken(interfaceGeneralService: GeneralServiceInterface, modelPreMaternityReimbursementGallery: PreMaternityReimbursementGalleryModel): void
	{
		this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelPreMaternityReimbursementGallery), WebAddressConstant.STRING_URL_PREMATERNITYREIMBURSEMENT_DOWNLOADPREMATERNITYREIMBURSEMENTGALLERYBYTOKEN);
	}

	//#endregion

}

//#endregion