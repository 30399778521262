/* eslint-disable max-len */
//#region IMPORT

import { ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { StringConstant } from "../constants/string.constant";
import { BaseModel } from "./bases/base.model";
import { ProductAsriReportModel } from "./productasrireport.model";
import { ProductAsriSyariahReportModel } from "./productasrisyariahreport.model";
import { ProductBicycleReportModel } from "./productbicyclereport.model";
import { ProductDamageOfBusinessPlaceReportModel } from "./productdamageofbusinessplacereport.model";
import { ProductDamageOfBusinessPlaceSyariahReportModel } from "./productdamageofbusinessplacesyariahreport.model";
import { ProductMyHouseReportModel } from "./productmyhousereport.model";
import { ProductMyHouseSyariahReportModel } from "./productmyhousesyariahreport.model";
import { ProductMyMotorcycleReportModel } from "./productmymotorcyclereport.model";
import { ProductOtoReportModel } from "./productotoreport.model";
import { ProductOtoSyariahReportModel } from "./productotosyariahreport.model";
import { ProductPersonalAccidentReportModel } from "./productpersonalaccidentreport.model";
import { ProductPersonalAccidentSyariahReportModel } from "./productpersonalaccidentsyariahreport.model";
import { ResponseModel } from "./response.model";

//#endregion


//#region CLASS

export class ProductGeneralReportModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for product GENERAL report.
		Author: Dimas Dandy Aryarajendra Suprapto.
		Created on : Tuesday, 17 January 2022. 		    		Updated on : -.
		Created by : Dimas Dandy Aryarajendra Suprapto.			Updated by : -.
		Version : 1.0:0.
	*/

	OrderDateStartRange?: Date;
	OrderDateEndRange?: Date;

	ProductName?: string;
	Channel?: string;
	OrderDate?: string;
	ReferenceNumber?: string;
	BranchName?: string;
	PolicyNumber?: string;
	CoverageName?: string;
	PolicyStartDate?: string;
	PolicyEndDate?: string;
	PolicyholderFullName?: string;
	PolicyholderIdentificationNumber?: string;
	PolicyholderPhoneNumber?: string;
	InsuredFullName?: string;
	InsuredAddress?: string;
	BeneficiaryFullName?: string;
	BeneficiaryRelationship?: string;
	BeneficiaryAddress?: string;
	BeneficiaryPhoneNumber?: string;
	RiskLocationAddress?: string;
	RiskProvince?: string;
	RiskCity?: string;
	PremiumAmount?: string;
	AdministrationAmount?: string;
	DiscountAmount?: string;
	PromoCode?: string;
	PremiumTotalAmount?: string;
	PaymentMethod?: string;
	PaymentDate?: string;
	ReferralCode?: string;
	ReferralOwnerFullName?: string;
	ReferralOwnerIdentificationNumber?: string;
	ReferralOwnerPhoneNumber?: string;
	Note?: string;
	BRIVANumber?: string;

	modelProductAsriReport?: ProductAsriReportModel;
	modelProductOtoReport?: ProductOtoReportModel;
	modelProductBicycleReport?: ProductBicycleReportModel;
	modelProductPersonalAccidentReport?: ProductPersonalAccidentReportModel;
	modelProductDamageOfBusinessPlaceReport?: ProductDamageOfBusinessPlaceReportModel;
	modelProductMyHouseReport?: ProductMyHouseReportModel;
	modelProductMyMotorcycleReport?: ProductMyMotorcycleReportModel;
	modelProductAsriSyariahReport?: ProductAsriSyariahReportModel;
	modelProductOtoSyariahReport?: ProductOtoSyariahReportModel;
	modelProductPersonalAccidentSyariahReport?: ProductPersonalAccidentSyariahReportModel;
	modelProductDamageOfBusinessPlaceSyariahReport?: ProductDamageOfBusinessPlaceSyariahReportModel;
	modelProductMyHouseSyariahReport?: ProductMyHouseSyariahReportModel;

	/* Attribute - END */


	//#region  VALIDATION

	validatePeriod(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Product General Report");

		if (this.OrderDateStartRange == null || this.OrderDateStartRange === undefined)
		{
			modelResponse.MessageContent = "Start date can't be empty.";
		}
		else if (this.OrderDateEndRange == null || this.OrderDateEndRange === undefined)
		{
			modelResponse.MessageContent = "End date can't be empty.";
		}
		else if (this.OrderDateEndRange < this.OrderDateStartRange)
		{
			modelResponse.MessageContent = "End date can't be less than Start date.";
		}
		else if (this.Channel === StringConstant.STRING_CHANNEL_BRIMO && this.ProductName === StringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_PERSONALACCIDENT || this.Channel === StringConstant.STRING_CHANNEL_BRIMO && this.ProductName === StringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_DAMAGEOFBUSINESSPLACE || this.Channel === StringConstant.STRING_CHANNEL_BRIMO && this.ProductName === StringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_MYHOUSE || this.Channel === StringConstant.STRING_CHANNEL_BRIMO && this.ProductName === StringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_MYMOTORCYCLE || this.Channel === StringConstant.STRING_CHANNEL_NDSUB && this.ProductName === StringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_PERSONALACCIDENT)
		{
			modelResponse.MessageContent = "Please Choose Product.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
		}

		return modelResponse;
	}

	//#endregion

}

//#endregion