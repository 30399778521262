/* eslint-disable max-len */
//#region IMPORT

import { Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ENUM_RATINGPRODUCT_TYPE, ENUM_RESPONSE_STATE } from "src/app/constants/enum.constant";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { TableModel } from "src/app/models/bases/table.model";
import { BranchModel } from "src/app/models/branch.model";
import { ProductGeneralReportModel } from "src/app/models/productgeneralreport.model";
import { PurchaseRatingReviewsModel } from "src/app/models/purchaseratingreviews.model";
import { ResponseModel } from "src/app/models/response.model";
import { BranchService } from "src/app/services/branch.service";
import { ProductGeneralReportService } from "src/app/services/productgeneralreport.service";
import { PurchaseRatingReviewsService } from "src/app/services/purchaseratingreviews.service";
import { SessionService } from "src/app/services/session.service";
import * as xlsx from "xlsx";
import { BaseAuthourizeComponent } from "../bases/baseauthourize.component";
import { TableControlIncludeComponent } from "../includes/tablecontrol.include/tablecontrol.include.component";

//#endregion


//#region COMPONENT

@Component({
	selector: "app-reportmonitoringdigitalchannel",
	templateUrl: "./reportmonitoringdigitalchannel.component.html",
	styleUrls: ["./reportmonitoringdigitalchannel.component.sass"]
})

//#endregion


//#region CLASS

export class ReportMonitoringDigitalChannelComponent extends BaseAuthourizeComponent
{

	//#region DECLARATION

	@ViewChild(TableControlIncludeComponent) private _componentTableControlInclude!: TableControlIncludeComponent;
	// @ViewChild("componentTableControleIncledAsri", {static: false}) private _componentTableControlIncludeAsri!: TableControlIncludeComponent;
	// @ViewChild("componentTableControleIncledOto", {static: false}) private _componentTableControlIncludeOto!: TableControlIncludeComponent;

	public _stringConstant = StringConstant;
	public _stringUndefined = undefined;

	public _dateStart: Date;
	public _dateEnd: Date;

	public _modelTable: TableModel;
	public _modelTableReportAsri: TableModel;
	public _modelTableReportOto: TableModel;
	public _modelBranch: BranchModel;

	public _modelProductGeneralReport: ProductGeneralReportModel;
	public _modelProductGeneralReportRequest: ProductGeneralReportModel;
	public _modelPurchaseRatingReviews: PurchaseRatingReviewsModel;

	public _arrayModelBranch: Array<BranchModel>;
	public _arrayModelProductGeneralReport: Array<ProductGeneralReportModel>;
	public _arrayModelProductGeneralForAsriReport: Array<ProductGeneralReportModel>;
	public _arrayModelProductGeneralForOtoReport: Array<ProductGeneralReportModel>;
	public _arrayModelProductGeneralForBicycleReport: Array<ProductGeneralReportModel>;
	public _arrayModelProductGeneralForPersonalAccidentReport: Array<ProductGeneralReportModel>;
	public _arrayModelProductGeneralForDamageOfBusinessPlaceReport: Array<ProductGeneralReportModel>;
	public _arrayModelProductGeneralForMyHouseReport: Array<ProductGeneralReportModel>;
	public _arrayModelProductGeneralForMyMotorcycleReport: Array<ProductGeneralReportModel>;
	public _arrayModelPurhcaseRatingReviews: Array<PurchaseRatingReviewsModel>;
	public _arrayModelProductGeneralForAsriSyariahReport: Array<ProductGeneralReportModel>;
	public _arrayModelProductGeneralForOtoSyariahReport: Array<ProductGeneralReportModel>;
	public _arrayModelProductGeneralForPersonalAccidentSyariahReport: Array<ProductGeneralReportModel>;
	public _arrayModelProductGeneralForDamageOfBusinessPlaceSyariahReport: Array<ProductGeneralReportModel>;
	public _arrayModelProductGeneralForMyHouseSyariahReport: Array<ProductGeneralReportModel>;

	public _booleanView: boolean | null;
	public _booleanChannelBRINSWebsite: boolean | null;
	public _booleanChannelBRINSMobile: boolean | null;
	public _booleanChannelBRIMO: boolean | null;
	public _booleanChannelNDSUB: boolean | null;
	public _booleanAllProduct: boolean | null;
	public _booleanProductAsri: boolean | null;
	public _booleanProductOto: boolean | null;
	public _booleanProductBicycle: boolean | null;
	public _booleanProductPersonalAccident: boolean | null;
	public _booleanProductDamageOfBusinessPlace: boolean | null;
	public _booleanProductMyHouse: boolean | null;
	public _booleanProductMyMotorcycle: boolean | null;
	public _booleanProductAsriSyariah: boolean | null;
	public _booleanProductOtoSyariah: boolean | null;
	public _booleanProductPersonalAccidentSyariah: boolean | null;
	public _booleanProductDamageOfBusinessPlaceSyariah: boolean | null;
	public _booleanProductMyHouseSyariah: boolean | null;

	//Matrix Excel BRINSWebsite
	public _matrixExportExcelProductAsri: any = [];
	public _matrixExportExcelProductOto: any = [];
	public _matrixExportExcelProductBicycle: any = [];
	public _matrixExportExcelProductPersonalAccident: any = [];
	public _matrixExportExcelProductDamageOfBusinessPlace: any = [];
	public _matrixExportExcelProductMyHouse: any = [];
	public _matrixExportExcelProductMyMotorcycle: any = [];
	public _matrixExportExcelProductAsriSyariah: any = [];
	public _matrixExportExcelProductOtoSyariah: any = [];
	public _matrixExportExcelProductPersonalAccidentSyariah: any = [];
	public _matrixExportExcelProductDamageOfBusinessPlaceSyariah: any = [];
	public _matrixExportExcelProductMyHouseSyariah: any = [];

	public _fileWorkBookExcel: xlsx.WorkBook;

	public _elementHTMLDivMenuAllProductListContainer: HTMLElement | any;
	public _elementHTMLRadioProductAsri: HTMLElement | null;

	//#endregion


	//#region CONSTRUCTOR

	constructor(serviceSession: SessionService, router: Router, private _serviceProductGeneralReport: ProductGeneralReportService, private _serviceBranch: BranchService, private _servicePurchaseRatingReviews: PurchaseRatingReviewsService)
	{
		super(serviceSession, router);

		this._dateStart = new Date();
		this._dateEnd = new Date();

		this._modelTable = new TableModel();
		this._modelTableReportAsri = new TableModel();
		this._modelTableReportOto = new TableModel();
		this._modelBranch = new BranchModel();

		this._modelProductGeneralReport = new ProductGeneralReportModel();
		this._modelProductGeneralReportRequest = new ProductGeneralReportModel();
		this._modelPurchaseRatingReviews = new PurchaseRatingReviewsModel();

		this._arrayModelBranch = [];
		this._arrayModelProductGeneralReport = [];
		this._arrayModelProductGeneralForAsriReport = [];
		this._arrayModelProductGeneralForOtoReport = [];
		this._arrayModelProductGeneralForPersonalAccidentReport = [];
		this._arrayModelProductGeneralForBicycleReport = [];
		this._arrayModelProductGeneralForDamageOfBusinessPlaceReport = [];
		this._arrayModelProductGeneralForMyHouseReport = [];
		this._arrayModelProductGeneralForMyMotorcycleReport = [];
		this._arrayModelPurhcaseRatingReviews = [];
		this._arrayModelProductGeneralForAsriSyariahReport = [];
		this._arrayModelProductGeneralForOtoSyariahReport = [];
		this._arrayModelProductGeneralForPersonalAccidentSyariahReport = [];
		this._arrayModelProductGeneralForDamageOfBusinessPlaceSyariahReport = [];
		this._arrayModelProductGeneralForMyHouseSyariahReport = [];

		this._booleanView = null;
		this._booleanChannelBRINSWebsite = null;
		this._booleanChannelBRINSMobile = null;
		this._booleanChannelBRIMO = null;
		this._booleanChannelNDSUB = null;
		this._booleanAllProduct = null;
		this._booleanProductAsri = null;
		this._booleanProductOto = null;
		this._booleanProductBicycle = null;
		this._booleanProductPersonalAccident = null;
		this._booleanProductDamageOfBusinessPlace = null;
		this._booleanProductMyHouse = null;
		this._booleanProductMyMotorcycle = null;
		this._booleanProductAsriSyariah = null;
		this._booleanProductOtoSyariah = null;
		this._booleanProductPersonalAccidentSyariah = null;
		this._booleanProductDamageOfBusinessPlaceSyariah = null;
		this._booleanProductMyHouseSyariah = null;

		this._fileWorkBookExcel = xlsx.utils.book_new();

		this._elementHTMLDivMenuAllProductListContainer = document.getElementById("divMenuAllProductListContainer");
		this._elementHTMLRadioProductAsri = document.getElementById("inputProductAsri");
	}

	//#endregion


	//#region INIT

	ngOnInit(): void
	{
		this._elementHTMLDivMenuAllProductListContainer = document.getElementById("divMenuAllProductListContainer");
		this._elementHTMLRadioProductAsri = document.getElementById("inputProductAsri");

		this.callSelectBranchForConventionalAndSyariah(this);
	}

	//#endregion


	//#region WEB SERVICE

	public callSelectProductGeneralReportByAttributes(componentCurrent: ReportMonitoringDigitalChannelComponent, booleanSearch: boolean): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent.setArrayAllProductReportEmpty();

		componentCurrent._modelProductGeneralReport.OrderDateStartRange = componentCurrent._dateStart;
		componentCurrent._modelProductGeneralReport.OrderDateEndRange = componentCurrent._dateEnd;
		componentCurrent._modelProductGeneralReport.Channel = componentCurrent._modelProductGeneralReportRequest.Channel;
		componentCurrent._modelProductGeneralReport.ProductName = componentCurrent._modelProductGeneralReportRequest.ProductName;
		componentCurrent._modelProductGeneralReport.BranchName = componentCurrent._modelProductGeneralReportRequest.BranchName;

		if (booleanSearch)
		{
			componentCurrent._modelTable.Page = 1;
			componentCurrent._modelTable.RowPerPage = 20;
		}
		else
		{
		}

		componentCurrent._modelTable.Search = JSON.stringify(componentCurrent._modelProductGeneralReport);

		const modelResponseValidation: ResponseModel = this._modelProductGeneralReport.validatePeriod();

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			componentCurrent._serviceProductGeneralReport.selectProductGeneralReportByAttributes
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							componentCurrent._modelTable.setModelFromString(modelResponse.Data);

							if (componentCurrent._modelTable.Result !== undefined)
							{
								let arrayModelProductGeneralTemp: Array<ProductGeneralReportModel> = [];
								arrayModelProductGeneralTemp = JSON.parse(componentCurrent._modelTable.Result);
								componentCurrent._modelTable.Result = undefined;

								let modelProductGeneral: ProductGeneralReportModel;
								for(const modelProductGeneralTemp of arrayModelProductGeneralTemp)
								{
									modelProductGeneral = new ProductGeneralReportModel();
									modelProductGeneral.setModelFromObject(modelProductGeneralTemp);
									componentCurrent._arrayModelProductGeneralReport.push(modelProductGeneral);
								}

								componentCurrent.callFilterBooleanProduct();

								componentCurrent._elementHTMLDivMenuAllProductListContainer = document.getElementById("divMenuAllProductListContainer");
								componentCurrent._elementHTMLDivMenuAllProductListContainer.style.display = "block";

								componentCurrent._functionUserInterface.updateLoadingProgress();
								componentCurrent._componentTableControlInclude.setButtonState();
							}
							else
							{
							}
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
							{
								componentCurrent._elementHTMLDivMenuAllProductListContainer = document.getElementById("divMenuAllProductListContainer");
								componentCurrent._elementHTMLDivMenuAllProductListContainer.style.display = "none";
								componentCurrent._modelTable.Page = 1;
								componentCurrent._modelTable.RowPerPage = 20;
							});
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
					componentCurrent._functionUserInterface.updateLoadingProgress();
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectProductGeneralReportByAttributes(componentCurrent, true); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, componentCurrent._modelTable);
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () =>
			{
				if (componentCurrent._elementHTMLDivMenuAllProductListContainer != null)
				{
					componentCurrent._elementHTMLDivMenuAllProductListContainer.style.display = "none";
				}
			});
		}
	};

	public callSelectProductGeneralReportByAttributesForExcel(componentCurrent: ReportMonitoringDigitalChannelComponent, stringChannel: string, stringProduct: string | undefined, success: () => void): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent.setArrayAllProductReportEmpty();

		componentCurrent._modelProductGeneralReport.OrderDateStartRange = componentCurrent._dateStart;
		componentCurrent._modelProductGeneralReport.OrderDateEndRange = componentCurrent._dateEnd;
		componentCurrent._modelProductGeneralReport.Channel = componentCurrent._modelProductGeneralReportRequest.Channel;
		componentCurrent._modelProductGeneralReport.ProductName = stringProduct;
		componentCurrent._modelProductGeneralReport.BranchName = componentCurrent._modelProductGeneralReportRequest.BranchName;

		const modelResponseValidation: ResponseModel = this._modelProductGeneralReport.validatePeriod();

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			componentCurrent._serviceProductGeneralReport.selectProductGeneralReportByAttributesForExcel
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							let arrayModelProductGeneralTemp: Array<ProductGeneralReportModel> = [];
							arrayModelProductGeneralTemp = JSON.parse(modelResponse.Data);

							let modelProductGeneral: ProductGeneralReportModel;
							for(const modelProductGeneralTemp of arrayModelProductGeneralTemp)
							{
								modelProductGeneral = new ProductGeneralReportModel();
								modelProductGeneral.setModelFromObject(modelProductGeneralTemp);
								componentCurrent._arrayModelProductGeneralReport.push(modelProductGeneral);

								if (modelProductGeneral.ProductName === componentCurrent._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_ASRI)
								{
									componentCurrent._arrayModelProductGeneralForAsriReport.push(modelProductGeneral);
								}
								else if (modelProductGeneral.ProductName === componentCurrent._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_OTO)
								{
									componentCurrent._arrayModelProductGeneralForOtoReport.push(modelProductGeneral);
								}
								else if (modelProductGeneral.ProductName === componentCurrent._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_BICYCLE)
								{
									componentCurrent._arrayModelProductGeneralForBicycleReport.push(modelProductGeneral);
								}
								else if (modelProductGeneral.ProductName === componentCurrent._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_PERSONALACCIDENT)
								{
									componentCurrent._arrayModelProductGeneralForPersonalAccidentReport.push(modelProductGeneral);
								}
								else if (modelProductGeneral.ProductName === componentCurrent._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_DAMAGEOFBUSINESSPLACE)
								{
									componentCurrent._arrayModelProductGeneralForDamageOfBusinessPlaceReport.push(modelProductGeneral);
								}
								else if (modelProductGeneral.ProductName === componentCurrent._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_MYHOUSE)
								{
									componentCurrent._arrayModelProductGeneralForMyHouseReport.push(modelProductGeneral);
								}
								else if (modelProductGeneral.ProductName === componentCurrent._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_MYMOTORCYCLE)
								{
									componentCurrent._arrayModelProductGeneralForMyMotorcycleReport.push(modelProductGeneral);
								}
								else if (modelProductGeneral.ProductName === componentCurrent._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_ASRISYARIAH)
								{
									componentCurrent._arrayModelProductGeneralForAsriSyariahReport.push(modelProductGeneral);
								}
								else if (modelProductGeneral.ProductName === componentCurrent._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_OTOSYARIAH)
								{
									componentCurrent._arrayModelProductGeneralForOtoSyariahReport.push(modelProductGeneral);
								}
								else if (modelProductGeneral.ProductName === componentCurrent._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_PERSONALACCIDENTSYARIAH)
								{
									componentCurrent._arrayModelProductGeneralForPersonalAccidentSyariahReport.push(modelProductGeneral);
								}
								else if (modelProductGeneral.ProductName === componentCurrent._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_DAMAGEOFBUSINESSPLACESYARIAH)
								{
									componentCurrent._arrayModelProductGeneralForDamageOfBusinessPlaceSyariahReport.push(modelProductGeneral);
								}
								else if (modelProductGeneral.ProductName === componentCurrent._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_MYHOUSESYARIAH)
								{
									componentCurrent._arrayModelProductGeneralForMyHouseSyariahReport.push(modelProductGeneral);
								}
								else
								{
								}
							}
						}
						else
						{
							componentCurrent.setArrayAllProductReportEmpty();
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
					componentCurrent._functionUserInterface.updateLoadingProgress();
					success();
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectProductGeneralReportByAttributesForExcel(componentCurrent, stringChannel, stringProduct, () => {}); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, componentCurrent._modelProductGeneralReport);
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => {});
		}
	};

	public callSelectPolicyGeneralReportRatingByAttributes(componentCurrent: ReportMonitoringDigitalChannelComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._arrayModelPurhcaseRatingReviews = [];
		componentCurrent._modelPurchaseRatingReviews.OrderDateStartRange = componentCurrent._dateStart;
		componentCurrent._modelPurchaseRatingReviews.OrderDateEndRange = componentCurrent._dateEnd;
		componentCurrent._modelPurchaseRatingReviews.Channel = componentCurrent._modelProductGeneralReportRequest.Channel;
		componentCurrent._modelPurchaseRatingReviews.ProductName = componentCurrent._modelProductGeneralReportRequest.ProductName;
		componentCurrent._modelPurchaseRatingReviews.BranchName = componentCurrent._modelProductGeneralReportRequest.BranchName;

		const modelResponseValidation: ResponseModel = this._modelPurchaseRatingReviews.validatePeriod();

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			componentCurrent._servicePurchaseRatingReviews.selectPolicyGeneralReportRatingByAttributes
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							let arrayModelPurchaseRatingReviewsTemp: Array<PurchaseRatingReviewsModel> = [];
							arrayModelPurchaseRatingReviewsTemp = JSON.parse(modelResponse.Data);

							let modelPurchaseRatingReviews: PurchaseRatingReviewsModel;
							for(const modelPurchaseRatingReviewsTemp of arrayModelPurchaseRatingReviewsTemp)
							{
								modelPurchaseRatingReviews = new PurchaseRatingReviewsModel();
								modelPurchaseRatingReviews.setModelFromObject(modelPurchaseRatingReviewsTemp);
								componentCurrent._arrayModelPurhcaseRatingReviews.push(modelPurchaseRatingReviews);
							}
						}
						else
						{
						}

						//generate excel
						componentCurrent.callGenerateExcelByFilter();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
					// componentCurrent._functionUserInterface.updateLoadingProgress();
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectPolicyGeneralReportRatingByAttributes(componentCurrent); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, componentCurrent._modelPurchaseRatingReviews);
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => {});
		}
	};

	public callSelectBranchForConventionalAndSyariah(componentCurrent: ReportMonitoringDigitalChannelComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._arrayModelBranch = [];

		const modelTable = new TableModel();
		modelTable.RowPerPage = 100;
		modelTable.Search = JSON.stringify(componentCurrent._modelBranch);

		this._serviceBranch.selectBranchForConventionalAndSyariah
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelTable.setModelFromString(modelResponse.Data);
						if (componentCurrent._modelTable.Result !== undefined)
						{
							let arrayModelBranch: Array<BranchModel> = [];
							arrayModelBranch = JSON.parse(componentCurrent._modelTable.Result);
							componentCurrent._modelTable.Result = undefined;

							let modelBranch: BranchModel;
							for(const modelBranchTemp of arrayModelBranch)
							{
								modelBranch = new BranchModel();
								modelBranch.setModelFromObject(modelBranchTemp);
								componentCurrent._arrayModelBranch.push(modelBranch);
							}
						}
						else
						{
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
					componentCurrent._functionUserInterface.updateLoadingProgress();
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, modelTable);
	}

	//#endregion


	//#region FUNCTION

	booleanChannelBRINSWebsite(): void
	{
		this._booleanView = true;
		this._booleanChannelBRINSWebsite = true;
		this._booleanChannelBRINSMobile = false;
		this._booleanChannelBRIMO = false;
		this._booleanChannelNDSUB = false;
	}

	booleanChannelBRINSMobile(): void
	{
		this._booleanView = true;
		this._booleanChannelBRINSWebsite = false;
		this._booleanChannelBRINSMobile = true;
		this._booleanChannelBRIMO = false;
		this._booleanChannelNDSUB = false;
	}

	booleanChannelBRIMO(): void
	{
		this._booleanView = true;
		this._booleanChannelBRINSWebsite = false;
		this._booleanChannelBRINSMobile = false;
		this._booleanChannelBRIMO = true;
		this._booleanChannelNDSUB = false;
	}

	booleanChannelNDSUB(): void
	{
		this._booleanView = true;
		this._booleanChannelBRINSWebsite = false;
		this._booleanChannelBRINSMobile = false;
		this._booleanChannelBRIMO = false;
		this._booleanChannelNDSUB = true;
	}

	checkBooleanChannel(): void
	{
		if (this._modelProductGeneralReportRequest.Channel === this._stringConstant.STRING_CHANNEL_BRINSWEBSITE)
		{
			this.booleanChannelBRINSWebsite();
		}
		else if (this._modelProductGeneralReportRequest.Channel === this._stringConstant.STRING_CHANNEL_BRINSMOBILE)
		{
			this.booleanChannelBRINSMobile();
		}
		else if (this._modelProductGeneralReportRequest.Channel === this._stringConstant.STRING_CHANNEL_BRIMO)
		{
			this.booleanChannelBRIMO();
		}
		else if (this._modelProductGeneralReportRequest.Channel === this._stringConstant.STRING_CHANNEL_NDSUB)
		{
			this.booleanChannelNDSUB();
		}
		else
		{
		}
	}

	booleanAllProduct(): void
	{
		this._booleanAllProduct = true;
		this._booleanProductAsri = false;
		this._booleanProductOto = false;
		this._booleanProductBicycle = false;
		this._booleanProductPersonalAccident = false;
		this._booleanProductDamageOfBusinessPlace = false;
		this._booleanProductMyHouse = false;
		this._booleanProductMyMotorcycle = false;
	}

	booleanProductAsri(): void
	{
		this._booleanAllProduct = false;
		this._booleanProductAsri = true;
		this._booleanProductOto = false;
		this._booleanProductBicycle = false;
		this._booleanProductPersonalAccident = false;
		this._booleanProductDamageOfBusinessPlace = false;
		this._booleanProductMyHouse = false;
		this._booleanProductMyMotorcycle = false;
	}

	booleanProductOto(): void
	{
		this._booleanAllProduct = false;
		this._booleanProductAsri = false;
		this._booleanProductOto = true;
		this._booleanProductBicycle = false;
		this._booleanProductPersonalAccident = false;
		this._booleanProductDamageOfBusinessPlace = false;
		this._booleanProductMyHouse = false;
		this._booleanProductMyMotorcycle = false;
	}

	booleanProductBicycle(): void
	{
		this._booleanAllProduct = false;
		this._booleanProductAsri = false;
		this._booleanProductOto = false;
		this._booleanProductBicycle = true;
		this._booleanProductPersonalAccident = false;
		this._booleanProductDamageOfBusinessPlace = false;
		this._booleanProductMyHouse = false;
		this._booleanProductMyMotorcycle = false;
	}

	booleanProductPersonalAccident(): void
	{
		this._booleanAllProduct = false;
		this._booleanProductAsri = false;
		this._booleanProductOto = false;
		this._booleanProductBicycle = false;
		this._booleanProductPersonalAccident = true;
		this._booleanProductDamageOfBusinessPlace = false;
		this._booleanProductMyHouse = false;
		this._booleanProductMyMotorcycle = false;
	}

	booleanProductDamageOfBusinessPlace(): void
	{
		this._booleanAllProduct = false;
		this._booleanProductAsri = false;
		this._booleanProductOto = false;
		this._booleanProductBicycle = false;
		this._booleanProductPersonalAccident = false;
		this._booleanProductDamageOfBusinessPlace = true;
		this._booleanProductMyHouse = false;
		this._booleanProductMyMotorcycle = false;
	}

	booleanProductMyHouse(): void
	{
		this._booleanAllProduct = false;
		this._booleanProductAsri = false;
		this._booleanProductOto = false;
		this._booleanProductBicycle = false;
		this._booleanProductPersonalAccident = false;
		this._booleanProductDamageOfBusinessPlace = false;
		this._booleanProductMyHouse = true;
		this._booleanProductMyMotorcycle = false;
	}

	booleanProductMyMotorcycle(): void
	{
		this._booleanAllProduct = false;
		this._booleanProductAsri = false;
		this._booleanProductOto = false;
		this._booleanProductBicycle = false;
		this._booleanProductPersonalAccident = false;
		this._booleanProductDamageOfBusinessPlace = false;
		this._booleanProductMyHouse = false;
		this._booleanProductMyMotorcycle = true;
	}

	callFilterBooleanProduct(): void
	{
		if (this._modelProductGeneralReport.ProductName === this._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_ASRI)
		{
			this.booleanProductAsri();
		}
		else if (this._modelProductGeneralReport.ProductName === this._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_OTO)
		{
			this.booleanProductOto();
		}
		else if (this._modelProductGeneralReport.ProductName === this._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_BICYCLE)
		{
			this.booleanProductBicycle();
		}
		else if (this._modelProductGeneralReport.ProductName === this._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_PERSONALACCIDENT)
		{
			this.booleanProductPersonalAccident();
		}
		else if (this._modelProductGeneralReport.ProductName === this._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_DAMAGEOFBUSINESSPLACE)
		{
			this.booleanProductDamageOfBusinessPlace();
		}
		else if (this._modelProductGeneralReport.ProductName === this._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_MYHOUSE)
		{
			this.booleanProductMyHouse();
		}
		else if (this._modelProductGeneralReport.ProductName === this._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_MYMOTORCYCLE)
		{
			this.booleanProductMyMotorcycle();
		}
		else if (this._modelProductGeneralReport.ProductName === this._stringUndefined)
		{
			this.booleanAllProduct();
		}
		else
		{
		}
	}

	//#endregion


	//#region FUNCTION CALL GENERATE EXCEL

	public callGenerateExcelByFilter(): void
	{
		if (this._modelProductGeneralReportRequest.ProductName === this._stringUndefined && this._modelProductGeneralReportRequest.Channel === this._stringConstant.STRING_CHANNEL_BRINSWEBSITE)
		{
			this.callGenerateExcelAllProductForBRINSWebsite(this);
		}
		else if ((this._modelProductGeneralReportRequest.ProductName === this._stringUndefined && this._modelProductGeneralReportRequest.Channel === this._stringUndefined) ||
		(this._modelProductGeneralReportRequest.ProductName === this._stringUndefined && this._modelProductGeneralReportRequest.Channel === this._stringConstant.STRING_CHANNEL_BRINSMOBILE))
		{
			this.callGenerateExcelAllProductForBRINSmobile(this);
		}
		else if (this._modelProductGeneralReportRequest.ProductName === this._stringUndefined && this._modelProductGeneralReportRequest.Channel === this._stringConstant.STRING_CHANNEL_BRIMO)
		{
			this.callGenerateExcelAllProductForBRIMO(this);
		}
		else if (this._modelProductGeneralReportRequest.ProductName === this._stringUndefined && this._modelProductGeneralReportRequest.Channel === this._stringConstant.STRING_CHANNEL_NDSUB)
		{
			this.callGenerateExcelAllProductForNDSUB(this);
		}
		else if (this._modelProductGeneralReportRequest.ProductName === this._stringUndefined && this._modelProductGeneralReportRequest.Channel === this._stringConstant.STRING_CHANNEL_SELFSERVICEMONITORING)
		{
			this.callGenerateExcelAllProductForSelfServiceMonitoring(this);
		}
		else if (this._modelProductGeneralReportRequest.ProductName === this._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_ASRI)
		{
			this.callGenerateExcelAsriProduct(this);
		}
		else if (this._modelProductGeneralReportRequest.ProductName === this._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_OTO)
		{
			this.callGenerateExcelOtoProduct(this);
		}
		else if (this._modelProductGeneralReportRequest.ProductName === this._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_BICYCLE)
		{
			this.callGenerateExcelBicycleProduct(this);
		}
		else if (this._modelProductGeneralReportRequest.ProductName === this._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_PERSONALACCIDENT)
		{
			this.callGenerateExcelPersonalAccidentProduct(this);
		}
		else if (this._modelProductGeneralReportRequest.ProductName === this._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_DAMAGEOFBUSINESSPLACE)
		{
			this.callGenerateExcelDamageOfBusinessPlaceProduct(this);
		}
		else if (this._modelProductGeneralReportRequest.ProductName === this._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_MYHOUSE)
		{
			this.callGenerateExcelMyHouseProduct(this);
		}
		else if (this._modelProductGeneralReportRequest.ProductName === this._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_MYMOTORCYCLE)
		{
			this.callGenerateExcelMyMotorcycleProduct(this);
		}

		else if (this._modelProductGeneralReportRequest.ProductName === this._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_ASRISYARIAH)
		{
			this.callGenerateExcelAsriSyariahProduct(this);
		}
		else if (this._modelProductGeneralReportRequest.ProductName === this._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_OTOSYARIAH)
		{
			this.callGenerateExcelOtoSyariahProduct(this);
		}
		else if (this._modelProductGeneralReportRequest.ProductName === this._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_PERSONALACCIDENTSYARIAH)
		{
			this.callGenerateExcelPersonalAccidentSyariahProduct(this);
		}
		else
		{
		}
	}

	public callGenerateExcelAllProductForBRINSWebsite(componentCurrent: ReportMonitoringDigitalChannelComponent): void
	{
		this._functionUserInterface.setLoadingProgress(1);
		this._fileWorkBookExcel = xlsx.utils.book_new();

		const stringChannel: string = this._modelProductGeneralReportRequest.Channel ?? "";
		const stringAllProduct = this._stringUndefined;

		componentCurrent.callSelectProductGeneralReportByAttributesForExcel(componentCurrent, stringChannel, stringAllProduct, () =>
		{
			this._functionUserInterface.updateLoadingProgress();

			if (this._arrayModelProductGeneralForAsriReport)
			{
				componentCurrent.getAsriExportToExcel();
				if (this._arrayModelProductGeneralForOtoReport)
				{
					componentCurrent.getOtoExportToExcel();
					if (this._arrayModelProductGeneralForBicycleReport)
					{
						componentCurrent.getBicycleExportToExcel();
						if (this._arrayModelProductGeneralForPersonalAccidentReport)
						{
							componentCurrent.getPersonalAccidentExportToExcel();
							if (this._arrayModelProductGeneralForDamageOfBusinessPlaceReport)
							{
								componentCurrent.getDamageOfBusinessPlaceExportToExcel();
								if (this._arrayModelProductGeneralForMyHouseReport)
								{
									componentCurrent.getMyHouseExportToExcel();
									if (this._arrayModelProductGeneralForMyMotorcycleReport)
									{
										componentCurrent.getMyMotorcycleExportToExcel();

										//#region SAVE TO FILE

										const numberStartDate = this._dateStart.getDate();
										const numberStartMonth = this._dateStart.getMonth()+1;
										const numberStartYear = this._dateStart.getFullYear();

										const numberEndDate = this._dateEnd.getDate();
										const numberEndMonth = this._dateEnd.getMonth()+1;
										const numberEndYear = this._dateEnd.getFullYear();

										const stringDatePeriod: string = ""+numberStartDate+numberStartMonth+numberStartYear+" s/d "+numberEndDate+numberEndMonth+numberEndYear;
										const stringFileName: string = "Laporan Data Transaksi Digital Periode "+stringDatePeriod+".xlsx";

										xlsx.writeFile(componentCurrent._fileWorkBookExcel, stringFileName);

										const modelResponseExcel: ResponseModel = new ResponseModel();
										modelResponseExcel.MessageTitle = "Export Excel";
										modelResponseExcel.MessageContent = "File excel download success! Please wait.";
										this._functionUserInterface.showDialogFromModelResponse(modelResponseExcel, () => { this._functionUserInterface.updateLoadingProgress(); });

										//#endregion

										this._functionUserInterface.updateLoadingProgress();
									}
								}
							}
						}
					}
				}
			}
			else
			{
			}
		});
	}

	public callGenerateExcelAllProductForBRINSmobile(componentCurrent: ReportMonitoringDigitalChannelComponent): void
	{
		this._functionUserInterface.setLoadingProgress(1);
		this._fileWorkBookExcel = xlsx.utils.book_new();

		const stringChannel: string = this._modelProductGeneralReportRequest.Channel ?? "";
		const stringAllProduct = this._stringUndefined;

		componentCurrent.callSelectProductGeneralReportByAttributesForExcel(componentCurrent, stringChannel, stringAllProduct, () =>
		{
			this._functionUserInterface.updateLoadingProgress();

			if (this._arrayModelProductGeneralForAsriReport)
			{
				componentCurrent.getAsriExportToExcel();
				if (this._arrayModelProductGeneralForOtoReport)
				{
					componentCurrent.getOtoExportToExcel();
					if (this._arrayModelProductGeneralForBicycleReport)
					{
						componentCurrent.getBicycleExportToExcel();
						if (this._arrayModelProductGeneralForPersonalAccidentReport)
						{
							componentCurrent.getPersonalAccidentExportToExcel();
							if (this._arrayModelProductGeneralForDamageOfBusinessPlaceReport)
							{
								componentCurrent.getDamageOfBusinessPlaceExportToExcel();
								if (this._arrayModelProductGeneralForMyHouseReport)
								{
									componentCurrent.getMyHouseExportToExcel();
									if (this._arrayModelProductGeneralForMyMotorcycleReport)
									{
										componentCurrent.getMyMotorcycleExportToExcel();
										if (this._arrayModelProductGeneralForAsriSyariahReport)
										{
											componentCurrent.getAsriSyariahExportToExcel();
											if (this._arrayModelProductGeneralForOtoSyariahReport)
											{
												componentCurrent.getOtoSyariahExportToExcel();
												if (this._arrayModelProductGeneralForPersonalAccidentSyariahReport)
												{
													componentCurrent.getPersonalAccidentSyariahExportToExcel();
													if (this._arrayModelProductGeneralForDamageOfBusinessPlaceSyariahReport)
													{
														componentCurrent.getDamageOfBusinessPlaceSyariahExportToExcel();
														if (this._arrayModelProductGeneralForMyHouseReport)
														{
															componentCurrent.getMyHouseSyariahExportToExcel();

															//#region SAVE TO FILE

															const numberStartDate = this._dateStart.getDate();
															const numberStartMonth = this._dateStart.getMonth()+1;
															const numberStartYear = this._dateStart.getFullYear();

															const numberEndDate = this._dateEnd.getDate();
															const numberEndMonth = this._dateEnd.getMonth()+1;
															const numberEndYear = this._dateEnd.getFullYear();

															const stringDatePeriod: string = ""+numberStartDate+numberStartMonth+numberStartYear+" s/d "+numberEndDate+numberEndMonth+numberEndYear;
															const stringFileName: string = "Laporan Data Transaksi Digital Periode "+stringDatePeriod+".xlsx";

															xlsx.writeFile(componentCurrent._fileWorkBookExcel, stringFileName);

															const modelResponseExcel: ResponseModel = new ResponseModel();
															modelResponseExcel.MessageTitle = "Export Excel";
															modelResponseExcel.MessageContent = "File excel download success! Please wait.";
															this._functionUserInterface.showDialogFromModelResponse(modelResponseExcel, () => { this._functionUserInterface.updateLoadingProgress(); });

															//#endregion

															this._functionUserInterface.updateLoadingProgress();
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
			else
			{
			}
		});
	}

	public callGenerateExcelAllProductForBRIMO(componentCurrent: ReportMonitoringDigitalChannelComponent): void
	{
		this._functionUserInterface.setLoadingProgress(1);
		this._fileWorkBookExcel = xlsx.utils.book_new();

		const stringChannel: string = this._modelProductGeneralReportRequest.Channel ?? "";
		const stringAllProduct = this._stringUndefined;

		componentCurrent.callSelectProductGeneralReportByAttributesForExcel(componentCurrent, stringChannel, stringAllProduct, () =>
		{
			this._functionUserInterface.updateLoadingProgress();

			if (this._arrayModelProductGeneralForAsriReport)
			{
				componentCurrent.getAsriExportToExcel();
				if (this._arrayModelProductGeneralForOtoReport)
				{
					componentCurrent.getOtoExportToExcel();
					if (this._arrayModelProductGeneralForBicycleReport)
					{
						componentCurrent.getBicycleExportToExcel();

						//#region SAVE TO FILE

						const numberStartDate = this._dateStart.getDate();
						const numberStartMonth = this._dateStart.getMonth()+1;
						const numberStartYear = this._dateStart.getFullYear();

						const numberEndDate = this._dateEnd.getDate();
						const numberEndMonth = this._dateEnd.getMonth()+1;
						const numberEndYear = this._dateEnd.getFullYear();

						const stringDatePeriod: string = ""+numberStartDate+numberStartMonth+numberStartYear+" s/d "+numberEndDate+numberEndMonth+numberEndYear;
						const stringFileName: string = "Laporan Data Transaksi Digital Periode "+stringDatePeriod+".xlsx";

						xlsx.writeFile(componentCurrent._fileWorkBookExcel, stringFileName);

						const modelResponseExcel: ResponseModel = new ResponseModel();
						modelResponseExcel.MessageTitle = "Export Excel";
						modelResponseExcel.MessageContent = "File excel download success! Please wait.";
						this._functionUserInterface.showDialogFromModelResponse(modelResponseExcel, () => { this._functionUserInterface.updateLoadingProgress(); });

						//#endregion

						this._functionUserInterface.updateLoadingProgress();
					}
				}
			}
			else
			{
			}
		});
	}

	public callGenerateExcelAllProductForNDSUB(componentCurrent: ReportMonitoringDigitalChannelComponent): void
	{
		this._functionUserInterface.setLoadingProgress(1);
		this._fileWorkBookExcel = xlsx.utils.book_new();

		const stringChannel: string = this._modelProductGeneralReportRequest.Channel ?? "";
		const stringAllProduct = this._stringUndefined;

		componentCurrent.callSelectProductGeneralReportByAttributesForExcel(componentCurrent, stringChannel, stringAllProduct, () =>
		{
			this._functionUserInterface.updateLoadingProgress();

			if (this._arrayModelProductGeneralForAsriReport)
			{
				componentCurrent.getAsriExportToExcel();
				if (this._arrayModelProductGeneralForOtoReport)
				{
					componentCurrent.getOtoExportToExcel();
					if (this._arrayModelProductGeneralForBicycleReport)
					{
						componentCurrent.getBicycleExportToExcel();
						if (this._arrayModelProductGeneralForDamageOfBusinessPlaceReport)
						{
							componentCurrent.getDamageOfBusinessPlaceExportToExcel();
							if (this._arrayModelProductGeneralForMyHouseReport)
							{
								componentCurrent.getMyHouseExportToExcel();
								if (this._arrayModelProductGeneralForMyMotorcycleReport)
								{
									componentCurrent.getMyMotorcycleExportToExcel();

									//#region SAVE TO FILE

									const numberStartDate = this._dateStart.getDate();
									const numberStartMonth = this._dateStart.getMonth()+1;
									const numberStartYear = this._dateStart.getFullYear();

									const numberEndDate = this._dateEnd.getDate();
									const numberEndMonth = this._dateEnd.getMonth()+1;
									const numberEndYear = this._dateEnd.getFullYear();

									const stringDatePeriod: string = ""+numberStartDate+numberStartMonth+numberStartYear+" s/d "+numberEndDate+numberEndMonth+numberEndYear;
									const stringFileName: string = "Laporan Data Transaksi Digital Periode "+stringDatePeriod+".xlsx";

									xlsx.writeFile(componentCurrent._fileWorkBookExcel, stringFileName);

									const modelResponseExcel: ResponseModel = new ResponseModel();
									modelResponseExcel.MessageTitle = "Export Excel";
									modelResponseExcel.MessageContent = "File excel download success! Please wait.";
									this._functionUserInterface.showDialogFromModelResponse(modelResponseExcel, () => { this._functionUserInterface.updateLoadingProgress(); });

									//#endregion

									this._functionUserInterface.updateLoadingProgress();
								}
							}
						}
					}
				}
			}
			else
			{
			}
		});
	}

	public callGenerateExcelAllProductForSelfServiceMonitoring(componentCurrent: ReportMonitoringDigitalChannelComponent): void
	{
		this._functionUserInterface.setLoadingProgress(1);
		this._fileWorkBookExcel = xlsx.utils.book_new();

		const stringChannel: string = this._modelProductGeneralReportRequest.Channel ?? "";
		const stringAllProduct = this._stringUndefined;

		componentCurrent.callSelectProductGeneralReportByAttributesForExcel(componentCurrent, stringChannel, stringAllProduct, () =>
		{
			this._functionUserInterface.updateLoadingProgress();

			if (this._arrayModelProductGeneralForAsriReport)
			{
				componentCurrent.getAsriExportToExcel();
				if (this._arrayModelProductGeneralForOtoReport)
				{
					componentCurrent.getOtoExportToExcel();
					if (this._arrayModelProductGeneralForBicycleReport)
					{
						componentCurrent.getBicycleExportToExcel();
						if (this._arrayModelProductGeneralForPersonalAccidentReport)
						{
							componentCurrent.getPersonalAccidentExportToExcel();

							//#region SAVE TO FILE

							const numberStartDate = this._dateStart.getDate();
							const numberStartMonth = this._dateStart.getMonth()+1;
							const numberStartYear = this._dateStart.getFullYear();

							const numberEndDate = this._dateEnd.getDate();
							const numberEndMonth = this._dateEnd.getMonth()+1;
							const numberEndYear = this._dateEnd.getFullYear();

							const stringDatePeriod: string = ""+numberStartDate+numberStartMonth+numberStartYear+" s/d "+numberEndDate+numberEndMonth+numberEndYear;
							const stringFileName: string = "Laporan Data Transaksi Digital Periode "+stringDatePeriod+".xlsx";

							xlsx.writeFile(componentCurrent._fileWorkBookExcel, stringFileName);

							const modelResponseExcel: ResponseModel = new ResponseModel();
							modelResponseExcel.MessageTitle = "Export Excel";
							modelResponseExcel.MessageContent = "File excel download success! Please wait.";
							this._functionUserInterface.showDialogFromModelResponse(modelResponseExcel, () => { this._functionUserInterface.updateLoadingProgress(); });

							//#endregion

							this._functionUserInterface.updateLoadingProgress();
						}
					}
				}
			}
			else
			{
			}
		});
	}

	public callGenerateExcelAsriProduct(componentCurrent: ReportMonitoringDigitalChannelComponent): void
	{
		this._functionUserInterface.setLoadingProgress(1);
		this._fileWorkBookExcel = xlsx.utils.book_new();

		const stringChannel: string = this._modelProductGeneralReportRequest.Channel ?? "";
		const stringProduct: string = this._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_ASRI;

		componentCurrent.callSelectProductGeneralReportByAttributesForExcel(componentCurrent, stringChannel, stringProduct, () =>
		{
			componentCurrent.getAsriExportToExcel();

			//#region SAVE TO FILE

			const numberStartDate = this._dateStart.getDate();
			const numberStartMonth = this._dateStart.getMonth()+1;
			const numberStartYear = this._dateStart.getFullYear();

			const numberEndDate = this._dateEnd.getDate();
			const numberEndMonth = this._dateEnd.getMonth()+1;
			const numberEndYear = this._dateEnd.getFullYear();

			const stringDatePeriod: string = ""+numberStartDate+numberStartMonth+numberStartYear+" s/d "+numberEndDate+numberEndMonth+numberEndYear;
			const stringFileName: string = "Laporan Data Transaksi Digital Periode "+stringDatePeriod+".xlsx";

			xlsx.writeFile(componentCurrent._fileWorkBookExcel, stringFileName);

			const modelResponseExcel: ResponseModel = new ResponseModel();
			modelResponseExcel.MessageTitle = "Export Excel";
			modelResponseExcel.MessageContent = "File excel download success! Please wait.";
			this._functionUserInterface.showDialogFromModelResponse(modelResponseExcel, () => { this._functionUserInterface.updateLoadingProgress(); });

			//#endregion

			this._functionUserInterface.updateLoadingProgress();
		});
	}

	public callGenerateExcelOtoProduct(componentCurrent: ReportMonitoringDigitalChannelComponent): void
	{
		this._functionUserInterface.setLoadingProgress(1);
		this._fileWorkBookExcel = xlsx.utils.book_new();

		const stringChannel: string = this._modelProductGeneralReportRequest.Channel ?? "";
		const stringProduct: string = this._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_OTO;

		componentCurrent.callSelectProductGeneralReportByAttributesForExcel(componentCurrent, stringChannel, stringProduct, () =>
		{
			componentCurrent.getOtoExportToExcel();

			//#region SAVE TO FILE

			const numberStartDate = this._dateStart.getDate();
			const numberStartMonth = this._dateStart.getMonth()+1;
			const numberStartYear = this._dateStart.getFullYear();

			const numberEndDate = this._dateEnd.getDate();
			const numberEndMonth = this._dateEnd.getMonth()+1;
			const numberEndYear = this._dateEnd.getFullYear();

			const stringDatePeriod: string = ""+numberStartDate+numberStartMonth+numberStartYear+" s/d "+numberEndDate+numberEndMonth+numberEndYear;
			const stringFileName: string = "Laporan Data Transaksi Digital Periode "+stringDatePeriod+".xlsx";

			xlsx.writeFile(componentCurrent._fileWorkBookExcel, stringFileName);

			const modelResponseExcel: ResponseModel = new ResponseModel();
			modelResponseExcel.MessageTitle = "Export Excel";
			modelResponseExcel.MessageContent = "File excel download success! Please wait.";
			this._functionUserInterface.showDialogFromModelResponse(modelResponseExcel, () => { this._functionUserInterface.updateLoadingProgress(); });

			//#endregion

			this._functionUserInterface.updateLoadingProgress();
		});
	}

	public callGenerateExcelBicycleProduct(componentCurrent: ReportMonitoringDigitalChannelComponent): void
	{
		this._functionUserInterface.setLoadingProgress(1);
		this._fileWorkBookExcel = xlsx.utils.book_new();

		const stringChannel: string = this._modelProductGeneralReportRequest.Channel ?? "";
		const stringProduct: string = this._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_BICYCLE;

		componentCurrent.callSelectProductGeneralReportByAttributesForExcel(componentCurrent, stringChannel, stringProduct, () =>
		{
			componentCurrent.getBicycleExportToExcel();

			//#region SAVE TO FILE

			const numberStartDate = this._dateStart.getDate();
			const numberStartMonth = this._dateStart.getMonth()+1;
			const numberStartYear = this._dateStart.getFullYear();

			const numberEndDate = this._dateEnd.getDate();
			const numberEndMonth = this._dateEnd.getMonth()+1;
			const numberEndYear = this._dateEnd.getFullYear();

			const stringDatePeriod: string = ""+numberStartDate+numberStartMonth+numberStartYear+" s/d "+numberEndDate+numberEndMonth+numberEndYear;
			const stringFileName: string = "Laporan Data Transaksi Digital Periode "+stringDatePeriod+".xlsx";

			xlsx.writeFile(componentCurrent._fileWorkBookExcel, stringFileName);

			const modelResponseExcel: ResponseModel = new ResponseModel();
			modelResponseExcel.MessageTitle = "Export Excel";
			modelResponseExcel.MessageContent = "File excel download success! Please wait.";
			this._functionUserInterface.showDialogFromModelResponse(modelResponseExcel, () => { this._functionUserInterface.updateLoadingProgress(); });

			//#endregion

			this._functionUserInterface.updateLoadingProgress();
		});
	}

	public callGenerateExcelPersonalAccidentProduct(componentCurrent: ReportMonitoringDigitalChannelComponent): void
	{
		this._functionUserInterface.setLoadingProgress(1);
		this._fileWorkBookExcel = xlsx.utils.book_new();

		const stringChannel: string = this._modelProductGeneralReportRequest.Channel ?? "";
		const stringProduct: string = this._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_PERSONALACCIDENT;

		componentCurrent.callSelectProductGeneralReportByAttributesForExcel(componentCurrent, stringChannel, stringProduct, () =>
		{
			componentCurrent.getPersonalAccidentExportToExcel();

			//#region SAVE TO FILE

			const numberStartDate = this._dateStart.getDate();
			const numberStartMonth = this._dateStart.getMonth()+1;
			const numberStartYear = this._dateStart.getFullYear();

			const numberEndDate = this._dateEnd.getDate();
			const numberEndMonth = this._dateEnd.getMonth()+1;
			const numberEndYear = this._dateEnd.getFullYear();

			const stringDatePeriod: string = ""+numberStartDate+numberStartMonth+numberStartYear+" s/d "+numberEndDate+numberEndMonth+numberEndYear;
			const stringFileName: string = "Laporan Data Transaksi Digital Periode "+stringDatePeriod+".xlsx";

			xlsx.writeFile(componentCurrent._fileWorkBookExcel, stringFileName);

			const modelResponseExcel: ResponseModel = new ResponseModel();
			modelResponseExcel.MessageTitle = "Export Excel";
			modelResponseExcel.MessageContent = "File excel download success! Please wait.";
			this._functionUserInterface.showDialogFromModelResponse(modelResponseExcel, () => { this._functionUserInterface.updateLoadingProgress(); });

			//#endregion

			this._functionUserInterface.updateLoadingProgress();
		});
	}

	public callGenerateExcelDamageOfBusinessPlaceProduct(componentCurrent: ReportMonitoringDigitalChannelComponent): void
	{
		this._functionUserInterface.setLoadingProgress(1);
		this._fileWorkBookExcel = xlsx.utils.book_new();

		const stringChannel: string = this._modelProductGeneralReportRequest.Channel ?? "";
		const stringProduct: string = this._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_DAMAGEOFBUSINESSPLACE;

		componentCurrent.callSelectProductGeneralReportByAttributesForExcel(componentCurrent, stringChannel, stringProduct, () =>
		{
			componentCurrent.getDamageOfBusinessPlaceExportToExcel();

			//#region SAVE TO FILE

			const numberStartDate = this._dateStart.getDate();
			const numberStartMonth = this._dateStart.getMonth()+1;
			const numberStartYear = this._dateStart.getFullYear();

			const numberEndDate = this._dateEnd.getDate();
			const numberEndMonth = this._dateEnd.getMonth()+1;
			const numberEndYear = this._dateEnd.getFullYear();

			const stringDatePeriod: string = ""+numberStartDate+numberStartMonth+numberStartYear+" s/d "+numberEndDate+numberEndMonth+numberEndYear;
			const stringFileName: string = "Laporan Data Transaksi Digital Periode "+stringDatePeriod+".xlsx";

			xlsx.writeFile(componentCurrent._fileWorkBookExcel, stringFileName);

			const modelResponseExcel: ResponseModel = new ResponseModel();
			modelResponseExcel.MessageTitle = "Export Excel";
			modelResponseExcel.MessageContent = "File excel download success! Please wait.";
			this._functionUserInterface.showDialogFromModelResponse(modelResponseExcel, () => { this._functionUserInterface.updateLoadingProgress(); });

			//#endregion

			this._functionUserInterface.updateLoadingProgress();
		});
	}

	public callGenerateExcelMyHouseProduct(componentCurrent: ReportMonitoringDigitalChannelComponent): void
	{
		this._functionUserInterface.setLoadingProgress(1);
		this._fileWorkBookExcel = xlsx.utils.book_new();

		const stringChannel: string = this._modelProductGeneralReportRequest.Channel ?? "";
		const stringProduct: string = this._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_MYHOUSE;

		componentCurrent.callSelectProductGeneralReportByAttributesForExcel(componentCurrent, stringChannel, stringProduct, () =>
		{
			componentCurrent.getMyHouseExportToExcel();

			//#region SAVE TO FILE

			const numberStartDate = this._dateStart.getDate();
			const numberStartMonth = this._dateStart.getMonth()+1;
			const numberStartYear = this._dateStart.getFullYear();

			const numberEndDate = this._dateEnd.getDate();
			const numberEndMonth = this._dateEnd.getMonth()+1;
			const numberEndYear = this._dateEnd.getFullYear();

			const stringDatePeriod: string = ""+numberStartDate+numberStartMonth+numberStartYear+" s/d "+numberEndDate+numberEndMonth+numberEndYear;
			const stringFileName: string = "Laporan Data Transaksi Digital Periode "+stringDatePeriod+".xlsx";

			xlsx.writeFile(componentCurrent._fileWorkBookExcel, stringFileName);

			const modelResponseExcel: ResponseModel = new ResponseModel();
			modelResponseExcel.MessageTitle = "Export Excel";
			modelResponseExcel.MessageContent = "File excel download success! Please wait.";
			this._functionUserInterface.showDialogFromModelResponse(modelResponseExcel, () => { this._functionUserInterface.updateLoadingProgress(); });

			//#endregion

			this._functionUserInterface.updateLoadingProgress();
		});
	}

	public callGenerateExcelMyMotorcycleProduct(componentCurrent: ReportMonitoringDigitalChannelComponent): void
	{
		this._functionUserInterface.setLoadingProgress(1);
		this._fileWorkBookExcel = xlsx.utils.book_new();

		const stringChannel: string = this._modelProductGeneralReportRequest.Channel ?? "";
		const stringProduct: string = this._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_MYMOTORCYCLE;

		componentCurrent.callSelectProductGeneralReportByAttributesForExcel(componentCurrent, stringChannel, stringProduct, () =>
		{
			componentCurrent.getMyMotorcycleExportToExcel();

			//#region SAVE TO FILE

			const numberStartDate = this._dateStart.getDate();
			const numberStartMonth = this._dateStart.getMonth()+1;
			const numberStartYear = this._dateStart.getFullYear();

			const numberEndDate = this._dateEnd.getDate();
			const numberEndMonth = this._dateEnd.getMonth()+1;
			const numberEndYear = this._dateEnd.getFullYear();

			const stringDatePeriod: string = ""+numberStartDate+numberStartMonth+numberStartYear+" s/d "+numberEndDate+numberEndMonth+numberEndYear;
			const stringFileName: string = "Laporan Data Transaksi Digital Periode "+stringDatePeriod+".xlsx";

			xlsx.writeFile(componentCurrent._fileWorkBookExcel, stringFileName);

			const modelResponseExcel: ResponseModel = new ResponseModel();
			modelResponseExcel.MessageTitle = "Export Excel";
			modelResponseExcel.MessageContent = "File excel download success! Please wait.";
			this._functionUserInterface.showDialogFromModelResponse(modelResponseExcel, () => { this._functionUserInterface.updateLoadingProgress(); });

			//#endregion

			this._functionUserInterface.updateLoadingProgress();
		});
	}

	public callGenerateExcelAsriSyariahProduct(componentCurrent: ReportMonitoringDigitalChannelComponent): void
	{
		this._functionUserInterface.setLoadingProgress(1);
		this._fileWorkBookExcel = xlsx.utils.book_new();

		const stringChannel: string = this._modelProductGeneralReportRequest.Channel ?? "";
		const stringProduct: string = this._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_ASRISYARIAH;

		componentCurrent.callSelectProductGeneralReportByAttributesForExcel(componentCurrent, stringChannel, stringProduct, () =>
		{
			componentCurrent.getAsriSyariahExportToExcel();

			//#region SAVE TO FILE

			const numberStartDate = this._dateStart.getDate();
			const numberStartMonth = this._dateStart.getMonth()+1;
			const numberStartYear = this._dateStart.getFullYear();

			const numberEndDate = this._dateEnd.getDate();
			const numberEndMonth = this._dateEnd.getMonth()+1;
			const numberEndYear = this._dateEnd.getFullYear();

			const stringDatePeriod: string = ""+numberStartDate+numberStartMonth+numberStartYear+" s/d "+numberEndDate+numberEndMonth+numberEndYear;
			const stringFileName: string = "Laporan Data Transaksi Digital Periode "+stringDatePeriod+".xlsx";

			xlsx.writeFile(componentCurrent._fileWorkBookExcel, stringFileName);

			const modelResponseExcel: ResponseModel = new ResponseModel();
			modelResponseExcel.MessageTitle = "Export Excel";
			modelResponseExcel.MessageContent = "File excel download success! Please wait.";
			this._functionUserInterface.showDialogFromModelResponse(modelResponseExcel, () => { this._functionUserInterface.updateLoadingProgress(); });

			//#endregion

			this._functionUserInterface.updateLoadingProgress();
		});
	}

	public callGenerateExcelOtoSyariahProduct(componentCurrent: ReportMonitoringDigitalChannelComponent): void
	{
		this._functionUserInterface.setLoadingProgress(1);
		this._fileWorkBookExcel = xlsx.utils.book_new();

		const stringChannel: string = this._modelProductGeneralReportRequest.Channel ?? "";
		const stringProduct: string = this._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_OTOSYARIAH;

		componentCurrent.callSelectProductGeneralReportByAttributesForExcel(componentCurrent, stringChannel, stringProduct, () =>
		{
			componentCurrent.getOtoSyariahExportToExcel();

			//#region SAVE TO FILE

			const numberStartDate = this._dateStart.getDate();
			const numberStartMonth = this._dateStart.getMonth()+1;
			const numberStartYear = this._dateStart.getFullYear();

			const numberEndDate = this._dateEnd.getDate();
			const numberEndMonth = this._dateEnd.getMonth()+1;
			const numberEndYear = this._dateEnd.getFullYear();

			const stringDatePeriod: string = ""+numberStartDate+numberStartMonth+numberStartYear+" s/d "+numberEndDate+numberEndMonth+numberEndYear;
			const stringFileName: string = "Laporan Data Transaksi Digital Periode "+stringDatePeriod+".xlsx";

			xlsx.writeFile(componentCurrent._fileWorkBookExcel, stringFileName);

			const modelResponseExcel: ResponseModel = new ResponseModel();
			modelResponseExcel.MessageTitle = "Export Excel";
			modelResponseExcel.MessageContent = "File excel download success! Please wait.";
			this._functionUserInterface.showDialogFromModelResponse(modelResponseExcel, () => { this._functionUserInterface.updateLoadingProgress(); });

			//#endregion

			this._functionUserInterface.updateLoadingProgress();
		});
	}

	public callGenerateExcelPersonalAccidentSyariahProduct(componentCurrent: ReportMonitoringDigitalChannelComponent): void
	{
		this._functionUserInterface.setLoadingProgress(1);
		this._fileWorkBookExcel = xlsx.utils.book_new();

		const stringChannel: string = this._modelProductGeneralReportRequest.Channel ?? "";
		const stringProduct: string = this._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_PERSONALACCIDENTSYARIAH;

		componentCurrent.callSelectProductGeneralReportByAttributesForExcel(componentCurrent, stringChannel, stringProduct, () =>
		{
			componentCurrent.getPersonalAccidentSyariahExportToExcel();

			//#region SAVE TO FILE

			const numberStartDate = this._dateStart.getDate();
			const numberStartMonth = this._dateStart.getMonth()+1;
			const numberStartYear = this._dateStart.getFullYear();

			const numberEndDate = this._dateEnd.getDate();
			const numberEndMonth = this._dateEnd.getMonth()+1;
			const numberEndYear = this._dateEnd.getFullYear();

			const stringDatePeriod: string = ""+numberStartDate+numberStartMonth+numberStartYear+" s/d "+numberEndDate+numberEndMonth+numberEndYear;
			const stringFileName: string = "Laporan Data Transaksi Digital Periode "+stringDatePeriod+".xlsx";

			xlsx.writeFile(componentCurrent._fileWorkBookExcel, stringFileName);

			const modelResponseExcel: ResponseModel = new ResponseModel();
			modelResponseExcel.MessageTitle = "Export Excel";
			modelResponseExcel.MessageContent = "File excel download success! Please wait.";
			this._functionUserInterface.showDialogFromModelResponse(modelResponseExcel, () => { this._functionUserInterface.updateLoadingProgress(); });

			//#endregion

			this._functionUserInterface.updateLoadingProgress();
		});
	}

	public callGenerateExcelDamageOfBusinessPlaceSyariahProduct(componentCurrent: ReportMonitoringDigitalChannelComponent): void
	{
		this._functionUserInterface.setLoadingProgress(1);
		this._fileWorkBookExcel = xlsx.utils.book_new();

		const stringChannel: string = this._modelProductGeneralReportRequest.Channel ?? "";
		const stringProduct: string = this._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_DAMAGEOFBUSINESSPLACESYARIAH;

		componentCurrent.callSelectProductGeneralReportByAttributesForExcel(componentCurrent, stringChannel, stringProduct, () =>
		{
			componentCurrent.getDamageOfBusinessPlaceSyariahExportToExcel();

			//#region SAVE TO FILE

			const numberStartDate = this._dateStart.getDate();
			const numberStartMonth = this._dateStart.getMonth()+1;
			const numberStartYear = this._dateStart.getFullYear();

			const numberEndDate = this._dateEnd.getDate();
			const numberEndMonth = this._dateEnd.getMonth()+1;
			const numberEndYear = this._dateEnd.getFullYear();

			const stringDatePeriod: string = ""+numberStartDate+numberStartMonth+numberStartYear+" s/d "+numberEndDate+numberEndMonth+numberEndYear;
			const stringFileName: string = "Laporan Data Transaksi Digital Periode "+stringDatePeriod+".xlsx";

			xlsx.writeFile(componentCurrent._fileWorkBookExcel, stringFileName);

			const modelResponseExcel: ResponseModel = new ResponseModel();
			modelResponseExcel.MessageTitle = "Export Excel";
			modelResponseExcel.MessageContent = "File excel download success! Please wait.";
			this._functionUserInterface.showDialogFromModelResponse(modelResponseExcel, () => { this._functionUserInterface.updateLoadingProgress(); });

			//#endregion

			this._functionUserInterface.updateLoadingProgress();
		});
	}

	public callGenerateExcelMyHouseSyariahProduct(componentCurrent: ReportMonitoringDigitalChannelComponent): void
	{
		this._functionUserInterface.setLoadingProgress(1);
		this._fileWorkBookExcel = xlsx.utils.book_new();

		const stringChannel: string = this._modelProductGeneralReportRequest.Channel ?? "";
		const stringProduct: string = this._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_MYHOUSESYARIAH;

		componentCurrent.callSelectProductGeneralReportByAttributesForExcel(componentCurrent, stringChannel, stringProduct, () =>
		{
			componentCurrent.getMyHouseSyariahExportToExcel();

			//#region SAVE TO FILE

			const numberStartDate = this._dateStart.getDate();
			const numberStartMonth = this._dateStart.getMonth()+1;
			const numberStartYear = this._dateStart.getFullYear();

			const numberEndDate = this._dateEnd.getDate();
			const numberEndMonth = this._dateEnd.getMonth()+1;
			const numberEndYear = this._dateEnd.getFullYear();

			const stringDatePeriod: string = ""+numberStartDate+numberStartMonth+numberStartYear+" s/d "+numberEndDate+numberEndMonth+numberEndYear;
			const stringFileName: string = "Laporan Data Transaksi Digital Periode "+stringDatePeriod+".xlsx";

			xlsx.writeFile(componentCurrent._fileWorkBookExcel, stringFileName);

			const modelResponseExcel: ResponseModel = new ResponseModel();
			modelResponseExcel.MessageTitle = "Export Excel";
			modelResponseExcel.MessageContent = "File excel download success! Please wait.";
			this._functionUserInterface.showDialogFromModelResponse(modelResponseExcel, () => { this._functionUserInterface.updateLoadingProgress(); });

			//#endregion

			this._functionUserInterface.updateLoadingProgress();
		});
	}

	//#endregion


	//#region FUNCTION MATRIX EXCEL

	public getAsriExportToExcel(): void
	{
		//#region INITIALIZATION DECLARATION VARIABLE

		this._matrixExportExcelProductAsri = [];
		this._matrixExportExcelProductAsri[0] = [];
		let numberIndexRow: number = 0;

		//#endregion


		//#region SET HEADER EXCEL

		this._matrixExportExcelProductAsri[numberIndexRow] = [];
		this._matrixExportExcelProductAsri[numberIndexRow].push("Platform");
		this._matrixExportExcelProductAsri[numberIndexRow].push("Produk");
		this._matrixExportExcelProductAsri[numberIndexRow].push("Tanggal Pemesanan");
		this._matrixExportExcelProductAsri[numberIndexRow].push("No. Ref");
		this._matrixExportExcelProductAsri[numberIndexRow].push("Kantor Cabang BRINS");
		this._matrixExportExcelProductAsri[numberIndexRow].push("No. Polis");
		this._matrixExportExcelProductAsri[numberIndexRow].push("Nama Paket");
		this._matrixExportExcelProductAsri[numberIndexRow].push("Periode Awal Asuransi");
		this._matrixExportExcelProductAsri[numberIndexRow].push("Periode Akhir Asuransi");
		this._matrixExportExcelProductAsri[numberIndexRow].push("Nama Pemesan");
		// this._matrixExportExcelProductAsri[numberIndexRow].push("NIK Pemesan");
		this._matrixExportExcelProductAsri[numberIndexRow].push("No. Telepon Pemesan");
		this._matrixExportExcelProductAsri[numberIndexRow].push("Nama Tertanggung");
		this._matrixExportExcelProductAsri[numberIndexRow].push("Alamat Tertanggung");
		this._matrixExportExcelProductAsri[numberIndexRow].push("Ahli Waris");
		this._matrixExportExcelProductAsri[numberIndexRow].push("Hubungan Tertanggung");
		this._matrixExportExcelProductAsri[numberIndexRow].push("Alamat Ahli Waris");
		this._matrixExportExcelProductAsri[numberIndexRow].push("No. Telepon Ahli Waris");
		this._matrixExportExcelProductAsri[numberIndexRow].push("Alamat Lokasi Risiko");
		this._matrixExportExcelProductAsri[numberIndexRow].push("Provinsi Risiko");
		this._matrixExportExcelProductAsri[numberIndexRow].push("Kota Risiko");
		this._matrixExportExcelProductAsri[numberIndexRow].push("Harga Bangunan");
		this._matrixExportExcelProductAsri[numberIndexRow].push("Harga Perlengkapan");
		this._matrixExportExcelProductAsri[numberIndexRow].push("Premi");
		this._matrixExportExcelProductAsri[numberIndexRow].push("Biaya Admin");
		this._matrixExportExcelProductAsri[numberIndexRow].push("Diskon");
		this._matrixExportExcelProductAsri[numberIndexRow].push("Kode Promo");
		this._matrixExportExcelProductAsri[numberIndexRow].push("Total Pembayaran");
		this._matrixExportExcelProductAsri[numberIndexRow].push("Metode Pembayaran");
		this._matrixExportExcelProductAsri[numberIndexRow].push("Tanggal Pembayaran");
		this._matrixExportExcelProductAsri[numberIndexRow].push("Referal");
		this._matrixExportExcelProductAsri[numberIndexRow].push("Nama Pemilik Kode Referal");
		// this._matrixExportExcelProductAsri[numberIndexRow].push("NIK Referral");
		this._matrixExportExcelProductAsri[numberIndexRow].push("Telephone Referral");
		this._matrixExportExcelProductAsri[numberIndexRow].push("Keterangan");
		this._matrixExportExcelProductAsri[numberIndexRow].push("No. BRIVA");

		// this._matrixExportExcelProductAsri[numberIndexRow].push("RefNum");
		this._matrixExportExcelProductAsri[numberIndexRow].push("Rating");
		this._matrixExportExcelProductAsri[numberIndexRow].push("Saran");

		//#endregion


		//#region BINDING DATA TO EXCEL

		if(this._arrayModelProductGeneralForAsriReport !== undefined)
		{
			for (const modelProductAsriReport of this._arrayModelProductGeneralForAsriReport)
			{
				numberIndexRow++;

				this._matrixExportExcelProductAsri[numberIndexRow] = [];
				this._matrixExportExcelProductAsri[numberIndexRow].push(modelProductAsriReport.Channel ?? "-");
				this._matrixExportExcelProductAsri[numberIndexRow].push(modelProductAsriReport.ProductName ?? "-");
				this._matrixExportExcelProductAsri[numberIndexRow].push(modelProductAsriReport.OrderDate ?? "-");
				this._matrixExportExcelProductAsri[numberIndexRow].push(modelProductAsriReport.ReferenceNumber ?? "-");
				this._matrixExportExcelProductAsri[numberIndexRow].push(modelProductAsriReport.BranchName ?? "-");
				this._matrixExportExcelProductAsri[numberIndexRow].push(modelProductAsriReport.PolicyNumber ?? "-");
				this._matrixExportExcelProductAsri[numberIndexRow].push(modelProductAsriReport.CoverageName ?? "-");
				this._matrixExportExcelProductAsri[numberIndexRow].push(modelProductAsriReport.PolicyStartDate ?? "-");
				this._matrixExportExcelProductAsri[numberIndexRow].push(modelProductAsriReport.PolicyEndDate ?? "-");
				this._matrixExportExcelProductAsri[numberIndexRow].push(modelProductAsriReport.PolicyholderFullName ?? "-");
				// this._matrixExportExcelProductAsri[numberIndexRow].push(modelProductAsriReport.PolicyholderIdentificationNumber ?? "-");
				this._matrixExportExcelProductAsri[numberIndexRow].push(modelProductAsriReport.PolicyholderPhoneNumber ?? "-");
				this._matrixExportExcelProductAsri[numberIndexRow].push(modelProductAsriReport.InsuredFullName ?? "-");
				this._matrixExportExcelProductAsri[numberIndexRow].push(modelProductAsriReport.InsuredAddress ?? "-");
				this._matrixExportExcelProductAsri[numberIndexRow].push(modelProductAsriReport.BeneficiaryFullName ?? "-");
				this._matrixExportExcelProductAsri[numberIndexRow].push(modelProductAsriReport.BeneficiaryRelationship ?? "-");
				this._matrixExportExcelProductAsri[numberIndexRow].push(modelProductAsriReport.BeneficiaryAddress ?? "-");
				this._matrixExportExcelProductAsri[numberIndexRow].push(modelProductAsriReport.BeneficiaryPhoneNumber ?? "-");
				this._matrixExportExcelProductAsri[numberIndexRow].push(modelProductAsriReport.RiskLocationAddress ?? "-");
				this._matrixExportExcelProductAsri[numberIndexRow].push(modelProductAsriReport.RiskProvince ?? "-");
				this._matrixExportExcelProductAsri[numberIndexRow].push(modelProductAsriReport.RiskCity ?? "-");
				this._matrixExportExcelProductAsri[numberIndexRow].push(modelProductAsriReport.modelProductAsriReport?.BuildingPrice ?? "-");
				this._matrixExportExcelProductAsri[numberIndexRow].push(modelProductAsriReport.modelProductAsriReport?.InteriorPrice ?? "-");
				this._matrixExportExcelProductAsri[numberIndexRow].push(modelProductAsriReport.PremiumAmount ?? "-");
				this._matrixExportExcelProductAsri[numberIndexRow].push(modelProductAsriReport.AdministrationAmount ?? "-");
				this._matrixExportExcelProductAsri[numberIndexRow].push(modelProductAsriReport.DiscountAmount ?? "-");
				this._matrixExportExcelProductAsri[numberIndexRow].push(modelProductAsriReport.PromoCode ?? "-");
				this._matrixExportExcelProductAsri[numberIndexRow].push(modelProductAsriReport.PremiumTotalAmount ?? "-");
				this._matrixExportExcelProductAsri[numberIndexRow].push(modelProductAsriReport.PaymentMethod ?? "-");
				this._matrixExportExcelProductAsri[numberIndexRow].push(modelProductAsriReport.PaymentDate ?? "-");
				this._matrixExportExcelProductAsri[numberIndexRow].push(modelProductAsriReport.ReferralCode ?? "-");
				this._matrixExportExcelProductAsri[numberIndexRow].push(modelProductAsriReport.ReferralOwnerFullName ?? "-");
				// this._matrixExportExcelProductAsri[numberIndexRow].push(modelProductAsriReport.ReferralOwnerIdentificationNumber ?? "-");
				this._matrixExportExcelProductAsri[numberIndexRow].push(modelProductAsriReport.ReferralOwnerPhoneNumber ?? "-");
				this._matrixExportExcelProductAsri[numberIndexRow].push(modelProductAsriReport.Note ?? "-");
				this._matrixExportExcelProductAsri[numberIndexRow].push(modelProductAsriReport.BRIVANumber ?? "-");

				for (const modelPurchaseRatingReviews of this._arrayModelPurhcaseRatingReviews)
				{
					if (modelProductAsriReport.ReferenceNumber === modelPurchaseRatingReviews.ReferenceNumber)
					{
						// this._matrixExportExcelProductAsri[numberIndexRow].push(modelPurchaseRatingReviews.ReferenceNumber ?? "-");

						if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.VeryGood)
						{
							this._matrixExportExcelProductAsri[numberIndexRow].push("Sangat Baik");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.Good)
						{
							this._matrixExportExcelProductAsri[numberIndexRow].push("Baik");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.Standard)
						{
							this._matrixExportExcelProductAsri[numberIndexRow].push("Standar");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.Bad)
						{
							this._matrixExportExcelProductAsri[numberIndexRow].push("Buruk");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.VeryBad)
						{
							this._matrixExportExcelProductAsri[numberIndexRow].push("Sangat Buruk");
						}

						this._matrixExportExcelProductAsri[numberIndexRow].push(modelPurchaseRatingReviews.Message ?? "-");
					}
				}
			}

			//#region GENERATE WORKSHEET

			const workSheet: xlsx.WorkSheet = xlsx.utils.aoa_to_sheet(this._matrixExportExcelProductAsri, { cellDates: true, dateNF: "dd-mmmm-yyyy" });

			//#endregion


			//#region GENERATE WORKBOOK AND ADD THE WORKSHEET

			xlsx.utils.book_append_sheet(this._fileWorkBookExcel, workSheet, "ASRI");

			//#endregion
		}
		else
		{

		}

		//#endregion
	}

	public getOtoExportToExcel(): void
	{
		//#region INITIALIZATION DECLARATION VARIABLE

		this._matrixExportExcelProductOto = [];
		this._matrixExportExcelProductOto[0] = [];
		let numberIndexRow: number = 0;

		//#endregion


		//#region SET HEADER EXCEL

		this._matrixExportExcelProductOto[numberIndexRow] = [];
		this._matrixExportExcelProductOto[numberIndexRow].push("Platform");
		this._matrixExportExcelProductOto[numberIndexRow].push("Produk");
		this._matrixExportExcelProductOto[numberIndexRow].push("Tanggal Pemesanan");
		this._matrixExportExcelProductOto[numberIndexRow].push("No. Ref");
		this._matrixExportExcelProductOto[numberIndexRow].push("Kantor Cabang BRINS");
		this._matrixExportExcelProductOto[numberIndexRow].push("No. Polis");
		this._matrixExportExcelProductOto[numberIndexRow].push("Nama Paket");
		this._matrixExportExcelProductOto[numberIndexRow].push("Periode Awal Asuransi");
		this._matrixExportExcelProductOto[numberIndexRow].push("Periode Akhir Asuransi");
		this._matrixExportExcelProductOto[numberIndexRow].push("Nama Pemesan");
		// this._matrixExportExcelProductOto[numberIndexRow].push("NIK Pemesan");
		this._matrixExportExcelProductOto[numberIndexRow].push("No. Telepon Pemesan");
		this._matrixExportExcelProductOto[numberIndexRow].push("Nama Tertanggung");
		this._matrixExportExcelProductOto[numberIndexRow].push("Alamat Tertanggung");
		this._matrixExportExcelProductOto[numberIndexRow].push("Ahli Waris");
		this._matrixExportExcelProductOto[numberIndexRow].push("Hubungan Tertanggung");
		this._matrixExportExcelProductOto[numberIndexRow].push("Alamat Ahli Waris");
		this._matrixExportExcelProductOto[numberIndexRow].push("No. Telepon Ahli Waris");
		this._matrixExportExcelProductOto[numberIndexRow].push("Alamat Lokasi Risiko");
		this._matrixExportExcelProductOto[numberIndexRow].push("Provinsi Risiko");
		this._matrixExportExcelProductOto[numberIndexRow].push("Kota Risiko");

		this._matrixExportExcelProductOto[numberIndexRow].push("Harga Kendaraan");
		this._matrixExportExcelProductOto[numberIndexRow].push("Perlengkapan Non Standar");
		this._matrixExportExcelProductOto[numberIndexRow].push("Merek Kendaraan");
		this._matrixExportExcelProductOto[numberIndexRow].push("Type Kendaraan");
		this._matrixExportExcelProductOto[numberIndexRow].push("Tahun Kendaraan");
		this._matrixExportExcelProductOto[numberIndexRow].push("Wilayah Penggunaan");
		this._matrixExportExcelProductOto[numberIndexRow].push("Seri Kendaraan");

		this._matrixExportExcelProductOto[numberIndexRow].push("Premi");
		this._matrixExportExcelProductOto[numberIndexRow].push("Biaya Admin");
		this._matrixExportExcelProductOto[numberIndexRow].push("Diskon");
		this._matrixExportExcelProductOto[numberIndexRow].push("Kode Promo");
		this._matrixExportExcelProductOto[numberIndexRow].push("Total Pembayaran");
		this._matrixExportExcelProductOto[numberIndexRow].push("Metode Pembayaran");
		this._matrixExportExcelProductOto[numberIndexRow].push("Tanggal Pembayaran");
		this._matrixExportExcelProductOto[numberIndexRow].push("Referal");
		this._matrixExportExcelProductOto[numberIndexRow].push("Nama Pemilik Kode Referal");
		// this._matrixExportExcelProductOto[numberIndexRow].push("NIK Referral");
		this._matrixExportExcelProductOto[numberIndexRow].push("Telephone Referral");
		this._matrixExportExcelProductOto[numberIndexRow].push("Keterangan");
		this._matrixExportExcelProductOto[numberIndexRow].push("No. BRIVA");
		this._matrixExportExcelProductOto[numberIndexRow].push("Bengkel Resmi");
		this._matrixExportExcelProductOto[numberIndexRow].push("Masa Berlaku SIM");

		// this._matrixExportExcelProductOto[numberIndexRow].push("RefNum");
		this._matrixExportExcelProductOto[numberIndexRow].push("Rating");
		this._matrixExportExcelProductOto[numberIndexRow].push("Saran");

		//#endregion


		//#region BINDING DATA TO EXCEL

		if(this._arrayModelProductGeneralForOtoReport !== undefined)
		{
			for (const modelProductOtoReport of this._arrayModelProductGeneralForOtoReport)
			{
				numberIndexRow++;

				this._matrixExportExcelProductOto[numberIndexRow] = [];
				this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.Channel ?? "-");
				this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.ProductName ?? "-");
				this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.OrderDate ?? "-");
				this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.ReferenceNumber ?? "-");
				this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.BranchName ?? "-");
				this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.PolicyNumber ?? "-");
				this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.CoverageName ?? "-");
				this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.PolicyStartDate ?? "-");
				this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.PolicyEndDate ?? "-");
				this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.PolicyholderFullName ?? "-");
				// this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.PolicyholderIdentificationNumber ?? "-");
				this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.PolicyholderPhoneNumber ?? "-");
				this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.InsuredFullName ?? "-");
				this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.InsuredAddress ?? "-");
				this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.BeneficiaryFullName ?? "-");
				this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.BeneficiaryRelationship ?? "-");
				this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.BeneficiaryAddress ?? "-");
				this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.BeneficiaryPhoneNumber ?? "-");
				this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.RiskLocationAddress ?? "-");
				this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.RiskProvince ?? "-");
				this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.RiskCity ?? "-");

				this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.modelProductOtoReport?.VehiclePrice ?? "-");
				this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.modelProductOtoReport?.NonStandardEquipmentPrice ?? "-");
				this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.modelProductOtoReport?.VehicleBrand ?? "-");
				this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.modelProductOtoReport?.VehicleType ?? "-");
				this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.modelProductOtoReport?.VehicleYear ?? "-");
				this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.modelProductOtoReport?.UsageArea ?? "-");
				this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.modelProductOtoReport?.VehicleSeries ?? "-");

				this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.PremiumAmount ?? "-");
				this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.AdministrationAmount ?? "-");
				this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.DiscountAmount ?? "-");
				this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.PromoCode ?? "-");
				this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.PremiumTotalAmount ?? "-");
				this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.PaymentMethod ?? "-");
				this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.PaymentDate ?? "-");
				this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.ReferralCode ?? "-");
				this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.ReferralOwnerFullName ?? "-");
				// this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.ReferralOwnerIdentificationNumber ?? "-");
				this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.ReferralOwnerPhoneNumber ?? "-");
				this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.Note ?? "-");
				this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.BRIVANumber ?? "-");

				if (modelProductOtoReport.modelProductOtoReport?.AuthorizedWorkshop === true)
				{
					this._matrixExportExcelProductOto[numberIndexRow].push("Ya" ?? "-");
				}
				else if (modelProductOtoReport.modelProductOtoReport?.AuthorizedWorkshop === false)
				{
					this._matrixExportExcelProductOto[numberIndexRow].push("Tidak" ?? "-");
				}
				else
				{
					this._matrixExportExcelProductOto[numberIndexRow].push("-");
				}

				this._matrixExportExcelProductOto[numberIndexRow].push(modelProductOtoReport.modelProductOtoReport?.PolicyholderExpiredDrivingLicense ?? "-");

				for (const modelPurchaseRatingReviews of this._arrayModelPurhcaseRatingReviews)
				{
					if (modelProductOtoReport.ReferenceNumber === modelPurchaseRatingReviews.ReferenceNumber)
					{
						// this._matrixExportExcelProductOto[numberIndexRow].push(modelPurchaseRatingReviews.ReferenceNumber ?? "-");

						if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.VeryGood)
						{
							this._matrixExportExcelProductOto[numberIndexRow].push("Sangat Baik");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.Good)
						{
							this._matrixExportExcelProductOto[numberIndexRow].push("Baik");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.Standard)
						{
							this._matrixExportExcelProductOto[numberIndexRow].push("Standar");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.Bad)
						{
							this._matrixExportExcelProductOto[numberIndexRow].push("Buruk");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.VeryBad)
						{
							this._matrixExportExcelProductOto[numberIndexRow].push("Sangat Buruk");
						}

						this._matrixExportExcelProductOto[numberIndexRow].push(modelPurchaseRatingReviews.Message ?? "-");
					}
				}
			}

			//#region GENERATE WORKSHEET

			const workSheet: xlsx.WorkSheet = xlsx.utils.aoa_to_sheet(this._matrixExportExcelProductOto, { cellDates: true, dateNF: "dd-mmmm-yyyy" });

			//#endregion


			//#region GENERATE WORKBOOK AND ADD THE WORKSHEET

			xlsx.utils.book_append_sheet(this._fileWorkBookExcel, workSheet, "OTO");

			//#endregion
		}
		else
		{

		}

		//#endregion
	}

	public getBicycleExportToExcel(): void
	{
		//#region INITIALIZATION DECLARATION VARIABLE

		this._matrixExportExcelProductBicycle = [];
		this._matrixExportExcelProductBicycle[0] = [];
		let numberIndexRow: number = 0;

		//#endregion


		//#region SET HEADER EXCEL

		this._matrixExportExcelProductBicycle[numberIndexRow] = [];
		this._matrixExportExcelProductBicycle[numberIndexRow].push("Platform");
		this._matrixExportExcelProductBicycle[numberIndexRow].push("Produk");
		this._matrixExportExcelProductBicycle[numberIndexRow].push("Tanggal Pemesanan");
		this._matrixExportExcelProductBicycle[numberIndexRow].push("No. Ref");
		this._matrixExportExcelProductBicycle[numberIndexRow].push("Kantor Cabang BRINS");
		this._matrixExportExcelProductBicycle[numberIndexRow].push("No. Polis");
		this._matrixExportExcelProductBicycle[numberIndexRow].push("Nama Paket");
		this._matrixExportExcelProductBicycle[numberIndexRow].push("Periode Awal Asuransi");
		this._matrixExportExcelProductBicycle[numberIndexRow].push("Periode Akhir Asuransi");
		this._matrixExportExcelProductBicycle[numberIndexRow].push("Nama Pemesan");
		// this._matrixExportExcelProductBicycle[numberIndexRow].push("NIK Pemesan");
		this._matrixExportExcelProductBicycle[numberIndexRow].push("No. Telepon Pemesan");
		this._matrixExportExcelProductBicycle[numberIndexRow].push("Nama Tertanggung");
		this._matrixExportExcelProductBicycle[numberIndexRow].push("Alamat Tertanggung");
		this._matrixExportExcelProductBicycle[numberIndexRow].push("Ahli Waris");
		this._matrixExportExcelProductBicycle[numberIndexRow].push("Hubungan Tertanggung");
		this._matrixExportExcelProductBicycle[numberIndexRow].push("Alamat Ahli Waris");
		this._matrixExportExcelProductBicycle[numberIndexRow].push("No. Telepon Ahli Waris");
		this._matrixExportExcelProductBicycle[numberIndexRow].push("Alamat Lokasi Risiko");
		this._matrixExportExcelProductBicycle[numberIndexRow].push("Provinsi Risiko");
		this._matrixExportExcelProductBicycle[numberIndexRow].push("Kota Risiko");

		this._matrixExportExcelProductBicycle[numberIndexRow].push("Harga Sepeda");
		this._matrixExportExcelProductBicycle[numberIndexRow].push("Merek Sepeda");
		this._matrixExportExcelProductBicycle[numberIndexRow].push("Tipe Sepeda");
		this._matrixExportExcelProductBicycle[numberIndexRow].push("Model Sepeda");
		this._matrixExportExcelProductBicycle[numberIndexRow].push("Seri Sepeda");
		this._matrixExportExcelProductBicycle[numberIndexRow].push("Tahun Produksi Sepeda");

		this._matrixExportExcelProductBicycle[numberIndexRow].push("Premi");
		this._matrixExportExcelProductBicycle[numberIndexRow].push("Biaya Admin");
		this._matrixExportExcelProductBicycle[numberIndexRow].push("Diskon");
		this._matrixExportExcelProductBicycle[numberIndexRow].push("Kode Promo");
		this._matrixExportExcelProductBicycle[numberIndexRow].push("Total Pembayaran");
		this._matrixExportExcelProductBicycle[numberIndexRow].push("Metode Pembayaran");
		this._matrixExportExcelProductBicycle[numberIndexRow].push("Tanggal Pembayaran");
		this._matrixExportExcelProductBicycle[numberIndexRow].push("Referal");
		this._matrixExportExcelProductBicycle[numberIndexRow].push("Nama Pemilik Kode Referal");
		// this._matrixExportExcelProductBicycle[numberIndexRow].push("NIK Referral");
		this._matrixExportExcelProductBicycle[numberIndexRow].push("Telephone Referral");
		this._matrixExportExcelProductBicycle[numberIndexRow].push("Keterangan");
		this._matrixExportExcelProductBicycle[numberIndexRow].push("No. BRIVA");

		// this._matrixExportExcelProductBicycle[numberIndexRow].push("RefNum");
		this._matrixExportExcelProductBicycle[numberIndexRow].push("Rating");
		this._matrixExportExcelProductBicycle[numberIndexRow].push("Saran");

		//#endregion


		//#region BINDING DATA TO EXCEL

		if(this._arrayModelProductGeneralForBicycleReport !== undefined)
		{
			for (const modelProductBicycleReport of this._arrayModelProductGeneralForBicycleReport)
			{
				numberIndexRow++;

				this._matrixExportExcelProductBicycle[numberIndexRow] = [];
				this._matrixExportExcelProductBicycle[numberIndexRow].push(modelProductBicycleReport.Channel ?? "-");
				this._matrixExportExcelProductBicycle[numberIndexRow].push(modelProductBicycleReport.ProductName ?? "-");
				this._matrixExportExcelProductBicycle[numberIndexRow].push(modelProductBicycleReport.OrderDate ?? "-");
				this._matrixExportExcelProductBicycle[numberIndexRow].push(modelProductBicycleReport.ReferenceNumber ?? "-");
				this._matrixExportExcelProductBicycle[numberIndexRow].push(modelProductBicycleReport.BranchName ?? "-");
				this._matrixExportExcelProductBicycle[numberIndexRow].push(modelProductBicycleReport.PolicyNumber ?? "-");
				this._matrixExportExcelProductBicycle[numberIndexRow].push(modelProductBicycleReport.CoverageName ?? "-");
				this._matrixExportExcelProductBicycle[numberIndexRow].push(modelProductBicycleReport.PolicyStartDate ?? "-");
				this._matrixExportExcelProductBicycle[numberIndexRow].push(modelProductBicycleReport.PolicyEndDate ?? "-");
				this._matrixExportExcelProductBicycle[numberIndexRow].push(modelProductBicycleReport.PolicyholderFullName ?? "-");
				// this._matrixExportExcelProductBicycle[numberIndexRow].push(modelProductBicycleReport.PolicyholderIdentificationNumber ?? "-");
				this._matrixExportExcelProductBicycle[numberIndexRow].push(modelProductBicycleReport.PolicyholderPhoneNumber ?? "-");
				this._matrixExportExcelProductBicycle[numberIndexRow].push(modelProductBicycleReport.InsuredFullName ?? "-");
				this._matrixExportExcelProductBicycle[numberIndexRow].push(modelProductBicycleReport.InsuredAddress ?? "-");
				this._matrixExportExcelProductBicycle[numberIndexRow].push(modelProductBicycleReport.BeneficiaryFullName ?? "-");
				this._matrixExportExcelProductBicycle[numberIndexRow].push(modelProductBicycleReport.BeneficiaryRelationship ?? "-");
				this._matrixExportExcelProductBicycle[numberIndexRow].push(modelProductBicycleReport.BeneficiaryAddress ?? "-");
				this._matrixExportExcelProductBicycle[numberIndexRow].push(modelProductBicycleReport.BeneficiaryPhoneNumber ?? "-");
				this._matrixExportExcelProductBicycle[numberIndexRow].push(modelProductBicycleReport.RiskLocationAddress ?? "-");
				this._matrixExportExcelProductBicycle[numberIndexRow].push(modelProductBicycleReport.RiskProvince ?? "-");
				this._matrixExportExcelProductBicycle[numberIndexRow].push(modelProductBicycleReport.RiskCity ?? "-");

				this._matrixExportExcelProductBicycle[numberIndexRow].push(modelProductBicycleReport.modelProductBicycleReport?.BicyclePrice ?? "-");
				this._matrixExportExcelProductBicycle[numberIndexRow].push(modelProductBicycleReport.modelProductBicycleReport?.BicycleBrand ?? "-");
				this._matrixExportExcelProductBicycle[numberIndexRow].push(modelProductBicycleReport.modelProductBicycleReport?.BicycleType ?? "-");
				this._matrixExportExcelProductBicycle[numberIndexRow].push(modelProductBicycleReport.modelProductBicycleReport?.BicycleModel ?? "-");
				this._matrixExportExcelProductBicycle[numberIndexRow].push(modelProductBicycleReport.modelProductBicycleReport?.BicycleSeries ?? "-");
				this._matrixExportExcelProductBicycle[numberIndexRow].push(modelProductBicycleReport.modelProductBicycleReport?.BicycleProductionYear ?? "-");

				this._matrixExportExcelProductBicycle[numberIndexRow].push(modelProductBicycleReport.PremiumAmount ?? "-");
				this._matrixExportExcelProductBicycle[numberIndexRow].push(modelProductBicycleReport.AdministrationAmount ?? "-");
				this._matrixExportExcelProductBicycle[numberIndexRow].push(modelProductBicycleReport.DiscountAmount ?? "-");
				this._matrixExportExcelProductBicycle[numberIndexRow].push(modelProductBicycleReport.PromoCode ?? "-");
				this._matrixExportExcelProductBicycle[numberIndexRow].push(modelProductBicycleReport.PremiumTotalAmount ?? "-");
				this._matrixExportExcelProductBicycle[numberIndexRow].push(modelProductBicycleReport.PaymentMethod ?? "-");
				this._matrixExportExcelProductBicycle[numberIndexRow].push(modelProductBicycleReport.PaymentDate ?? "-");
				this._matrixExportExcelProductBicycle[numberIndexRow].push(modelProductBicycleReport.ReferralCode ?? "-");
				this._matrixExportExcelProductBicycle[numberIndexRow].push(modelProductBicycleReport.ReferralOwnerFullName ?? "-");
				// this._matrixExportExcelProductBicycle[numberIndexRow].push(modelProductBicycleReport.ReferralOwnerIdentificationNumber ?? "-");
				this._matrixExportExcelProductBicycle[numberIndexRow].push(modelProductBicycleReport.ReferralOwnerPhoneNumber ?? "-");
				this._matrixExportExcelProductBicycle[numberIndexRow].push(modelProductBicycleReport.Note ?? "-");
				this._matrixExportExcelProductBicycle[numberIndexRow].push(modelProductBicycleReport.BRIVANumber ?? "-");

				for (const modelPurchaseRatingReviews of this._arrayModelPurhcaseRatingReviews)
				{
					if (modelProductBicycleReport.ReferenceNumber === modelPurchaseRatingReviews.ReferenceNumber)
					{
						// this._matrixExportExcelProductBicycle[numberIndexRow].push(modelPurchaseRatingReviews.ReferenceNumber ?? "-");

						if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.VeryGood)
						{
							this._matrixExportExcelProductBicycle[numberIndexRow].push("Sangat Baik");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.Good)
						{
							this._matrixExportExcelProductBicycle[numberIndexRow].push("Baik");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.Standard)
						{
							this._matrixExportExcelProductBicycle[numberIndexRow].push("Standar");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.Bad)
						{
							this._matrixExportExcelProductBicycle[numberIndexRow].push("Buruk");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.VeryBad)
						{
							this._matrixExportExcelProductBicycle[numberIndexRow].push("Sangat Buruk");
						}

						this._matrixExportExcelProductBicycle[numberIndexRow].push(modelPurchaseRatingReviews.Message ?? "-");
					}
				}
			}

			//#region GENERATE WORKSHEET

			const workSheet: xlsx.WorkSheet = xlsx.utils.aoa_to_sheet(this._matrixExportExcelProductBicycle, { cellDates: true, dateNF: "dd-mmmm-yyyy" });

			//#endregion


			//#region GENERATE WORKBOOK AND ADD THE WORKSHEET

			xlsx.utils.book_append_sheet(this._fileWorkBookExcel, workSheet, "BICYCLE");

			//#endregion
		}
		else
		{

		}

		//#endregion
	}

	public getPersonalAccidentExportToExcel(): void
	{
		//#region INITIALIZATION DECLARATION VARIABLE

		this._matrixExportExcelProductPersonalAccident = [];
		this._matrixExportExcelProductPersonalAccident[0] = [];
		let numberIndexRow: number = 0;

		//#endregion


		//#region SET HEADER EXCEL

		this._matrixExportExcelProductPersonalAccident[numberIndexRow] = [];
		this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("Platform");
		this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("Produk");
		this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("Tanggal Pemesanan");
		this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("No. Ref");
		this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("Kantor Cabang BRINS");
		this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("No. Polis");
		this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("Nama Paket");
		this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("Periode Awal Asuransi");
		this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("Periode Akhir Asuransi");
		this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("Nama Pemesan");
		// this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("NIK Pemesan");
		this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("No. Telepon Pemesan");
		this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("Nama Tertanggung");
		this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("Alamat Tertanggung");
		this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("Ahli Waris");
		this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("Hubungan Tertanggung");
		this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("Alamat Ahli Waris");
		this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("No. Telepon Ahli Waris");
		this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("Alamat Lokasi Risiko");
		this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("Provinsi Risiko");
		this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("Kota Risiko");
		this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("Premi");
		this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("Biaya Admin");
		this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("Diskon");
		this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("Kode Promo");
		this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("Total Pembayaran");
		this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("Metode Pembayaran");
		this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("Tanggal Pembayaran");
		this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("Referal");
		this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("Nama Pemilik Kode Referal");
		// this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("NIK Referral");
		this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("Telephone Referral");
		this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("Keterangan");
		this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("No. BRIVA");

		// this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("RefNum");
		this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("Rating");
		this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("Pesan");

		//#endregion


		//#region BINDING DATA TO EXCEL

		if(this._arrayModelProductGeneralForPersonalAccidentReport !== undefined)
		{
			for (const modelProductPersonalAccidentReport of this._arrayModelProductGeneralForPersonalAccidentReport)
			{
				numberIndexRow++;

				this._matrixExportExcelProductPersonalAccident[numberIndexRow] = [];
				this._matrixExportExcelProductPersonalAccident[numberIndexRow].push(modelProductPersonalAccidentReport.Channel ?? "-");
				this._matrixExportExcelProductPersonalAccident[numberIndexRow].push(modelProductPersonalAccidentReport.ProductName ?? "-");
				this._matrixExportExcelProductPersonalAccident[numberIndexRow].push(modelProductPersonalAccidentReport.OrderDate ?? "-");
				this._matrixExportExcelProductPersonalAccident[numberIndexRow].push(modelProductPersonalAccidentReport.ReferenceNumber ?? "-");
				this._matrixExportExcelProductPersonalAccident[numberIndexRow].push(modelProductPersonalAccidentReport.BranchName ?? "-");
				this._matrixExportExcelProductPersonalAccident[numberIndexRow].push(modelProductPersonalAccidentReport.PolicyNumber ?? "-");
				this._matrixExportExcelProductPersonalAccident[numberIndexRow].push(modelProductPersonalAccidentReport.CoverageName ?? "-");
				this._matrixExportExcelProductPersonalAccident[numberIndexRow].push(modelProductPersonalAccidentReport.PolicyStartDate ?? "-");
				this._matrixExportExcelProductPersonalAccident[numberIndexRow].push(modelProductPersonalAccidentReport.PolicyEndDate ?? "-");
				this._matrixExportExcelProductPersonalAccident[numberIndexRow].push(modelProductPersonalAccidentReport.PolicyholderFullName ?? "-");
				// this._matrixExportExcelProductPersonalAccident[numberIndexRow].push(modelProductPersonalAccidentReport.PolicyholderIdentificationNumber ?? "-");
				this._matrixExportExcelProductPersonalAccident[numberIndexRow].push(modelProductPersonalAccidentReport.PolicyholderPhoneNumber ?? "-");
				this._matrixExportExcelProductPersonalAccident[numberIndexRow].push(modelProductPersonalAccidentReport.InsuredFullName ?? "-");
				this._matrixExportExcelProductPersonalAccident[numberIndexRow].push(modelProductPersonalAccidentReport.InsuredAddress ?? "-");
				this._matrixExportExcelProductPersonalAccident[numberIndexRow].push(modelProductPersonalAccidentReport.BeneficiaryFullName ?? "-");
				this._matrixExportExcelProductPersonalAccident[numberIndexRow].push(modelProductPersonalAccidentReport.BeneficiaryRelationship ?? "-");
				this._matrixExportExcelProductPersonalAccident[numberIndexRow].push(modelProductPersonalAccidentReport.BeneficiaryAddress ?? "-");
				this._matrixExportExcelProductPersonalAccident[numberIndexRow].push(modelProductPersonalAccidentReport.BeneficiaryPhoneNumber ?? "-");
				this._matrixExportExcelProductPersonalAccident[numberIndexRow].push(modelProductPersonalAccidentReport.RiskLocationAddress ?? "-");
				this._matrixExportExcelProductPersonalAccident[numberIndexRow].push(modelProductPersonalAccidentReport.RiskProvince ?? "-");
				this._matrixExportExcelProductPersonalAccident[numberIndexRow].push(modelProductPersonalAccidentReport.RiskCity ?? "-");
				this._matrixExportExcelProductPersonalAccident[numberIndexRow].push(modelProductPersonalAccidentReport.PremiumAmount ?? "-");
				this._matrixExportExcelProductPersonalAccident[numberIndexRow].push(modelProductPersonalAccidentReport.AdministrationAmount ?? "-");
				this._matrixExportExcelProductPersonalAccident[numberIndexRow].push(modelProductPersonalAccidentReport.DiscountAmount ?? "-");
				this._matrixExportExcelProductPersonalAccident[numberIndexRow].push(modelProductPersonalAccidentReport.PromoCode ?? "-");
				this._matrixExportExcelProductPersonalAccident[numberIndexRow].push(modelProductPersonalAccidentReport.PremiumTotalAmount ?? "-");
				this._matrixExportExcelProductPersonalAccident[numberIndexRow].push(modelProductPersonalAccidentReport.PaymentMethod ?? "-");
				this._matrixExportExcelProductPersonalAccident[numberIndexRow].push(modelProductPersonalAccidentReport.PaymentDate ?? "-");
				this._matrixExportExcelProductPersonalAccident[numberIndexRow].push(modelProductPersonalAccidentReport.ReferralCode ?? "-");
				this._matrixExportExcelProductPersonalAccident[numberIndexRow].push(modelProductPersonalAccidentReport.ReferralOwnerFullName ?? "-");
				// this._matrixExportExcelProductPersonalAccident[numberIndexRow].push(modelProductPersonalAccidentReport.ReferralOwnerIdentificationNumber ?? "-");
				this._matrixExportExcelProductPersonalAccident[numberIndexRow].push(modelProductPersonalAccidentReport.ReferralOwnerPhoneNumber ?? "-");
				this._matrixExportExcelProductPersonalAccident[numberIndexRow].push(modelProductPersonalAccidentReport.Note ?? "-");
				this._matrixExportExcelProductPersonalAccident[numberIndexRow].push(modelProductPersonalAccidentReport.BRIVANumber ?? "-");

				for (const modelPurchaseRatingReviews of this._arrayModelPurhcaseRatingReviews)
				{
					if (modelProductPersonalAccidentReport.ReferenceNumber === modelPurchaseRatingReviews.ReferenceNumber)
					{
						// this._matrixExportExcelProductPersonalAccident[numberIndexRow].push(modelPurchaseRatingReviews.ReferenceNumber ?? "-");

						if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.VeryGood)
						{
							this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("Sangat Baik");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.Good)
						{
							this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("Baik");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.Standard)
						{
							this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("Standar");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.Bad)
						{
							this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("Buruk");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.VeryBad)
						{
							this._matrixExportExcelProductPersonalAccident[numberIndexRow].push("Sangat Buruk");
						}

						this._matrixExportExcelProductPersonalAccident[numberIndexRow].push(modelPurchaseRatingReviews.Message ?? "-");
					}
				}
			}

			//#region GENERATE WORKSHEET

			const workSheet: xlsx.WorkSheet = xlsx.utils.aoa_to_sheet(this._matrixExportExcelProductPersonalAccident, { cellDates: true, dateNF: "dd-mmmm-yyyy" });

			//#endregion


			//#region GENERATE WORKBOOK AND ADD THE WORKSHEET

			xlsx.utils.book_append_sheet(this._fileWorkBookExcel, workSheet, "PERSONAL ACCIDENT");

			//#endregion
		}
		else
		{

		}

		//#endregion
	}

	public getDamageOfBusinessPlaceExportToExcel(): void
	{
		//#region INITIALIZATION DECLARATION VARIABLE

		this._matrixExportExcelProductDamageOfBusinessPlace = [];
		this._matrixExportExcelProductDamageOfBusinessPlace[0] = [];
		let numberIndexRow: number = 0;

		//#endregion


		//#region SET HEADER EXCEL

		this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow] = [];
		this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("Platform");
		this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("Produk");
		this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("Tanggal Pemesanan");
		this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("No. Ref");
		this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("Kantor Cabang BRINS");
		this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("No. Polis");
		this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("Nama Paket");
		this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("Periode Awal Asuransi");
		this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("Periode Akhir Asuransi");
		this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("Nama Pemesan");
		// this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("NIK Pemesan");
		this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("No. Telepon Pemesan");
		this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("Nama Tertanggung");
		this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("Alamat Tertanggung");
		this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("Ahli Waris");
		this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("Hubungan Tertanggung");
		this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("Alamat Ahli Waris");
		this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("No. Telepon Ahli Waris");
		this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("Alamat Lokasi Risiko");
		this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("Provinsi Risiko");
		this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("Kota Risiko");

		this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("Informasi Kepemilikan");
		this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("Informasi Lokasi");

		this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("Premi");
		this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("Biaya Admin");
		this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("Diskon");
		this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("Kode Promo");
		this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("Total Pembayaran");
		this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("Metode Pembayaran");
		this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("Tanggal Pembayaran");
		this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("Referal");
		this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("Nama Pemilik Kode Referal");
		// this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("NIK Referral");
		this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("Telephone Referral");
		this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("Keterangan");
		this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("No. BRIVA");

		// this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("RefNum");
		this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("Rating");
		this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("Saran");

		//#endregion


		//#region BINDING DATA TO EXCEL

		if(this._arrayModelProductGeneralForDamageOfBusinessPlaceReport !== undefined)
		{
			for (const modelProductDamageOfBusinessPlaceReport of this._arrayModelProductGeneralForDamageOfBusinessPlaceReport)
			{
				numberIndexRow++;

				this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow] = [];
				this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.Channel ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.ProductName ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.OrderDate ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.ReferenceNumber ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.BranchName ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.PolicyNumber ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.CoverageName ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.PolicyStartDate ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.PolicyEndDate ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.PolicyholderFullName ?? "-");
				// this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.PolicyholderIdentificationNumber ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.PolicyholderPhoneNumber ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.InsuredFullName ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.InsuredAddress ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.BeneficiaryFullName ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.BeneficiaryRelationship ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.BeneficiaryAddress ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.BeneficiaryPhoneNumber ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.RiskLocationAddress ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.RiskProvince ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.RiskCity ?? "-");

				this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.modelProductDamageOfBusinessPlaceReport?.OwnershipInformation ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.modelProductDamageOfBusinessPlaceReport?.LocationInformation ?? "-");

				this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.PremiumAmount ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.AdministrationAmount ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.DiscountAmount ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.PromoCode ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.PremiumTotalAmount ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.PaymentMethod ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.PaymentDate ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.ReferralCode ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.ReferralOwnerFullName ?? "-");
				// this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.ReferralOwnerIdentificationNumber ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.ReferralOwnerPhoneNumber ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.Note ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.BRIVANumber ?? "-");

				for (const modelPurchaseRatingReviews of this._arrayModelPurhcaseRatingReviews)
				{
					if (modelProductDamageOfBusinessPlaceReport.ReferenceNumber === modelPurchaseRatingReviews.ReferenceNumber)
					{
						// this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push(modelPurchaseRatingReviews.ReferenceNumber ?? "-");

						if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.VeryGood)
						{
							this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("Sangat Baik");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.Good)
						{
							this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("Baik");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.Standard)
						{
							this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("Standar");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.Bad)
						{
							this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("Buruk");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.VeryBad)
						{
							this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push("Sangat Buruk");
						}

						this._matrixExportExcelProductDamageOfBusinessPlace[numberIndexRow].push(modelPurchaseRatingReviews.Message ?? "-");
					}
				}
			}

			//#region GENERATE WORKSHEET

			const workSheet: xlsx.WorkSheet = xlsx.utils.aoa_to_sheet(this._matrixExportExcelProductDamageOfBusinessPlace, { cellDates: true, dateNF: "dd-mmmm-yyyy" });

			//#endregion


			//#region GENERATE WORKBOOK AND ADD THE WORKSHEET

			xlsx.utils.book_append_sheet(this._fileWorkBookExcel, workSheet, "KTU");

			//#endregion
		}
		else
		{

		}

		//#endregion
	}

	public getMyHouseExportToExcel(): void
	{
		//#region INITIALIZATION DECLARATION VARIABLE

		this._matrixExportExcelProductMyHouse = [];
		this._matrixExportExcelProductMyHouse[0] = [];
		let numberIndexRow: number = 0;

		//#endregion


		//#region SET HEADER EXCEL

		this._matrixExportExcelProductMyHouse[numberIndexRow] = [];
		this._matrixExportExcelProductMyHouse[numberIndexRow].push("Platform");
		this._matrixExportExcelProductMyHouse[numberIndexRow].push("Produk");
		this._matrixExportExcelProductMyHouse[numberIndexRow].push("Tanggal Pemesanan");
		this._matrixExportExcelProductMyHouse[numberIndexRow].push("No. Ref");
		this._matrixExportExcelProductMyHouse[numberIndexRow].push("Kantor Cabang BRINS");
		this._matrixExportExcelProductMyHouse[numberIndexRow].push("No. Polis");
		this._matrixExportExcelProductMyHouse[numberIndexRow].push("Nama Paket");
		this._matrixExportExcelProductMyHouse[numberIndexRow].push("Periode Awal Asuransi");
		this._matrixExportExcelProductMyHouse[numberIndexRow].push("Periode Akhir Asuransi");
		this._matrixExportExcelProductMyHouse[numberIndexRow].push("Nama Pemesan");
		// this._matrixExportExcelProductMyHouse[numberIndexRow].push("NIK Pemesan");
		this._matrixExportExcelProductMyHouse[numberIndexRow].push("No. Telepon Pemesan");
		this._matrixExportExcelProductMyHouse[numberIndexRow].push("Nama Tertanggung");
		this._matrixExportExcelProductMyHouse[numberIndexRow].push("Alamat Tertanggung");
		this._matrixExportExcelProductMyHouse[numberIndexRow].push("Ahli Waris");
		this._matrixExportExcelProductMyHouse[numberIndexRow].push("Hubungan Tertanggung");
		this._matrixExportExcelProductMyHouse[numberIndexRow].push("Alamat Ahli Waris");
		this._matrixExportExcelProductMyHouse[numberIndexRow].push("No. Telepon Ahli Waris");
		this._matrixExportExcelProductMyHouse[numberIndexRow].push("Alamat Lokasi Risiko");
		this._matrixExportExcelProductMyHouse[numberIndexRow].push("Provinsi Risiko");
		this._matrixExportExcelProductMyHouse[numberIndexRow].push("Kota Risiko");

		this._matrixExportExcelProductMyHouse[numberIndexRow].push("Informasi Kepemilikan");
		this._matrixExportExcelProductMyHouse[numberIndexRow].push("Informasi Lokasi");

		this._matrixExportExcelProductMyHouse[numberIndexRow].push("Premi");
		this._matrixExportExcelProductMyHouse[numberIndexRow].push("Biaya Admin");
		this._matrixExportExcelProductMyHouse[numberIndexRow].push("Diskon");
		this._matrixExportExcelProductMyHouse[numberIndexRow].push("Kode Promo");
		this._matrixExportExcelProductMyHouse[numberIndexRow].push("Total Pembayaran");
		this._matrixExportExcelProductMyHouse[numberIndexRow].push("Metode Pembayaran");
		this._matrixExportExcelProductMyHouse[numberIndexRow].push("Tanggal Pembayaran");
		this._matrixExportExcelProductMyHouse[numberIndexRow].push("Referal");
		this._matrixExportExcelProductMyHouse[numberIndexRow].push("Nama Pemilik Kode Referal");
		// this._matrixExportExcelProductMyHouse[numberIndexRow].push("NIK Referral");
		this._matrixExportExcelProductMyHouse[numberIndexRow].push("Telephone Referral");
		this._matrixExportExcelProductMyHouse[numberIndexRow].push("Keterangan");
		this._matrixExportExcelProductMyHouse[numberIndexRow].push("No. BRIVA");

		// this._matrixExportExcelProductMyHouse[numberIndexRow].push("RefNum");
		this._matrixExportExcelProductMyHouse[numberIndexRow].push("Rating");
		this._matrixExportExcelProductMyHouse[numberIndexRow].push("Saran");

		//#endregion


		//#region BINDING DATA TO EXCEL

		if(this._arrayModelProductGeneralForMyHouseReport !== undefined)
		{
			for (const modelProductMyHouseReport of this._arrayModelProductGeneralForMyHouseReport)
			{
				numberIndexRow++;

				this._matrixExportExcelProductMyHouse[numberIndexRow] = [];
				this._matrixExportExcelProductMyHouse[numberIndexRow].push(modelProductMyHouseReport.Channel ?? "-");
				this._matrixExportExcelProductMyHouse[numberIndexRow].push(modelProductMyHouseReport.ProductName ?? "-");
				this._matrixExportExcelProductMyHouse[numberIndexRow].push(modelProductMyHouseReport.OrderDate ?? "-");
				this._matrixExportExcelProductMyHouse[numberIndexRow].push(modelProductMyHouseReport.ReferenceNumber ?? "-");
				this._matrixExportExcelProductMyHouse[numberIndexRow].push(modelProductMyHouseReport.BranchName ?? "-");
				this._matrixExportExcelProductMyHouse[numberIndexRow].push(modelProductMyHouseReport.PolicyNumber ?? "-");
				this._matrixExportExcelProductMyHouse[numberIndexRow].push(modelProductMyHouseReport.CoverageName ?? "-");
				this._matrixExportExcelProductMyHouse[numberIndexRow].push(modelProductMyHouseReport.PolicyStartDate ?? "-");
				this._matrixExportExcelProductMyHouse[numberIndexRow].push(modelProductMyHouseReport.PolicyEndDate ?? "-");
				this._matrixExportExcelProductMyHouse[numberIndexRow].push(modelProductMyHouseReport.PolicyholderFullName ?? "-");
				// this._matrixExportExcelProductMyHouse[numberIndexRow].push(modelProductMyHouseReport.PolicyholderIdentificationNumber ?? "-");
				this._matrixExportExcelProductMyHouse[numberIndexRow].push(modelProductMyHouseReport.PolicyholderPhoneNumber ?? "-");
				this._matrixExportExcelProductMyHouse[numberIndexRow].push(modelProductMyHouseReport.InsuredFullName ?? "-");
				this._matrixExportExcelProductMyHouse[numberIndexRow].push(modelProductMyHouseReport.InsuredAddress ?? "-");
				this._matrixExportExcelProductMyHouse[numberIndexRow].push(modelProductMyHouseReport.BeneficiaryFullName ?? "-");
				this._matrixExportExcelProductMyHouse[numberIndexRow].push(modelProductMyHouseReport.BeneficiaryRelationship ?? "-");
				this._matrixExportExcelProductMyHouse[numberIndexRow].push(modelProductMyHouseReport.BeneficiaryAddress ?? "-");
				this._matrixExportExcelProductMyHouse[numberIndexRow].push(modelProductMyHouseReport.BeneficiaryPhoneNumber ?? "-");
				this._matrixExportExcelProductMyHouse[numberIndexRow].push(modelProductMyHouseReport.RiskLocationAddress ?? "-");
				this._matrixExportExcelProductMyHouse[numberIndexRow].push(modelProductMyHouseReport.RiskProvince ?? "-");
				this._matrixExportExcelProductMyHouse[numberIndexRow].push(modelProductMyHouseReport.RiskCity ?? "-");

				this._matrixExportExcelProductMyHouse[numberIndexRow].push(modelProductMyHouseReport.modelProductMyHouseReport?.OwnershipInformation ?? "-");
				this._matrixExportExcelProductMyHouse[numberIndexRow].push(modelProductMyHouseReport.modelProductMyHouseReport?.LocationInformation ?? "-");

				this._matrixExportExcelProductMyHouse[numberIndexRow].push(modelProductMyHouseReport.PremiumAmount ?? "-");
				this._matrixExportExcelProductMyHouse[numberIndexRow].push(modelProductMyHouseReport.AdministrationAmount ?? "-");
				this._matrixExportExcelProductMyHouse[numberIndexRow].push(modelProductMyHouseReport.DiscountAmount ?? "-");
				this._matrixExportExcelProductMyHouse[numberIndexRow].push(modelProductMyHouseReport.PromoCode ?? "-");
				this._matrixExportExcelProductMyHouse[numberIndexRow].push(modelProductMyHouseReport.PremiumTotalAmount ?? "-");
				this._matrixExportExcelProductMyHouse[numberIndexRow].push(modelProductMyHouseReport.PaymentMethod ?? "-");
				this._matrixExportExcelProductMyHouse[numberIndexRow].push(modelProductMyHouseReport.PaymentDate ?? "-");
				this._matrixExportExcelProductMyHouse[numberIndexRow].push(modelProductMyHouseReport.ReferralCode ?? "-");
				this._matrixExportExcelProductMyHouse[numberIndexRow].push(modelProductMyHouseReport.ReferralOwnerFullName ?? "-");
				// this._matrixExportExcelProductMyHouse[numberIndexRow].push(modelProductMyHouseReport.ReferralOwnerIdentificationNumber ?? "-");
				this._matrixExportExcelProductMyHouse[numberIndexRow].push(modelProductMyHouseReport.ReferralOwnerPhoneNumber ?? "-");
				this._matrixExportExcelProductMyHouse[numberIndexRow].push(modelProductMyHouseReport.Note ?? "-");
				this._matrixExportExcelProductMyHouse[numberIndexRow].push(modelProductMyHouseReport.BRIVANumber ?? "-");

				for (const modelPurchaseRatingReviews of this._arrayModelPurhcaseRatingReviews)
				{
					if (modelProductMyHouseReport.ReferenceNumber === modelPurchaseRatingReviews.ReferenceNumber)
					{
						// this._matrixExportExcelProductMyHouse[numberIndexRow].push(modelPurchaseRatingReviews.ReferenceNumber ?? "-");

						if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.VeryGood)
						{
							this._matrixExportExcelProductMyHouse[numberIndexRow].push("Sangat Baik");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.Good)
						{
							this._matrixExportExcelProductMyHouse[numberIndexRow].push("Baik");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.Standard)
						{
							this._matrixExportExcelProductMyHouse[numberIndexRow].push("Standar");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.Bad)
						{
							this._matrixExportExcelProductMyHouse[numberIndexRow].push("Buruk");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.VeryBad)
						{
							this._matrixExportExcelProductMyHouse[numberIndexRow].push("Sangat Buruk");
						}

						this._matrixExportExcelProductMyHouse[numberIndexRow].push(modelPurchaseRatingReviews.Message ?? "-");
					}
				}
			}

			//#region GENERATE WORKSHEET

			const workSheet: xlsx.WorkSheet = xlsx.utils.aoa_to_sheet(this._matrixExportExcelProductMyHouse, { cellDates: true, dateNF: "dd-mmmm-yyyy" });

			//#endregion


			//#region GENERATE WORKBOOK AND ADD THE WORKSHEET

			xlsx.utils.book_append_sheet(this._fileWorkBookExcel, workSheet, "MY HOUSE");

			//#endregion
		}
		else
		{

		}

		//#endregion
	}

	public getMyMotorcycleExportToExcel(): void
	{
		//#region INITIALIZATION DECLARATION VARIABLE

		this._matrixExportExcelProductMyMotorcycle = [];
		this._matrixExportExcelProductMyMotorcycle[0] = [];
		let numberIndexRow: number = 0;

		//#endregion


		//#region SET HEADER EXCEL

		this._matrixExportExcelProductMyMotorcycle[numberIndexRow] = [];
		this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("Platform");
		this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("Produk");
		this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("Tanggal Pemesanan");
		this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("No. Ref");
		this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("Kantor Cabang BRINS");
		this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("No. Polis");
		this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("Nama Paket");
		this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("Periode Awal Asuransi");
		this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("Periode Akhir Asuransi");
		this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("Nama Pemesan");
		// this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("NIK Pemesan");
		this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("No. Telepon Pemesan");
		this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("Nama Tertanggung");
		this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("Alamat Tertanggung");
		this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("Ahli Waris");
		this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("Hubungan Tertanggung");
		this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("Alamat Ahli Waris");
		this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("No. Telepon Ahli Waris");
		this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("Alamat Lokasi Risiko");
		this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("Provinsi Risiko");
		this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("Kota Risiko");

		this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("Merek Kendaraan");
		this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("Tahun Produksi Kendaraan");
		this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("Wilayah Penggunaan");

		this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("Premi");
		this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("Biaya Admin");
		this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("Diskon");
		this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("Kode Promo");
		this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("Total Pembayaran");
		this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("Metode Pembayaran");
		this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("Tanggal Pembayaran");
		this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("Referal");
		this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("Nama Pemilik Kode Referal");
		// this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("NIK Referral");
		this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("Telephone Referral");
		this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("Keterangan");
		this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("No. BRIVA");

		// this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("RefNum");
		this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("Rating");
		this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("Saran");

		//#endregion


		//#region BINDING DATA TO EXCEL

		if(this._arrayModelProductGeneralForMyMotorcycleReport !== undefined)
		{
			for (const modelProductMyMotorcycleReport of this._arrayModelProductGeneralForMyMotorcycleReport)
			{
				numberIndexRow++;

				this._matrixExportExcelProductMyMotorcycle[numberIndexRow] = [];
				this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push(modelProductMyMotorcycleReport.Channel ?? "-");
				this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push(modelProductMyMotorcycleReport.ProductName ?? "-");
				this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push(modelProductMyMotorcycleReport.OrderDate ?? "-");
				this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push(modelProductMyMotorcycleReport.ReferenceNumber ?? "-");
				this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push(modelProductMyMotorcycleReport.BranchName ?? "-");
				this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push(modelProductMyMotorcycleReport.PolicyNumber ?? "-");
				this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push(modelProductMyMotorcycleReport.CoverageName ?? "-");
				this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push(modelProductMyMotorcycleReport.PolicyStartDate ?? "-");
				this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push(modelProductMyMotorcycleReport.PolicyEndDate ?? "-");
				this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push(modelProductMyMotorcycleReport.PolicyholderFullName ?? "-");
				// this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push(modelProductMyMotorcycleReport.PolicyholderIdentificationNumber ?? "-");
				this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push(modelProductMyMotorcycleReport.PolicyholderPhoneNumber ?? "-");
				this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push(modelProductMyMotorcycleReport.InsuredFullName ?? "-");
				this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push(modelProductMyMotorcycleReport.InsuredAddress ?? "-");
				this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push(modelProductMyMotorcycleReport.BeneficiaryFullName ?? "-");
				this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push(modelProductMyMotorcycleReport.BeneficiaryRelationship ?? "-");
				this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push(modelProductMyMotorcycleReport.BeneficiaryAddress ?? "-");
				this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push(modelProductMyMotorcycleReport.BeneficiaryPhoneNumber ?? "-");
				this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push(modelProductMyMotorcycleReport.RiskLocationAddress ?? "-");
				this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push(modelProductMyMotorcycleReport.RiskProvince ?? "-");
				this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push(modelProductMyMotorcycleReport.RiskCity ?? "-");

				this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push(modelProductMyMotorcycleReport.modelProductMyMotorcycleReport?.VehicleBrand ?? "-");
				this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push(modelProductMyMotorcycleReport.modelProductMyMotorcycleReport?.VehicleProductionYear ?? "-");
				this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push(modelProductMyMotorcycleReport.modelProductMyMotorcycleReport?.UsageArea ?? "-");

				this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push(modelProductMyMotorcycleReport.PremiumAmount ?? "-");
				this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push(modelProductMyMotorcycleReport.AdministrationAmount ?? "-");
				this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push(modelProductMyMotorcycleReport.DiscountAmount ?? "-");
				this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push(modelProductMyMotorcycleReport.PromoCode ?? "-");
				this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push(modelProductMyMotorcycleReport.PremiumTotalAmount ?? "-");
				this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push(modelProductMyMotorcycleReport.PaymentMethod ?? "-");
				this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push(modelProductMyMotorcycleReport.PaymentDate ?? "-");
				this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push(modelProductMyMotorcycleReport.ReferralCode ?? "-");
				this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push(modelProductMyMotorcycleReport.ReferralOwnerFullName ?? "-");
				// this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push(modelProductMyMotorcycleReport.ReferralOwnerIdentificationNumber ?? "-");
				this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push(modelProductMyMotorcycleReport.ReferralOwnerPhoneNumber ?? "-");
				this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push(modelProductMyMotorcycleReport.Note ?? "-");
				this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push(modelProductMyMotorcycleReport.BRIVANumber ?? "-");

				for (const modelPurchaseRatingReviews of this._arrayModelPurhcaseRatingReviews)
				{
					if (modelProductMyMotorcycleReport.ReferenceNumber === modelPurchaseRatingReviews.ReferenceNumber)
					{
						// this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push(modelPurchaseRatingReviews.ReferenceNumber ?? "-");

						if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.VeryGood)
						{
							this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("Sangat Baik");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.Good)
						{
							this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("Baik");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.Standard)
						{
							this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("Standar");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.Bad)
						{
							this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("Buruk");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.VeryBad)
						{
							this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push("Sangat Buruk");
						}

						this._matrixExportExcelProductMyMotorcycle[numberIndexRow].push(modelPurchaseRatingReviews.Message ?? "-");
					}
				}
			}

			//#region GENERATE WORKSHEET

			const workSheet: xlsx.WorkSheet = xlsx.utils.aoa_to_sheet(this._matrixExportExcelProductMyMotorcycle, { cellDates: true, dateNF: "dd-mmmm-yyyy" });

			//#endregion


			//#region GENERATE WORKBOOK AND ADD THE WORKSHEET

			xlsx.utils.book_append_sheet(this._fileWorkBookExcel, workSheet, "MY MOTORCYCLE");

			//#endregion
		}
		else
		{

		}

		//#endregion
	}

	public getAsriSyariahExportToExcel(): void
	{
		//#region INITIALIZATION DECLARATION VARIABLE

		this._matrixExportExcelProductAsriSyariah = [];
		this._matrixExportExcelProductAsriSyariah[0] = [];
		let numberIndexRow: number = 0;

		//#endregion


		//#region SET HEADER EXCEL

		this._matrixExportExcelProductAsriSyariah[numberIndexRow] = [];
		this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("Platform");
		this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("Produk");
		this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("Tanggal Pemesanan");
		this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("No. Ref");
		this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("Kantor Cabang BRINS");
		this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("No. Polis");
		this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("Nama Paket");
		this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("Periode Awal Asuransi");
		this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("Periode Akhir Asuransi");
		this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("Nama Pemesan");
		// this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("NIK Pemesan");
		this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("No. Telepon Pemesan");
		this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("Nama Tertanggung");
		this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("Alamat Tertanggung");
		this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("Ahli Waris");
		this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("Hubungan Tertanggung");
		this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("Alamat Ahli Waris");
		this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("No. Telepon Ahli Waris");
		this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("Alamat Lokasi Risiko");
		this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("Provinsi Risiko");
		this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("Kota Risiko");
		this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("Harga Bangunan");
		this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("Harga Perlengkapan");
		this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("Premi");
		this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("Biaya Admin");
		this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("Diskon");
		this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("Kode Promo");
		this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("Total Pembayaran");
		this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("Metode Pembayaran");
		this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("Tanggal Pembayaran");
		this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("Referal");
		this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("Nama Pemilik Kode Referal");
		// this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("NIK Referral");
		this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("Telephone Referral");
		this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("Keterangan");
		this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("No. BRIVA");

		// this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("RefNum");
		this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("Rating");
		this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("Saran");

		//#endregion


		//#region BINDING DATA TO EXCEL

		if(this._arrayModelProductGeneralForAsriReport !== undefined)
		{
			for (const modelProductAsriReport of this._arrayModelProductGeneralForAsriSyariahReport)
			{
				numberIndexRow++;

				this._matrixExportExcelProductAsriSyariah[numberIndexRow] = [];
				this._matrixExportExcelProductAsriSyariah[numberIndexRow].push(modelProductAsriReport.Channel ?? "-");
				this._matrixExportExcelProductAsriSyariah[numberIndexRow].push(modelProductAsriReport.ProductName ?? "-");
				this._matrixExportExcelProductAsriSyariah[numberIndexRow].push(modelProductAsriReport.OrderDate ?? "-");
				this._matrixExportExcelProductAsriSyariah[numberIndexRow].push(modelProductAsriReport.ReferenceNumber ?? "-");
				this._matrixExportExcelProductAsriSyariah[numberIndexRow].push(modelProductAsriReport.BranchName ?? "-");
				this._matrixExportExcelProductAsriSyariah[numberIndexRow].push(modelProductAsriReport.PolicyNumber ?? "-");
				this._matrixExportExcelProductAsriSyariah[numberIndexRow].push(modelProductAsriReport.CoverageName ?? "-");
				this._matrixExportExcelProductAsriSyariah[numberIndexRow].push(modelProductAsriReport.PolicyStartDate ?? "-");
				this._matrixExportExcelProductAsriSyariah[numberIndexRow].push(modelProductAsriReport.PolicyEndDate ?? "-");
				this._matrixExportExcelProductAsriSyariah[numberIndexRow].push(modelProductAsriReport.PolicyholderFullName ?? "-");
				// this._matrixExportExcelProductAsriSyariah[numberIndexRow].push(modelProductAsriReport.PolicyholderIdentificationNumber ?? "-");
				this._matrixExportExcelProductAsriSyariah[numberIndexRow].push(modelProductAsriReport.PolicyholderPhoneNumber ?? "-");
				this._matrixExportExcelProductAsriSyariah[numberIndexRow].push(modelProductAsriReport.InsuredFullName ?? "-");
				this._matrixExportExcelProductAsriSyariah[numberIndexRow].push(modelProductAsriReport.InsuredAddress ?? "-");
				this._matrixExportExcelProductAsriSyariah[numberIndexRow].push(modelProductAsriReport.BeneficiaryFullName ?? "-");
				this._matrixExportExcelProductAsriSyariah[numberIndexRow].push(modelProductAsriReport.BeneficiaryRelationship ?? "-");
				this._matrixExportExcelProductAsriSyariah[numberIndexRow].push(modelProductAsriReport.BeneficiaryAddress ?? "-");
				this._matrixExportExcelProductAsriSyariah[numberIndexRow].push(modelProductAsriReport.BeneficiaryPhoneNumber ?? "-");
				this._matrixExportExcelProductAsriSyariah[numberIndexRow].push(modelProductAsriReport.RiskLocationAddress ?? "-");
				this._matrixExportExcelProductAsriSyariah[numberIndexRow].push(modelProductAsriReport.RiskProvince ?? "-");
				this._matrixExportExcelProductAsriSyariah[numberIndexRow].push(modelProductAsriReport.RiskCity ?? "-");
				this._matrixExportExcelProductAsriSyariah[numberIndexRow].push(modelProductAsriReport.modelProductAsriSyariahReport?.BuildingPrice ?? "-");
				this._matrixExportExcelProductAsriSyariah[numberIndexRow].push(modelProductAsriReport.modelProductAsriSyariahReport?.InteriorPrice ?? "-");
				this._matrixExportExcelProductAsriSyariah[numberIndexRow].push(modelProductAsriReport.PremiumAmount ?? "-");
				this._matrixExportExcelProductAsriSyariah[numberIndexRow].push(modelProductAsriReport.AdministrationAmount ?? "-");
				this._matrixExportExcelProductAsriSyariah[numberIndexRow].push(modelProductAsriReport.DiscountAmount ?? "-");
				this._matrixExportExcelProductAsriSyariah[numberIndexRow].push(modelProductAsriReport.PromoCode ?? "-");
				this._matrixExportExcelProductAsriSyariah[numberIndexRow].push(modelProductAsriReport.PremiumTotalAmount ?? "-");
				this._matrixExportExcelProductAsriSyariah[numberIndexRow].push(modelProductAsriReport.PaymentMethod ?? "-");
				this._matrixExportExcelProductAsriSyariah[numberIndexRow].push(modelProductAsriReport.PaymentDate ?? "-");
				this._matrixExportExcelProductAsriSyariah[numberIndexRow].push(modelProductAsriReport.ReferralCode ?? "-");
				this._matrixExportExcelProductAsriSyariah[numberIndexRow].push(modelProductAsriReport.ReferralOwnerFullName ?? "-");
				// this._matrixExportExcelProductAsriSyariah[numberIndexRow].push(modelProductAsriReport.ReferralOwnerIdentificationNumber ?? "-");
				this._matrixExportExcelProductAsriSyariah[numberIndexRow].push(modelProductAsriReport.ReferralOwnerPhoneNumber ?? "-");
				this._matrixExportExcelProductAsriSyariah[numberIndexRow].push(modelProductAsriReport.Note ?? "-");
				this._matrixExportExcelProductAsriSyariah[numberIndexRow].push(modelProductAsriReport.BRIVANumber ?? "-");

				for (const modelPurchaseRatingReviews of this._arrayModelPurhcaseRatingReviews)
				{
					if (modelProductAsriReport.ReferenceNumber === modelPurchaseRatingReviews.ReferenceNumber)
					{
						// this._matrixExportExcelProductAsriSyariah[numberIndexRow].push(modelPurchaseRatingReviews.ReferenceNumber ?? "-");

						if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.VeryGood)
						{
							this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("Sangat Baik");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.Good)
						{
							this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("Baik");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.Standard)
						{
							this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("Standar");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.Bad)
						{
							this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("Buruk");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.VeryBad)
						{
							this._matrixExportExcelProductAsriSyariah[numberIndexRow].push("Sangat Buruk");
						}

						this._matrixExportExcelProductAsriSyariah[numberIndexRow].push(modelPurchaseRatingReviews.Message ?? "-");
					}
				}
			}

			//#region GENERATE WORKSHEET

			const workSheet: xlsx.WorkSheet = xlsx.utils.aoa_to_sheet(this._matrixExportExcelProductAsriSyariah, { cellDates: true, dateNF: "dd-mmmm-yyyy" });

			//#endregion


			//#region GENERATE WORKBOOK AND ADD THE WORKSHEET

			xlsx.utils.book_append_sheet(this._fileWorkBookExcel, workSheet, "ASRI SYARIAH");

			//#endregion
		}
		else
		{

		}

		//#endregion
	}

	public getOtoSyariahExportToExcel(): void
	{
		//#region INITIALIZATION DECLARATION VARIABLE

		this._matrixExportExcelProductOtoSyariah = [];
		this._matrixExportExcelProductOtoSyariah[0] = [];
		let numberIndexRow: number = 0;

		//#endregion


		//#region SET HEADER EXCEL

		this._matrixExportExcelProductOtoSyariah[numberIndexRow] = [];
		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Platform");
		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Produk");
		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Tanggal Pemesanan");
		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("No. Ref");
		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Kantor Cabang BRINS");
		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("No. Polis");
		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Nama Paket");
		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Periode Awal Asuransi");
		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Periode Akhir Asuransi");
		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Nama Pemesan");
		// this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("NIK Pemesan");
		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("No. Telepon Pemesan");
		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Nama Tertanggung");
		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Alamat Tertanggung");
		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Ahli Waris");
		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Hubungan Tertanggung");
		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Alamat Ahli Waris");
		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("No. Telepon Ahli Waris");
		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Alamat Lokasi Risiko");
		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Provinsi Risiko");
		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Kota Risiko");

		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Harga Kendaraan");
		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Perlengkapan Non Standar");
		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Merek Kendaraan");
		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Type Kendaraan");
		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Tahun Kendaraan");
		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Wilayah Penggunaan");
		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Seri Kendaraan");

		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Premi");
		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Biaya Admin");
		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Diskon");
		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Kode Promo");
		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Total Pembayaran");
		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Metode Pembayaran");
		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Tanggal Pembayaran");
		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Referal");
		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Nama Pemilik Kode Referal");
		// this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("NIK Referral");
		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Telephone Referral");
		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Keterangan");
		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("No. BRIVA");
		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Bengkel Resmi");
		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Masa Berlaku SIM");

		// this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("RefNum");
		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Rating");
		this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Saran");

		//#endregion


		//#region BINDING DATA TO EXCEL

		if(this._arrayModelProductGeneralForOtoReport !== undefined)
		{
			for (const modelProductOtoReport of this._arrayModelProductGeneralForOtoSyariahReport)
			{
				numberIndexRow++;

				this._matrixExportExcelProductOtoSyariah[numberIndexRow] = [];
				this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.Channel ?? "-");
				this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.ProductName ?? "-");
				this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.OrderDate ?? "-");
				this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.ReferenceNumber ?? "-");
				this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.BranchName ?? "-");
				this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.PolicyNumber ?? "-");
				this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.CoverageName ?? "-");
				this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.PolicyStartDate ?? "-");
				this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.PolicyEndDate ?? "-");
				this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.PolicyholderFullName ?? "-");
				// this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.PolicyholderIdentificationNumber ?? "-");
				this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.PolicyholderPhoneNumber ?? "-");
				this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.InsuredFullName ?? "-");
				this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.InsuredAddress ?? "-");
				this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.BeneficiaryFullName ?? "-");
				this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.BeneficiaryRelationship ?? "-");
				this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.BeneficiaryAddress ?? "-");
				this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.BeneficiaryPhoneNumber ?? "-");
				this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.RiskLocationAddress ?? "-");
				this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.RiskProvince ?? "-");
				this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.RiskCity ?? "-");

				this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.modelProductOtoSyariahReport?.VehiclePrice ?? "-");
				this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.modelProductOtoSyariahReport?.NonStandardEquipmentPrice ?? "-");
				this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.modelProductOtoSyariahReport?.VehicleBrand ?? "-");
				this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.modelProductOtoSyariahReport?.VehicleType ?? "-");
				this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.modelProductOtoSyariahReport?.VehicleYear ?? "-");
				this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.modelProductOtoSyariahReport?.UsageArea ?? "-");
				this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.modelProductOtoSyariahReport?.VehicleSeries ?? "-");

				this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.PremiumAmount ?? "-");
				this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.AdministrationAmount ?? "-");
				this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.DiscountAmount ?? "-");
				this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.PromoCode ?? "-");
				this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.PremiumTotalAmount ?? "-");
				this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.PaymentMethod ?? "-");
				this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.PaymentDate ?? "-");
				this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.ReferralCode ?? "-");
				this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.ReferralOwnerFullName ?? "-");
				// this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.ReferralOwnerIdentificationNumber ?? "-");
				this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.ReferralOwnerPhoneNumber ?? "-");
				this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.Note ?? "-");
				this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.BRIVANumber ?? "-");

				if (modelProductOtoReport.modelProductOtoReport?.AuthorizedWorkshop === true)
				{
					this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Ya" ?? "-");
				}
				else if (modelProductOtoReport.modelProductOtoReport?.AuthorizedWorkshop === false)
				{
					this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Tidak" ?? "-");
				}
				else
				{
					this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("-");
				}

				this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelProductOtoReport.modelProductOtoReport?.PolicyholderExpiredDrivingLicense ?? "-");

				for (const modelPurchaseRatingReviews of this._arrayModelPurhcaseRatingReviews)
				{
					if (modelProductOtoReport.ReferenceNumber === modelPurchaseRatingReviews.ReferenceNumber)
					{
						// this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelPurchaseRatingReviews.ReferenceNumber ?? "-");

						if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.VeryGood)
						{
							this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Sangat Baik");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.Good)
						{
							this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Baik");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.Standard)
						{
							this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Standar");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.Bad)
						{
							this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Buruk");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.VeryBad)
						{
							this._matrixExportExcelProductOtoSyariah[numberIndexRow].push("Sangat Buruk");
						}

						this._matrixExportExcelProductOtoSyariah[numberIndexRow].push(modelPurchaseRatingReviews.Message ?? "-");
					}
				}
			}

			//#region GENERATE WORKSHEET

			const workSheet: xlsx.WorkSheet = xlsx.utils.aoa_to_sheet(this._matrixExportExcelProductOtoSyariah, { cellDates: true, dateNF: "dd-mmmm-yyyy" });

			//#endregion


			//#region GENERATE WORKBOOK AND ADD THE WORKSHEET

			xlsx.utils.book_append_sheet(this._fileWorkBookExcel, workSheet, "OTO SYARIAH");

			//#endregion
		}
		else
		{

		}

		//#endregion
	}

	public getPersonalAccidentSyariahExportToExcel(): void
	{
		//#region INITIALIZATION DECLARATION VARIABLE

		this._matrixExportExcelProductPersonalAccidentSyariah = [];
		this._matrixExportExcelProductPersonalAccidentSyariah[0] = [];
		let numberIndexRow: number = 0;

		//#endregion


		//#region SET HEADER EXCEL

		this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow] = [];
		this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("Platform");
		this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("Produk");
		this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("Tanggal Pemesanan");
		this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("No. Ref");
		this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("Kantor Cabang BRINS");
		this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("No. Polis");
		this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("Nama Paket");
		this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("Periode Awal Asuransi");
		this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("Periode Akhir Asuransi");
		this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("Nama Pemesan");
		// this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("NIK Pemesan");
		this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("No. Telepon Pemesan");
		this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("Nama Tertanggung");
		this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("Alamat Tertanggung");
		this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("Ahli Waris");
		this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("Hubungan Tertanggung");
		this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("Alamat Ahli Waris");
		this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("No. Telepon Ahli Waris");
		this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("Alamat Lokasi Risiko");
		this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("Provinsi Risiko");
		this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("Kota Risiko");
		this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("Premi");
		this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("Biaya Admin");
		this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("Diskon");
		this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("Kode Promo");
		this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("Total Pembayaran");
		this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("Metode Pembayaran");
		this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("Tanggal Pembayaran");
		this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("Referal");
		this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("Nama Pemilik Kode Referal");
		// this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("NIK Referral");
		this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("Telephone Referral");
		this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("Keterangan");
		this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("No. BRIVA");

		// this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("RefNum");
		this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("Rating");
		this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("Pesan");

		//#endregion


		//#region BINDING DATA TO EXCEL

		if(this._arrayModelProductGeneralForPersonalAccidentReport !== undefined)
		{
			for (const modelProductPersonalAccidentReport of this._arrayModelProductGeneralForPersonalAccidentSyariahReport)
			{
				numberIndexRow++;

				this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow] = [];
				this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push(modelProductPersonalAccidentReport.Channel ?? "-");
				this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push(modelProductPersonalAccidentReport.ProductName ?? "-");
				this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push(modelProductPersonalAccidentReport.OrderDate ?? "-");
				this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push(modelProductPersonalAccidentReport.ReferenceNumber ?? "-");
				this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push(modelProductPersonalAccidentReport.BranchName ?? "-");
				this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push(modelProductPersonalAccidentReport.PolicyNumber ?? "-");
				this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push(modelProductPersonalAccidentReport.CoverageName ?? "-");
				this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push(modelProductPersonalAccidentReport.PolicyStartDate ?? "-");
				this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push(modelProductPersonalAccidentReport.PolicyEndDate ?? "-");
				this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push(modelProductPersonalAccidentReport.PolicyholderFullName ?? "-");
				// this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push(modelProductPersonalAccidentReport.PolicyholderIdentificationNumber ?? "-");
				this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push(modelProductPersonalAccidentReport.PolicyholderPhoneNumber ?? "-");
				this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push(modelProductPersonalAccidentReport.InsuredFullName ?? "-");
				this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push(modelProductPersonalAccidentReport.InsuredAddress ?? "-");
				this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push(modelProductPersonalAccidentReport.BeneficiaryFullName ?? "-");
				this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push(modelProductPersonalAccidentReport.BeneficiaryRelationship ?? "-");
				this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push(modelProductPersonalAccidentReport.BeneficiaryAddress ?? "-");
				this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push(modelProductPersonalAccidentReport.BeneficiaryPhoneNumber ?? "-");
				this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push(modelProductPersonalAccidentReport.RiskLocationAddress ?? "-");
				this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push(modelProductPersonalAccidentReport.RiskProvince ?? "-");
				this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push(modelProductPersonalAccidentReport.RiskCity ?? "-");
				this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push(modelProductPersonalAccidentReport.PremiumAmount ?? "-");
				this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push(modelProductPersonalAccidentReport.AdministrationAmount ?? "-");
				this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push(modelProductPersonalAccidentReport.DiscountAmount ?? "-");
				this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push(modelProductPersonalAccidentReport.PromoCode ?? "-");
				this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push(modelProductPersonalAccidentReport.PremiumTotalAmount ?? "-");
				this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push(modelProductPersonalAccidentReport.PaymentMethod ?? "-");
				this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push(modelProductPersonalAccidentReport.PaymentDate ?? "-");
				this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push(modelProductPersonalAccidentReport.ReferralCode ?? "-");
				this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push(modelProductPersonalAccidentReport.ReferralOwnerFullName ?? "-");
				// this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push(modelProductPersonalAccidentReport.ReferralOwnerIdentificationNumber ?? "-");
				this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push(modelProductPersonalAccidentReport.ReferralOwnerPhoneNumber ?? "-");
				this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push(modelProductPersonalAccidentReport.Note ?? "-");
				this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push(modelProductPersonalAccidentReport.BRIVANumber ?? "-");

				for (const modelPurchaseRatingReviews of this._arrayModelPurhcaseRatingReviews)
				{
					if (modelProductPersonalAccidentReport.ReferenceNumber === modelPurchaseRatingReviews.ReferenceNumber)
					{
						// this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push(modelPurchaseRatingReviews.ReferenceNumber ?? "-");

						if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.VeryGood)
						{
							this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("Sangat Baik");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.Good)
						{
							this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("Baik");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.Standard)
						{
							this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("Standar");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.Bad)
						{
							this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("Buruk");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.VeryBad)
						{
							this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push("Sangat Buruk");
						}

						this._matrixExportExcelProductPersonalAccidentSyariah[numberIndexRow].push(modelPurchaseRatingReviews.Message ?? "-");
					}
				}
			}

			//#region GENERATE WORKSHEET

			const workSheet: xlsx.WorkSheet = xlsx.utils.aoa_to_sheet(this._matrixExportExcelProductPersonalAccidentSyariah, { cellDates: true, dateNF: "dd-mmmm-yyyy" });

			//#endregion


			//#region GENERATE WORKBOOK AND ADD THE WORKSHEET

			xlsx.utils.book_append_sheet(this._fileWorkBookExcel, workSheet, "PERSONAL ACCIDENT SYARIAH");

			//#endregion
		}
		else
		{

		}

		//#endregion
	}

	public getDamageOfBusinessPlaceSyariahExportToExcel(): void
	{
		//#region INITIALIZATION DECLARATION VARIABLE

		this._matrixExportExcelProductDamageOfBusinessPlaceSyariah = [];
		this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[0] = [];
		let numberIndexRow: number = 0;

		//#endregion


		//#region SET HEADER EXCEL

		this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow] = [];
		this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("Platform");
		this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("Produk");
		this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("Tanggal Pemesanan");
		this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("No. Ref");
		this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("Kantor Cabang BRINS");
		this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("No. Polis");
		this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("Nama Paket");
		this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("Periode Awal Asuransi");
		this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("Periode Akhir Asuransi");
		this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("Nama Pemesan");
		// this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("NIK Pemesan");
		this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("No. Telepon Pemesan");
		this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("Nama Tertanggung");
		this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("Alamat Tertanggung");
		this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("Ahli Waris");
		this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("Hubungan Tertanggung");
		this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("Alamat Ahli Waris");
		this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("No. Telepon Ahli Waris");
		this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("Alamat Lokasi Risiko");
		this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("Provinsi Risiko");
		this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("Kota Risiko");

		this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("Informasi Kepemilikan");
		this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("Informasi Lokasi");

		this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("Premi");
		this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("Biaya Admin");
		this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("Diskon");
		this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("Kode Promo");
		this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("Total Pembayaran");
		this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("Metode Pembayaran");
		this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("Tanggal Pembayaran");
		this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("Referal");
		this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("Nama Pemilik Kode Referal");
		// this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("NIK Referral");
		this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("Telephone Referral");
		this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("Keterangan");
		this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("No. BRIVA");

		// this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("RefNum");
		this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("Rating");
		this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("Saran");

		//#endregion


		//#region BINDING DATA TO EXCEL

		if(this._arrayModelProductGeneralForDamageOfBusinessPlaceReport !== undefined)
		{
			for (const modelProductDamageOfBusinessPlaceReport of this._arrayModelProductGeneralForDamageOfBusinessPlaceSyariahReport)
			{
				numberIndexRow++;

				this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow] = [];
				this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.Channel ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.ProductName ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.OrderDate ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.ReferenceNumber ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.BranchName ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.PolicyNumber ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.CoverageName ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.PolicyStartDate ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.PolicyEndDate ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.PolicyholderFullName ?? "-");
				// this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.PolicyholderIdentificationNumber ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.PolicyholderPhoneNumber ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.InsuredFullName ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.InsuredAddress ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.BeneficiaryFullName ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.BeneficiaryRelationship ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.BeneficiaryAddress ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.BeneficiaryPhoneNumber ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.RiskLocationAddress ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.RiskProvince ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.RiskCity ?? "-");

				this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.modelProductDamageOfBusinessPlaceReport?.OwnershipInformation ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.modelProductDamageOfBusinessPlaceReport?.LocationInformation ?? "-");

				this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.PremiumAmount ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.AdministrationAmount ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.DiscountAmount ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.PromoCode ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.PremiumTotalAmount ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.PaymentMethod ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.PaymentDate ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.ReferralCode ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.ReferralOwnerFullName ?? "-");
				// this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.ReferralOwnerIdentificationNumber ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.ReferralOwnerPhoneNumber ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.Note ?? "-");
				this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push(modelProductDamageOfBusinessPlaceReport.BRIVANumber ?? "-");

				for (const modelPurchaseRatingReviews of this._arrayModelPurhcaseRatingReviews)
				{
					if (modelProductDamageOfBusinessPlaceReport.ReferenceNumber === modelPurchaseRatingReviews.ReferenceNumber)
					{
						// this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push(modelPurchaseRatingReviews.ReferenceNumber ?? "-");

						if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.VeryGood)
						{
							this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("Sangat Baik");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.Good)
						{
							this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("Baik");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.Standard)
						{
							this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("Standar");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.Bad)
						{
							this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("Buruk");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.VeryBad)
						{
							this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push("Sangat Buruk");
						}

						this._matrixExportExcelProductDamageOfBusinessPlaceSyariah[numberIndexRow].push(modelPurchaseRatingReviews.Message ?? "-");
					}
				}
			}

			//#region GENERATE WORKSHEET

			const workSheet: xlsx.WorkSheet = xlsx.utils.aoa_to_sheet(this._matrixExportExcelProductDamageOfBusinessPlaceSyariah, { cellDates: true, dateNF: "dd-mmmm-yyyy" });

			//#endregion


			//#region GENERATE WORKBOOK AND ADD THE WORKSHEET

			xlsx.utils.book_append_sheet(this._fileWorkBookExcel, workSheet, "KTU SYARIAH");

			//#endregion
		}
		else
		{

		}

		//#endregion
	}

	public getMyHouseSyariahExportToExcel(): void
	{
		//#region INITIALIZATION DECLARATION VARIABLE

		this._matrixExportExcelProductMyHouseSyariah = [];
		this._matrixExportExcelProductMyHouseSyariah[0] = [];
		let numberIndexRow: number = 0;

		//#endregion


		//#region SET HEADER EXCEL

		this._matrixExportExcelProductMyHouseSyariah[numberIndexRow] = [];
		this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("Platform");
		this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("Produk");
		this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("Tanggal Pemesanan");
		this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("No. Ref");
		this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("Kantor Cabang BRINS");
		this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("No. Polis");
		this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("Nama Paket");
		this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("Periode Awal Asuransi");
		this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("Periode Akhir Asuransi");
		this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("Nama Pemesan");
		// this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("NIK Pemesan");
		this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("No. Telepon Pemesan");
		this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("Nama Tertanggung");
		this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("Alamat Tertanggung");
		this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("Ahli Waris");
		this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("Hubungan Tertanggung");
		this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("Alamat Ahli Waris");
		this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("No. Telepon Ahli Waris");
		this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("Alamat Lokasi Risiko");
		this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("Provinsi Risiko");
		this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("Kota Risiko");

		this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("Informasi Kepemilikan");
		this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("Informasi Lokasi");

		this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("Premi");
		this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("Biaya Admin");
		this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("Diskon");
		this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("Kode Promo");
		this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("Total Pembayaran");
		this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("Metode Pembayaran");
		this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("Tanggal Pembayaran");
		this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("Referal");
		this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("Nama Pemilik Kode Referal");
		// this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("NIK Referral");
		this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("Telephone Referral");
		this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("Keterangan");
		this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("No. BRIVA");

		// this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("RefNum");
		this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("Rating");
		this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("Saran");

		//#endregion


		//#region BINDING DATA TO EXCEL

		if(this._arrayModelProductGeneralForMyHouseReport !== undefined)
		{
			for (const modelProductMyHouseReport of this._arrayModelProductGeneralForMyHouseSyariahReport)
			{
				numberIndexRow++;

				this._matrixExportExcelProductMyHouseSyariah[numberIndexRow] = [];
				this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push(modelProductMyHouseReport.Channel ?? "-");
				this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push(modelProductMyHouseReport.ProductName ?? "-");
				this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push(modelProductMyHouseReport.OrderDate ?? "-");
				this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push(modelProductMyHouseReport.ReferenceNumber ?? "-");
				this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push(modelProductMyHouseReport.BranchName ?? "-");
				this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push(modelProductMyHouseReport.PolicyNumber ?? "-");
				this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push(modelProductMyHouseReport.CoverageName ?? "-");
				this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push(modelProductMyHouseReport.PolicyStartDate ?? "-");
				this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push(modelProductMyHouseReport.PolicyEndDate ?? "-");
				this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push(modelProductMyHouseReport.PolicyholderFullName ?? "-");
				// this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push(modelProductMyHouseReport.PolicyholderIdentificationNumber ?? "-");
				this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push(modelProductMyHouseReport.PolicyholderPhoneNumber ?? "-");
				this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push(modelProductMyHouseReport.InsuredFullName ?? "-");
				this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push(modelProductMyHouseReport.InsuredAddress ?? "-");
				this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push(modelProductMyHouseReport.BeneficiaryFullName ?? "-");
				this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push(modelProductMyHouseReport.BeneficiaryRelationship ?? "-");
				this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push(modelProductMyHouseReport.BeneficiaryAddress ?? "-");
				this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push(modelProductMyHouseReport.BeneficiaryPhoneNumber ?? "-");
				this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push(modelProductMyHouseReport.RiskLocationAddress ?? "-");
				this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push(modelProductMyHouseReport.RiskProvince ?? "-");
				this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push(modelProductMyHouseReport.RiskCity ?? "-");

				this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push(modelProductMyHouseReport.modelProductMyHouseSyariahReport?.OwnershipInformation ?? "-");
				this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push(modelProductMyHouseReport.modelProductMyHouseSyariahReport?.LocationInformation ?? "-");

				this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push(modelProductMyHouseReport.PremiumAmount ?? "-");
				this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push(modelProductMyHouseReport.AdministrationAmount ?? "-");
				this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push(modelProductMyHouseReport.DiscountAmount ?? "-");
				this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push(modelProductMyHouseReport.PromoCode ?? "-");
				this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push(modelProductMyHouseReport.PremiumTotalAmount ?? "-");
				this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push(modelProductMyHouseReport.PaymentMethod ?? "-");
				this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push(modelProductMyHouseReport.PaymentDate ?? "-");
				this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push(modelProductMyHouseReport.ReferralCode ?? "-");
				this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push(modelProductMyHouseReport.ReferralOwnerFullName ?? "-");
				// this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push(modelProductMyHouseReport.ReferralOwnerIdentificationNumber ?? "-");
				this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push(modelProductMyHouseReport.ReferralOwnerPhoneNumber ?? "-");
				this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push(modelProductMyHouseReport.Note ?? "-");
				this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push(modelProductMyHouseReport.BRIVANumber ?? "-");

				for (const modelPurchaseRatingReviews of this._arrayModelPurhcaseRatingReviews)
				{
					if (modelProductMyHouseReport.ReferenceNumber === modelPurchaseRatingReviews.ReferenceNumber)
					{
						// this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push(modelPurchaseRatingReviews.ReferenceNumber ?? "-");

						if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.VeryGood)
						{
							this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("Sangat Baik");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.Good)
						{
							this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("Baik");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.Standard)
						{
							this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("Standar");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.Bad)
						{
							this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("Buruk");
						}
						else if (modelPurchaseRatingReviews.ProductPurchasingExperience === ENUM_RATINGPRODUCT_TYPE.VeryBad)
						{
							this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push("Sangat Buruk");
						}

						this._matrixExportExcelProductMyHouseSyariah[numberIndexRow].push(modelPurchaseRatingReviews.Message ?? "-");
					}
				}
			}

			//#region GENERATE WORKSHEET

			const workSheet: xlsx.WorkSheet = xlsx.utils.aoa_to_sheet(this._matrixExportExcelProductMyHouseSyariah, { cellDates: true, dateNF: "dd-mmmm-yyyy" });

			//#endregion


			//#region GENERATE WORKBOOK AND ADD THE WORKSHEET

			xlsx.utils.book_append_sheet(this._fileWorkBookExcel, workSheet, "MY HOUSE SYARIAH");

			//#endregion
		}
		else
		{

		}

		//#endregion
	}

	//#endregion


	//#region GETTER

	getStartDate(event): void
	{
		this.setStartDate(event.value);
	};

	getEndDate(event): void
	{
		this.setEndDate(event.value);
	};

	//#endregion


	//#region SETTER

	private setStartDate(dateStart?: Date): void
	{
		if (dateStart !== undefined && dateStart !== null)
		{
			this._dateStart = dateStart.clearUTC();
			this._modelProductGeneralReport.OrderDateStartRange = dateStart.clearUTC();
		}
		else
		{

		}
	}

	private setEndDate(dateEnd?: Date): void
	{
		if (dateEnd !== undefined && dateEnd !== null)
		{
			this._dateEnd = dateEnd.clearUTC();
			this._modelProductGeneralReport.OrderDateEndRange = dateEnd.clearUTC();
		}
		else
		{

		}
	}

	private setArrayAllProductReportEmpty(): void
	{
		this._arrayModelProductGeneralReport = [];
		this._arrayModelProductGeneralForAsriReport = [];
		this._arrayModelProductGeneralForOtoReport = [];
		this._arrayModelProductGeneralForPersonalAccidentReport = [];
		this._arrayModelProductGeneralForBicycleReport = [];
		this._arrayModelProductGeneralForDamageOfBusinessPlaceReport = [];
		this._arrayModelProductGeneralForMyHouseReport = [];
		this._arrayModelProductGeneralForMyMotorcycleReport = [];
		this._arrayModelProductGeneralForAsriSyariahReport = [];
		this._arrayModelProductGeneralForOtoSyariahReport = [];
		this._arrayModelProductGeneralForPersonalAccidentSyariahReport = [];
	}

	private setClickBooleanAsri(): void
	{
		if (this._modelProductGeneralReport.ProductName === this._stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_ASRI)
		{
			this._elementHTMLRadioProductAsri = document.querySelector("input[name=inputRadio][id=inputProductAsri]");
			if (this._elementHTMLRadioProductAsri != null)
			{
				if (this._booleanProductAsri === false)
				{
					this._elementHTMLRadioProductAsri.click();
				}
				else
				{
				}
			}
			else
			{
			}
		}
		else
		{
		}
	}

	setEventEmitterSelect(modelTableUpdate: TableModel): void
	{
		this._modelTable = modelTableUpdate;
		this.callSelectProductGeneralReportByAttributes(this, false);
	}

	//#endregion

}

//#endregion