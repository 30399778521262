//#region IMPORT

import { ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { BaseModel } from "./bases/base.model";
import { DeclarationDetailModel } from "./declarationdetail.model";
import { ResponseModel } from "./response.model";

//#endregion


//#region CLASS

export class BankAccountSourceModel extends BaseModel
{
	ID?: number;
	SourceAccount?: string;
	SourceAccountName?: string;
	SourceAccountStatus?: string;
	SourceAccountBalance?: number;
	BeneficiaryAccount?: string;
	BeneficiaryAccountName?: string;
	BeneficiaryAccountStatus?: string;
	BankName?: string;
	BankCode?: string;
	Token?: string;

	modelDeclarationDetail?: DeclarationDetailModel;
	modelSubmissionDetail?: DeclarationDetailModel;

	//#region VALIDATE

	validateToken(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Bank Account Source");

		if (this.Token === undefined || this.Token === null || this.Token === "")
		{
			modelResponse.MessageContent = "Token can't be empty!";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;

	}

	validateBankAccountSourceAttributes(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Reimbursement general");

		if (this.BeneficiaryAccountName == null || this.BeneficiaryAccountName === undefined)
		{
			modelResponse.MessageContent = "Beneficiary Account Name can't be empty!";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		return modelResponse;
	}

	//#endregion


	//#region SETTER

	setForBankAccountRequest(modelBankAccount: BankAccountSourceModel): void
	{
		this.BeneficiaryAccountName = modelBankAccount.AccountHolder;

		//#region DUMMY

		this.BeneficiaryAccount = "888809999999918";
		this.SourceAccount = "888801000157508";

		//#endregion
	}

	setFromBankAccountResponse(modelBankAccount: BankAccountSourceModel): void
	{
		this.BeneficiaryAccount = modelBankAccount.BeneficiaryAccount;
		this.BeneficiaryAccountName = modelBankAccount.BeneficiaryAccountName;
		this.BeneficiaryAccountStatus = modelBankAccount.BeneficiaryAccountStatus;
	}

	//#endregion
}

//#endregion