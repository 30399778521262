//#region IMPORT

import { ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { BaseModel } from "./bases/base.model";
import { ReimbursementGeneralModel } from "./reimbursementgeneral.model";
import { ResponseModel } from "./response.model";
import { OpticReimbursementGalleryModel } from "./opticreimbursementgallery.model";

//#endregion


//#region CLASS

export class OpticReimbursementModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for optic reimbursement.
		Author: Andryana Baros.
		Created on : Wednesday, 17 November 2021. 				Updated on : -.
		Created by : Andryana Baros.							Updated by : -.
		Version : 1.0:2.
	*/

	ID?: number;
	ReimbursementGeneralID?: number;
	FramePlafond?: number;
	LensPlafond?: number;
	ReceiptDate?: Date;
	ReceiptFrameCost?: number;
	CoveredFrameCost?: number;
	ReceiptLensCost?: number;
	CoveredLensCost?: number;
	ReceiptTreatmentCost?: number;
	CoveredTreatmentCost?: number;
	modelReimbursementGeneral?: ReimbursementGeneralModel;
	listModelOpticReimbursementGallery?: Array<OpticReimbursementGalleryModel>;

	/* Attribute - END */


	//#region  VALIDATION

	validateAddModelDentalReimbursementDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Dental Reimbursement");

		if(this.listModelOpticReimbursementGallery == null || this.listModelOpticReimbursementGallery === undefined)
		{
			modelResponse.MessageContent = "Terjadi kesalahan pada daftar attachment.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}


	validateCheckListModelOpticReimbursementDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Dental Reimbursement");

		if(this.listModelOpticReimbursementGallery == null || this.listModelOpticReimbursementGallery === undefined)
		{
			modelResponse.MessageContent = "Terjadi kesalahan pada daftar attachment.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		return modelResponse;
	}


	validateCheckModelOpticReimbursementDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = this.validateCheckListModelOpticReimbursementDocument();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			let modelResponseOpticReimbursementDocument: ResponseModel;
			let modelOpticReimbursementDocumentCheck: OpticReimbursementGalleryModel;

			for (const modelOpticReimbursementDocument of this.listModelOpticReimbursementGallery ?? [])
			{
				modelOpticReimbursementDocumentCheck = new OpticReimbursementGalleryModel();
				modelOpticReimbursementDocumentCheck.setModelFromObject(modelOpticReimbursementDocument);
				modelResponseOpticReimbursementDocument = modelOpticReimbursementDocumentCheck.validateCheck();

				if (modelResponseOpticReimbursementDocument.State === ENUM_RESPONSE_STATE.Fail)
				{
					return modelResponseOpticReimbursementDocument;
				}
				else
				{

				}
			}
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{

		}

		return modelResponse;
	}
	//#endregion
}

//#endregion