<div id="divBodyVerifierDeclaration" class="DivVerifierGeneral">
	<header class="HeaderGeneral">
		<h2>Declaration Information</h2>
		<p>Display and verify declaration letter that has been made.</p>
	</header>

	<img id="imageLogoHeader" src="../../assets/logos/sakina/logo_briinsurance_header.svg" alt="BRI Insurance - Logo Header">

	<div class="DivMainAsideSearch">
		<form>
			<div class="DivForm">
				<h3>Letter General</h3>

				<fieldset>
					<label for="spanDeclarationNumber">Declaration Number</label>
					<span>{{ _modelLetterGeneral.modelDeclaration!.DeclarationNumber | convertEmptyToDash}}</span>

					<label for="spanType">Letter Type</label>
					<span>{{ _modelLetterGeneral.getLetterTypeName() | convertEmptyToDash}}</span>

					<label for="spanPaymentPriority">Payment Priority</label>
					<span>{{ _modelLetterGeneral.getPaymentPriorityName() | convertEmptyToDash }}</span>

					<label for="spanPaymentType">Payment Type</label>
					<span>{{ _enumPaymentType[_modelLetterGeneral.PaymentType!] | convertEmptyToDash }}</span>

					<label for="spanBudgetType">Budget Type</label>
					<span>{{ _modelLetterGeneral.BudgetType | convertEmptyToDash }}</span>

					<label for="spanBudgetYear">Budget Year</label>
					<span>{{ _modelLetterGeneral.BudgetYear | convertEmptyToDash }}</span>
				</fieldset>
			</div>
		</form>

		<div class="DivDynamicContainer">
			<form>
				<div class="DivSubDynamicContainer" *ngFor="let modelDeclarationDetail of _modelLetterGeneral.modelDeclaration!.listModelDeclarationDetail; let numberIndex = index">
					<div class="DivForm">
						<h3>Transaction - {{ numberIndex + 1 }} </h3>

						<fieldset>
							<label for="spanTitle">Transaction Title</label>
							<span>{{ modelDeclarationDetail.Title | convertEmptyToDash}}</span>

							<label for="spanDescription">Transaction Description</label>
							<p>{{ modelDeclarationDetail.Description | convertEmptyToDash}}</p>

							<label for="spanBranchNameResponsibility">Branch Name Responsibility</label>
							<span>{{ modelDeclarationDetail.BranchNameResponsibility | convertEmptyToDash }}</span>

							<div class="DivFormHorizontalContainer">
								<div class="DivForm2Column">
									<label for="spanBeneficiaryName">Beneficiary Name</label>
									<span *ngIf="_modelLetterGeneral.PaymentType === _enumPaymentType.Transfer">{{ modelDeclarationDetail.modelBankAccount?.BeneficiaryName | convertEmptyToDash }}</span>
									<span *ngIf="_modelLetterGeneral.PaymentType === _enumPaymentType.Cash">{{ modelDeclarationDetail.BeneficiaryName | convertEmptyToDash }}</span>

									<label for="spanAccountNumber">Account Number</label>
									<span>{{ modelDeclarationDetail.modelBankAccount?.AccountNumber | convertEmptyToDash }}</span>
								</div>

								<div class="DivForm2Column">
									<label for="spanBankName">Bank Name</label>
									<span>{{ modelDeclarationDetail.modelBankAccount?.BankName | convertEmptyToDash }}</span>
								</div>
							</div>
						</fieldset>
					</div>

					<div class="DivTableContainer">
						<table>
							<thead>
								<tr>
									<th>Details</th>
									<th>Amount</th>
									<th>Number and Account Name</th>
								</tr>
							</thead>

							<tbody>
								<tr *ngFor="let modelDeclarationVide of modelDeclarationDetail.listModelDeclarationVide">
									<td>{{ modelDeclarationVide.Name | convertEmptyToDash }}</td>
									<td>{{ modelDeclarationVide.Amount | currencyValue | convertEmptyToDash}}</td>
									<td>{{ modelDeclarationVide.modelAccountCharge?.Code | convertEmptyToDash }} | {{ modelDeclarationVide.modelAccountCharge?.Name | convertEmptyToDash }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div class="DivForm">
					<h3>Approver</h3>

					<fieldset>
						<div class="DivFormHorizontalContainer">
							<div class="DivForm2Column">
								<label for="spanModelUserFirstChecker">Checker</label>
								<span>{{ _stringCheckerFullName }}</span>
							</div>

							<div class="DivForm2Column">
								<label for="spanModelUserFirstSigner">Signer</label>
								<span>{{ _stringSignerFullName }}</span>

								<label for="spanModelUserSecondSigner">Final Signer</label>
								<span>{{ _stringFinalSignerFullName }}</span>
							</div>
						</div>
					</fieldset>
				</div>
			</form>
		</div>
	</div>
</div>