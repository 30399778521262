//#region IMPORT

import { ENUM_PHOTO_TYPE, ENUM_RESPONSE_STATE } from "src/app/constants/enum.constant";
import { RuleConstant } from "src/app/constants/rule.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { ResponseModel } from "../response.model";
import { BaseModel } from "./base.model";

//#endregion


//#region CLASS

export class BaseGalleryModel extends BaseModel
{
	/*
        Attributes
        Description: Attributes for BaseGallery model
        Author: Andrew
        Created on: 20220511            Updated on: 20220511
        Created by: Andrew              Updated by: Andrew
        Version: 1.0:1
    */

	Name?: string;
	URL?: string;
	Type?: ENUM_PHOTO_TYPE;
	Order?: number;
	Width?: number;
	Height?: number;
	Format?: string;
	Extension?: string;
	Note?: string;
	Data?: string;
	Size?: number;
	Latitude?: number;
	Longitude?: number;

	/* Attribute - END */


	//#region VALIDATION

	validationDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Upload Document");

		if(this.validateEmpty(this.Size))
		{
			modelResponse.MessageContent = "Document size cannot be empty.";
		}
		else if(this.Size && this.Size >= 20000000)
		{
			modelResponse.MessageContent = "Document size cannot be higher than 20mb.";
		}
		else if(this.validateEmpty(this.Name))
		{
			modelResponse.MessageContent = "Document nam cannot be empty.";
		}
		else
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_PAT_FILLEDCORRECLY;
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateAddForFinancialReportDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Supporting Document");

		if (this.Name == null || this.Name === undefined || this.Name === "")
		{
			modelResponse.MessageContent = "Nama foto tidak boleh kosong!";
		}
		else if (this.Format == null || this.Format === undefined)
		{
			modelResponse.MessageContent = "Format attachment tidak boleh kosong!";
		}
		else if (this.Data == null || this.Data === undefined || this.Data === "")
		{
			modelResponse.MessageContent = "Data attachment tidak boleh kosong!";
		}
		else if (this.Extension == null || this.Extension === undefined || this.Extension === "")
		{
			modelResponse.MessageContent = "Attachment extension tidak boleh kosong!";
		}
		else if (StringConstant.ARRAY_VALUE_FORMAT.indexOf(this.Extension.toLowerCase()) === -1)
		{
			modelResponse.MessageContent = "Format file attachment tidak diperbolehkan!";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;

			if (this.Extension?.toLowerCase() === StringConstant.STRING_FORMAT_DOCUMENT_PDF)
			{
				if ((this.Size ?? 0.0) < RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM_FOR_FINANCIALREPORT_DOCUMENT)
				{
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
				else
				{
					modelResponse.MessageContent = "Maksimum ukuran file adalah 10mb.";
				}
			}
			else
			{
				modelResponse.MessageContent = "Format file attachment tidak diperbolehkan!";
			}
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;

			if (this.Extension?.toLowerCase() === StringConstant.STRING_FORMAT_COMPRESS_ZIP || this.Extension?.toLowerCase() === StringConstant.STRING_FORMAT_COMPRESS_RAR)
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{
				if (this.Format === "")
				{
					modelResponse.MessageContent = "Format tidak boleh kosong.";
				}
				else
				{
					modelResponse.MessageContent = "Form is filled correctly.";
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
			}
		}
		else
		{

		}

		return modelResponse;
	}

	//#endregion
}

//#endregion