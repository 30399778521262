//#region IMPORT

import { ENUM_CHILD_NUMBER, ENUM_PHOTO_TYPE, ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { StringConstant } from "../constants/string.constant";
import { BaseModel } from "./bases/base.model";
import { ResponseModel } from "./response.model";
import { PostMaternityReimbursementModel } from "./postmaternityreimbursementmodel";

//#endregion


//#region CLASS

export class PostMaternityReimbursementGalleryModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for document Post maternity reimbursement.
		Author: Andryana Baros.
		Created on : Wednesday, 17 November 2021. 				Updated on : -.
		Created by : Andryana Baros.							Updated by : -.
		Version : 1.0:2.
	*/

	ID?: number;
	ReimbursementGeneralID?: number;
	MaternityNumber?: ENUM_CHILD_NUMBER;
	Token?: string;
	PostMaternityReimbursementID?: number;
	PostMaternityReimbursementToken?: number;
	Name?: string;
	URL?: string;
	Type?: ENUM_PHOTO_TYPE;
	Order?: number;
	Width?: number;
	Height?: number;
	Size?: number;
	Data?: string;
	Extension?: string;
	Format?: string;
	Note?: string;
	modelPostMaternityReimbursement?: PostMaternityReimbursementModel;

	Downloaded?: boolean;

	/* Attribute - END */


	//#region  VALIDATION

	validateDownload(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Post maternity document");

		if (this.URL == null || this.URL === undefined && this.URL === "")
		{
			modelResponse.MessageContent = "URL document tidak ditemukan.";
		}
		if (this.Name == null || this.Name === undefined || this.Name === "")
		{
			modelResponse.MessageContent = "Nama document tidak ditemukan.";
		}
		else if (this.Extension == null || this.Extension === undefined || this.Extension === "")
		{
			modelResponse.MessageContent = "Nama extension tidak ditemukan.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		return modelResponse;
	}


	validateCheck(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Post maternity document");

		if (this.Downloaded == null || this.Downloaded === undefined || this.Downloaded === false)
		{
			modelResponse.MessageContent = "Harap periksa file attachment terlebih dahulu sebelum melakukan persetujuan.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		return modelResponse;
	}


	validateDocumentToken(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Pengunduhan Lampiran.");
		const regularExpression: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABETNUMERIC);

		if (this.Token == null || this.Token === undefined)
		{
			modelResponse.MessageContent = "Token dokumen kosong! Silahkan hubungi developer!";
		}
		else if (!regularExpression.test(this.Token))
		{
			modelResponse.MessageContent = "Token tidak dalam format yang benar!";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	//#endregion
}

//#endregion