//#region IMPORT

import { environment } from "src/environments/environment";

//#endregion


//#region CLASS

export class WebAddressConstant
{
	//#region IPADDRESS

	static STRING_WEBADDRESS_WEBSITE_IPADRESS_BRINS: string = "www.brins.co.id";

	//#endregion


	//#region URL API

	static STRING_URL_USER_API: string = environment.URL_USER_API;
	static STRING_URL_PRODUCT_API: string = environment.URL_PRODUCT_API;
	static STRING_URL_POLICY_API: string = environment.URL_POLICY_API;
	static STRING_URL_BROADCAST_API: string = environment.URL_BROADCAST_API;
	static STRING_URL_INTERNAL_WEB: string = environment.URL_INTERNAL_WEB;
	static STRING_URL_INTEGRATION_API: string = environment.URL_INTEGRATION_API;

	//#endregion


	//#region URL WEBSITE SOCKET

	/*
	URL_USER_WEBSITESOCKET: String = BuildConfig.SCHEMA_WEBSITESOCKET + BuildConfig.HOST_USER_API + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + BuildConfig.PORT_USER;
	URLT_PRODUCT_WEBSITESOCKET: String = BuildConfig.SCHEMA_WEBSITESOCKET + BuildConfig.HOST_PRODUCT_API + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + BuildConfig.PORT_PRODUCT;
	URL_POLICY_WEBSITESOCKET: String = BuildConfig.SCHEMA_WEBSITESOCKET + BuildConfig.HOST_POLICY_API + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + BuildConfig.PORT_POLICY;
	*/

	//#endregion


	//#region HANDSHAKE SERVICE

	static STRING_PATH_CONTROLLER_HANDSHAKE: string = "/Handshake";

	static STRING_URL_HANDSHAKE_GENERATETOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/generateToken`;
	static STRING_URL_HANDSHAKE_REFRESHTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/refreshToken`;
	static STRING_URL_HANDSHAKE_SELECTUSERBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/selectUserByToken`;
	static STRING_URL_HANDSHAKE_REFRESHTOKENFORACCOUNTINGANDFINANCE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/refreshTokenForAccountingAndFinance`;

	//#endregion


	//#region CONTROLLER DASHBOARD

	static STRING_PATH_CONTROLLER_DASHBOARD: string = "/Dashboard";

	static STRING_URL_DASHBOARD_SELECTDASHBOARDLETTER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardLetter`;
	static STRING_URL_DASHBOARD_SELECTDASHBOARDDOCUMENTSTAMPCOLLECTOR: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardDocumentStampCollectorSummaryByAttributes`;

	//#endregion


	//#region CONTROLLER DOCUMENT STAMP COLLECTOR DASHBOARD

	static STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTORDASHBOARD: string = "/DocumentStampCollectorDashboard";

	static 	STRING_URL_DOCUMENTSTAMPCOLLECTORDASHBOARD_SELECTDASHBOARDDOCUMENTSTAMPCOLLECTORDASHBOARDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTORDASHBOARD}/selectDocumentStampCollectorDashboardByAttributes`;

	//#endregion


	//#region CONTROLLER DOCUMENT STAMP ENTERPRISE DASHBOARD

	static STRING_PATH_CONTROLLER_DOCUMENTSTAMPENTERPRISEDASHBOARD: string = "/DocumentStampEnterpriseDashboard";

	static 	STRING_URL_DOCUMENTSTAMPENTERPRISEDASHBOARD_SELECTDASHBOARDDOCUMENTSTAMPCOLLECTORDASHBOARDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPENTERPRISEDASHBOARD}/selectDocumentStampEnterpriseDashboardByAttributes`;

	//#endregion


	//#region CONTROLLER UPDATE DOCUMENT STAMP COLLECTOR STATIC

	static STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTORSTATIC: string = "/DocumentStampCollectorStatic";

	static 	STRING_URL_DOCUMENTSTAMPCOLLECTORSTATIC_UPDATEDASHBOARDDOCUMENTSTAMPCOLLECTORSTATICBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTORSTATIC}/updateDocumentStampCollectorStaticByAttributes`;

	//#endregion


	//#region CONTROLLER UPDATE DOCUMENT STAMP ENTERPRISE STATIC

	static STRING_PATH_CONTROLLER_DOCUMENTSTAMPENTERPRISESTATIC: string = "/DocumentStampEnterpriseStatic";

	static 	STRING_URL_DOCUMENTSTAMPENTERPRISESTATIC_SELECTDASHBOARDDOCUMENTSTAMPENTERPRISESTATICBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPENTERPRISESTATIC}/updateDocumentStampEnterpriseStaticByAttributes`;

	//#endregion


	//#region CONTROLLER DOCUMENT STAMP COLLECTOR DASHBOARD

	static STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTORREPORT: string = "/DocumentStampCollectorReport";

	static STRING_URL_DOCUMENTSTAMPCOLLECTORREPORT_SELECTDASHBOARDDOCUMENTSTAMPCOLLECTORREPORTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTORREPORT}/selectDocumentStampCollectorReportByAttributes`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTORREPORT_SELECTDASHBOARDDOCUMENTSTAMPCOLLECTORREPORTBYATTRIBUTESFOREXCEL: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTORREPORT}/selectDocumentStampCollectorReportByAttributesForExcel`;

	//#endregion


	//#region CONTROLLER DOCUMENT STAMP ENTERPRISE DASHBOARD

	static STRING_PATH_CONTROLLER_DOCUMENTSTAMPENTERPRISEREPORT: string = "/DocumentStampEnterpriseReport";

	static STRING_URL_DOCUMENTSTAMPENTERPRISEREPORT_SELECTDASHBOARDDOCUMENTSTAMPENTERPRISEREPORTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPENTERPRISEREPORT}/selectDocumentStampEnterpriseReportByAttributes`;
	static STRING_URL_DOCUMENTSTAMPENTERPRISEREPORT_SELECTDASHBOARDDOCUMENTSTAMPENTERPRISEREPORTBYATTRIBUTESFOREXCEL: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPENTERPRISEREPORT}/selectDocumentStampEnterpriseReportByAttributesForExcel`;

	//#endregion


	//#region ACCOUNTING AND FINANCE CONTROLLER

	static STRING_PATH_CONTROLLER_ACCOUNTINGANDFINANCE: string = "/AccountingAndFinance";

	static STRING_URL_ACCOUNTINGANDFINANCE_SELECTACCOUNTINGANDFINANCEBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ACCOUNTINGANDFINANCE}/selectAccountingAndFinanceByEmail`;
	static STRING_URL_ACCOUNTINGANDFINANCE_VERIFYACCOUNTINGANDFINANCEBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ACCOUNTINGANDFINANCE}/verifyAccountingAndFinanceByEmail`;
	static STRING_URL_ACCOUNTINGANDFINANCE_SIGNOUT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ACCOUNTINGANDFINANCE}/signOut`;
	static STRING_URL_ACCOUNTINGANDFINANCE_SELECTACCOUNTINGANDFINANCEBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ACCOUNTINGANDFINANCE}/selectAccountingAndFinanceByToken`;
	static STRING_URL_SELECTCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ACCOUNTINGANDFINANCE}/selectChecker`;
	static STRING_URL_SELECTSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ACCOUNTINGANDFINANCE}/selectSigner`;

	//#endregion


	//#region DECLARATION

	static STRING_PATH_CONTROLLER_DECLARATION: string = "/Declaration";

	static STRING_URL_DECLARATION_SELECTDECLARATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/selectDeclarationByToken`;
	static STRING_URL_DECLARATION_SELECTDECLARATIONFORPAYMENTREQUESTACCOUNTINGBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/selectDeclarationForPaymentRequestAccounting`;
	static STRING_URL_DECLARATION_SELECTDECLARATIONFORACCOUNTINGBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/selectDeclarationForAccountingByToken`;
	static STRING_URL_DECLARATION_APPROVEDECLARATIONBYTOKENFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/approveDeclarationByTokenForMaker`;
	static STRING_URL_DECLARATION_APPROVEDECLARATIONFORPAYMENTREQUESTACCOUNTING: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/approveDeclarationForPaymentRequestAccounting`;
	static STRING_URL_DECLARATION_REJECTECLARATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/rejectDeclarationByToken`;
	static STRING_URL_DECLARATION_SELECTDECLARATIONBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/selectDeclarationByAttributesForMaker`;
	static STRING_URL_DECLARATION_SELECTDECLARATIONQRBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/selectDeclarationQRByToken`;
	static STRING_URL_DECLARATION_DOWNLOADDECLARATIONDOCUMENT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/downloadAccountingAndFinanceDeclarationByDocumentToken`;
	static STRING_URL_DECLARATION_DOWNLOADDECLARATION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/downloadDeclarationDocumentByTokenForAccountingAndFinance`;
	static STRING_URL_DECLARATION_SELECTDECLARATIONBYATTRIBUTESFORACCOUNTING: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/selectDeclarationByAttributesForAccounting`;
	static STRING_URL_DECLARATION_DOWNLOADDECLARATIONFORREIMBURSEMENT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/downloadDeclarationReimburseByTokenForAccountingAndFinance`;
	static STRING_URL_DECLARATION_DOWNLOADDECLARATIONBRANCHHEAD: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/downloadDeclarationDocumentByTokenForBranchHead`;
	static STRING_URL_DECLARATION_DOWNLOADDECLARATIONHUMANRESOURCE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/downloadDeclarationDocumentByTokenForHumanResource`;
	static STRING_URL_DECLARATION_DOWNLOADDECLARATIONLOGISTIC: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/downloadDeclarationDocumentByTokenForLogistic`;
	static STRING_URL_DECLARATION_DOWNLOADDECLARATIONCOMPANYSECRETARIAT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/downloadDeclarationDocumentByTokenForCompanySecretariat`;
	static STRING_URL_DECLARATION_UPDATEDECLARATIONPAYMENTREQUESTBYLETTERGENERALTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/updateDeclarationPaymentRequestByLetterGeneralToken`;
	static STRING_URL_DECLARATION_UPDATEDECLARATIONPENDINGFORPAYMENTAKUBYLETTERGENERALTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/updateDeclarationPendingForPaymentAKUByLetterGeneralToken`;
	static STRING_URL_DECLARATION_APPROVEDECLARATIONPENDINGFORSIGNERAKUBYLETTERGENERALTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/approveDeclarationPendingForSignerAKUByLetterGeneralToken`;
	static STRING_URL_DECLARATION_DOWNLOADDECLARATIONTRANSFERRECEIPTBYLETTERGENERALTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/downloadDeclarationTransferReceiptByLetterGeneralToken`;

	//#endregion


	//#region LOGISTIC CONTROLLER

	static STRING_PATH_CONTROLLER_LOGISTIC: string = "/Logistic";

	static STRING_URL_LOGISTIC_SELECTCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LOGISTIC}/selectChecker`;
	static STRING_URL_LOGISTIC_SELECTSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LOGISTIC}/selectSigner`;
	static STRING_URL_LOGISTIC_SELECTLOGISTICBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LOGISTIC}/selectLogisticByToken`;

	//#endregion


	//#region VOUCHER

	static STRING_PATH_CONTROLLER_VOUCHER: string = "/Voucher";

	static STRING_URL_VOUCHER_SELECTVOUCHERBYATTRIBUTESFORACCOUNTINGMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VOUCHER}/selectVoucherByAttributesForAccountingMaker`;
	static STRING_URL_VOUCHER_SELECTDECLARATIONFORACCOUNTINGBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VOUCHER}/selectVoucherForAccountingByToken`;
	static STRING_URL_DECLARATION_APPROVEVOUCHERBYTOKENFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/approveVoucherByTokenForMaker`;
	static STRING_URL_VOUCHER_SELECTDECLARATIONBYATTRIBUTESFORACCOUNTINGSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VOUCHER}/selectVoucherByAttributesForAccountingSigner`;
	static STRING_URL_VOUCHER_INSERTVOUCERFORDECLARATION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VOUCHER}/insertVoucherForDeclaration`;
	static STRING_URL_VOUCHER_INSERTVOUCERFORSUBMISSION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VOUCHER}/insertVoucherForSubmission`;
	static STRING_URL_VOUCHER_SIGNVOUCHERBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VOUCHER}/signVoucherByToken`;
	static STRING_URL_VOUCHER_UPDATEVOUCHERFORDECLARATION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VOUCHER}/updateVoucherForDeclaration`;
	static STRING_URL_VOUCHER_UPDATEVOUCHERFORSUBMISSION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VOUCHER}/updateVoucherForSubmission`;

	//#endregion


	//#region SUBMISSION

	static STRING_PATH_CONTROLLER_SUBMISSION: string = "/Submission";

	static STRING_URL_SUBMISSION_INSERTSUBMISSION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/insertSubmission`;
	static STRING_URL_SUBMISSION_SELECTSUBMISSIONBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/selectSubmissionByAttributesForMaker`;
	static STRING_URL_DECLARATION_SELECTSUBMISSIONFORACCOUNTINGBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/selectSubmissionForAccountingByToken`;
	static STRING_URL_SUBMISSION_SELECTSUBMISSIONBYATTRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/selectSubmissionByAttributesForChecker`;
	static STRING_URL_SUBMISSION_SELECTSUBMISSIONBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/selectSubmissionByAttributesForSigner`;
	static STRING_URL_SUBMISSION_SELECTSUBMISSIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/selectSubmissionByToken`;
	static STRING_URL_SUBMISSION_SELECTSUBMISSIONQRBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/selectSubmissionQRByToken`;
	static STRING_URL_SUBMISSION_DELETESUBMISSIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/deleteSubmissionByToken`;
	static STRING_URL_SUBMISSION_UPDATESUBMISSIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/updateSubmissionByToken`;
	static STRING_URL_SUBMISSION_REJECTSUBMISSIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/rejectSubmissionByToken`;
	static STRING_URL_SUBMISSION_CHECKSUBMISSIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/checkSubmissionByToken`;
	static STRING_URL_SUBMISSION_SIGNSUBMISSIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/signSubmissionByToken`;
	static STRING_URL_SUBMISSION_UPLOADSUBMISSION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/uploadSubmission`;
	static STRING_URL_SUBMISSION_SELECTSUBMISSIONBYATTRIBUTESFORACCOUNTING: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/selectSubmissionByAttributesForAccounting`;
	static STRING_URL_SUBMISSION_DOWNLOADSUBMISSIONDOCUMENT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/downloadAccountingAndFinanceSubmissionByDocumentToken`;
	static STRING_URL_SUBMISSION_DOWNLOADSUBMISSION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/downloadSubmissionDocumentByTokenForAccountingAndFinance`;
	static STRING_URL_SUBMISSION_DOWNLOADSUBMISSIONBRANCHHEAD: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/downloadSubmissionDocumentByTokenForBranchHead`;
	static STRING_URL_SUBMISSION_DOWNLOADSUBMISSIONHUMANRESOURCE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/downloadSubmissionDocumentByTokenForHumanResource`;
	static STRING_URL_SUBMISSION_DOWNLOADSUBMISSIONLOGISTIC: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/downloadSubmissionDocumentByTokenForLogistic`;
	static STRING_URL_SUBMISSION_DOWNLOADSUBMISSIONCOMPANYSECRETARIAT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/downloadSubmissionDocumentByTokenForCompanySecretariat`;
	static STRING_URL_SUBMISSION_UPDATESUBMISSIONPAYMENTREQUESTBYLETTERGENERALTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/updateSubmissionPaymentRequestByLetterGeneralToken`;
	static STRING_URL_SUBMISSION_UPDATESUBMISSIONPENDINGFORPAYMENTAKUBYLETTERGENERALTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/updateSubmissionPendingForPaymentAKUByLetterGeneralToken`;
	static STRING_URL_SUBMISSION_APPROVESUBMISSIONPENDINGFORSIGNERAKUBYLETTERGENERALTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/approveSubmissionPendingForSignerAKUByLetterGeneralToken`;
	static STRING_URL_SUBMISSION_DOWNLOADSUBMISSIONTRANSFERRECEIPTBYLETTERGENERALTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION }/downloadSubmissionTransferReceiptByLetterGeneralToken`;

	//#endregion


	//#region LETTER GENERAL

	static STRING_PATH_CONTROLLER_LETTERGENERAL: string = "/LetterGeneral";

	static STRING_URL_LETTERGENERAL_SELECTLETTERGENERALSIGNEDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LETTERGENERAL}/selectLetterGeneralSignedByAttributes`;
	static STRING_URL_LETTERGENERAL_SELECTLETTERGENERALREJECTEDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LETTERGENERAL}/selectLetterGeneralRejectedByAttributes`;
	static STRING_URL_LETTERGENERAL_SELECTLETTERGENERALAPPROVEDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LETTERGENERAL}/selectLetterGeneralApprovedByAttributes`;
	static STRING_URL_LETTERGENERAL_SELECTLETTERGENERALPENDINGFORCHECKERAKUBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LETTERGENERAL}/selectLetterGeneralPendingForCheckerAKUByAttributes`;
	static STRING_URL_LETTERGENERAL_APPROVELETTERGENERALPENDINGFORCHECKERAKUBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LETTERGENERAL}/approveLetterGeneralPendingForCheckerAKUByToken`;
	static STRING_URL_LETTERGENERAL_REJECTLETTERGENERALPENDINGFORCHECKERAKUBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LETTERGENERAL}/rejectLetterGeneralPendingForCheckerAKUByToken`;
	static STRING_URL_LETTERGENERAL_SELECTLETTERGENERALPENDINGFORSIGNERAKUBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LETTERGENERAL}/selectLetterGeneralPendingForSignerAKUByAttributes`;
	static STRING_URL_LETTERGENERAL_REJECTLETTERGENERALPENDINGFORSIGNERAKUBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LETTERGENERAL}/rejectLetterGeneralPendingForSignerAKUByToken`;
	static STRING_URL_LETTERGENERAL_APPROVELETTERGENERALSIGNEDBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LETTERGENERAL}/approveLetterGeneralSignedByToken`;
	static STRING_URL_LETTERGENERAL_REJECTLETTERGENERALSIGNEDBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LETTERGENERAL}/rejectLetterGeneralSignedByToken`;
	static STRING_URL_LETTERGENERAL_SENDOTPFORSIGNERAKU: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LETTERGENERAL}/sendOTPForSignerAKU`;
	static STRING_URL_LETTERGENERAL_VERIFYOTPFORSIGNERAKU: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LETTERGENERAL}/verifyOTPForSignerAKU`;
	static STRING_URL_LETTERGENERAL_SELECTLETTERGENERALBYATTRIBUTESFORREPORT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LETTERGENERAL}/selectLetterGeneralByAttributesForReport`;
	static STRING_URL_LETTERGENERAL_SELECTLETTERGENERALBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LETTERGENERAL}/selectLetterGeneralByAttributes`;
	static STRING_URL_LETTERGENERAL_REJECTLETTERGENERALPAYMENTREQUESTBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LETTERGENERAL}/rejectLetterGeneralPaymentRequestByToken`;

	//#endregion


	//#region BANK ACCOUNT

	static STRING_PATH_CONTROLLER_BANKACCOUNT: string = "/BankAccount";

	static STRING_URL_BANKACCOUNT_SELECTBANKACCOUNTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BANKACCOUNT}/selectBankAccountByAttributes`;
	static STRING_URL_BANKACCOUNT_SELECTBANKACCOUNTBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BANKACCOUNT}/selectBankAccountByToken`;
	static STRING_URL_BANKACCOUNT_INSERTBANKACCOUNT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BANKACCOUNT}/insertBankAccount`;
	static STRING_URL_BANKACCOUNT_UPDATEBANKACCOUNTBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BANKACCOUNT}/updateBankAccountByToken`;
	static STRING_URL_BANKACCOUNT_DELETEBANKACCOUNTBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BANKACCOUNT}/deleteBankAccountByToken`;
	static STRING_URL_BANKACCOUNT_VALIDATEBANKACCOUNTBYACCOUNTNUMBER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BANKACCOUNT}/validateBankAccountByAccountNumber`;

	//#endregion


	//#region BANK ACCOUNT SOURCE

	static STRING_PATH_CONTROLLER_BANKACCOUNTSOURCE: string = "/BankAccountSource";

	static STRING_URL_BANKACCOUNTSOURCE_SELECTBANKACCOUNTSOURCEBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BANKACCOUNTSOURCE}/selectBankAccountSourceByAttributes`;
	static STRING_URL_BANKACCOUNTSOURCE_SELECTBANKACCOUNTBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BANKACCOUNTSOURCE}/selectBankAccountSourceByToken`;

	//#endregion


	//#region ACCOUNT CHARGE

	static STRING_PATH_CONTROLLER_ACCOUNTCHARGE: string = "/AccountCharge";

	static STRING_URL_ACCOUNTCHARGE_SELECTACCOUNTCHARGEBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ACCOUNTCHARGE}/selectAccountChargeByAttributes`;

	//#endregion


	//#region BRANCH CONTROLLER

	static STRING_PATH_CONTROLLER_BRANCH: string = "/Branch";

	static STRING_URL_BRANCH_SELECTBRANCH: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranch`;
	static STRING_URL_BRANCH_SELECTBRANCHFORTICKETBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchForTicketByAttributes`;
	static STRING_URL_BRANCH_SELECTBRANCHFORCONVENTIONALANDSYARIAH: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchForConventionalAndSyariah`;
	static STRING_URL_BRANCH_SELECTBRANCHFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchForCustomer`;

	//#endregion


	//#region DIVISION

	static STRING_PATH_CONTROLLER_DIVISION: string = "/Division";

	static STRING_URL_DIVISION_SELECTDIVISION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DIVISION}/selectDivision`;

	//#endregion


	//#region TICKET CONTROLLER

	static STRING_PATH_CONTROLLER_TICKET: string = "/Ticket";

	static STRING_URL_TICKET_SELECTTICKETBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectTicketByAttributes`;
	static STRING_URL_TICKET_SELECTTICKETBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectTicketByToken`;
	static STRING_URL_TICKET_SELECTTICKETINBOXBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectTicketReceivedByAttributes`;
	static STRING_URL_TICKET_SELECTTICKETOUTBOXBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectTicketSentByAttributes`;
	static STRING_URL_TICKET_INSERTTICKET: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/insertTicket`;
	static STRING_URL_TICKET_INSERTTICKETFORDIVISION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/insertTicketForDivision`;
	static STRING_URL_TICKET_UPDATETICKETBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/updateTicketByToken`;
	static STRING_URL_TICKET_UPDATETICKETBYTOKENFORCLOSEWITHOUTCONFIRMATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/updateTicketByTokenForCloseWithoutConfirmation`;
	static STRING_URL_TICKET_SELECTREPORTTICKETBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectReportTicketByAttributes`;

	//#endregion


	//#region TICKET CONVERSATION CONTROLLER

	static STRING_PATH_CONTROLLER_TICKETCONVERSATION: string = "/TicketConversation";

	static STRING_URL_TICKET_INSERTTICKETCONVERSATIONFORDIVISION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETCONVERSATION}/insertTicketConversationForDivision`;
	static STRING_URL_TICKET_SELECTTICKETCONVERSATIONBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETCONVERSATION}/selectTicketConversationByAttributes`;
	static STRING_URL_TICKET_UPDATETICKETCONVERSATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETCONVERSATION}/updateTicketConversationByToken`;
	static STRING_URL_TICKET_UPLOADCONVERSATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETCONVERSATION}/uploadConversation`;

	//#endregion


	//#region TICKET DOCUMENT CONTROLLER

	static STRING_PATH_CONTROLLER_TICKETDOCUMENT: string = "/TicketDocument";

	static STRING_URL_TICKET_DOWNLOADCONVERSATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETDOCUMENT}/downloadConversationByDocumentToken`;

	//#endregion


	//#region CURRENCY

	static STRING_PATH_CONTROLLER_CURRENCY: string = "/Currency";

	static STRING_URL_CURRENCY_SELECTCURRENCY: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CURRENCY}/selectCurrency`;

	//#endregion


	//#region DOCUMENT STAMP COLLECTOR TYPE

	static STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTORTYPE: string = "/DocumentStampCollectorType";


	static STRING_URL_DOCUMENTSTAMPCOLLECTORTYPE_SELECTDOCUMENTSTAMPCONTROLLERTYPEBYATRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTORTYPE}/selectDocumentStampCollectorTypeByAttributes`;

	//#endregion


	//#region DOCUMENT STAMP COLLECTOR TYPE

	static STRING_PATH_CONTROLLER_DOCUMENTSTAMPENTERPRISETYPE: string = "/DocumentStampEnterpriseType";

	static STRING_URL_DOCUMENTSTAMPENTERPRISETYPE_SELECTDOCUMENTSTAMPENTERPRISETYPEBYATRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPENTERPRISETYPE}/selectDocumentStampEnterpriseTypeByAttributes`;

	//#endregion


	//#region DOCUMENT STAMP COLLECTOR

	static STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR: string = "/DocumentStampCollector";

	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SELECTDOCUMENTSTAMPCONTROLLERBYATRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/selectDocumentStampCollectorByAttributes`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_UPLOADDOCUMENTSTAMPCOLLECTOR: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/uploadDocumentStampCollector`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_DOWNLOADDOCUMENTSTAMPCOLLECTORBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/downloadDocumentStampCollectorByToken`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SIGNDOCUMENTSTAMPCOLLECTORBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/signDocumentStampCollectorByToken`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_RESIGNDOCUMENTSTAMPCOLLECTORBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/resignDocumentStampCollectorByToken`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_CANCELRESIGNDOCUMENTSTAMPCOLLECTORBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/cancelResignDocumentStampCollectorByToken`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SIGNDOCUMENTSTAMPCOLLECTOR: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/signDocumentStampCollector`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SELECTDOCUMENTSTAMPCOLLECTORDOCUMENTTYPE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/selectDocumentStampCollectorDocumentType`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SELECTDOCUMENTSTAMPCONTROLLERBYATRIBUTESFORREPORTING: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/selectDocumentStampCollectorByAttributesForReporting`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SELECTDOCUMENTSTAMPCONTROLLERBYATRIBUTESFORREPORTINGEXCEL: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/selectDocumentStampCollectorByAttributesForReportingExcel`;

	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SELECTDOCUMENTSTAMPCONTROLLERBYATRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/selectDocumentStampCollectorByAttributesForMaker`;

	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SELECTDOCUMENTSTAMPCONTROLLERBYATRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/selectDocumentStampCollectorByAttributesForChecker`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SELECTDOCUMENTSTAMPCONTROLLERBYATRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/selectDocumentStampCollectorByAttributesForSigner`;

	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SELECTDOCUMENTSTAMPCONTROLLERBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/selectDocumentStampCollectorByToken`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SUBMITDOCUMENTSTAMPCONTROLLERFORMAKER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/submitDocumentStampCollectorForMaker`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_REVISEDOCUMENTSTAMPCOLLECTORFORMAKER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/reviseDocumentStampCollectorForMaker`;

	//#region CHECKER

	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_APPROVEDOCUMENTSTAMPCOLLECTORBYTOKENFORCHECKER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/approveDocumentStampCollectorByTokenForChecker`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_REJECTDOCUMENTSTAMPCOLLECTORBYTOKENFORCHECKER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/rejectDocumentStampCollectorByTokenForChecker`;

	//#endregion


	//#region SIGNER

	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_APPROVEDOCUMENTSTAMPCOLLECTORBYTOKENFORSIGNER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/approveDocumentStampCollectorByTokenForSigner`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_REJECTDOCUMENTSTAMPCOLLECTORBYTOKENFORSIGNER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/rejectDocumentStampCollectorByTokenForSigner`;

	//#endregion

	//#endregion


	//#region REIMBURSEMENT GENERAL

	static STRING_PATH_CONTROLLER_REIMBURSEMENTGENERAL: string = "/reimbursementgeneral";

	static STRING_URL_REIMBURSEMENTGENERAL_SELECTREIMBURSEMENTGENERALBYATTRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_REIMBURSEMENTGENERAL}/selectReimbursementGeneralByAttributesForChecker`;
	static STRING_URL_REIMBURSEMENTGENERAL_SELECTREIMBURSEMENTGENERALBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_REIMBURSEMENTGENERAL}/selectReimbursementGeneralByAttributesForSigner`;

	//#endregion


	//#region DENTAL REIMBURSEMENT

	static STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT: string = "/dentalreimbursement";

	static STRING_URL_DENTALREIMBURSEMENT_SELECTDENTALREIMBURSEMENTBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/selectDentalReimbursementByAttributesForMaker`;
	static STRING_URL_DENTALREIMBURSEMENT_SELECTDENTALREIMBURSEMENTBYATTRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/selectDentalReimbursementByAttributesForChecker`;
	static STRING_URL_DENTALREIMBURSEMENT_SELECTDENTALREIMBURSEMENTBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/selectDentalReimbursementByAttributesForSigner`;
	static STRING_URL_DENTALREIMBURSEMENT_CHECKDENTALREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/checkDentalReimbursementByToken`;
	static STRING_URL_DENTALREIMBURSEMENT_SELECTDENTALREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/selectDentalReimbursementByToken`;
	static STRING_URL_DENTALREIMBURSEMENT_SIGNDENTALREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/signDentalReimbursementByToken`;
	static STRING_URL_DENTALREIMBURSEMENT_REJECTDENTALREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/rejectDentalReimbursementByToken`;
	static STRING_URL_DENTALREIMBURSEMENT_REVISEDENTALREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/reviseDentalReimbursementByToken`;
	static STRING_URL_DENTALREIMBURSEMENT_UPDATEDENTALREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/updateDentalReimbursementByToken`;
	static STRING_URL_DENTALREIMBURSEMENT_CHECKDENTALREIMBURSEMENTPLAFONDBYUSERID: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/checkDentalReimbursementPlafondByUserID`;
	static STRING_URL_DENTALREIMBURSEMENT_DOWNLOADDENTALREIMBURSEMENTDOCUMENT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/downloadLogisticDeclarationByDocumentToken?stringHandshakeToken=[HandshakeToken]&stringDeclarationDocumentToken=[DocumentToken]`;
	static STRING_URL_DENTALREIMBURSEMENT_DOWNLOADDENTALREIMBURSEMENT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/downloadDeclarationDocumentByTokenForLogistic?stringHandshakeToken=[HandshakeToken]&stringLetterGeneralToken=[DocumentToken]`;

	//#endregion


	//#region OPTIC REIMBURSEMENT

	static STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT: string = "/OpticReimbursement";

	static STRING_URL_OPTICREIMBURSEMENT_SELECTOPTICREIMBURSEMENTBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/selectOpticReimbursementByAttributesForMaker`;
	static STRING_URL_OPTICREIMBURSEMENT_SELECTOPTICREIMBURSEMENTBYATTRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/selectOpticReimbursementByAttributesForChecker`;
	static STRING_URL_OPTICREIMBURSEMENT_SELECTOPTICREIMBURSEMENTBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/selectOpticReimbursementByAttributesForSigner`;
	static STRING_URL_OPTICREIMBURSEMENT_CHECKOPTICREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/checkOpticReimbursementByToken`;
	static STRING_URL_OPTICREIMBURSEMENT_SELECTOPTICREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/selectOpticReimbursementByToken`;
	static STRING_URL_OPTICREIMBURSEMENT_SIGNOPTICREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/signOpticReimbursementByToken`;
	static STRING_URL_OPTICREIMBURSEMENT_REJECTOPTICREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/rejectOpticReimbursementByToken`;
	static STRING_URL_OPTICREIMBURSEMENT_REVISEOPTICREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/reviseOpticReimbursementByToken`;
	static STRING_URL_OPTICREIMBURSEMENT_UPDATEOPTICREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/updateOpticReimbursementByToken`;
	static STRING_URL_OPTICREIMBURSEMENT_CHECKOPTICREIMBURSEMENTPLAFONDBYUSERID: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/checkOpticReimbursementPlafondByUserID`;
	static STRING_URL_OPTICREIMBURSEMENT_DOWNLOADOPTICREIMBURSEMENTGALLERYBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/downloadOpticReimbursementGalleryByToken`;

	//#endregion


	//#region PREMATERNITY REIMBURSEMENT

	static STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT: string = "/PreMaternityReimbursement";

	static STRING_URL_PREMATERNITYREIMBURSEMENT_SELECTPREMATERNITYREIMBURSEMENTBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/selectPreMaternityReimbursementByAttributesForMaker`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_SELECTPREMATERNITYREIMBURSEMENTBYATTRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/selectPreMaternityReimbursementByAttributesForChecker`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_SELECTPREMATERNITYREIMBURSEMENTBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/selectPreMaternityReimbursementByAttributesForSigner`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_CHECKPREMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/checkPreMaternityReimbursementByToken`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_SELECTPREMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/selectPreMaternityReimbursementByToken`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_SIGNPREMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/signPreMaternityReimbursementByToken`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_REJECTPREMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/rejectPreMaternityReimbursementByToken`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_REVISEPREMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/revisePreMaternityReimbursementByToken`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_UPDATEPREMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/updatePreMaternityReimbursementByToken`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_CHECKPREMATERNITYREIMBURSEMENTPLAFONDBYUSERID: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/checkPreMaternityReimbursementPlafondByUserID`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_DOWNLOADPREMATERNITYREIMBURSEMENTGALLERYBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/downloadPreMaternityReimbursementGalleryByToken`;

	//#endregion


	//#region POSTMATERNITY REIMBURSEMENT

	static STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT: string = "/PostMaternityReimbursement";

	static STRING_URL_POSTMATERNITYREIMBURSEMENT_SELECTPOSTMATERNITYREIMBURSEMENTBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/selectPostMaternityReimbursementByAttributesForMaker`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_SELECTPOSTMATERNITYREIMBURSEMENTBYATTRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/selectPostMaternityReimbursementByAttributesForChecker`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_SELECTPOSTMATERNITYREIMBURSEMENTBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/selectPostMaternityReimbursementByAttributesForSigner`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_CHECKPOSTMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/checkPostMaternityReimbursementByToken`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_SELECTPOSTMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/selectPostMaternityReimbursementByToken`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_SIGNPOSTMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/signPostMaternityReimbursementByToken`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_REJECTPOSTMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/rejectPostMaternityReimbursementByToken`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_REVISEPOSTMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/revisePostMaternityReimbursementByToken`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_UPDATEPOSTMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/updatePostMaternityReimbursementByToken`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_CHECKPOSTMATERNITYREIMBURSEMENTPLAFONDBYUSERID: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/checkPostMaternityReimbursementPlafondByUserID`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_DOWNLOADPOSTMATERNITYREIMBURSEMENTGALLERYBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/downloadPostMaternityReimbursementGalleryByToken`;

	//#endregion


	//#region MATERNITY REIMBURSEMENT

	static STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT: string = "/MaternityReimbursement";

	static STRING_URL_MATERNITYREIMBURSEMENT_SELECTMATERNITYREIMBURSEMENTBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/selectMaternityReimbursementByAttributesForMaker`;
	static STRING_URL_MATERNITYREIMBURSEMENT_SELECTMATERNITYREIMBURSEMENTBYATTRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/selectMaternityReimbursementByAttributesForChecker`;
	static STRING_URL_MATERNITYREIMBURSEMENT_SELECTMATERNITYREIMBURSEMENTBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/selectMaternityReimbursementByAttributesForSigner`;
	static STRING_URL_MATERNITYREIMBURSEMENT_CHECKMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/checkMaternityReimbursementByToken`;
	static STRING_URL_MATERNITYREIMBURSEMENT_SELECTMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/selectMaternityReimbursementByToken`;
	static STRING_URL_MATERNITYREIMBURSEMENT_SIGNMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/signMaternityReimbursementByToken`;
	static STRING_URL_MATERNITYREIMBURSEMENT_REJECTMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/rejectMaternityReimbursementByToken`;
	static STRING_URL_MATERNITYREIMBURSEMENT_REVISEMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/reviseMaternityReimbursementByToken`;
	static STRING_URL_MATERNITYREIMBURSEMENT_UPDATEMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/updateMaternityReimbursementByToken`;
	static STRING_URL_MATERNITYREIMBURSEMENT_CHECKMATERNITYREIMBURSEMENTPLAFONDBYUSERID: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/checkMaternityReimbursementPlafondByUserID`;
	static STRING_URL_MATERNITYREIMBURSEMENT_DOWNLOADMATERNITYREIMBURSEMENTGALLERYBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/downloadMaternityReimbursementGalleryByToken`;

	//#endregion


	//#region RISK LIBRARY ACTIVITY

	static STRING_PATH_CONTROLLER_RISKACTIVITY: string = "/RiskActivity";

	static STRING_URL_RISKACTIVITY_SELECTRISKACTIVITYBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKACTIVITY}/selectRiskActivityByAttributes`;
	static STRING_URL_RISKACTIVITY_SELECTRISKACTIVITYBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKACTIVITY}/selectRiskActivityByToken`;

	//#endregion


	//#region RISK ASSIGN

	static STRING_PATH_CONTROLLER_RISKASSIGN: string = "/RiskAssign";

	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNBYPARTICIPANT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignByParticipant`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignByToken`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNBYPARTICIPANTTOKENFORPARTICIPANT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignByParticipantTokenForParticipant`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNBYPARTICIPANTTOKENFORASSESSMENT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignByParticipantTokenForAssessment`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNBYPARTICIPANTTOKENFORAPPROVAL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignByParticipantTokenForApproval`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNCONTENTBYRISKASSIGNTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignContentByRiskAssignToken`;
	static STRING_URL_RISKASSIGN_SAVEALLRISKASSESSMENT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/saveAllRiskAssessment`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNBYTOKENFORAPPROVAL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignByTokenForApproval`;
	static STRING_URL_RISKASSIGN_APPROVERISKASSIGNBYTOKENFORPARTICIPANT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/approveRiskAssignByTokenForParticipant`;
	static STRING_URL_RISKASSIGN_REJECTRISKASSIGNBYTOKENFORPARTICIPANT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/rejectRiskAssignByTokenForParticipant`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNQUARTERLYREPORTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignQuarterlyReportByAttributes`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNQUARTERLYREPORTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignQuarterlyReportByToken`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNCONSOLIDATIONREPORTBYPERIOD: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignForConsolidationReportByPeriod`;

	//#endregion


	//#region RISK PLAN

	static STRING_PATH_CONTROLLER_RISKPLAN: string = "/RiskPlan";

	static STRING_URL_RISKPLAN_SELECTRISKPLANBYRISKASSIGNCONTENTTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKPLAN}/selectRiskPlanByRiskAssignContentToken`;
	static STRING_URL_RISKPLAN_INSERTRISKPLANBYRISKPLANLIST: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKPLAN}/insertRiskPlanByRiskPlanList`;

	//#endregion


	//#region RISK ASSIGN CONTENT

	static STRING_PATH_CONTROLLER_RISKASSIGNCONTENT: string = "/RiskAssignContent";

	static STRING_URL_RISKASSIGNCONTENT_SELECTRISKASSIGNCONTENTBYRISKASSIGNTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGNCONTENT}/selectRiskAssignContentByRiskAssignToken`;
	static STRING_URL_RISKASSIGNCONTENT_SELECTRISKASSIGNCONTENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGNCONTENT}/selectRiskAssignContentByToken`;
	static STRING_URL_RISKASSIGNCONTENT_CALCULATERISKASSIGNCONTENTSCORE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGNCONTENT}/calculateRiskAssignContentScore`;
	static STRING_URL_RISKASSIGNCONTENT_SAVERISKASSIGNCONTENTFORASSESSMENT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGNCONTENT}/saveRiskAssignContentForAssessment`;

	//#endregion


	//#region RISK ASSESSMENT GUIDE DOCUMENT

	static STRING_PATH_CONTROLLER_RISKASSESSMENTGUIDEDOCUMENT: string = "/RiskAssessmentGuideDocument";

	static STRING_URL_RISKASSESSMENTGUIDEDOCUMENT_SELECTRISKASSESSMENTGUIDEDOCUMENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSESSMENTGUIDEDOCUMENT}/selectRiskAssessmentGuideDocumentByAttributes`;
	static STRING_URL_RISKASSESSMENTGUIDEDOCUMENT_DOWNLOADRISKASSESSMENTGUIDEDOCUMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSESSMENTGUIDEDOCUMENT}/downloadRiskAssessmentGuideDocumentByToken`;

	//#endregion


	//#region RISK REQUEST

	static STRING_PATH_CONTROLLER_RISKREQUEST: string = "/RiskRequest";

	static STRING_URL_RISKREQUEST_SELECTRISKREQUESTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKREQUEST}/selectRiskRequestByAttributes`;
	static STRING_URL_RISKREQUEST_SELECTRISKREQUESTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKREQUEST}/selectRiskRequestByToken`;
	static STRING_URL_RISKREQUEST_SUBMITRISKREQUESTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKREQUEST}/submitRiskRequestByToken`;
	static STRING_URL_RISKREQUEST_SAVEALLREQUESTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKREQUEST}/saveAllRiskRequestByToken`;
	static STRING_URL_RISKREQUEST_INSERTRISKREQUEST: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKREQUEST}/insertRiskRequest`;
	static STRING_URL_RISKREQUEST_SENTRISKREQUESTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKREQUEST}/sentRiskRequestByToken`;
	static STRING_URL_RISKREQUEST_DELETERISKREQUESTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKREQUEST}/deleteRiskRequestByToken`;

	//#endregion


	//#region RISK APPETITE STATEMENT

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTRISK: string = "/RiskAppetiteStatementRisk";
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTRISK}/selectRiskAppetiteStatementByAttributes`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTRISK}/selectRiskAppetiteStatementByToken`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTRISKBYATTRIBUTESFORREALIZATIONDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTRISK}/selectRiskAppetiteStatementRiskByAttributesForRealizationDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTRISKBYATTRIBUTESFORREALIZATIONAPPROVALDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTRISK}/selectRiskAppetiteStatementRiskByAttributesForRealizationApprovalDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTRISKBYATTRIBUTESFORFOLLOWUPPLANDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTRISK}/selectRiskAppetiteStatementRiskByAttributesForFollowUpPlanDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTRISKBYATTRIBUTESFORFOLLOWUPPLANAPPROVALDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTRISK}/selectRiskAppetiteStatementRiskByAttributesForFollowUpPlanApprovalDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTRISKBYATTRIBUTESFORDASHBOARDDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTRISK}/selectRiskAppetiteStatementRiskByAttributesForDashboardDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTRISKBYATTRIBUTESFORREPORTDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTRISK}/selectRiskAppetiteStatementRiskByAttributesForReportDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTRISKBYATTRIBUTESFORREALIZATIONMONITORINGDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTRISK}/selectRiskAppetiteStatementRiskByAttributesForRealizationMonitoringDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTRISKBYATTRIBUTESFORREALIZATIONCONFIRMATIONDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTRISK}/selectRiskAppetiteStatementRiskByAttributesForRealizationConfirmationDropdown`;

	//#endregion


	//#region RISK APPETITE STATEMENT PARAMETER

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER: string = "/RiskAppetiteStatementParameter";

	static STRING_URL_RISKAPPETITESTATEMENTRISKPARAMETER_SELECTRISKAPPETITESTATEMENTPARAMETERBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER}/selectRiskAppetiteStatementParameterByToken`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTPARAMETERBYATTRIBUTESFORREALIZATIONDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER}/selectRiskAppetiteStatementParameterByAttributesForRealizationDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTPARAMETERBYATTRIBUTESFORREALIZATIONAPPROVALDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER}/selectRiskAppetiteStatementParameterByAttributesForRealizationApprovalDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTPARAMETERBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER}/selectRiskAppetiteStatementParameterByToken`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTPARAMETERBYATTRIBUTESFORREALIZATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER}/selectRiskAppetiteStatementParameterByAttributesForRealization`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTPARAMETERBYATTRIBUTESFORFOLLOWUPPLANAPPROVALDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER}/selectRiskAppetiteStatementParameterByAttributesForFollowUpPlanApprovalDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTPARAMETERBYATTRIBUTESFORFOLLOWUPPLANDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER}/selectRiskAppetiteStatementParameterByAttributesForFollowUpPlanDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTPARAMETERBYATTRIBUTESFORDASHBOARDDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER}/selectRiskAppetiteStatementParameterByAttributesForDashboardDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTPARAMETERBYATTRIBUTESFORDASHBOARD: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER}/selectRiskAppetiteStatementParameterByAttributesForDashboard`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTPARAMETERBYTOKENFORDASHBOARD: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER}/selectRiskAppetiteStatementParameterByTokenForDashboard`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTPARAMETERBYATTRIBUTESFORREPORTDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER}/selectRiskAppetiteStatementParameterByAttributesForReportDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTPARAMETERBYATTRIBUTESFORREALIZATIONMONITORINGDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER}/selectRiskAppetiteStatementParameterByAttributesForRealizationMonitoringDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTPARAMETERBYATTRIBUTESFORREALIZATIONCONFIRMATIONDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER}/selectRiskAppetiteStatementParameterByAttributesForRealizationConfirmationDropdown`;

	//#endregion


	//#region RISK APPETITE STATEMENT PARAMETER REALIZATION

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATION: string = "/RiskAppetiteStatementParameterRealization";

	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_INSERTRISKAPPETITESTATEMENTPARAMETERREALIZATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATION}/insertRiskAppetiteStatementParameterRealization`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_UPDATERISKAPPETITESTATEMENTPARAMETERREALIZATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATION}/updateRiskAppetiteStatementParameterRealization`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTPARAMETERREALIZATIONBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATION}/selectRiskAppetiteStatementParameterRealizationByAttributesForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SUBMITRISKAPPETITESTATEMENTPARAMETERREALIZATIONFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATION}/submitRiskAppetiteStatementParameterRealizationForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTPARAMETERREALIZATIONBYTOKENFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATION}/selectRiskAppetiteStatementParameterRealizationByTokenForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTPARAMETERREALIZATIONBYTOKENFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATION}/selectRiskAppetiteStatementParameterRealizationByTokenForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_REVISERISKAPPETITESTATEMENTPARAMETERREALIZATIONFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATION}/reviseRiskAppetiteStatementParameterRealizationForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTPARAMETERREALIZATIONBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATION}/selectRiskAppetiteStatementParameterRealizationByAttributesForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_APPROVERISKAPPETITESTATEMENTPARAMETERREALIZATIONFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATION}/approveRiskAppetiteStatementParameterRealizationForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_REJECTRISKAPPETITESTATEMENTPARAMETERREALIZATIONFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATION}/rejectRiskAppetiteStatementParameterRealizationForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTPARAMETERREALIZATIONBYATTRIBUTESFORMONITORING: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATION}/selectRiskAppetiteStatementParameterRealizationByAttributesForMonitoring`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTPARAMETERREALIZATIONBYTOKENFORMONITORING: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATION}/selectRiskAppetiteStatementParameterRealizationByTokenForMonitoring`;

	//#endregion


	//#region RISK APPETITE STATEMENT PARAMETER REALIZATION APPROVAL

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATIONAPPROVAL: string = "/RiskAppetiteStatementParameterRealizationApproval";

	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTPARAMETERREALIZATIONAPPROVALBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATIONAPPROVAL}/selectRiskAppetiteStatementParameterRealizationApprovalByAttributes`;

	//#endregion


	//#region RISK APPETITE STATEMENT PARAMETER REALIZATION DOCUMENT

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATIONDOCUMENT: string = "/RiskAppetiteStatementParameterRealizationDocument";

	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONDOCUMENT_DOWNLOADRISKAPPETITESTATEMENTPARAMETERREALIZATIONDOCUMENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATIONDOCUMENT}/downloadRiskAppetiteStatementParameterRealizationDocumentByAttributes`;

	//#endregion


	//#region RISK APPETITE STATEMENT PARAMETER REALIZATION FOLLOW UP PLAN

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLAN: string = "/RiskAppetiteStatementParameterFollowUpPlan";

	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLAN_SELECTRISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLAN}/selectRiskAppetiteStatementParameterFollowUpPlanByAttributesForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLAN_SELECTRISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANBYTOKENFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLAN}/selectRiskAppetiteStatementParameterFollowUpPlanByTokenForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLAN_SUBMITRISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLAN}/submitRiskAppetiteStatementParameterFollowUpPlanForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLAN_REVISERISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLAN}/reviseRiskAppetiteStatementParameterFollowUpPlanForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLAN_SELECTRISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLAN}/selectRiskAppetiteStatementParameterFollowUpPlanByAttributesForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLAN_SELECTRISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANBYTOKENFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLAN}/selectRiskAppetiteStatementParameterFollowUpPlanByTokenForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLAN_APPROVERISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLAN}/approveRiskAppetiteStatementParameterFollowUpPlanForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLAN_REJECTRISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLAN}/rejectRiskAppetiteStatementParameterFollowUpPlanForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLAN_SELECTRISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANBYATTRIBUTESFORREVIEWER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLAN}/selectRiskAppetiteStatementParameterFollowUpPlanByAttributesForReviewer`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLAN_SELECTRISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANBYTOKENFORREVIEWER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLAN}/selectRiskAppetiteStatementParameterFollowUpPlanByTokenForReviewer`;

	//#endregion


	//#region RISK APPETITE STATEMENT PARAMETER REALIZATION FOLLOW UP PLAN ITEM

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANITEM: string = "/RiskAppetiteStatementParameterFollowUpPlanItem";

	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLANITEM_SELECTRISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANITEMBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANITEM}/selectRiskAppetiteStatementParameterFollowUpPlanItemByAttributesForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLANITEM_SELECTRISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANITEMBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANITEM}/selectRiskAppetiteStatementParameterFollowUpPlanItemByAttributesForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLANITEM_SELECTRISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANITEMBYATTRIBUTESFORREVIEWER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANITEM}/selectRiskAppetiteStatementParameterFollowUpPlanItemByAttributesForReviewer`;

	//#endregion


	//#region RISK APPETITE STATEMENT PARAMETER REALIZATION FOLLOW UP PLAN APPROVAL

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANAPPROVAL: string = "/RiskAppetiteStatementParameterFollowUpPlanApproval";

	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLANAPPROVAL_SELECTRISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANAPPROVALBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANAPPROVAL}/selectRiskAppetiteStatementParameterFollowUpPlanApprovalByAttributesForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLANAPPROVAL_SELECTRISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANAPPROVALBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANAPPROVAL}/selectRiskAppetiteStatementParameterFollowUpPlanApprovalByAttributesForSigner`;

	//#endregion


	//#region RISK APPETITE STATEMENT SUB PARAMETER

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETER: string = "/RiskAppetiteStatementSubParameter";

	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTSUBPARAMETERBYATTRIBUTESFORREALIZATIONDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETER}/selectRiskAppetiteStatementSubParameterByAttributesForRealizationDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTSUBPARAMETERBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETER}/selectRiskAppetiteStatementSubParameterByToken`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTSUBPARAMETERBYATTRIBUTESFORREALIZATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETER}/selectRiskAppetiteStatementSubParameterByAttributesForRealization`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTSUBPARAMETERBYATTRIBUTESFORDASHBOARD: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETER}/selectRiskAppetiteStatementSubParameterByAttributesForDashboard`;

	//#endregion


	//#region RISK APPETITE STATEMENT SUB PARAMETER REALIZATION

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION: string = "/RiskAppetiteStatementSubParameterRealization";

	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_INSERTRISKAPPETITESTATEMENTSUBPARAMETERREALIZATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION}/insertRiskAppetiteStatementSubParameterRealization`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_UPDATERISKAPPETITESTATEMENTSUBPARAMETERREALIZATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION}/updateRiskAppetiteStatementSubParameterRealization`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION}/selectRiskAppetiteStatementSubParameterRealizationByAttributesForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SUBMITRISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION}/submitRiskAppetiteStatementSubParameterRealizationForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONBYTOKENFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION}/selectRiskAppetiteStatementSubParameterRealizationByTokenForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONBYTOKENFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION}/selectRiskAppetiteStatementSubParameterRealizationByTokenForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_REVISERISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION}/reviseRiskAppetiteStatementSubParameterRealizationForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION}/selectRiskAppetiteStatementSubParameterRealizationByAttributesForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_APPROVERISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION}/approveRiskAppetiteStatementSubParameterRealizationForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_REJECTRISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION}/rejectRiskAppetiteStatementSubParameterRealizationForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONBYATTRIBUTESFORMONITORING: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION}/selectRiskAppetiteStatementSubParameterRealizationByAttributesForMonitoring`;

	//#endregion


	//#region RISK APPETITE STATEMENT SUB PARAMETER REALIZATION APPROVAL

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONAPPROVAL: string = "/RiskAppetiteStatementSubParameterRealizationApproval";

	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONAPPROVALBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONAPPROVAL}/selectRiskAppetiteStatementSubParameterRealizationApprovalByAttributes`;

	//#endregion


	//#region RISK APPETITE STATEMENT SUB PARAMETER REALIZATION DOCUMENT

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONDOCUMENT: string = "/RiskAppetiteStatementSubParameterRealizationDocument";

	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONDOCUMENT_DOWNLOADRISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONDOCUMENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONDOCUMENT}/downloadRiskAppetiteStatementSubParameterRealizationDocumentByAttributes`;

	//#endregion


	//#region RISK APPETITE STATEMENT SUB SUB PARAMETER

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETER: string = "/RiskAppetiteStatementSubSubParameter";

	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTSUBSUBPARAMETERBYATTRIBUTESFORREALIZATIONDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETER}/selectRiskAppetiteStatementSubSubParameterByAttributesForRealizationDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTSUBSUBPARAMETERBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETER}/selectRiskAppetiteStatementSubSubParameterByToken`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTSUBSUBPARAMETERBYATTRIBUTESFORREALIZATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETER}/selectRiskAppetiteStatementSubSubParameterByAttributesForRealization`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTSUBSUBPARAMETERBYATTRIBUTESFORDASHBOARD: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETER}/selectRiskAppetiteStatementSubSubParameterByAttributesForDashboard`;

	//#endregion


	//#region RISK APPETITE STATEMENT SUB SUB PARAMETER REALIZATION

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION: string = "/RiskAppetiteStatementSubSubParameterRealization";

	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_INSERTRISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION}/insertRiskAppetiteStatementSubSubParameterRealization`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_UPDATERISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION}/updateRiskAppetiteStatementSubSubParameterRealization`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION}/selectRiskAppetiteStatementSubSubParameterRealizationByAttributesForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SUBMITRISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION}/submitRiskAppetiteStatementSubSubParameterRealizationForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONBYTOKENFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION}/selectRiskAppetiteStatementSubSubParameterRealizationByTokenForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONBYTOKENFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION}/selectRiskAppetiteStatementSubSubParameterRealizationByTokenForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_REVISERISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION}/reviseRiskAppetiteStatementSubSubParameterRealizationForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION}/selectRiskAppetiteStatementSubSubParameterRealizationByAttributesForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_APPROVERISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION}/approveRiskAppetiteStatementSubSubParameterRealizationForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_REJECTRISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION}/rejectRiskAppetiteStatementSubSubParameterRealizationForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONBYATTRIBUTESFORMONITORING: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION}/selectRiskAppetiteStatementSubSubParameterRealizationByAttributesForMonitoring`;

	//#endregion


	//#region RISK APPETITE STATEMENT SUB SUB PARAMETER REALIZATION DOCUMENT

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONDOCUMENT: string = "/RiskAppetiteStatementSubSubParameterRealizationDocument";

	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONDOCUMENT_DOWNLOADRISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONDOCUMENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONDOCUMENT}/downloadRiskAppetiteStatementSubSubParameterRealizationDocumentByAttributes`;

	//#endregion


	//#region RISK APPETITE STATEMENT SUB SUB PARAMETER REALIZATION APPROVAL

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONAPPROVAL: string = "/RiskAppetiteStatementSubSubParameterRealizationApproval";

	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONAPPROVALBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONAPPROVAL}/selectRiskAppetiteStatementSubSubParameterRealizationApprovalByAttributes`;

	//#endregion


	//#region LEGAL

	static STRING_PATH_CONTROLLER_LEGAL: string = "/Legal";

	static STRING_URL_SELECTCHECKERLEGAL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LEGAL}/selectChecker`;
	static STRING_URL_SELECTSIGNERLEGAL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LEGAL}/selectSigner`;

	//#endregion


	//#region DOCUMENT APPLICATION CONTROLLER

	static STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION: string = "/DocumentApplication";

	static STRING_URL_DOCUMENTAPPLICATION_INSERTDOCUMENTAPPLICATION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/insertDocumentApplication`;
	static STRING_URL_DOCUMENTAPPLICATION_SAVEDOCUMENTAPPLICATION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/saveDocumentApplication`;
	static STRING_URL_DOCUMENTAPPLICATION_UPDATEDOCUMENTAPPLICATION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/updateDocumentApplication`;
	static STRING_URL_DOCUMENTAPPLICATION_SUBMITDOCUMENTAPPLICATION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/submitDocumentApplication`;
	static STRING_URL_DOCUMENTAPPLICATION_UPDATEDOCUMENTAPPLICATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/updateDocumentApplicationByToken`;
	static STRING_URL_DOCUMENTAPPLICATION_REJECTDOCUMENTAPPLICATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/rejectDocumentApplicationByTokenForDivision`;
	static STRING_URL_DOCUMENTAPPLICATION_APPROVALDOCUMENTAPPLICATIONBYTOKENFORSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/approvalDocumentApplicationByTokenForSignerDivision`;
	static STRING_URL_DOCUMENTAPPLICATION_APPROVALDOCUMENTAPPLICATIONBYTOKENFORCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/approvalDocumentApplicationByTokenForCheckerDivision`;
	static STRING_URL_DOCUMENTAPPLICATION_DELETEDOCUMENT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/deleteDocumentApplicationByToken`;
	static STRING_URL_DOCUMENTAPPLICATION_SELECTDOCUMENTBYATTRIBUTEFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/selectDocumentApplicationByAttributesForMaker`;
	static STRING_URL_DOCUMENTAPPLICATION_SELECTDOCUMENTBYATTRIBUTEFORCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/selectDocumentApplicationByAttributesForChecker`;
	static STRING_URL_DOCUMENTAPPLICATION_SELECTDOCUMENTBYATTRIBUTEFORSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/selectDocumentApplicationByAttributesForSigner`;
	static STRING_URL_DOCUMENTAPPLICATION_SELECTDOCUMENTBYATTRIBUTE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/selectDocumentApplicationByAttributes`;
	static STRING_URL_DOCUMENTAPPLICATION_SELECTDOCUMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/selectDocumentApplicationByToken`;
	static STRING_URL_DOCUMENTAPPLICATION_DOWNLOADDOCUMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/downloadDocumentRequestAttachmentByToken`;

	//#endregion


	//#region ANOMALY BATCH

	static STRING_PATH_CONTROLLER_ANOMALYBATCH: string = "/AnomalyBatch";

	static STRING_URL_ANOMALYBATCH_INSERTANOMALYBATCH: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/insertAnomalyBatch`;
	static STRING_URL_ANOMALYBATCH_SELECTANOMALYBATCHBYATTRIBUTESFORMAKERASQ: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/selectAnomalyBatchByAttributesForMakerASQ`;
	static STRING_URL_ANOMALYBATCH_SELECTANOMALYBATCHBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/selectAnomalyBatchByToken`;
	static STRING_URL_ANOMALYBATCH_SELECTANOMALYBATCHBYATTRIBUTESFORCHECKERASQ: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/selectAnomalyBatchByAttributesForCheckerASQ`;
	static STRING_URL_ANOMALYBATCH_SELECTANOMALYBATCHBYATTRIBUTESFORSIGNERASQ: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/selectAnomalyBatchByAttributesForSignerASQ`;
	static STRING_URL_ANOMALYBATCH_SELECTANOMALYBATCHBYATTRIBUTESFORMAKERAUDITOR: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/selectAnomalyBatchByAttributesForMakerAuditor`;
	static STRING_URL_ANOMALYBATCH_SELECTANOMALYBATCHBYATTRIBUTESFORCHECKERAUDITOR: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/selectAnomalyBatchByAttributesForCheckerAuditor`;
	static STRING_URL_ANOMALYBATCH_SELECTANOMALYBATCHBYATTRIBUTESFORSIGNERAUDITOR: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/selectAnomalyBatchByAttributesForSignerAuditor`;
	static STRING_URL_ANOMALYBATCH_SELECTANOMALYBATCHBYATTRIBUTESFORMAKERAUDITEE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/selectAnomalyBatchByAttributesForMakerAuditee`;
	static STRING_URL_ANOMALYBATCH_SELECTANOMALYBATCHBYATTRIBUTESFORCHECKERAUDITEE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/selectAnomalyBatchByAttributesForCheckerAuditee`;
	static STRING_URL_ANOMALYBATCH_SELECTANOMALYBATCHBYATTRIBUTESFORSIGNERAUDITEE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/selectAnomalyBatchByAttributesForSignerAuditee`;
	static STRING_URL_ANOMALYBATCH_UPDATEASSIGNEE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/updateAssignee`;
	static STRING_URL_ANOMALYBATCH_UPDATEAUDITOR: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/updateAuditor`;
	static STRING_URL_ANOMALYBATCH_UPDATEFOLLOWUPANALYSIS: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/updateFollowUpAnalysis`;
	static STRING_URL_ANOMALYBATCH_UPDATEAUDITORFORAPPROVE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/updateAuditorForApprove`;
	static STRING_URL_ANOMALYBATCH_UPDATEFOLLOWUPANALYSISFORAPPROVE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/updateFollowUpAnalysisForApprove`;
	static STRING_URL_ANOMALYBATCH_APPROVECHECKERASQ: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/approveCheckerASQ`;
	static STRING_URL_ANOMALYBATCH_APPROVESIGNERASQ: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/approveSignerASQ`;
	static STRING_URL_ANOMALYBATCH_REJECTASQ: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/rejectASQ`;
	static STRING_URL_ANOMALYBATCH_APPROVECHECKERAUDITOR: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/approveCheckerAuditor`;
	static STRING_URL_ANOMALYBATCH_APPROVESIGNERAUDITOR: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/approveSignerAuditor`;
	static STRING_URL_ANOMALYBATCH_REJECTAUDITOR: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/rejectAuditor`;
	static STRING_URL_ANOMALYBATCH_UPLOADAUDITORFEEDBACK: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/uploadAuditorFeedback`;
	static STRING_URL_ANOMALYBATCH_APPROVECHECKERAUDITEE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/approveCheckerAuditee`;
	static STRING_URL_ANOMALYBATCH_APPROVESIGNERAUDITEE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/approveSignerAuditee`;
	static STRING_URL_ANOMALYBATCH_REJECTAUDITEE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/rejectAuditee`;
	static STRING_URL_ANOMALYBATCH_DOWNLOADANOMALYDOCUMENTAUDITORFEEDBACKBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/downloadAnomalyDocumentAuditorFeedbackByToken`;

	//#endregion


	//#region EMPLOYEE

	static STRING_PATH_CONTROLLER_ANOMALYGENERAL: string = "/AnomalyGeneral";

	static STRING_URL_ANOMALYGENERAL_SELECTANOMALYGENERALBYANOMALYBATCH: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYGENERAL}/selectAnomalyGeneralByAnomalyBatch`;
	static STRING_URL_ANOMALYGENERAL_UPLOADFOLLOWUP: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYGENERAL}/uploadFollowUp`;
	static STRING_URL_ANOMALYGENERAL_DOWNLOADFOLLOWUP: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYGENERAL}/downloadFollowUp`;
	static STRING_URL_ANOMALYGENERAL_DOWNLOADFOLLOWUPEXPORT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYGENERAL}/downloadFollowUpExport`;
	static STRING_URL_ANOMALYGENERAL_DOWNLOADANOMALYGENERALSPREADSHEET: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYGENERAL}/downloadAnomalyGeneralSpreadsheet`;

	//#endregion


	//#region AUDIT TEAM

	static STRING_PATH_CONTROLLER_AUDITTEAM: string = "/AuditTeam";

	static STRING_URL_AUDITTEAM_SELECTAUDITTEAMBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_AUDITTEAM}/selectAuditTeamByAttributes`;

	//#endregion


	//#region AUDIT TEAM MEMBER

	static STRING_PATH_CONTROLLER_AUDITTEAMMEMBER: string = "/AuditTeamMember";

	static STRING_URL_AUDITTEAM_SELECTAUDITTEAMMEMBERBYUSERID: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_AUDITTEAMMEMBER}/selectAuditTeamMemberByUserID`;
	static STRING_URL_AUDITTEAMMEMBER_SELECTAUDITTEAMLEADERBYAUDITGENERALTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_AUDITTEAMMEMBER}/selectAuditTeamLeaderByAuditGeneralToken`;
	static STRING_URL_AUDITTEAMMEMBER_SELECTAUDITTEAMMEMBERBYAUDITGENERALTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_AUDITTEAMMEMBER}/selectAuditTeamMemberByAuditGeneralToken`;

	//#endregion


	//#region ANOMALYNOTIFICATION

	static STRING_PATH_CONTROLLER_ANOMALYNOTIFICATION: string = "/AnomalyNotification";

	static STRING_URL_ANOMALYNOTIFICATION_SELECTANOMALYNOTIFICATIONBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYNOTIFICATION}/selectAnomalyNotificationByAttributes`;
	static STRING_URL_ANOMALYNOTIFICATION_SELECTANOMALYNOTIFICATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYNOTIFICATION}/selectAnomalyNotificationByToken`;
	static STRING_URL_ANOMALYNOTIFICATION_SELECTANOMALYNOTIFICATIONCOUNT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYNOTIFICATION}/selectAnomalyNotificationCount`;

	//#endregion


	//#region PRODUCT GENERAL REPORT

	static STRING_PATH_CONTROLLER_PRODUCTGENERALREPORT: string = "/ProductGeneralReport";

	static STRING_URL_PRODUCTGENERALREPORT_SELECTPRODUCTGENERALREPORTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTGENERALREPORT}/selectProductGeneralReportByAttributes`;
	static STRING_URL_PRODUCTGENERALREPORT_SELECTPRODUCTGENERALREPORTBYATTRIBUTESFOREXCEL: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTGENERALREPORT}/selectProductGeneralReportByAttributesForExcel`;

	//#endregion


	//#region PURCHASERATINGREVIEWS CONTROLLER

	static STRING_PATH_CONTROLLER_PURCHASERATINGREVIEWS: string = "/PurchaseRatingReviews";

	static STRING_URL_PURCHASERATINGREVIEWS_SELECTPOLICYGENERALREPORTRATINGBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PURCHASERATINGREVIEWS}/selectPolicyGeneralReportRatingByAttributes`;

	//#endregion


	//#region TICKET SERVICE

	static STRING_PATH_CONTROLLER_TICKETSERVICE: string = "/TicketService";

	static STRING_URL_TICKETSERVICE_SELECTTICKETSERVICEBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICE}/selectTicketServiceByAttributes`;
	static STRING_URL_TICKETSERVICE_SELECTTICKETSERVICEBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICE}/selectTicketServiceByToken`;
	static STRING_URL_TICKETSERVICE_INSERTTICKETSERVICE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICE}/insertTicketService`;
	static STRING_URL_TICKETSERVICE_INSERTLISTTICKETSERVICE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICE}/insertListTicketService`;
	static STRING_URL_TICKETSERVICE_DELETETICKETSERVICEBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICE}/deleteTicketServiceByToken`;
	static STRING_URL_TICKETSERVICE_UPDATETICKETSERVICEBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICE}/updateTicketServiceByToken`;

	//#endregion


	//#region TICKET SERVICE AGENT

	static STRING_PATH_CONTROLLER_TICKETSERVICEAGENT: string = "/TicketServiceAgent";

	static STRING_URL_TICKETSERVICEAGENT_SELECTTICKETSERVICEAGENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICEAGENT}/selectTicketServiceAgentByAttributes`;
	static STRING_URL_TICKETSERVICEAGENT_SELECTTICKETSERVICEAGENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICEAGENT}/selectTicketServiceAgentByToken`;
	static STRING_URL_TICKETSERVICEAGENT_INSERTTICKETSERVICEAGENT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICEAGENT}/insertTicketServiceAgent`;
	static STRING_URL_TICKETSERVICEAGENT_INSERTLISTTICKETSERVICEAGENT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICEAGENT}/insertListTicketServiceAgent`;
	static STRING_URL_TICKETSERVICEAGENT_DELETETICKETSERVICEAGENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICEAGENT}/deleteTicketServiceAgentByToken`;
	static STRING_URL_TICKETSERVICEAGENT_UPDATETICKETSERVICEAGENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICEAGENT}/updateTicketServiceAgentByToken`;

	//#endregion


	//#region EMPLOYEE

	static STRING_PATH_CONTROLLER_EMPLOYEE: string = "/Employee";

	static STRING_URL_EMPLOYEE_SELECTEMPLOYEEBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EMPLOYEE}/selectEmployeeByAttributes`;
	static STRING_URL_EMPLOYEE_SELECTEMPLOYEEBYBRANCH: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EMPLOYEE}/selectEmployeeByBranch`;
	static STRING_URL_EMPLOYEE_SELECTEMPLOYEEBYDIVISION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EMPLOYEE}/selectEmployeeByDivison`;

	//#endregion


	//#region FORMATION

	static STRING_PATH_CONTROLLER_FORMATION: string = "/Formation";

	static STRING_URL_FORMATION_SELECTFORMATIONBYDIVISIONID: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FORMATION}/selectFormationByDivisionID`;

	//#endregion


	//#region GCG ONLINE

	/* PROFILE COMPONENT - START */

	static STRING_PATH_CONTROLLER_GCGPROFILEPAGE: string = "/GCGProfilePage";

	static STRING_URL_GCGONLINEPROFILEPAGE_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GCGPROFILEPAGE}/SelectForEmployee`;

	/* PROFILE COMPONENT - END */

	/* GRATIFICATION LIST COMPONENT - START */

	static STRING_PATH_CONTROLLER_GRATIFICATIONLISTPAGE: string = "/GratificationListPage";

	static STRING_URL_GCGONLINEGRATIFICATIONLIST_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GRATIFICATIONLISTPAGE}/SelectForEmployee`;

	static STRING_PATH_CONTROLLER_GRATIFICATIONEXCELPAGE: string = "/GratificationExcelPage";

	/* GRATIFICATION LIST COMPONENT - END */

	/* GRATIFICATION DETAIL COMPONENT - START */

	static STRING_PATH_CONTROLLER_GRATIFICATIONSUBMISSIONPAGE: string = "/GratificationSubmissionPage";

	static STRING_URL_GCGONLINEGRATIFICATIONSUBMISSION_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GRATIFICATIONSUBMISSIONPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINEGRATIFICATIONSUBMISSION_SUBMITFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GRATIFICATIONSUBMISSIONPAGE}/SubmitForEmployee`;

	static STRING_PATH_CONTROLLER_GRATIFICATIONDETAILPAGE: string = "/GratificationDetailPage";

	static STRING_URL_GCGONLINEGRATIFICATIONDETAIL_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GRATIFICATIONDETAILPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINEGRATIFICATIONDETAIL_SELECTREVIEWFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GRATIFICATIONDETAILPAGE}/SelectReviewForEmployee`;
	static STRING_URL_GCGONLINEGRATIFICATIONDETAIL_SUBMITREVIEWFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GRATIFICATIONDETAILPAGE}/SubmitReviewForEmployee`;
	static STRING_URL_GCGONLINEGRATIFICATIONDETAIL_SELECTDOCUMENTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GRATIFICATIONDETAILPAGE}/SelectDocumentForEmployee`;
	static STRING_URL_GCGONLINEGRATIFICATIONDETAIL_DOWNLOADFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GRATIFICATIONDETAILPAGE}/DownloadForEmployee`;

	/* GRATIFICATION DETAIL COMPONENT - END */

	/* INTEGRITY PACT LIST COMPONENT - START */

	static STRING_PATH_CONTROLLER_INTEGRITYPACTLISTPAGE: string = "/IntegrityPactListPage";

	static STRING_URL_GCGONLINEINTEGRITYPACTLIST_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_INTEGRITYPACTLISTPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINEINTEGRITYPACTLIST_SELECTWORKINGUNITFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_INTEGRITYPACTLISTPAGE}/SelectWorkingUnitForEmployee`;
	static STRING_URL_GCGONLINEINTEGRITYPACTLIST_DOWNLOADFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_INTEGRITYPACTLISTPAGE}/DownloadForEmployee`;

	static STRING_PATH_CONTROLLER_INTEGRITYPACTEXCELPAGE: string = "/IntegrityPactExcelPage";

	/* INTEGRITY PACT LIST COMPONENT - END */

	/* INTEGRITY PACT DETAIL COMPONENT - START */

	static STRING_PATH_CONTROLLER_INTEGRITYPACTSUBMISSIONPAGE: string = "/IntegrityPactSubmissionPage";

	static STRING_URL_GCGONLINEINTEGRITYPACTSUBMISSION_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_INTEGRITYPACTSUBMISSIONPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINEINTEGRITYPACTSUBMISSION_SUBMITFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_INTEGRITYPACTSUBMISSIONPAGE}/SubmitForEmployee`;

	static STRING_PATH_CONTROLLER_INTEGRITYPACTDETAILPAGE: string = "/IntegrityPactDetailPage";

	static STRING_URL_GCGONLINEINTEGRITYPACTDETAIL_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_INTEGRITYPACTDETAILPAGE}/SelectForEmployee`;

	/* INTEGRITY PACT DETAIL COMPONENT - END */

	/* CODE OF ETHICS LIST COMPONENT - START */

	static STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE: string = "/CodeOfEthicsListPage";

	static STRING_URL_GCGONLINECODEOFETHICSLIST_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSLIST_SELECTFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/SelectForGCGChecker`;
	static STRING_URL_GCGONLINECODEOFETHICSLIST_SELECTFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/SelectForGCGSigner`;
	static STRING_URL_GCGONLINECODEOFETHICSLIST_SELECTWORKINGUNITFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/SelectWorkingUnitForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSLIST_SELECTWORKINGUNITFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/SelectWorkingUnitForGCGChecker`;
	static STRING_URL_GCGONLINECODEOFETHICSLIST_SELECTWORKINGUNITFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/SelectWorkingUnitForGCGSigner`;
	static STRING_URL_GCGONLINECODEOFETHICSLIST_DOWNLOADFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/DownloadForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSLIST_DOWNLOADFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/DownloadForGCGChecker`;
	static STRING_URL_GCGONLINECODEOFETHICSLIST_DOWNLOADFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/DownloadForGCGSigner`;

	static STRING_PATH_CONTROLLER_CODEOFETHICSEXCELPAGE: string = "/CodeOfEthicsExcelPage";

	/* CODE OF ETHICS LIST COMPONENT - END */

	/* CODE OF ETHICS DETAIL COMPONENT - START */

	static STRING_PATH_CONTROLLER_CODEOFETHICSSUBMISSIONPAGE: string = "/CodeOfEthicsSubmissionPage";

	static STRING_URL_GCGONLINECODEOFETHICSSUBMISSION_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSSUBMISSIONPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSSUBMISSION_SELECTCHECKERFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSSUBMISSIONPAGE}/SelectCheckerForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSSUBMISSION_SELECTSIGNERFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSSUBMISSIONPAGE}/SelectSignerForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSSUBMISSION_SUBMITFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSSUBMISSIONPAGE}/SubmitForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSSUBMISSION_REVISEFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSSUBMISSIONPAGE}/ReviseForEmployee`;

	static STRING_PATH_CONTROLLER_CODEOFETHICSDETAILPAGE: string = "/CodeOfEthicsDetailPage";

	static STRING_URL_GCGONLINECODEOFETHICSDETAIL_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSDETAILPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSDETAIL_SELECTAPPROVALFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSDETAILPAGE}/SelectApprovalForEmployee`;

	static STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE: string = "/CodeOfEthicsApprovalPage";

	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_SELECTFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/SelectForGCGChecker`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_SELECTFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/SelectForGCGSigner`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_SELECTAPPROVALFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/SelectApprovalForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_SELECTAPPROVALFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/SelectApprovalForGCGChecker`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_SELECTAPPROVALFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/SelectApprovalForGCGSigner`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_SELECTCHECKERFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/SelectCheckerForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_SELECTSIGNERFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/SelectSignerForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_REJECTFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/RejectForGCGChecker`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_APPROVEFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/ApproveForGCGChecker`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_REJECTFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/RejectForGCGSigner`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_APPROVEFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/ApproveForGCGSigner`;

	/* CODE OF ETHICS DETAIL COMPONENT - END */

	/* ANNUAL DISCLOSURE LIST COMPONENT - START */

	static STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE: string = "/AnnualDisclosureListPage";

	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_SELECTFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/SelectForGCGChecker`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_SELECTFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/SelectForGCGSigner`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_SELECTWORKINGUNITFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/SelectWorkingUnitForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_SELECTWORKINGUNITFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/SelectWorkingUnitForGCGChecker`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_SELECTWORKINGUNITFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/SelectWorkingUnitForGCGSigner`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_DOWNLOADFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/DownloadForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_DOWNLOADFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/DownloadForGCGChecker`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_DOWNLOADFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/DownloadForGCGSigner`;

	static STRING_PATH_CONTROLLER_ANNUALDISCLOSUREEXCELPAGE: string = "/AnnualDisclosureExcelPage";

	/* ANNUAL DISCLOSURE LIST COMPONENT - END */

	/* ANNUAL DISCLOSURE DETAIL COMPONENT - START */

	static STRING_PATH_CONTROLLER_ANNUALDISCLOSURESUBMISSIONPAGE: string = "/AnnualDisclosureSubmissionPage";

	static STRING_URL_GCGONLINEANNUALDISCLOSURESUBMISSION_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURESUBMISSIONPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURESUBMISSION_SELECTCHECKERFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURESUBMISSIONPAGE}/SelectCheckerForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURESUBMISSION_SELECTSIGNERFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURESUBMISSIONPAGE}/SelectSignerForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURESUBMISSION_SUBMITFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURESUBMISSIONPAGE}/SubmitForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURESUBMISSION_REVISEFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURESUBMISSIONPAGE}/ReviseForEmployee`;

	static STRING_PATH_CONTROLLER_ANNUALDISCLOSUREDETAILPAGE: string = "/AnnualDisclosureDetailPage";

	static STRING_URL_GCGONLINEANNUALDISCLOSUREDETAIL_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREDETAILPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREDETAIL_SELECTQUESTIONFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREDETAILPAGE}/SelectQuestionForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREDETAIL_SELECTAPPROVALFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREDETAILPAGE}/SelectApprovalForEmployee`;

	static STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE: string = "/AnnualDisclosureApprovalPage";

	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectForGCGChecker`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectForGCGSigner`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTAPPROVALFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectApprovalForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTAPPROVALFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectApprovalForGCGChecker`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTAPPROVALFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectApprovalForGCGSigner`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTQUESTIONFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectQuestionForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTQUESTIONFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectQuestionForGCGChecker`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTQUESTIONFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectQuestionForGCGSigner`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTCHECKERFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectCheckerForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTSIGNERFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectSignerForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_REJECTFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/RejectForGCGChecker`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_APPROVEFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/ApproveForGCGChecker`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_REJECTFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/RejectForGCGSigner`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_APPROVEFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/ApproveForGCGSigner`;

	/* ANNUAL DISCLOSURE DETAIL COMPONENT - END */

	//#endregion


	//#region CUSTOMER SATISFACTION

	static STRING_PATH_CONTROLLER_CUSTOMERSATISFACTION: string = "/CustomerSatisfaction";

	static STRING_URL_CUSTOMERSATISFACTION_SELECTCUSTOMERSATISFACTIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMERSATISFACTION}/selectCustomerSatisfactionByAttributes`;
	static STRING_URL_CUSTOMERSATISFACTION_SELECTCUSTOMERSATISFACTIONBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMERSATISFACTION}/selectCustomerSatisfactionByToken`;
	static STRING_URL_CUSTOMERSATISFACTION_SELECTREPORTCUSTOMERSATISFACTIONBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMERSATISFACTION}/selectReportCustomerSatisfactionByAttributes`;
	static STRING_URL_CUSTOMERSATISFACTION_INSERTCUSTOMERSATISFACTION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMERSATISFACTION}/insertCustomerSatisfaction`;
	static STRING_URL_CUSTOMERSATISFACTION_DELETECUSTOMERSATISFACTIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMERSATISFACTION}/deleteCustomerSatisfactionByToken`;

	//#endregion


	//#region E-BUDGETING

	/* ANNUAL DIVISION LIST PAGE - START */

	static STRING_PATH_CONTROLLER_EBUDGETINGDIVISIONLISTPAGE: string = "/EBudgetingDivisionListPage";

	static STRING_URL_EBUDGETINGDIVISIONLISTPAGE_SELECTDIVISIONFOREBUDGETINGAFNMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDIVISIONLISTPAGE}/selectDivisionForEBudgetingAFNMaker`;
	static STRING_URL_EBUDGETINGDIVISIONLISTPAGE_SELECTLISTFOREBUDGETINGAFNMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDIVISIONLISTPAGE}/selectListForEBudgetingAFNMaker`;

	/* ANNUAL DIVISION LIST PAGE - END */

	/* ANNUAL ACCOUNT LIST PAGE - START */

	static STRING_PATH_CONTROLLER_EBUDGETINGACCOUNTLISTPAGE: string = "/EBudgetingAccountListPage";

	static STRING_URL_EBUDGETINGACCOUNTLISTPAGE_SELECTACCOUNTFOREBUDGETINGAFNMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGACCOUNTLISTPAGE}/selectAccountForEBudgetingAFNMaker`;
	static STRING_URL_EBUDGETINGACCOUNTLISTPAGE_SUBMITFOREBUDGETINGAFNMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGACCOUNTLISTPAGE}/submitForEBudgetingAFNMaker`;
	static STRING_URL_EBUDGETINGACCOUNTLISTPAGE_SELECTLISTFOREBUDGETINGAFNMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGACCOUNTLISTPAGE}/selectListForEBudgetingAFNMaker`;
	static STRING_URL_EBUDGETINGACCOUNTLISTPAGE_SELECTSUBMISSIONFOREBUDGETINGAFNMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGACCOUNTLISTPAGE}/selectSubmissionForEBudgetingAFNMaker`;
	static STRING_URL_EBUDGETINGACCOUNTLISTPAGE_ACTIVATEFOREBUDGETINGAFNMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGACCOUNTLISTPAGE}/activateForEBudgetingAFNMaker`;
	static STRING_URL_EBUDGETINGACCOUNTLISTPAGE_DEACTIVATEFOREBUDGETINGAFNMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGACCOUNTLISTPAGE}/deactivateForEBudgetingAFNMaker`;

	/* ANNUAL ACCOUNT LIST PAGE - END */

	/* ANNUAL BUDGET LIST PAGE - START */

	static STRING_PATH_CONTROLLER_EBUDGETINGBUDGETLISTPAGE: string = "/EBudgetingBudgetListPage";

	static STRING_URL_EBUDGETINGBUDGETLISTPAGE_SELECTCHECKERFOREBUDGETINGAFNMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGBUDGETLISTPAGE}/selectCheckerForEBudgetingAFNMaker`;
	static STRING_URL_EBUDGETINGBUDGETLISTPAGE_SELECTSIGNERFOREBUDGETINGAFNMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGBUDGETLISTPAGE}/selectSignerForEBudgetingAFNMaker`;
	static STRING_URL_EBUDGETINGBUDGETLISTPAGE_SELECTSUBMISSIONFOREBUDGETINGAFNMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGBUDGETLISTPAGE}/selectSubmissionForEBudgetingAFNMaker`;
	static STRING_URL_EBUDGETINGBUDGETLISTPAGE_SELECTREVISIONFOREBUDGETINGAFNMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGBUDGETLISTPAGE}/selectRevisionForEBudgetingAFNMaker`;
	static STRING_URL_EBUDGETINGBUDGETLISTPAGE_SELECTBUDGETFOREBUDGETINGAFNMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGBUDGETLISTPAGE}/selectBudgetForEBudgetingAFNMaker`;
	static STRING_URL_EBUDGETINGBUDGETLISTPAGE_SUBMITFOREBUDGETINGAFNMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGBUDGETLISTPAGE}/submitForEBudgetingAFNMaker`;
	static STRING_URL_EBUDGETINGBUDGETLISTPAGE_REVISEFOREBUDGETINGAFNMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGBUDGETLISTPAGE}/reviseForEBudgetingAFNMaker`;

	/* ANNUAL BUDGET LIST PAGE - END */

	/* BUDGET APPROVAL LIST PAGE - START */

	static STRING_PATH_CONTROLLER_EBUDGETINGBUDGETAPPROVALLISTPAGE: string = "/EBudgetingBudgetApprovalListPage";

	static STRING_URL_EBUDGETINGBUDGETAPPROVALLISTPAGE_SELECTDIVISIONFOREBUDGETINGAFNCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGBUDGETAPPROVALLISTPAGE}/selectDivisionForEBudgetingAFNChecker`;
	static STRING_URL_EBUDGETINGBUDGETAPPROVALLISTPAGE_SELECTDIVISIONFOREBUDGETINGAFNSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGBUDGETAPPROVALLISTPAGE}/selectDivisionForEBudgetingAFNSigner`;
	static STRING_URL_EBUDGETINGBUDGETAPPROVALLISTPAGE_SELECTACCOUNTFOREBUDGETINGAFNCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGBUDGETAPPROVALLISTPAGE}/selectAccountForEBudgetingAFNChecker`;
	static STRING_URL_EBUDGETINGBUDGETAPPROVALLISTPAGE_SELECTACCOUNTFOREBUDGETINGAFNSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGBUDGETAPPROVALLISTPAGE}/selectAccountForEBudgetingAFNSigner`;
	static STRING_URL_EBUDGETINGBUDGETAPPROVALLISTPAGE_SELECTLISTFOREBUDGETINGAFNCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGBUDGETAPPROVALLISTPAGE}/selectListForEBudgetingAFNChecker`;
	static STRING_URL_EBUDGETINGBUDGETAPPROVALLISTPAGE_SELECTLISTFOREBUDGETINGAFNSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGBUDGETAPPROVALLISTPAGE}/selectListForEBudgetingAFNSigner`;
	static STRING_URL_EBUDGETINGBUDGETAPPROVALLISTPAGE_APPROVEFOREBUDGETINGAFNCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGBUDGETAPPROVALLISTPAGE}/approveForEBudgetingAFNChecker`;
	static STRING_URL_EBUDGETINGBUDGETAPPROVALLISTPAGE_APPROVEFOREBUDGETINGAFNSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGBUDGETAPPROVALLISTPAGE}/approveForEBudgetingAFNSigner`;

	/* BUDGET APPROVAL LIST PAGE - END */

	/* BUDGET APPROVAL DETAIL PAGE - START */

	static STRING_PATH_CONTROLLER_EBUDGETINGBUDGETAPPROVALDETAILPAGE: string = "/EBudgetingBudgetApprovalDetailPage";

	static STRING_URL_EBUDGETINGBUDGETAPPROVALDETAILPAGE_SELECTDETAILFOREBUDGETINGAFNCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGBUDGETAPPROVALDETAILPAGE}/selectDetailForEBudgetingAFNChecker`;
	static STRING_URL_EBUDGETINGBUDGETAPPROVALDETAILPAGE_SELECTDETAILFOREBUDGETINGAFNSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGBUDGETAPPROVALDETAILPAGE}/selectDetailForEBudgetingAFNSigner`;
	static STRING_URL_EBUDGETINGBUDGETAPPROVALDETAILPAGE_SELECTAPPROVALFOREBUDGETINGAFNCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGBUDGETAPPROVALDETAILPAGE}/selectApprovalForEBudgetingAFNChecker`;
	static STRING_URL_EBUDGETINGBUDGETAPPROVALDETAILPAGE_SELECTAPPROVALFOREBUDGETINGAFNSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGBUDGETAPPROVALDETAILPAGE}/selectApprovalForEBudgetingAFNSigner`;
	static STRING_URL_EBUDGETINGBUDGETAPPROVALDETAILPAGE_REJECTFOREBUDGETINGAFNCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGBUDGETAPPROVALDETAILPAGE}/rejectForEBudgetingAFNChecker`;
	static STRING_URL_EBUDGETINGBUDGETAPPROVALDETAILPAGE_REJECTFOREBUDGETINGAFNSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGBUDGETAPPROVALDETAILPAGE}/rejectForEBudgetingAFNSigner`;
	static STRING_URL_EBUDGETINGBUDGETAPPROVALDETAILPAGE_APPROVEFOREBUDGETINGAFNCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGBUDGETAPPROVALDETAILPAGE}/approveForEBudgetingAFNChecker`;
	static STRING_URL_EBUDGETINGBUDGETAPPROVALDETAILPAGE_APPROVEFOREBUDGETINGAFNSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGBUDGETAPPROVALDETAILPAGE}/approveForEBudgetingAFNSigner`;

	/* BUDGET APPROVAL DETAIL PAGE - END */

	/* REPORT - START */

	static STRING_PATH_CONTROLLER_EBUDGETINGREPORTPAGE: string = "/EBudgetingReportPage";

	static STRING_URL_EBUDGETINGREPORTPAGE_SELECTDIVISIONFOREBUDGETINGAFNMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGREPORTPAGE}/selectDivisionForEBudgetingAFNMaker`;
	static STRING_URL_EBUDGETINGREPORTPAGE_SELECTDIVISIONFOREBUDGETINGAFNCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGREPORTPAGE}/selectDivisionForEBudgetingAFNChecker`;
	static STRING_URL_EBUDGETINGREPORTPAGE_SELECTDIVISIONFOREBUDGETINGAFNSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGREPORTPAGE}/selectDivisionForEBudgetingAFNSigner`;
	static STRING_URL_EBUDGETINGREPORTPAGE_SELECTACCOUNTFOREBUDGETINGAFNMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGREPORTPAGE}/selectAccountForEBudgetingAFNMaker`;
	static STRING_URL_EBUDGETINGREPORTPAGE_SELECTACCOUNTFOREBUDGETINGAFNCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGREPORTPAGE}/selectAccountForEBudgetingAFNChecker`;
	static STRING_URL_EBUDGETINGREPORTPAGE_SELECTACCOUNTFOREBUDGETINGAFNSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGREPORTPAGE}/selectAccountForEBudgetingAFNSigner`;
	static STRING_URL_EBUDGETINGREPORTPAGE_SELECTLISTFOREBUDGETINGAFNMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGREPORTPAGE}/selectListForEBudgetingAFNMaker`;
	static STRING_URL_EBUDGETINGREPORTPAGE_SELECTLISTFOREBUDGETINGAFNCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGREPORTPAGE}/selectListForEBudgetingAFNChecker`;
	static STRING_URL_EBUDGETINGREPORTPAGE_SELECTLISTFOREBUDGETINGAFNSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGREPORTPAGE}/selectListForEBudgetingAFNSigner`;

	static STRING_PATH_CONTROLLER_EBUDGETINGREPORTEXCELPAGE: string = "/EBudgetingReportExcelPage";

	static STRING_URL_EBUDGETINGREPORTEXCELPAGE_SELECTLISTFOREBUDGETINGAFNMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGREPORTEXCELPAGE}/selectListForEBudgetingAFNMaker`;
	static STRING_URL_EBUDGETINGREPORTEXCELPAGE_SELECTLISTFOREBUDGETINGAFNCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGREPORTEXCELPAGE}/selectListForEBudgetingAFNChecker`;
	static STRING_URL_EBUDGETINGREPORTEXCELPAGE_SELECTLISTFOREBUDGETINGAFNSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGREPORTEXCELPAGE}/selectListForEBudgetingAFNSigner`;

	/* REPORT - END */

	/* DASHBOARD - START */

	static STRING_PATH_CONTROLLER_EBUDGETINGDASHBOARDPAGE: string = "/EBudgetingDashboardPage";

	static STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTDIVISIONFOREBUDGETINGAFNMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDASHBOARDPAGE}/selectDivisionForEBudgetingAFNMaker`;
	static STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTDIVISIONFOREBUDGETINGAFNCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDASHBOARDPAGE}/selectDivisionForEBudgetingAFNChecker`;
	static STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTDIVISIONFOREBUDGETINGAFNSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDASHBOARDPAGE}/selectDivisionForEBudgetingAFNSigner`;
	static STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTDETAILFOREBUDGETINGAFNMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDASHBOARDPAGE}/selectDetailForEBudgetingAFNMaker`;
	static STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTDETAILFOREBUDGETINGAFNCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDASHBOARDPAGE}/selectDetailForEBudgetingAFNChecker`;
	static STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTDETAILFOREBUDGETINGAFNSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDASHBOARDPAGE}/selectDetailForEBudgetingAFNSigner`;
	static STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTLISTFOREBUDGETINGAFNMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDASHBOARDPAGE}/selectListForEBudgetingAFNMaker`;
	static STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTLISTFOREBUDGETINGAFNCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDASHBOARDPAGE}/selectListForEBudgetingAFNChecker`;
	static STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTLISTFOREBUDGETINGAFNSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDASHBOARDPAGE}/selectListForEBudgetingAFNSigner`;
	static STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTCHARTFOREBUDGETINGAFNMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDASHBOARDPAGE}/selectChartForEBudgetingAFNMaker`;
	static STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTCHARTFOREBUDGETINGAFNCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDASHBOARDPAGE}/selectChartForEBudgetingAFNChecker`;
	static STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTCHARTFOREBUDGETINGAFNSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDASHBOARDPAGE}/selectChartForEBudgetingAFNSigner`;
	static STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTSUBLISTFOREBUDGETINGAFNMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDASHBOARDPAGE}/selectSubListForEBudgetingAFNMaker`;
	static STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTSUBLISTFOREBUDGETINGAFNCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDASHBOARDPAGE}/selectSubListForEBudgetingAFNChecker`;
	static STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTSUBLISTFOREBUDGETINGAFNSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDASHBOARDPAGE}/selectSubListForEBudgetingAFNSigner`;

	/* DASHBOARD - END */

	/* SUBMISSION LIST - START */

	static STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONLISTPAGE: string = "/EBudgetingSubmissionListPage";

	static STRING_URL_EBUDGETINGSUBMISSIONLISTPAGE_SELECTSUBMITTERLISTFOREBUDGETINGAFNCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONLISTPAGE}/selectSubmitterListForEBudgetingAFNChecker`;
	static STRING_URL_EBUDGETINGSUBMISSIONLISTPAGE_SELECTSUBMITTERLISTFOREBUDGETINGAFNSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONLISTPAGE}/selectSubmitterListForEBudgetingAFNSigner`;

	/* SUBMISSION LIST - END */

	/* SUBMISSION DETAIL - START */

	static STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE: string = "/EBudgetingSubmissionDetailPage";

	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTDETAILFOREBUDGETINGAFNCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/selectDetailForEBudgetingAFNChecker`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTDETAILFOREBUDGETINGAFNSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/selectDetailForEBudgetingAFNSigner`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_DOWNLOADFOREBUDGETINGAFNCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/downloadForEBudgetingAFNChecker`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_DOWNLOADFOREBUDGETINGAFNSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/downloadForEBudgetingAFNSigner`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_REJECTSUBMITTERFOREBUDGETINGAFNCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/rejectSubmitterForEBudgetingAFNChecker`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_REJECTSUBMITTERFOREBUDGETINGAFNSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/rejectSubmitterForEBudgetingAFNSigner`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_APPROVESUBMITTERFOREBUDGETINGAFNCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/approveSubmitterForEBudgetingAFNChecker`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_APPROVESUBMITTERFOREBUDGETINGAFNSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/approveSubmitterForEBudgetingAFNSigner`;

	/* SUBMISSION DETAIL - END */

	//#endregion


	//#region RISK PRODUCT SCORING

	static STRING_PATH_CONTROLLER_RISKCUSTOMERDATA: string = "/RiskCustomerData";

	static STRING_URL_RISKCUSTOMERDATA_SELECTRISKCUSTOMERDATABYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/selectRiskCustomerDataByAttributes`;
	static STRING_URL_RISKCUSTOMERDATA_SELECTRISKCUSTOMERDATABYCIPNUMBER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/selectRiskCustomerDataByCIPNumber`;
	static STRING_URL_RISKCUSTOMERDATA_SELECTRISKBASEDASSESSMENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/selectRiskBasedAssessmentByAttributes`;
	static STRING_URL_RISKCUSTOMERDATA_SELECTRISKTRANSACTIONCUSTOMERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/selectRiskTransactionCustomerByAttributes`;
	static STRING_URL_RISKCUSTOMERDATA_SELECTJOBPROFILECUSTOMERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/selectJobProfileCustomerByAttributes`;
	static STRING_URL_RISKCUSTOMERDATA_SELECTGEOGRAPHICCUSTOMERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/selectGeographicCustomerByAttributes`;
	static STRING_URL_RISKCUSTOMERDATA_SELECTPRODUCTCUSTOMERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/selectProductCustomerByAttributes`;
	static STRING_URL_RISKCUSTOMERDATA_SELECTDISTRIBUTIONNETWORKBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/selectDistributionNetworkByAttributes`;
	static STRING_URL_RISKCUSTOMERDATA_DOWNLOADRISKTRANSACTIONCUSTOMERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/downloadRiskTransactionCustomerByAttributes`;
	static STRING_URL_RISKCUSTOMERDATA_DOWNLOADRISKBASEDASSESSMENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/downloadRiskBasedAssessmentByAttributes`;
	static STRING_URL_RISKCUSTOMERDATA_SENDRISKTRANSACTIONCUSTOMERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/sendRiskTransactionCustomerByAttributes`;
	static STRING_URL_RISKCUSTOMERDATA_SENDRISKBASEDASSESSMENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/sendRiskBasedAssessmentByAttributes`;

	//#endregion


	//#region BRINSAVE

	/* BRINSAVE UPLOAD DOCUMENT - START */

	static STRING_PATH_CONTROLLER_BRINSAVEARCHIVEUPLOADPAGE: string = "/BrinsaveArchiveUploadPage";

	static STRING_URL_BRINSAVEARCHIVEUPLOADPAGE_SELECTKEYWORDFORBRINSAVEWORKINGUNITMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEUPLOADPAGE}/selectKeywordForBrinsaveWorkingUnitMaker`;

	static STRING_URL_BRINSAVEARCHIVEUPLOADPAGE_UPLOADARCHIVEFORBRINSAVEWORKINGUNITMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEUPLOADPAGE}/uploadArchiveForBrinsaveWorkingUnitMaker`;

	/* BRINSAVE UPLOAD DOCUMENT - END */


	/* BRINSAVE ARCHIVE ALLOCATION LIST PAGE - START */

	static STRING_PATH_CONTROLLER_BRINSAVEARCHIVEALLOCATIONLISTPAGE: string = "/BrinsaveArchiveAllocationListPage";

	static STRING_URL_BRINSAVEARCHIVEALLOCATIONLISTPAGE_SELECTKEYWORDFORBRINSAVEWORKINGUNITMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEALLOCATIONLISTPAGE}/selectKeywordForBrinsaveWorkingUnitMaker`;
	static STRING_URL_BRINSAVEARCHIVEALLOCATIONLISTPAGE_SELECTLISTFORBRINSAVEWORKINGUNITMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEALLOCATIONLISTPAGE}/selectListForBrinsaveWorkingUnitMaker`;

	static STRING_URL_BRINSAVEARCHIVEALLOCATIONLISTPAGE_SELECTKEYWORDFORBRINSAVEWORKINGUNITCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEALLOCATIONLISTPAGE}/selectKeywordForBrinsaveWorkingUnitChecker`;
	static STRING_URL_BRINSAVEARCHIVEALLOCATIONLISTPAGE_SELECTLISTFORBRINSAVEWORKINGUNITCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEALLOCATIONLISTPAGE}/selectListForBrinsaveWorkingUnitChecker`;

	static STRING_URL_BRINSAVEARCHIVEALLOCATIONLISTPAGE_SELECTKEYWORDFORBRINSAVEWORKINGUNITSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEALLOCATIONLISTPAGE}/selectKeywordForBrinsaveWorkingUnitSigner`;
	static STRING_URL_BRINSAVEARCHIVEALLOCATIONLISTPAGE_SELECTLISTFORBRINSAVEWORKINGUNITSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEALLOCATIONLISTPAGE}/selectListForBrinsaveWorkingUnitSigner`;

	/* BRINSAVE ARCHIVE ALLOCATION LIST PAGE - END */


	/* BRINSAVE ARCHIVE ALLOCATION DETAIL PAGE - START */

	static STRING_PATH_CONTROLLER_BRINSAVEARCHIVEALLOCATIONDETAILPAGE: string = "/BrinsaveArchiveAllocationDetailPage";

	static STRING_URL_BRINSAVEARCHIVEALLOCATIONDETAILPAGE_SELECTDETAILFORBRINSAVEWORKINGUNITMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEALLOCATIONDETAILPAGE}/selectDetailForBrinsaveWorkingUnitMaker`;
	static STRING_URL_BRINSAVEARCHIVEALLOCATIONDETAILPAGE_SELECTLOCATIONFORBRINSAVEWORKINGUNITMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEALLOCATIONDETAILPAGE}/selectLocationForBrinsaveWorkingUnitMaker`;
	static STRING_URL_BRINSAVEARCHIVEALLOCATIONDETAILPAGE_SELECTSHELFFORBRINSAVEWORKINGUNITMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEALLOCATIONDETAILPAGE}/selectShelfForBrinsaveWorkingUnitMaker`;

	static STRING_URL_BRINSAVEARCHIVEALLOCATIONDETAILPAGE_ALLOCATIONARCHIVEFORBRINSAVEWORKINGUNITMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEALLOCATIONDETAILPAGE}/allocateArchiveForBrinsaveWorkingUnitMaker`;

	static STRING_URL_BRINSAVEARCHIVEALLOCATIONDETAILPAGE_SELECTDETAILFORBRINSAVEWORKINGUNITCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEALLOCATIONDETAILPAGE}/selectDetailForBrinsaveWorkingUnitChecker`;
	static STRING_URL_BRINSAVEARCHIVEALLOCATIONDETAILPAGE_SELECTLOCATIONFORBRINSAVEWORKINGUNITCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEALLOCATIONDETAILPAGE}/selectLocationForBrinsaveWorkingUnitChecker`;
	static STRING_URL_BRINSAVEARCHIVEALLOCATIONDETAILPAGE_SELECTSHELFFORBRINSAVEWORKINGUNITCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEALLOCATIONDETAILPAGE}/selectShelfForBrinsaveWorkingUnitChecker`;

	static STRING_URL_BRINSAVEARCHIVEALLOCATIONDETAILPAGE_ALLOCATIONARCHIVEFORBRINSAVEWORKINGUNITCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEALLOCATIONDETAILPAGE}/allocateArchiveForBrinsaveWorkingUnitChecker`;

	static STRING_URL_BRINSAVEARCHIVEALLOCATIONDETAILPAGE_SELECTDETAILFORBRINSAVEWORKINGUNITSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEALLOCATIONDETAILPAGE}/selectDetailForBrinsaveWorkingUnitSigner`;
	static STRING_URL_BRINSAVEARCHIVEALLOCATIONDETAILPAGE_SELECTLOCATIONFORBRINSAVEWORKINGUNITSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEALLOCATIONDETAILPAGE}/selectLocationForBrinsaveWorkingUnitSigner`;
	static STRING_URL_BRINSAVEARCHIVEALLOCATIONDETAILPAGE_SELECTSHELFFORBRINSAVEWORKINGUNITSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEALLOCATIONDETAILPAGE}/selectShelfForBrinsaveWorkingUnitSigner`;

	static STRING_URL_BRINSAVEARCHIVEALLOCATIONDETAILPAGE_ALLOCATIONARCHIVEFORBRINSAVEWORKINGUNITSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEALLOCATIONDETAILPAGE}/allocateArchiveForBrinsaveWorkingUnitSigner`;

	/* BRINSAVE ARCHIVE ALLOCATION DETAIL PAGE - END */


	/* BRINSAVE ARCHIVE DATA LIST PAGE - START */

	static STRING_PATH_CONTROLLER_BRINSAVEARCHIVELISTPAGE: string = "/BrinsaveArchiveListPage";

	static STRING_URL_BRINSAVEARCHIVELISTPAGE_SELECTKEYWORDFORBRINSAVEWORKINGUNITMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVELISTPAGE}/selectKeywordForBrinsaveWorkingUnitMaker`;
	static STRING_URL_BRINSAVEARCHIVELISTPAGE_SELECTLISTFORBRINSAVEWORKINGUNITMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVELISTPAGE}/selectListForBrinsaveWorkingUnitMaker`;


	static STRING_URL_BRINSAVEARCHIVELISTPAGE_SELECTKEYWORDFORBRINSAVEWORKINGUNITCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVELISTPAGE}/selectKeywordForBrinsaveWorkingUnitChecker`;
	static STRING_URL_BRINSAVEARCHIVELISTPAGE_SELECTLISTFORBRINSAVEWORKINGUNITCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVELISTPAGE}/selectListForBrinsaveWorkingUnitChecker`;
	static STRING_URL_BRINSAVEARCHIVELISTPAGE_SELECTKEYWORDFORBRINSAVEWORKINGUNITSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVELISTPAGE}/selectKeywordForBrinsaveWorkingUnitSigner`;
	static STRING_URL_BRINSAVEARCHIVELISTPAGE_SELECTLISTFORBRINSAVEWORKINGUNITSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVELISTPAGE}/selectListForBrinsaveWorkingUnitSigner`;

	/* BRINSAVE ARCHIVE DATA LIST PAGE - END */


	/* BRINSAVE ARCHIVE DATA DETAIL PAGE - START */

	static STRING_PATH_CONTROLLER_BRINSAVEARCHIVEDETAILPAGE: string = "/BrinsaveArchiveDetailPage";

	static STRING_URL_BRINSAVEARCHIVEDETAILPAGE_SELECTDETAILFORBRINSAVEWORKINGUNITMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEDETAILPAGE}/selectDetailForBrinsaveWorkingUnitMaker`;
	static STRING_URL_BRINSAVEARCHIVEDETAILPAGE_UPDATEDETAILFORBRINSAVEWORKINGUNITMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEDETAILPAGE}/updateDetailForBrinsaveWorkingUnitMaker`;

	static STRING_URL_BRINSAVEARCHIVEDETAILPAGE_SELECTDETAILFORBRINSAVEWORKINGUNITCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEDETAILPAGE}/selectDetailForBrinsaveWorkingUnitChecker`;
	static STRING_URL_BRINSAVEARCHIVEDETAILPAGE_UPDATEDETAILFORBRINSAVEWORKINGUNITCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEDETAILPAGE}/updateDetailForBrinsaveWorkingUnitChecker`;

	static STRING_URL_BRINSAVEARCHIVEDETAILPAGE_SELECTDETAILFORBRINSAVEWORKINGUNITSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEDETAILPAGE}/selectDetailForBrinsaveWorkingUnitSigner`;
	static STRING_URL_BRINSAVEARCHIVEDETAILPAGE_UPDATEDETAILFORBRINSAVEWORKINGUNITSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEDETAILPAGE}/updateDetailForBrinsaveWorkingUnitSigner`;

	static STRING_URL_BRINSAVEARCHIVEDETAILPAGE_DOWNLOADDOCUMENTFORBRINSAVEWORKINGUNITMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEDETAILPAGE}/downloadDocumentForBrinsaveWorkingUnitMaker`;
	static STRING_URL_BRINSAVEARCHIVEDETAILPAGE_DOWNLOADDOCUMENTFORBRINSAVEWORKINGUNITCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEDETAILPAGE}/downloadDocumentForBrinsaveWorkingUnitChecker`;
	static STRING_URL_BRINSAVEARCHIVEDETAILPAGE_DOWNLOADDOCUMENTFORBRINSAVEWORKINGUNITSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEDETAILPAGE}/downloadDocumentForBrinsaveWorkingUnitSigner`;

	/* BRINSAVE ARCHIVE DATA DETAIL PAGE - END */


	/* BRINSAVE ARCHIVE LOAN LIST PAGE - START */

	static STRING_PATH_CONTROLLER_BRINSAVEARCHIVELOANLISTPAGE: string = "/BrinsaveArchiveLoanListPage";

	static STRING_URL_BRINSAVEARCHIVELOANLISTPAGE_SELECTLISTFORBRINSAVEWORKINGUNITMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVELOANLISTPAGE}/selectListForBrinsaveWorkingUnitMaker`;
	static STRING_URL_BRINSAVEARCHIVELOANLISTPAGE_SELECTLISTFORBRINSAVEWORKINGUNITCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVELOANLISTPAGE}/selectListForBrinsaveWorkingUnitChecker`;
	static STRING_URL_BRINSAVEARCHIVELOANLISTPAGE_SELECTLISTFORBRINSAVEWORKINGUNITSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVELOANLISTPAGE}/selectListForBrinsaveWorkingUnitSigner`;

	/* BRINSAVE ARCHIVE LOAN LIST PAGE - END */


	/* BRINSAVE ARCHIVE LOAN DETAIL PAGE - START */

	static STRING_PATH_CONTROLLER_BRINSAVEARCHIVELOANDETAILPAGE: string = "/BrinsaveArchiveLoanDetailPage";

	static STRING_URL_BRINSAVEARCHIVELOANDETAILPAGE_SELECTLOANFORBRINSAVEWORKINGUNITMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVELOANDETAILPAGE}/selectLoanForBrinsaveWorkingUnitMaker`;
	static STRING_URL_BRINSAVEARCHIVELOANDETAILPAGE_SELECTRETURNFORBRINSAVEWORKINGUNITMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVELOANDETAILPAGE}/selectReturnForBrinsaveWorkingUnitMaker`;
	static STRING_URL_BRINSAVEARCHIVELOANDETAILPAGE_SAVELOANFORBRINSAVEWORKINGUNITMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVELOANDETAILPAGE}/saveLoanForBrinsaveWorkingUnitMaker`;
	static STRING_URL_BRINSAVEARCHIVELOANDETAILPAGE_SAVERETURNFORBRINSAVEWORKINGUNITMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVELOANDETAILPAGE}/saveReturnForBrinsaveWorkingUnitMaker`;

	static STRING_URL_BRINSAVEARCHIVELOANDETAILPAGE_SELECTLOANFORBRINSAVEWORKINGUNITCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVELOANDETAILPAGE}/selectLoanForBrinsaveWorkingUnitChecker`;
	static STRING_URL_BRINSAVEARCHIVELOANDETAILPAGE_SELECTRETURNFORBRINSAVEWORKINGUNITCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVELOANDETAILPAGE}/selectReturnForBrinsaveWorkingUnitChecker`;
	static STRING_URL_BRINSAVEARCHIVELOANDETAILPAGE_SAVELOANFORBRINSAVEWORKINGUNITCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVELOANDETAILPAGE}/saveLoanForBrinsaveWorkingUnitChecker`;
	static STRING_URL_BRINSAVEARCHIVELOANDETAILPAGE_SAVERETURNFORBRINSAVEWORKINGUNITCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVELOANDETAILPAGE}/saveReturnForBrinsaveWorkingUnitChecker`;

	static STRING_URL_BRINSAVEARCHIVELOANDETAILPAGE_SELECTLOANFORBRINSAVEWORKINGUNITSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVELOANDETAILPAGE}/selectLoanForBrinsaveWorkingUnitSigner`;
	static STRING_URL_BRINSAVEARCHIVELOANDETAILPAGE_SELECTRETURNFORBRINSAVEWORKINGUNITSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVELOANDETAILPAGE}/selectReturnForBrinsaveWorkingUnitSigner`;
	static STRING_URL_BRINSAVEARCHIVELOANDETAILPAGE_SAVELOANFORBRINSAVEWORKINGUNITSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVELOANDETAILPAGE}/saveLoanForBrinsaveWorkingUnitSigner`;
	static STRING_URL_BRINSAVEARCHIVELOANDETAILPAGE_SAVERETURNFORBRINSAVEWORKINGUNITSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVELOANDETAILPAGE}/saveReturnForBrinsaveWorkingUnitSigner`;

	/* BRINSAVE ARCHIVE LOAN DETAIL PAGE - END */



	/* BRINSAVE ARCHIVE SHELF REALLOCATION LIST PAGE - START */

	static STRING_PATH_CONTROLLER_BRINSAVEARCHIVESHELFREALLOCATIONLISTPAGE: string = "/BrinsaveArchiveShelfReallocationListPage";

	static STRING_URL_BRINSAVEARCHIVESHELFREALLOCATIONLISTPAGE_SELECTLOCATIONFORBRINSAVEWORKINGUNITMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVESHELFREALLOCATIONLISTPAGE}/selectLocationForBrinsaveWorkingUnitMaker`;
	static STRING_URL_BRINSAVEARCHIVESHELFREALLOCATIONLISTPAGE_SELECTLISTFORBRINSAVEWORKINGUNITMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVESHELFREALLOCATIONLISTPAGE}/selectListForBrinsaveWorkingUnitMaker`;

	static STRING_URL_BRINSAVEARCHIVESHELFREALLOCATIONLISTPAGE_SELECTLOCATIONFORBRINSAVEWORKINGUNITCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVESHELFREALLOCATIONLISTPAGE}/selectLocationForBrinsaveWorkingUnitChecker`;
	static STRING_URL_BRINSAVEARCHIVESHELFREALLOCATIONLISTPAGE_SELECTLISTFORBRINSAVEWORKINGUNITCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVESHELFREALLOCATIONLISTPAGE}/selectListForBrinsaveWorkingUnitChecker`;
	static STRING_URL_BRINSAVEARCHIVESHELFREALLOCATIONLISTPAGE_APPROVELISTFORBRINSAVEWORKINGUNITCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVESHELFREALLOCATIONLISTPAGE}/approveListForBrinsaveWorkingUnitChecker`;
	static STRING_URL_BRINSAVEARCHIVESHELFREALLOCATIONLISTPAGE_REJECTLISTFORBRINSAVEWORKINGUNITCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVESHELFREALLOCATIONLISTPAGE}/rejectListForBrinsaveWorkingUnitChecker`;

	static STRING_URL_BRINSAVEARCHIVESHELFREALLOCATIONLISTPAGE_SELECTLOCATIONFORBRINSAVEWORKINGUNITSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVESHELFREALLOCATIONLISTPAGE}/selectLocationForBrinsaveWorkingUnitSigner`;
	static STRING_URL_BRINSAVEARCHIVESHELFREALLOCATIONLISTPAGE_SELECTLISTFORBRINSAVEWORKINGUNITSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVESHELFREALLOCATIONLISTPAGE}/selectListForBrinsaveWorkingUnitSigner`;
	static STRING_URL_BRINSAVEARCHIVESHELFREALLOCATIONLISTPAGE_APPROVELISTFORBRINSAVEWORKINGUNITSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVESHELFREALLOCATIONLISTPAGE}/approveListForBrinsaveWorkingUnitSigner`;
	static STRING_URL_BRINSAVEARCHIVESHELFREALLOCATIONLISTPAGE_REJECTLISTFORBRINSAVEWORKINGUNITSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVESHELFREALLOCATIONLISTPAGE}/rejectListForBrinsaveWorkingUnitSigner`;

	/* BRINSAVE ARCHIVE SHELF REALLOCATION LIST PAGE - END */


	/* BRINSAVE ARCHIVE SHELF REALLOCATION DETAIL PAGE - START */

	static STRING_PATH_CONTROLLER_BRINSAVEARCHIVESHELFREALLOCATIONDETAILPAGE: string = "/BrinsaveArchiveShelfReallocationDetailPage";

	static STRING_URL_BRINSAVEARCHIVESHELFREALLOCATIONDETAILPAGE_SELECTLOCATIONFORBRINSAVEWORKINGUNITMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVESHELFREALLOCATIONDETAILPAGE}/selectLocationForBrinsaveWorkingUnitMaker`;
	static STRING_URL_BRINSAVEARCHIVESHELFREALLOCATIONDETAILPAGE_SELECTNEWLOCATIONFORBRINSAVEWORKINGUNITMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVESHELFREALLOCATIONDETAILPAGE}/selectNewLocationForBrinsaveWorkingUnitMaker`;
	static STRING_URL_BRINSAVEARCHIVESHELFREALLOCATIONDETAILPAGE_SELECTSHELFFORBRINSAVEWORKINGUNITMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVESHELFREALLOCATIONDETAILPAGE}/selectShelfForBrinsaveWorkingUnitMaker`;
	static STRING_URL_BRINSAVEARCHIVESHELFREALLOCATIONDETAILPAGE_SELECTCHECKERFORBRINSAVEWORKINGUNITMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVESHELFREALLOCATIONDETAILPAGE}/selectCheckerForBrinsaveWorkingUnitMaker`;
	static STRING_URL_BRINSAVEARCHIVESHELFREALLOCATIONDETAILPAGE_SELECTSIGNERFORBRINSAVEWORKINGUNITMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVESHELFREALLOCATIONDETAILPAGE}/selectSignerForBrinsaveWorkingUnitMaker`;

	static STRING_URL_BRINSAVEARCHIVESHELFREALLOCATIONDETAILPAGE_SUBMITREALLOCATIONFORBRINSAVEWORKINGUNITMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVESHELFREALLOCATIONDETAILPAGE}/submitReallocationForBrinsaveWorkingUnitMaker`;

	/* BRINSAVE ARCHIVE SHELF REALLOCATION DETAIL PAGE - END */


	/* BRINSAVE ARCHIVE REALLOCATION LIST PAGE - START */

	static STRING_PATH_CONTROLLER_BRINSAVEARCHIVEREALLOCATIONLISTPAGE: string = "/BrinsaveArchiveReallocationListPage";

	static STRING_URL_BRINSAVEARCHIVEREALLOCATIONLISTPAGE_SELECTSHELFFORBRINSAVEWORKINGUNITMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEREALLOCATIONLISTPAGE}/selectShelfForBrinsaveWorkingUnitMaker`;
	static STRING_URL_BRINSAVEARCHIVEREALLOCATIONLISTPAGE_SELECTLISTFORBRINSAVEWORKINGUNITMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEREALLOCATIONLISTPAGE}/selectListForBrinsaveWorkingUnitMaker`;

	static STRING_URL_BRINSAVEARCHIVEREALLOCATIONLISTPAGE_SELECTSHELFFORBRINSAVEWORKINGUNITCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEREALLOCATIONLISTPAGE}/selectShelfForBrinsaveWorkingUnitChecker`;
	static STRING_URL_BRINSAVEARCHIVEREALLOCATIONLISTPAGE_SELECTLISTFORBRINSAVEWORKINGUNITCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEREALLOCATIONLISTPAGE}/selectListForBrinsaveWorkingUnitChecker`;
	static STRING_URL_BRINSAVEARCHIVEREALLOCATIONLISTPAGE_APPROVELISTFORBRINSAVEWORKINGUNITCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEREALLOCATIONLISTPAGE}/approveListForBrinsaveWorkingUnitChecker`;
	static STRING_URL_BRINSAVEARCHIVEREALLOCATIONLISTPAGE_REJECTLISTFORBRINSAVEWORKINGUNITCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEREALLOCATIONLISTPAGE}/rejectListForBrinsaveWorkingUnitChecker`;

	static STRING_URL_BRINSAVEARCHIVEREALLOCATIONLISTPAGE_SELECTSHELFFORBRINSAVEWORKINGUNITSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEREALLOCATIONLISTPAGE}/selectShelfForBrinsaveWorkingUnitSigner`;
	static STRING_URL_BRINSAVEARCHIVEREALLOCATIONLISTPAGE_SELECTLISTFORBRINSAVEWORKINGUNITSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEREALLOCATIONLISTPAGE}/selectListForBrinsaveWorkingUnitSigner`;
	static STRING_URL_BRINSAVEARCHIVEREALLOCATIONLISTPAGE_APPROVELISTFORBRINSAVEWORKINGUNITSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEREALLOCATIONLISTPAGE}/approveListForBrinsaveWorkingUnitSigner`;
	static STRING_URL_BRINSAVEARCHIVEREALLOCATIONLISTPAGE_REJECTLISTFORBRINSAVEWORKINGUNITSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEREALLOCATIONLISTPAGE}/rejectListForBrinsaveWorkingUnitSigner`;

	/* BRINSAVE ARCHIVE REALLOCATION LIST PAGE - END */


	/* BRINSAVE ARCHIVE REALLOCATION DETAIL PAGE - START */

	static STRING_PATH_CONTROLLER_BRINSAVEARCHIVEREALLOCATIONDETAILPAGE: string = "/BrinsaveArchiveReallocationDetailPage";

	static STRING_URL_BRINSAVEARCHIVEREALLOCATIONDETAILPAGE_SELECTDETAILFORBRINSAVEWORKINGUNITMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEREALLOCATIONDETAILPAGE}/selectDetailForBrinsaveWorkingUnitMaker`;
	static STRING_URL_BRINSAVEARCHIVEREALLOCATIONDETAILPAGE_SELECTLOCATIONFORBRINSAVEWORKINGUNITMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEREALLOCATIONDETAILPAGE}/selectLocationForBrinsaveWorkingUnitMaker`;
	static STRING_URL_BRINSAVEARCHIVEREALLOCATIONDETAILPAGE_SELECTARCHIVEFORBRINSAVEWORKINGUNITMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEREALLOCATIONDETAILPAGE}/selectArchiveForBrinsaveWorkingUnitMaker`;
	static STRING_URL_BRINSAVEARCHIVEREALLOCATIONDETAILPAGE_SELECTSHELFFORBRINSAVEWORKINGUNITMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEREALLOCATIONDETAILPAGE}/selectShelfForBrinsaveWorkingUnitMaker`;
	static STRING_URL_BRINSAVEARCHIVEREALLOCATIONDETAILPAGE_SELECTCHECKERFORBRINSAVEWORKINGUNITMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEREALLOCATIONDETAILPAGE}/selectCheckerForBrinsaveWorkingUnitMaker`;
	static STRING_URL_BRINSAVEARCHIVEREALLOCATIONDETAILPAGE_SELECTSIGNERFORBRINSAVEWORKINGUNITMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEREALLOCATIONDETAILPAGE}/selectSignerForBrinsaveWorkingUnitMaker`;

	static STRING_URL_BRINSAVEARCHIVEREALLOCATIONDETAILPAGE_SUBMITREALLOCATIONFORBRINSAVEWORKINGUNITMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEREALLOCATIONDETAILPAGE}/submitReallocationForBrinsaveWorkingUnitMaker`;

	/* BRINSAVE ARCHIVE REALLOCATION DETAIL PAGE - END */


	/* BRINSAVE ARCHIVE REPORT PAGE - START */

	static STRING_PATH_CONTROLLER_BRINSAVEARCHIVEREPORTPAGE: string = "/BrinsaveArchiveReportPage";

	static STRING_URL_BRINSAVEARCHIVEREPORTPAGE_SELECTLOCATIONFORBRINSAVEWORKINGUNITMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEREPORTPAGE}/selectLocationForBrinsaveWorkingUnitMaker`;
	static STRING_URL_BRINSAVEARCHIVEREPORTPAGE_SELECTLOCATIONFORBRINSAVEWORKINGUNITCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEREPORTPAGE}/selectLocationForBrinsaveWorkingUnitChecker`;
	static STRING_URL_BRINSAVEARCHIVEREPORTPAGE_SELECTLOCATIONFORBRINSAVEWORKINGUNITSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEREPORTPAGE}/selectLocationForBrinsaveWorkingUnitSigner`;

	static STRING_URL_BRINSAVEARCHIVEREPORTPAGE_SELECTSHELFFORBRINSAVEWORKINGUNITMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEREPORTPAGE}/selectShelfForBrinsaveWorkingUnitMaker`;
	static STRING_URL_BRINSAVEARCHIVEREPORTPAGE_SELECTSHELFFORBRINSAVEWORKINGUNITCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEREPORTPAGE}/selectShelfForBrinsaveWorkingUnitChecker`;
	static STRING_URL_BRINSAVEARCHIVEREPORTPAGE_SELECTSHELFFORBRINSAVEWORKINGUNITSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEREPORTPAGE}/selectShelfForBrinsaveWorkingUnitSigner`;

	static STRING_URL_BRINSAVEARCHIVEREPORTPAGE_SELECTREPORTFORBRINSAVEWORKINGUNITMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEREPORTPAGE}/selectReportForBrinsaveWorkingUnitMaker`;
	static STRING_URL_BRINSAVEARCHIVEREPORTPAGE_SELECTREPORTFORBRINSAVEWORKINGUNITCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEREPORTPAGE}/selectReportForBrinsaveWorkingUnitChecker`;
	static STRING_URL_BRINSAVEARCHIVEREPORTPAGE_SELECTREPORTFORBRINSAVEWORKINGUNITSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEREPORTPAGE}/selectReportForBrinsaveWorkingUnitSigner`;

	/* BRINSAVE ARCHIVE REALLOCATION DETAIL PAGE - END */

	static STRING_PATH_CONTROLLER_BRINSAVEARCHIVEDASHBOARDPAGE: string = "/BrinsaveArchiveDashboardPage";

	static STRING_URL_BRINSAVEARCHIVEDASHBOARDPAGE_SELECTDASHBOARDFORBRINSAVEWORKINGUNITMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEDASHBOARDPAGE}/selectDashboardForBrinsaveWorkingUnitMaker`;
	static STRING_URL_BRINSAVEARCHIVEDASHBOARDPAGE_SELECTDASHBOARDFORBRINSAVEWORKINGUNITCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEDASHBOARDPAGE}/selectDashboardForBrinsaveWorkingUnitChecker`;
	static STRING_URL_BRINSAVEARCHIVEDASHBOARDPAGE_SELECTDASHBOARDFORBRINSAVEWORKINGUNITSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSAVEARCHIVEDASHBOARDPAGE}/selectDashboardForBrinsaveWorkingUnitSigner`;


	/* BRINSAVE ARCHIVE REALLOCATION DETAIL PAGE - START */

	/* BRINSAVE ARCHIVE REALLOCATION DETAIL PAGE - END */


	//#endregion


	//#region EARLY WARNING SIGNAL RISK

	static STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALRISK: string = "/EarlyWarningSignalRisk";

	static STRING_URL_EARLYWARNINGSIGNALRISK_SELECTEARLYWARNINGSIGNALRISKBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALRISK}/selectEarlyWarningSignalRiskByAttributes`;
	static STRING_URL_EARLYWARNINGSIGNALRISK_SELECTEARLYWARNINGSIGNALRISKBYATTRIBUTESFORDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALRISK}/selectEarlyWarningSignalRiskByAttributesForDropdown`;

	//#endregion


	//#region PRODUCT GENERAL

	static STRING_PATH_CONTROLLER_PRODUCTGENERAL: string = "/ProductGeneral";

	static STRING_URL_PRODUCTGENERAL_SELECTPRODUCTFORPNMMONITORINGBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTGENERAL}/selectProductForPNMMonitoringByAttributes`;
	static STRING_URL_PRODUCTGENERAL_SELECTPRODUCTFORPNMMONITORINGBYATTRIBUTESFOREXCEL: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTGENERAL}/selectProductForPNMMonitoringByAttributesForExcel`;
	static STRING_URL_PRODUCTGENERAL_DOWNLOADPRODUCTFORPNMMONITORINGBYTOKEN: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTGENERAL}/downloadProductForPNMMonitoringByToken`;

	static STRING_URL_PRODUCTGENERAL_SELECTPRODUCTFORPEGADAIANMONITORINGBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTGENERAL}/selectProductForPegadaianMonitoringByAttributes`;
	static STRING_URL_PRODUCTGENERAL_SELECTPRODUCTFORPEGADAIANMONITORINGBYATTRIBUTESFOREXCEL: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTGENERAL}/selectProductForPegadaianMonitoringByAttributesForExcel`;
	static STRING_URL_PRODUCTGENERAL_SELECTSUMMARYPRODUCTFORPEGADAIANMONITORINGBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTGENERAL}/selectSummaryProductForPegadaianMonitoringByAttributes`;
	static STRING_URL_PRODUCTGENERAL_DOWNLOADIKHTISARPERTANGGUNGANFORPEGADAIANBYTOKEN: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTGENERAL}/downloadIkhtisarPertanggunganForPegadaianByToken`;
	//#region BRINSPRO

	static STRING_PATH_CONTROLLERPROJECTMANAGEMENT: string = "/ProjectManagement";

	static STRING_URL_SELECTPROJECTMANAGEMENTLISTFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENT}/selectProjectManagementListForMakerForDivision`;
	static STRING_URL_SELECTPROJECTMANAGEMENTLBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENT}/selectProjectManagementByToken`;

	static STRING_URL_INSERTPROJECTMANAGEMENTFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENT}/insertProjectManagementForMaker`;
	static STRING_URL_UPDATEPROJECTMANAGEMENTFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENT}/updateProjectManagementForMaker`;

	static STRING_PATH_CONTROLLERPROJECTMANAGEMENTAPPROVAL: string = "/ProjectManagementApproval";

	static STRING_URL_SELECTPROJECTMANAGEMENTLISTFORCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENTAPPROVAL}/selectProjectManagementListForChecker`;
	static STRING_URL_SELECTPROJECTMANAGEMENTLISTFORSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENTAPPROVAL}/selectProjectManagementListForSigner`;
	static STRING_URL_APPROVEPROJECTMANAGEMENTFORCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENTAPPROVAL}/approveProjectManagementForChecker`;
	static STRING_URL_REVICEPROJECTMANAGEMENTFORCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENTAPPROVAL}/reviseProjectManagementForChecker`;
	static STRING_URL_APPROVEPROJECTMANAGEMENTFORSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENTAPPROVAL}/approveProjectManagementForSigner`;
	static STRING_URL_REVICEPROJECTMANAGEMENTFORSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENTAPPROVAL}/reviseProjectManagementForSigner`;
	static STRING_URL_SELECTPROJECTMANAGEMENTAPPROVALBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENTAPPROVAL}/selectProjectManagementApprovalByToken`;

	static STRING_PATH_CONTROLLERPROJECTMANAGEMENTDOCUMENT: string = "/ProjectManagementDocument";

	static STRING_URL_SELECTPROJECTMANAGEMENTDOCUMENT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENTDOCUMENT}/selectProjectManagementDocumentList`;
	static STRING_URL_SELECTPROJECTMANAGEMENTDOCUMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENTDOCUMENT}/selectProjectManagementDocumentByToken`;
	static STRING_URL_INSERTPROJECTMANAGEMENTDOCUMENT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENTDOCUMENT}/insertProjectManagementDocument`;
	static STRING_URL_UPDATEPROJECTMANAGEMENTDOCUMENT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENTDOCUMENT}/updateProjectManagementDocument`;
	static STRING_URL_DOWNLOADPROJECTMANAGEMENTDOCUMENTDETAILBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENTDOCUMENT}/downloadProjectManagementDocumentDetailByToken`;

	static STRING_PATH_CONTROLLERPROJECTMANAGEMENTDASHBOARD: string = "/ProjectManagementDashboard";

	static STRING_URL_SELECTPROJECTMANAGEMENTDASHBOARD: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENTDASHBOARD}/selectProjectManagementDashboard`;
	//#region EARLY WARNING SIGNAL PARAMETER

	static STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALPARAMETER: string = "/EarlyWarningSignalParameter";

	static STRING_URL_EARLYWARNINGSIGNALPARAMETER_SELECTEARLYWARNINGSIGNALPARAMETERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALPARAMETER}/selectEarlyWarningSignalParameterByAttributes`;
	static STRING_URL_EARLYWARNINGSIGNALPARAMETER_SELECTEARLYWARNINGSIGNALPARAMETERBYATTRIBUTESFORDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALPARAMETER}/selectEarlyWarningSignalParameterByAttributesForDropdown`;
	static STRING_URL_EARLYWARNINGSIGNALPARAMETER_SELECTEARLYWARNINGSIGNALPARAMETERBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALPARAMETER}/selectEarlyWarningSignalParameterByToken`;

	//#endregion


	//#region EARLY WARNING SIGNAL LIMIT

	static STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALLIMIT: string = "/EarlyWarningSignalLimit";

	static STRING_URL_EARLYWARNINGSIGNALLIMIT_SELECTEARLYWARNINGSIGNALLIMITBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALLIMIT}/selectEarlyWarningSignalLimitByAttributes`;
	static STRING_URL_EARLYWARNINGSIGNALLIMIT_SELECTEARLYWARNINGSIGNALLIMITBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALLIMIT}/selectEarlyWarningSignalLimitByToken`;
	static STRING_URL_EARLYWARNINGSIGNALLIMIT_INSERTEARLYWARNINGSIGNALLIMIT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALLIMIT}/insertEarlyWarningSignalLimit`;
	static STRING_URL_EARLYWARNINGSIGNALLIMIT_REVISEEARLYWARNINGSIGNALLIMITBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALLIMIT}/reviseEarlyWarningSignalLimitByToken`;

	//#endregion


	//#region EARLY WARNING FOLLOW UP PLAN

	static STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALFOLLOWUPPLAN: string = "/EarlyWarningSignalFollowUpPlan";

	static STRING_URL_EARLYWARNINGSIGNALFOLLOWUPPLAN_SELECTEARLYWARNINGSIGNALFOLLOWUPPLANBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALFOLLOWUPPLAN}/selectEarlyWarningSignalFollowUpPlanByAttributes`;
	static STRING_URL_EARLYWARNINGSIGNALFOLLOWUPPLAN_SELECTEARLYWARNINGSIGNALFOLLOWUPPLANBYATTRIBUTESFORAPPROVAL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALFOLLOWUPPLAN}/selectEarlyWarningSignalFollowUpPlanByAttributesForApproval`;
	static STRING_URL_EARLYWARNINGSIGNALFOLLOWUPPLAN_SELECTEARLYWARNINGSIGNALFOLLOWUPPLANBYATTRIBUTESFORWORKINGUNITAPPROVAL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALFOLLOWUPPLAN}/selectEarlyWarningSignalFollowUpPlanByAttributesForWorkingUnitApproval`;
	static STRING_URL_EARLYWARNINGSIGNALFOLLOWUPPLAN_SELECTEARLYWARNINGSIGNALFOLLOWUPPLANBYATTRIBUTESFORWORKINGUNIT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALFOLLOWUPPLAN}/selectEarlyWarningSignalFollowUpPlanByAttributesForWorkingUnit`;
	static STRING_URL_EARLYWARNINGSIGNALFOLLOWUPPLAN_SELECTEARLYWARNINGSIGNALFOLLOWUPPLANBYATTRIBUTESFORDASHBOARD: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALFOLLOWUPPLAN}/selectEarlyWarningSignalFollowUpPlanByAttributesForDashboard`;
	static STRING_URL_EARLYWARNINGSIGNALFOLLOWUPPLAN_SELECTEARLYWARNINGSIGNALFOLLOWUPPLANBYATTRIBUTESFORMONITORING: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALFOLLOWUPPLAN}/selectEarlyWarningSignalFollowUpPlanByAttributesForMonitoring`;
	static STRING_URL_EARLYWARNINGSIGNALFOLLOWUPPLAN_SELECTEARLYWARNINGSIGNALFOLLOWUPPLANBYATTRIBUTESFORMONITORINGFORWORKINGUNIT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALFOLLOWUPPLAN}/selectEarlyWarningSignalFollowUpPlanByAttributesForMonitoringForWorkingUnit`;
	static STRING_URL_EARLYWARNINGSIGNALFOLLOWUPPLAN_SELECTEARLYWARNINGSIGNALFOLLOWUPPLANBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALFOLLOWUPPLAN}/selectEarlyWarningSignalFollowUpPlanByToken`;
	static STRING_URL_EARLYWARNINGSIGNALFOLLOWUPPLAN_SUBMITEARLYWARNINGSIGNALFOLLOWUPPLANBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALFOLLOWUPPLAN}/submitEarlyWarningSignalFollowUpPlanByToken`;

	//#endregion


	//#region EARLY WARNING FOLLOW UP PLAN ITEM

	static STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALFOLLOWUPPLANITEM: string = "/EarlyWarningSignalFollowUpPlanItem";

	static STRING_URL_EARLYWARNINGSIGNALFOLLOWUPPLANITEM_SELECTEARLYWARNINGSIGNALFOLLOWUPPLANITEMBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALFOLLOWUPPLANITEM}/selectEarlyWarningSignalFollowUpPlanItemByAttributes`;
	static STRING_URL_EARLYWARNINGSIGNALFOLLOWUPPLANITEM_INSERTEARLYWARNINGSIGNALFOLLOWUPPLANITEM: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALFOLLOWUPPLANITEM}/insertEarlyWarningSignalFollowUpPlanItem`;
	static STRING_URL_EARLYWARNINGSIGNALFOLLOWUPPLANITEM_UPDATEEARLYWARNINGSIGNALFOLLOWUPPLANITEMINACTIVEBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALFOLLOWUPPLANITEM}/updateEarlyWarningSignalFollowUpPlanItemInactiveByToken`;

	//#endregion


	//#region EARLY WARNING FOLLOW UP PLAN APPROVAL

	static STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALFOLLOWUPPLANAPPROVAL: string = "/EarlyWarningSignalFollowUpPlanApproval";

	static STRING_URL_EARLYWARNINGSIGNALFOLLOWUPPLANAPPROVAL_SELECTEARLYWARNINGSIGNALFOLLOWUPPLANAPPROVALBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALFOLLOWUPPLANAPPROVAL}/selectEarlyWarningSignalFollowUpPlanApprovalByAttributes`;
	static STRING_URL_EARLYWARNINGSIGNALFOLLOWUPPLANAPPROVAL_APPROVEEARLYWARNINGSIGNALFOLLOWUPPLANAPPROVALBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALFOLLOWUPPLANAPPROVAL}/approveEarlyWarningSignalFollowUpPlanApprovalByToken`;
	static STRING_URL_EARLYWARNINGSIGNALFOLLOWUPPLANAPPROVAL_REJECTEARLYWARNINGSIGNALFOLLOWUPPLANAPPROVALBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALFOLLOWUPPLANAPPROVAL}/rejectEarlyWarningSignalFollowUpPlanApprovalByToken`;

	//#endregion


	//#region EARLY WARNING FOLLOW UP PLAN SUB ISSUE

	static STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALFOLLOWUPPLANSUBISSUE: string = "/EarlyWarningSignalFollowUpPlanSubIssue";

	static STRING_URL_EARLYWARNINGSIGNALFOLLOWUPPLANSUBISSUE_SELECTEARLYWARNINGSIGNALFOLLOWUPPLANSUBISSUEBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALFOLLOWUPPLANSUBISSUE}/selectEarlyWarningSignalFollowUpPlanSubIssueByAttributes`;

	//#endregion


	//#region EARLY WARNING SIGNAL ISSUE

	static STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALISSUE: string = "/EarlyWarningSignalIssue";

	static STRING_URL_EARLYWARNINGSIGNALISSUE_SELECTEARLYWARNINGSIGNALISSUEBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALISSUE}/selectEarlyWarningSignalIssueByToken`;
	static STRING_URL_EARLYWARNINGSIGNALISSUE_SELECTEARLYWARNINGSIGNALISSUEBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALISSUE}/selectEarlyWarningSignalIssueByAttributes`;
	static STRING_URL_EARLYWARNINGSIGNALISSUE_SELECTEARLYWARNINGSIGNALISSUEBYATTRIBUTESFORDASHBOARD: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALISSUE}/selectEarlyWarningSignalIssueByAttributesForDashboard`;

	//#endregion


	//#region EARLY WARNING SIGNAL SUB ISSUE

	static STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALSUBISSUE: string = "/EarlyWarningSignalSubIssue";

	static STRING_URL_EARLYWARNINGSIGNALSUBISSUE_SELECTEARLYWARNINGSIGNALSUBISSUEBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALSUBISSUE}/selectEarlyWarningSignalSubIssueByAttributes`;

	//#endregion


	//#region USER

	static STRING_PATH_CONTROLLER_USER: string = "/User";

	static STRING_URL_USER_SELECTUSERBYATTRIBUTESFORSIGNEREARLYWARNINGSIGNAL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_USER}/selectUserByAttributesForSignerEarlyWarningSignal`;

	//#endregion


	//#region Financial Report

	static STRING_PATH_CONTROLLER_FINANCIALREPORT: string = "/FinancialReport";

	static STRING_URL_FINANCIALREPORT_SELECTFINANCIALREPORTBYATTRIBUTESFORFORACCOUNTINGANDFINANCE: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FINANCIALREPORT}/selectFinancialReportByAttributesForAccountingAndFinance`;
	static STRING_URL_FINANCIALREPORT_SELECTFINANCIALREPORTBYTOKENFORFORACCOUNTINGANDFINANCE: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FINANCIALREPORT}/selectFinancialReportByTokenForAccountingAndFinance`;
	static STRING_URL_FINANCIALREPORT_INSERTFINANCIALREPORTFORFORACCOUNTINGANDFINANCE: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FINANCIALREPORT}/insertFinancialReportForAccountingAndFinance`;
	static STRING_URL_FINANCIALREPORT_UPDATEFINANCIALREPORTFORFORACCOUNTINGANDFINANCE: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FINANCIALREPORT}/updateFinancialReportForAccountingAndFinance`;
	static STRING_URL_FINANCIALREPORT_DELETEFINANCIALREPORTARTICLEBYTOKENFORFORACCOUNTINGANDFINANCE: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FINANCIALREPORT}/deleteFinancialReportByTokenForAccountingAndFinance`;

	//#endregion
}

//#endregion