//#region IMPORT

import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { ENUM_LETTER_ROLE, ENUM_USERGROUPID } from "src/app/constants/enum.constant";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { AccountingAndFinanceModel } from "src/app/models/accountingandmodel.model";
import { UserSessionModel } from "src/app/models/usersession.model";
import { AccountingAndFinanceService } from "src/app/services/accountingandfinance.service";
import { PwaService } from "src/app/services/pwa.service";
import { SessionService } from "src/app/services/session.service";
import { NumberConstant } from "src/app/constants/number.constant";
import { TableModel } from "src/app/models/bases/table.model";
import { ResponseModel } from "src/app/models/response.model";
import { AnomalyNotificationService } from "src/app/services/anomalynotification.service";
import { BaseAuthourizeComponent } from "../bases/baseauthourize.component";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-home",
		templateUrl: "./home.component.html",
		styleUrls: ["./home.component.sass"]
	}
)

//#endregion


//#region CLASS

export class HomeComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	public _booleanNavigation: boolean | null;
	public _booleanNavigationItem: boolean | null;
	public _booleanNavigationVoucher: boolean | null;
	public _booleanNavigationEmaterai: boolean | null;
	public _booleanNavigationEbudgeting: boolean | null;
	public _booleanNavigationReport: boolean | null;
	public _booleanNavigationReimbursement: boolean | null;
	public _booleanNavigationApproval: boolean | null;
	public _booleanNavigationItemRCSA: boolean | null;
	public _booleanNavigationItemDocumentRequest: boolean | null;
	public _booleanNavigationPaymentSubmission: boolean | null;
	public _booleanNavigationDashboard: boolean | null;
	public _booleanNavigationPaymentRequest: boolean | null;
	public _booleanNavigationItemRAS: boolean | null;
	public _booleanNavigationPROTECT: boolean | null;
	public _booleanNavigationRealization: boolean | null;
	public _booleanNavigationItemGCGOnline: boolean | null;
	public _booleanNavigationCodeOfEthics: boolean | null;
	public _booleanNavigationAnnualDisclosure: boolean | null;
	public _booleanNavigationApuPPT: boolean | null;
	public _booleanNavigationApprovalEBudgetingKuota: boolean | null;
	public _booleanNavigationBRINSAVE: boolean | null;
	public _booleanNavigationManagement: boolean | null;
	public _booleanNavigationReallocation: boolean | null;
	public _booleanNavigationTransactionMonitoring: boolean | null;
	public _booleanNavigationBrinspro: boolean | null;
	public _booleanNavigationEWS: boolean | null;
	public _booleanNavigationEWSFollowUp: boolean | null;
	public _booleanNavigationCMS: boolean | null;
	public _booleanNavigationFinancialReport: boolean | null;

	public _enumLetterRole = ENUM_LETTER_ROLE;
	public _enumUserGroupID = ENUM_USERGROUPID;

	public _numberConstant = NumberConstant;
	public _numberAnomalyNotification: number;
	public _stringConstant = StringConstant;
	public _stringHeadOffice = StringConstant.STRING_FIELD_BRANCHCODE_HEADOFFICE;

	//#endregion


	//#region CONSTRUCTOR

	constructor(private _serviceAccountingAndFinance: AccountingAndFinanceService, serviceSession: SessionService, router: Router, public _servicePWA: PwaService, private _serviceAnomalyNotification: AnomalyNotificationService)
	{
		super(serviceSession, router);

		this._booleanNavigation = null;
		this._booleanNavigationItem = null;
		this._booleanNavigationVoucher = null;
		this._booleanNavigationReport = null;
		this._booleanNavigationEmaterai = null;
		this._booleanNavigationEbudgeting = null;
		this._booleanNavigationReimbursement = null;
		this._booleanNavigationApproval = null;
		this._booleanNavigationItemRCSA = null;
		this._booleanNavigationItemDocumentRequest = null;
		this._booleanNavigationPaymentSubmission = null;
		this._booleanNavigationDashboard = null;
		this._booleanNavigationPaymentRequest = null;
		this._booleanNavigationItemRAS = null;
		this._booleanNavigationPROTECT = false;
		this._booleanNavigationRealization = null;
		this._numberAnomalyNotification = 0;
		this._booleanNavigationItemGCGOnline = null;
		this._booleanNavigationCodeOfEthics = null;
		this._booleanNavigationAnnualDisclosure = null;
		this._booleanNavigationApuPPT = null;
		this._booleanNavigationApprovalEBudgetingKuota = null;
		this._booleanNavigationBRINSAVE = null;
		this._booleanNavigationManagement = null;
		this._booleanNavigationReallocation = null;
		this._booleanNavigationTransactionMonitoring = null;
		this._booleanNavigationBrinspro = null;
		this._booleanNavigationEWS = null;
		this._booleanNavigationEWSFollowUp = null;
		this._booleanNavigationCMS = null;
		this._booleanNavigationFinancialReport = null;
	}

	//#endregion


	//#region NAVIGATION

	ngOnInit(): void
	{
		console.log(this._modelUserSignIn);
	}

	//#endregion


	//#region FUNCTION

	toggleNavigation(): void
	{
		this._booleanNavigation = !this._booleanNavigation;
		this._booleanNavigationItem = false;
		this._booleanNavigationItemDocumentRequest = false;
		this._booleanNavigationPaymentSubmission = false;
		this._booleanNavigationDashboard = false;
		this._booleanNavigationPaymentRequest = false;
		this._booleanNavigationEbudgeting = false;
		this._booleanNavigationItemRAS = false;
		this._booleanNavigationPROTECT = false;
		this._booleanNavigationRealization = false;
		this._booleanNavigationEmaterai = false;
		this._booleanNavigationReport = false;
		this._booleanNavigationItemGCGOnline = false;
		this._booleanNavigationCodeOfEthics = false;
		this._booleanNavigationAnnualDisclosure = false;
		this._booleanNavigationApuPPT = false;
		this._booleanNavigationBRINSAVE = false;
		this._booleanNavigationManagement = false;
		this._booleanNavigationReallocation = false;
		this._booleanNavigationEWS = false;
		this._booleanNavigationEWSFollowUp = false;
	}

	//#endregion


	//#region WEB SERVICE

	callSignOut(): void
	{
		this._functionUserInterface.setLoadingProgress(1);
		const componentCurrent: HomeComponent = this;
		const modelUserSession: UserSessionModel | null = this._serviceSession.getUserSession();

		if (modelUserSession != null)
		{
			this._serviceAccountingAndFinance.signOut
			(
				{
					success(modelResponse): void
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
						{
							if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
							{
								componentCurrent._functionUserInterface.updateLoadingProgress();
								componentCurrent.signOut();
							}
							else
							{
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
							}
						});
					},
					fail(modelResponse): void
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSignOut(); });
					},
					signOut(modelResponse): void
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
					}
				}, new AccountingAndFinanceModel()
			);
		}
		else
		{
			componentCurrent.signOut();
		}
	}

	public callSelectAnomalyNotificationCount(componentCurrent: this): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(this._numberConstant.NUMBER_COMPARE_VALUE_ONE);

		const modelTable: TableModel = new TableModel();

		this._serviceAnomalyNotification.selectAnomalyNotificationCount
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if(modelResponse.Data != null || modelResponse.Data !== undefined)
					{
						componentCurrent._numberAnomalyNotification = Number(modelResponse.Data);
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
				}
				else
				{
					componentCurrent._functionUserInterface.updateLoadingProgress();
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectAnomalyNotificationCount(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, modelTable);
	}

	//#endregion


	//#region NAVIGATION

	goToDashboardEmaterai(): void
	{
		this._router.navigate(["home", "dashboard", "ematerai"]);
		this.toggleNavigation();
	}

	goToDashboardEbudgeting(): void
	{
		this._router.navigate(["home", "dashboard", "ebudgeting"]);
		this.toggleNavigation();
	}

	goToAbout(): void
	{
		this._router.navigate(["home", "about"]);
		this.toggleNavigation();
	}

	goToDeclaration(): void
	{
		this._router.navigate(["home", "declaration", "list"]);
		this.toggleNavigation();
	}

	goToSubmission(): void
	{
		this._router.navigate(["home", "submission", "list"]);
		this.toggleNavigation();
	}

	goToEmaterai(): void
	{
		this._router.navigate(["home", "ematerai", "list"]);
		this.toggleNavigation();
	}

	goToEmateraiForMaker(): void
	{
		this._router.navigate(["home", "ematerai", "maker", "list"]);
		this.toggleNavigation();
	}

	goToEmateraiForChecker(): void
	{
		this._router.navigate(["home", "ematerai", "checker", "list"]);
		this.toggleNavigation();
	}

	goToEmateraiForSigner(): void
	{
		this._router.navigate(["home", "ematerai", "signer", "list"]);
		this.toggleNavigation();
	}

	goToEbudgetingMaster(): void
	{
		this._router.navigate(["home", "ebudgeting", "master", "list"]);
		this.toggleNavigation();
	}

	goToEbudgetingApproval(): void
	{
		this._router.navigate(["home", "ebudgeting", "approval", "list"]);
		this.toggleNavigation();
	}

	goToPDF(): void
	{
		this._router.navigate(["home", "ematerai", "pdf"]);
		this.toggleNavigation();
	}

	goToTicketReceived(): void
	{
		this._router.navigate(["home", "ticket", "received", "list"]);
		this.toggleNavigation();
	}

	goToTicketSent(): void
	{
		this._router.navigate(["home", "ticket", "sent", "list"]);
		this.toggleNavigation();
	}

	goToTicketReport(): void
	{
		this._router.navigate(["home", "ticket", "report"]);
		this.toggleNavigation();
	}

	goToTicketSatisfactionReport(): void
	{
		this._router.navigate(["home", "ticket", "satisfactionreport"]);
		this.toggleNavigation();
	}

	goToRequestPaymentMaker(): void
	{
		this._router.navigate(["home", "requestpayment", "list"]);
		this.toggleNavigation();
	}

	goToVoucherMakerPayment(): void
	{
		this._router.navigate(["home", "voucherpayment", "maker", "list"]);
		this.toggleNavigation();
	}

	goToVoucherCheckerPayment(): void
	{
		this._router.navigate(["home", "voucherpayment", "checker", "list"]);
		this.toggleNavigation();
	}

	goToVoucherSignerPayment(): void
	{
		this._router.navigate(["home", "voucherpayment", "signer", "list"]);
		this.toggleNavigation();
	}

	goToCashflow(): void
	{
		this._router.navigate(["home", "report", "cashflow"]);
		this.toggleNavigation();
	}

	goToReportEmaterai(): void
	{
		this._router.navigate(["home", "report", "ematerai"]);
		this.toggleNavigation();
	}

	goToReportEbudgeting(): void
	{
		this._router.navigate(["home", "report", "ebudgeting"]);
		this.toggleNavigation();
	}

	goToApprovalEBudgetingKuotaCheckerSigner(): void
	{
		this._router.navigate(["home", "ebudgeting", "approval", "quota", "list"]);
		this.toggleNavigation();
	}

	goToReimbursementGeneralChecker(): void
	{
		this._router.navigate(["home", "reimbursement" ,"approval", "checker", "list"]);
		this.toggleNavigation();
	}

	goToReimbursementGeneralSigner(): void
	{
		this._router.navigate(["home", "reimbursement", "approval", "signer", "list"]);
		this.toggleNavigation();
	}

	goToAssessment(): void
	{
		this._router.navigate(["home", "rcsa", "assessment", "list"]);
		this.toggleNavigation();
	}

	goToApproval(): void
	{
		this._router.navigate(["home", "rcsa", "approval", "list"]);
		this.toggleNavigation();
	}

	goToRCSAReport(): void
	{
		this._router.navigate(["home", "rcsa", "report", "list"]);
		this.toggleNavigation();
	}

	goToRiskIssueRequest(): void
	{
		this._router.navigate(["home", "rcsa", "riskissuerequest", "list"]);
		this.toggleNavigation();
	}

	goToRASRealization(): void
	{
		this._router.navigate(["home", "ras", "realization", "list"]);
		this.toggleNavigation();
	}

	goToRASFollowUpPlan(): void
	{
		this._router.navigate(["home", "ras", "followupplan", "list"]);
		this.toggleNavigation();
	}

	goToRASRealizationApproval(): void
	{
		this._router.navigate(["home", "ras", "realization", "approval", "list"]);
		this.toggleNavigation();
	}

	goToRASMonitoring(): void
	{
		this._router.navigate(["home", "ras", "realization", "monitoring", "list"]);
		this.toggleNavigation();
	}

	goToRASDashboard(): void
	{
		this._router.navigate(["home", "ras", "dashboard", "list"]);
		this.toggleNavigation();
	}

	goToDigitalizationChecker(): void
	{
		this._router.navigate(["home", "digitalization", "checker", "list"]);
		this.toggleNavigation();
	}

	goToDigitalizationMaker(): void
	{
		this._router.navigate(["home", "digitalization", "maker", "list"]);
		this.toggleNavigation();
	}

	goToDigitalizationSigner(): void
	{
		this._router.navigate(["home", "digitalization", "signer", "list"]);
		this.toggleNavigation();
	}

	goToPaymentRequestMaker(): void
	{
		this._router.navigate(["home", "paymentrequest", "maker", "list"]);
		this.toggleNavigation();
	}

	goToPaymentRequestChecker(): void
	{
		this._router.navigate(["home", "paymentrequest", "checker", "list"]);
		this.toggleNavigation();
	}

	goToPaymentRequestSigner(): void
	{
		this._router.navigate(["home", "paymentrequest", "signer", "list"]);
		this.toggleNavigation();
	}

	goToPaymentSubmission(): void
	{
		this._router.navigate(["home", "paymentsubmission", "list"]);
		this.toggleNavigation();
	}

	goToRejectedPaymentSubmission(): void
	{
		this._router.navigate(["home", "paymentsubmission", "rejected", "list"]);
		this.toggleNavigation();
	}

	goToAnomaly(): void
	{
		this._router.navigate(["home", "protect", "anomaly", "list"]);
		this.toggleNavigation();
	}

	goToAnomalyNotification(): void
	{
		this._router.navigate(["home", "protect", "anomaly", "notification"]);
		this.toggleNavigation();
	}

	goToSalvageList(): void
	{
		this._router.navigate(["home", "salvage", "list"]);
		this.toggleNavigation();
	}

	goToReportMonitoringDigitalChannel(): void
	{
		this._router.navigate(["home", "reportmonitoringdigitalchannel"]);
		this.toggleNavigation();
	}

	goToGCGOnlineProfile(): void
	{
		this._router.navigate(["home", "gcgonline", "profile"]);
		this.toggleNavigation();
	}

	goToGCGOnlineGratification(): void
	{
		this._router.navigate(["home", "gcgonline", "gratification", "list"]);
		this.toggleNavigation();
	}

	goToGCGOnlineIntegrityPact(): void
	{
		this._router.navigate(["home", "gcgonline", "integritypact", "list"]);
		this.toggleNavigation();
	}

	goToGCGOnlineCodeOfEthics(): void
	{
		this._router.navigate(["home", "gcgonline", "codeofethics", "list"]);
		this.toggleNavigation();
	}

	goToGCGOnlineCodeOfEthicsApproval(): void
	{
		if(this._modelUserSignIn.checkGroupAccessGCGRole(StringConstant.STRING_ACCESS_USER_GCGCHECKER))
		{
			this._router.navigate(["home", "gcgonline", "codeofethics", "checker", "list"]);
			this.toggleNavigation();
		}
		else if(this._modelUserSignIn.checkGroupAccessGCGRole(StringConstant.STRING_ACCESS_USER_GCGSIGNER))
		{
			this._router.navigate(["home", "gcgonline", "codeofethics", "signer", "list"]);
			this.toggleNavigation();
		}
		else
		{
		}
	}

	goToGCGOnlineAnnualDisclosure(): void
	{
		this._router.navigate(["home", "gcgonline", "annualdisclosure", "list"]);
		this.toggleNavigation();
	}

	goToGCGOnlineAnnualDisclosureApproval(): void
	{
		if(this._modelUserSignIn.checkGroupAccessGCGRole(StringConstant.STRING_ACCESS_USER_GCGCHECKER))
		{
			this._router.navigate(["home", "gcgonline", "annualdisclosure", "checker", "list"]);
			this.toggleNavigation();
		}
		else if(this._modelUserSignIn.checkGroupAccessGCGRole(StringConstant.STRING_ACCESS_USER_GCGSIGNER))
		{
			this._router.navigate(["home", "gcgonline", "annualdisclosure", "signer", "list"]);
			this.toggleNavigation();
		}
		else
		{
		}
	}

	goToAPUPPTCustomer(): void
	{
		this._router.navigate([StringConstant.STRING_PATH_ROUTING_HOME, StringConstant.STRING_PATH_ROUTING_APUPPT, StringConstant.STRING_PATH_ROUTING_CUSTOMERDATA]);
		this.toggleNavigation();
	}

	goToRiskBasedAssessment(): void
	{
		this._router.navigate([StringConstant.STRING_PATH_ROUTING_HOME, StringConstant.STRING_PATH_ROUTING_APUPPT, StringConstant.STRING_PATH_ROUTING_RISKBASEDASSESSMENT]);
		this.toggleNavigation();
	}

	goToTransaction(): void
	{
		this._router.navigate([StringConstant.STRING_PATH_ROUTING_HOME, StringConstant.STRING_PATH_ROUTING_APUPPT, StringConstant.STRING_PATH_ROUTING_TRANSACTION, StringConstant.STRING_PATH_ROUTING_LIST]);
	}

	goToPegadaianTransactionMonitoring(): void
	{
		this._router.navigate(["home", "pegadaian", "transactionmonitoring"]);
		this.toggleNavigation();
	}

	goToBrinsaveDashboard(): void
	{
		this._router.navigate(["home", "brinsave", "dashboard"]);
		this.toggleNavigation();
	}

	goToManagementAllocation(): void
	{
		this._router.navigate(["home", "brinsave", "management", "allocation", "list"]);
		this.toggleNavigation();
	}

	goToManagementArchiveData(): void
	{
		this._router.navigate(["home", "brinsave", "management", "archivedata", "list"]);
		this.toggleNavigation();
	}

	goToManagementArchiveLoan(): void
	{
		this._router.navigate(["home", "brinsave", "management", "archiveloan", "list"]);
		this.toggleNavigation();
	}

	goToManagementUploadDocument(): void
	{
		this._router.navigate(["home", "brinsave", "management", "uploaddocument"]);
		this.toggleNavigation();
	}

	goToReallocationArchiveShelf(): void
	{
		this._router.navigate(["home", "brinsave", "management", "reallocation", "archiveshelf", "list"]);
		this.toggleNavigation();
	}

	goToReallocationArchive(): void
	{
		this._router.navigate(["home", "brinsave", "management", "reallocation", "archive", "list"]);
		this.toggleNavigation();
	}

	goToReportBrinsave(): void
	{
		this._router.navigate(["home", "brinsave", "report"]);
		this.toggleNavigation();
	}

	goProjectManagementDashboard(): void
	{
		this._router.navigate(["home", "brinspro", "dashboard"]);
		this.toggleNavigation();
	}

	goToProjectManagementProject(): void
	{
		this._router.navigate(["home", "brinspro", "project", "list"]);
		this.toggleNavigation();
	}

	goToProjectManagementDocument(): void
	{
		this._router.navigate(["home", "brinspro", "document", "list"]);
		this.toggleNavigation();
	}

	goToEWSDashboard(): void
	{
		this._router.navigate([StringConstant.STRING_PATH_ROUTING_HOME, StringConstant.STRING_PATH_ROUTING_EWS, StringConstant.STRING_PATH_ROUTING_DASHBOARD, StringConstant.STRING_PATH_ROUTING_LIST]);
		this.toggleNavigation();
	}

	goToEWSFollowUpPlan(): void
	{
		this._router.navigate([StringConstant.STRING_PATH_ROUTING_HOME, StringConstant.STRING_PATH_ROUTING_EWS, StringConstant.STRING_PATH_ROUTING_FOLLOWUPPLAN, StringConstant.STRING_PATH_ROUTING_LIST]);
		this.toggleNavigation();
	}

	goToEWSFollowUpPlanMonitoring(): void
	{
		this._router.navigate([StringConstant.STRING_PATH_ROUTING_HOME, StringConstant.STRING_PATH_ROUTING_EWS, StringConstant.STRING_PATH_ROUTING_FOLLOWUPPLAN, StringConstant.STRING_PATH_ROUTING_MONITORING, StringConstant.STRING_PATH_ROUTING_LIST]);
		this.toggleNavigation();
	}

	goToEWSReport(): void
	{
		this._router.navigate([StringConstant.STRING_PATH_ROUTING_HOME, StringConstant.STRING_PATH_ROUTING_EWS, StringConstant.STRING_PATH_ROUTING_REPORT]);
		this.toggleNavigation();
	}

	goToFinancialReport(): void
	{
		this._router.navigate([StringConstant.STRING_PATH_ROUTING_HOME, StringConstant.STRING_PATH_ROUTING_CMS, StringConstant.STRING_PATH_ROUTING_CMS_FINANCIALREPORT, StringConstant.STRING_PATH_ROUTING_LIST]);
		this.toggleNavigation();
	}

	//#endregion


	//#region FUNCTION

	booleanNavigationCMS()
	{
		if (!this._booleanNavigationCMS)
		{
			this._booleanNavigationCMS = true;
		}
		else
		{
			this._booleanNavigationCMS = false;
		}
	}

	//#endregion
}

//#endregion