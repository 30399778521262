//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { TableModel } from "../models/bases/table.model";
import { LetterGeneralModel } from "../models/lettergeneral.model";
import { SubmissionDocumentModel } from "../models/submissiomdocument.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class SubmissionService extends BaseService
{

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#region INSERT

	insertSubmission(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral), WebAddressConstant.STRING_URL_SUBMISSION_INSERTSUBMISSION);
	}

	//#endregion


	//#region SELECT

	selectSubmissionByAttributesForMaker(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_SUBMISSION_SELECTSUBMISSIONBYATTRIBUTESFORMAKER);
	}

	selectSubmissionByAttributesForAccounting(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_SUBMISSION_SELECTSUBMISSIONBYATTRIBUTESFORACCOUNTING);
	}

	selectSubmissionForAccountingByToken(interfaceGeneralService: GeneralServiceInterface, modelLeterGeneral: LetterGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLeterGeneral), WebAddressConstant.STRING_URL_DECLARATION_SELECTSUBMISSIONFORACCOUNTINGBYTOKEN);
	}

	selectSubmissionByToken(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral), WebAddressConstant.STRING_URL_SUBMISSION_SELECTSUBMISSIONBYTOKEN);
	}

	selectSubmissionQRByToken(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral), WebAddressConstant.STRING_URL_SUBMISSION_SELECTSUBMISSIONQRBYTOKEN);
	}

	//#endregion


	//#region UPDATE

	updateSubmissionByToken(interfaceGeneralService: GeneralServiceInterface, modelLeterGeneral: LetterGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLeterGeneral), WebAddressConstant.STRING_URL_SUBMISSION_UPDATESUBMISSIONBYTOKEN);
	}

	//#endregion


	//#region DELETE

	deleteSubmissionByToken(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral), WebAddressConstant.STRING_URL_SUBMISSION_DELETESUBMISSIONBYTOKEN);
	}

	//#endregion


	//#region UPLOAD

	uploadSubmission(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel)
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral), WebAddressConstant.STRING_URL_SUBMISSION_UPLOADSUBMISSION);
	}

	//#endregion


	//#region DOWNLOAD

	downloadSubmissionAttachment(interfaceGeneralService: GeneralServiceInterface, modelSubmissionDocument: SubmissionDocumentModel): void
	{
		this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelSubmissionDocument), WebAddressConstant.STRING_URL_SUBMISSION_DOWNLOADSUBMISSIONDOCUMENT);
	}

	downloadSubmissionLetterAKU(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel): void
	{
		this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral), WebAddressConstant.STRING_URL_SUBMISSION_DOWNLOADSUBMISSION);
	}

	downloadSubmissionLetterBranchHead(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel): void
	{
		this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral), WebAddressConstant.STRING_URL_SUBMISSION_DOWNLOADSUBMISSIONBRANCHHEAD);
	}

	downloadSubmissionLetterHumanResource(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel): void
	{
		this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral), WebAddressConstant.STRING_URL_SUBMISSION_DOWNLOADSUBMISSIONHUMANRESOURCE);
	}

	downloadSubmissionLetterLogistic(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel): void
	{
		this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral), WebAddressConstant.STRING_URL_SUBMISSION_DOWNLOADSUBMISSIONLOGISTIC);
	}

	downloadSubmissionLetterCompanySecretariat(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel): void
	{
		this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral), WebAddressConstant.STRING_URL_SUBMISSION_DOWNLOADSUBMISSIONCOMPANYSECRETARIAT);
	}

	updateSubmissionPaymentRequestByLetterGeneralToken(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral) ,WebAddressConstant.STRING_URL_SUBMISSION_UPDATESUBMISSIONPAYMENTREQUESTBYLETTERGENERALTOKEN);
	}

	updateSubmissionPendingForPaymentAKUByLetterGeneralToken(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral) ,WebAddressConstant.STRING_URL_SUBMISSION_UPDATESUBMISSIONPENDINGFORPAYMENTAKUBYLETTERGENERALTOKEN);
	}

	approveSubmissionPendingForSignerAKUByLetterGeneralToken(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral) ,WebAddressConstant.STRING_URL_SUBMISSION_APPROVESUBMISSIONPENDINGFORSIGNERAKUBYLETTERGENERALTOKEN);
	}

	downloadSubmissionTransferReceiptByLetterGeneralToken(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral) ,WebAddressConstant.STRING_URL_SUBMISSION_DOWNLOADSUBMISSIONTRANSFERRECEIPTBYLETTERGENERALTOKEN);
	}

	//#endregion
}

//#endregion