/* eslint-disable no-unused-vars */
//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { TableModel } from "../models/bases/table.model";
import { ReimbursementGeneralModel } from "../models/reimbursementgeneral.model";
import { MaternityReimbursementModel } from "../models/maternityreimbursement.model";
import { MaternityReimbursementGalleryModel } from "../models/maternityreimbursementgallery.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class MaternityreimbursementService extends BaseService
{
	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	// #region SELECT

	selectMaternityReimbursementGeneralByAttributesForChecker(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_MATERNITYREIMBURSEMENT_SELECTMATERNITYREIMBURSEMENTBYATTRIBUTESFORCHECKER);
	}

	selectMaternityReimbursementByAttributesForSigner(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_MATERNITYREIMBURSEMENT_SELECTMATERNITYREIMBURSEMENTBYATTRIBUTESFORSIGNER);
	}

	selectMaternityReimbursementByToken(interfaceGeneralService: GeneralServiceInterface, modelReimbursementGeneral: ReimbursementGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelReimbursementGeneral), WebAddressConstant.STRING_URL_MATERNITYREIMBURSEMENT_SELECTMATERNITYREIMBURSEMENTBYTOKEN);
	}

	// #endregion


	//#region APPROVAL

	checkMaternityReimbursementByToken(interfaceGeneralService: GeneralServiceInterface, modelReimbursementGeneral: ReimbursementGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelReimbursementGeneral), WebAddressConstant.STRING_URL_MATERNITYREIMBURSEMENT_CHECKMATERNITYREIMBURSEMENTBYTOKEN);
	}

	signMaternityReimbursementByToken(interfaceGeneralService: GeneralServiceInterface, modelReimbursementGeneral: ReimbursementGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelReimbursementGeneral), WebAddressConstant.STRING_URL_MATERNITYREIMBURSEMENT_SIGNMATERNITYREIMBURSEMENTBYTOKEN);
	}

	rejectMaternityReimbursementByToken(interfaceGeneralService: GeneralServiceInterface, modelReimbursementGeneral: ReimbursementGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelReimbursementGeneral), WebAddressConstant.STRING_URL_MATERNITYREIMBURSEMENT_REJECTMATERNITYREIMBURSEMENTBYTOKEN);
	}

	reviseMaternityReimbursementByToken(interfaceGeneralService: GeneralServiceInterface, modelReimbursementGeneral: ReimbursementGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelReimbursementGeneral), WebAddressConstant.STRING_URL_MATERNITYREIMBURSEMENT_REVISEMATERNITYREIMBURSEMENTBYTOKEN);
	}

	//#endregion

	//#region DOWNLOAD

	downloadMaternityReimbursementGalleryByToken(interfaceGeneralService: GeneralServiceInterface, modelMaternityReimbursementGallery: MaternityReimbursementGalleryModel): void
	{
		this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelMaternityReimbursementGallery), WebAddressConstant.STRING_URL_MATERNITYREIMBURSEMENT_DOWNLOADMATERNITYREIMBURSEMENTGALLERYBYTOKEN);
	}

	//#endregion

}

//#endregion