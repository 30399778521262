/* eslint-disable no-unused-vars */
//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { TableModel } from "../models/bases/table.model";
import { ReimbursementGeneralModel } from "../models/reimbursementgeneral.model";
import { PostMaternityReimbursementModel } from "../models/postmaternityreimbursementmodel";
import { PostMaternityReimbursementGalleryModel } from "../models/postmaternityreimbursementgallerymodel";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class PostmaternityreimbursementService extends BaseService
{
	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	// #region SELECT

	selectPostMaternityReimbursementGeneralByAttributesForChecker(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_POSTMATERNITYREIMBURSEMENT_SELECTPOSTMATERNITYREIMBURSEMENTBYATTRIBUTESFORCHECKER);
	}

	selectPostMaternityReimbursementByAttributesForSigner(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_POSTMATERNITYREIMBURSEMENT_SELECTPOSTMATERNITYREIMBURSEMENTBYATTRIBUTESFORSIGNER);
	}

	selectPostMaternityReimbursementByToken(interfaceGeneralService: GeneralServiceInterface, modelReimbursementGeneral: ReimbursementGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelReimbursementGeneral), WebAddressConstant.STRING_URL_POSTMATERNITYREIMBURSEMENT_SELECTPOSTMATERNITYREIMBURSEMENTBYTOKEN);
	}

	// #endregion


	//#region APPROVAL

	checkPostMaternityReimbursementByToken(interfaceGeneralService: GeneralServiceInterface, modelReimbursementGeneral: ReimbursementGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelReimbursementGeneral), WebAddressConstant.STRING_URL_POSTMATERNITYREIMBURSEMENT_CHECKPOSTMATERNITYREIMBURSEMENTBYTOKEN);
	}

	signPostMaternityReimbursementByToken(interfaceGeneralService: GeneralServiceInterface, modelReimbursementGeneral: ReimbursementGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelReimbursementGeneral), WebAddressConstant.STRING_URL_POSTMATERNITYREIMBURSEMENT_SIGNPOSTMATERNITYREIMBURSEMENTBYTOKEN);
	}

	rejectPostMaternityReimbursementByToken(interfaceGeneralService: GeneralServiceInterface, modelReimbursementGeneral: ReimbursementGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelReimbursementGeneral), WebAddressConstant.STRING_URL_POSTMATERNITYREIMBURSEMENT_REJECTPOSTMATERNITYREIMBURSEMENTBYTOKEN);
	}

	revisePostMaternityReimbursementByToken(interfaceGeneralService: GeneralServiceInterface, modelReimbursementGeneral: ReimbursementGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelReimbursementGeneral), WebAddressConstant.STRING_URL_POSTMATERNITYREIMBURSEMENT_REVISEPOSTMATERNITYREIMBURSEMENTBYTOKEN);
	}

	//#endregion


	//#region DOWNLOAD

	downloadPostMaternityReimbursementGalleryByToken(interfaceGeneralService: GeneralServiceInterface, modelPostMaternityReimbursementGallery: PostMaternityReimbursementGalleryModel): void
	{
		this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelPostMaternityReimbursementGallery), WebAddressConstant.STRING_URL_POSTMATERNITYREIMBURSEMENT_DOWNLOADPOSTMATERNITYREIMBURSEMENTGALLERYBYTOKEN);
	}

	//#endregion

}

//#endregion