//#region IMPORT

import { ENUM_COVERED_PERSON, ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { BaseModel } from "./bases/base.model";
import { DentalReimbursementGalleryModel } from "./dentalreimbursementgallery.model";
import { ReimbursementGeneralModel } from "./reimbursementgeneral.model";
import { ResponseModel } from "./response.model";

//#endregion


//#region CLASS

export class DentalReimbursementModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for dental reimbursement.
		Author: Andryana Baros.
		Created on : Friday, 05 November 2021. 				Updated on : -.
		Created by : Andryana Baros.							Updated by : -.
		Version : 1.0:2.
	*/

	ID?: number;
	ReimbursementGeneralID?: number;
	Plafond?: number;
	CoveredPerson?: ENUM_COVERED_PERSON;
	ReceiptDate?: Date;
	modelReimbursementGeneral?: ReimbursementGeneralModel;
	listModelDentalReimbursementGallery?: Array<DentalReimbursementGalleryModel>;


	/* Attribute - END */


	constructor()
	{
		super();
		this.listModelDentalReimbursementGallery = [];
	}


	//#region  VALIDATION

	validateAddModelDentalReimbursementDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Dental Reimbursement");

		if(this.listModelDentalReimbursementGallery == null || this.listModelDentalReimbursementGallery === undefined)
		{
			modelResponse.MessageContent = "Terjadi kesalahan pada daftar attachment.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}


	validateCheckListModelDentalReimbursementDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Dental Reimbursement");

		if(this.listModelDentalReimbursementGallery == null || this.listModelDentalReimbursementGallery === undefined)
		{
			modelResponse.MessageContent = "Terjadi kesalahan pada daftar attachment.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}



	validateCheckModelDentalReimbursementDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = this.validateCheckListModelDentalReimbursementDocument();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			let modelResponseDentalReimbursementDocument: ResponseModel;
			let modelDentalReimbursementDocumentCheck: DentalReimbursementGalleryModel;

			for (const modelDentalReimbursementDocument of this.listModelDentalReimbursementGallery ?? [])
			{
				modelDentalReimbursementDocumentCheck = new DentalReimbursementGalleryModel();
				modelDentalReimbursementDocumentCheck.setModelFromObject(modelDentalReimbursementDocument);
				modelResponseDentalReimbursementDocument = modelDentalReimbursementDocumentCheck.validateCheck();

				if (modelResponseDentalReimbursementDocument.State === ENUM_RESPONSE_STATE.Fail)
				{
					return modelResponseDentalReimbursementDocument;
				}
				else
				{

				}
			}

			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{

		}

		return modelResponse;
	}

	//#endregion
}

//#endregion