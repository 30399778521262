//#region IMPORT

import { ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { BankAccountModel } from "./bankaccount.model";
import { CurrencyModel } from "./currency.model";
import { DeclarationModel } from "./declaration.model";
import { DeclarationVideModel } from "./declarationvide.model";
import { BaseModel } from "./bases/base.model";
import { DeclarationCostModel } from "./declarationcost.model";
import { ResponseModel } from "./response.model";

//#endregion


//#region CLASS

export class DeclarationDetailModel extends BaseModel
{
	ID?: number;
	DeclarationID?: number;
	Title?: string;
	Description?: string;
	BeneficiaryName?: string;
	BankAccountToken?: string;
	BranchCodeResponsibility?: string;
	BranchNameResponsibility?: string;
	EmployeeAccountNumber?: string;
	Amount?: number;
	CurrencyID?: number;
	RealizationAmount?: number;
	PaidOn?: Date;

	modelBankAccount?: BankAccountModel;
	modelDeclaration?: DeclarationModel;
	modelCurrency?: CurrencyModel;
	listModelDeclarationVide?: Array<DeclarationVideModel>;
	listModelDeclarationCost?: Array<DeclarationCostModel>;

	//#region FRONT END ONLY MODEL

	TotalAmount?: number;
	TotalCost?: number;
	TotalAmountWithCost?: number;
	modelDeclarationCostInsert?: DeclarationCostModel;
	BooleanBankAccountVerification?: boolean;

	//#endregion


	//#region CONSTRUCTOR

	constructor()
	{
		super();
		this.TotalAmount = 0;
		this.TotalCost = 0;
		this.TotalAmountWithCost = 0;
		this.modelCurrency = new CurrencyModel();
		this.modelDeclarationCostInsert = new DeclarationCostModel();
		this.listModelDeclarationVide = [];
		this.listModelDeclarationCost = [];
		this.BooleanBankAccountVerification = false;
	}

	//#endregion


	//#region SETTER

	setRealizationAmount(): void
	{
		if (this.Amount == null || this.Amount === undefined)
		{
			this.RealizationAmount = 0;
		}
		else
		{
			if (this.Amount > 0)
			{
				if (this.listModelDeclarationVide != null || this.listModelDeclarationVide !== undefined)
				{
					if (this.listModelDeclarationVide.length > 0)
					{
						let modelDeclarationVide: DeclarationVideModel;
						let numberAmountVideTotal: number = 0;

						// eslint-disable-next-line @typescript-eslint/prefer-for-of
						for (let numberIndex: number = 0; numberIndex < this.listModelDeclarationVide.length; numberIndex++)
						{
							modelDeclarationVide = this.listModelDeclarationVide[numberIndex];
							numberAmountVideTotal += modelDeclarationVide.RealizationAmount ?? 0;
						}

						this.RealizationAmount = this.Amount - numberAmountVideTotal;
					}
					else
					{
						this.RealizationAmount = 0;
					}
				}
				else
				{
					this.RealizationAmount = 0;
				}
			}
			else
			{
				this.RealizationAmount = 0;
			}
		}
	}

	setListModelDeclarationVide(): void
	{
		if (this.listModelDeclarationVide == null || this.listModelDeclarationVide === undefined)
		{

		}
		else
		{
			if (this.listModelDeclarationVide.length > 0 && this.TotalAmount !== undefined)
			{
				const arrayModelDeclarationVideTemporary: Array<DeclarationVideModel> = this.listModelDeclarationVide;
				this.listModelDeclarationVide = [];

				let modelDeclarationVide: DeclarationVideModel;

				for (const modelDeclarationVideTemporary of arrayModelDeclarationVideTemporary)
				{
					modelDeclarationVide = new DeclarationVideModel();
					modelDeclarationVide.setModelFromObject(modelDeclarationVideTemporary);
					this.TotalAmount += modelDeclarationVide.Amount ?? 0;
					this.TotalAmountWithCost = this.TotalAmount;
					this.listModelDeclarationVide.push(modelDeclarationVide);
				}
			}
			else
			{

			}
		}
	}

	setListModelDeclarationCost(): void
	{
		if (this.listModelDeclarationCost == null || this.listModelDeclarationCost === undefined)
		{

		}
		else
		{
			if (this.listModelDeclarationCost.length > 0)
			{
				const arrayModelDeclarationCostTemporary: Array<DeclarationCostModel> = this.listModelDeclarationCost;
				this.listModelDeclarationCost = [];

				let modelDeclarationCost: DeclarationCostModel;

				for (const modelDeclarationCostTemporary of arrayModelDeclarationCostTemporary)
				{
					modelDeclarationCost = new DeclarationCostModel();
					modelDeclarationCost.setModelFromObject(modelDeclarationCostTemporary);
					this.listModelDeclarationCost.push(modelDeclarationCost);
				}
			}
			else
			{

			}
		}
	}

	setAmountAdjustment(modelDeclarationCost: DeclarationCostModel): void
	{
		if(modelDeclarationCost !== undefined && modelDeclarationCost.Amount !== undefined && this.TotalAmount !== undefined && this.TotalCost !== undefined)
		{
			this.TotalCost += modelDeclarationCost.Amount;
			this.TotalAmountWithCost = this.TotalAmount + this.TotalCost;
		}
		else
		{

		}
	}

	setDecreaseAmountAdjustment(modelDeclarationCost: DeclarationCostModel): void
	{
		if(modelDeclarationCost !== undefined && modelDeclarationCost.Amount !== undefined && this.TotalAmount !== undefined && this.TotalCost !== undefined)
		{
			this.TotalCost -= modelDeclarationCost.Amount;
			this.TotalAmountWithCost = this.TotalAmount + this.TotalCost;
		}
		else
		{

		}
	}

	setModelBankAccount(): void
	{
		if(this.modelBankAccount === undefined)
		{
			this.modelBankAccount = new BankAccountModel();
		}
		else
		{

		}

		const stringModelBankAccount: string = JSON.stringify(this.modelBankAccount);
		this.modelBankAccount = new BankAccountModel();
		this.modelBankAccount.setModelFromString(stringModelBankAccount);
	}

	//#endregion


	//#region VALIDATE

	validateCheck(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Bank Account Verification");

		if (this.BooleanBankAccountVerification == null || this.BooleanBankAccountVerification === undefined || this.BooleanBankAccountVerification === false)
		{
			modelResponse.MessageContent = "Please check bank account for verification before approve.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	//#endregion


	//#region CLEAN

	cleanFootprintForPaymentRequest(): void
	{
		this.cleanAllFootprint();
		this.BeneficiaryName = undefined;
		this.BankAccountToken = undefined;
		this.Description = undefined;
		this.RealizationAmount = undefined;
		this.Title = undefined;
		this.TotalAmount = undefined;
		this.TotalAmountWithCost = undefined;
		this.TotalCost = undefined;
		this.modelBankAccount = undefined;
		this.modelCurrency = undefined;
		this.modelDeclarationCostInsert = undefined;
		this.listModelDeclarationVide = undefined;
		this.BooleanBankAccountVerification = undefined;
	}

	//#endregion
}

//#endregion


