<div id="divBodyClaimProposal" class="DivBodyGeneral">
    <header class="HeaderGeneral">
		<h2>Report Monitoring List</h2>
		<p>Report Monitoring Channel & Product</p>
    </header>

	<div id="divMainFinancialRatioKonsolidasi" class="DivContainerListHorizontal FlexColumn">
		<form>
			<div class="DivForm">
				<h3>Pencarian</h3>
				<fieldset>
					<div class="DivFormHorizontalContainer">
						<div class="DivForm2Column">
							<label for="datePeriod">Periode</label>
							<mat-form-field appearance="fill">
								<mat-label>Enter a date range</mat-label>
								<mat-date-range-input [rangePicker]="picker">
									<input matStartDate placeholder="Start date" [value]="_dateStart" (dateChange)="getStartDate($event)">
									<input matEndDate placeholder="End date" [value]="_dateEnd" (dateChange)="getEndDate($event)">
								</mat-date-range-input>
								<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
								<mat-date-range-picker #picker></mat-date-range-picker>
							</mat-form-field>
		
							<label for="selectChannel">Channel</label>
							<select type="text" id="selectChannel" name="modelFinancialHighlightMonthOfYear" [(ngModel)]="_modelProductGeneralReportRequest.Channel" required>
								<option [ngValue]="" hidden></option>
								<option [ngValue]="_stringUndefined">All</option>
								<option [ngValue]="_stringConstant.STRING_CHANNEL_BRINSWEBSITE">{{_stringConstant.STRING_CHANNEL_BRINSWEBSITE}}</option>
								<option [ngValue]="_stringConstant.STRING_CHANNEL_BRINSMOBILE">{{_stringConstant.STRING_CHANNEL_BRINSMOBILE}}</option>
								<option [ngValue]="_stringConstant.STRING_CHANNEL_BRIMO">{{_stringConstant.STRING_CHANNEL_BRIMO}}</option>
								<option [ngValue]="_stringConstant.STRING_CHANNEL_NDSUB">{{_stringConstant.STRING_CHANNEL_NDSUB}}</option>
								<option [ngValue]="_stringConstant.STRING_CHANNEL_SELFSERVICEMONITORING">{{_stringConstant.STRING_CHANNEL_SELFSERVICEMONITORING}}</option>
							</select>
						</div>
	
						<div class="DivForm2Column">
							<label for="selectProduct">Produk</label>
							<select type="text" id="selectProduct" class="MarginBottom30" name="modelFinancialHighlightMonthOfYear" [(ngModel)]="_modelProductGeneralReportRequest.ProductName" required>
								<ng-container *ngIf="_modelProductGeneralReportRequest.Channel === _stringConstant.STRING_CHANNEL_BRINSWEBSITE">
									<option [ngValue]="" hidden></option>
									<option [ngValue]="_stringUndefined">All</option>
									<option [ngValue]="_stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_ASRI">{{_stringConstant.STRING_PRODUCT_ASRI}}</option>
									<option [ngValue]="_stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_OTO">{{_stringConstant.STRING_PRODUCT_OTO}}</option>
									<option [ngValue]="_stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_BICYCLE">{{_stringConstant.STRING_PRODUCT_BICYCLE}}</option>
									<option [ngValue]="_stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_PERSONALACCIDENT">{{_stringConstant.STRING_PRODUCT_PERSONALACCIDENT}}</option>
									<option [ngValue]="_stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_DAMAGEOFBUSINESSPLACE">{{_stringConstant.STRING_PRODUCT_DAMAGEOFBUSINESSPLACE}}</option>
									<option [ngValue]="_stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_MYHOUSE">{{_stringConstant.STRING_PRODUCT_MYHOUSE}}</option>
									<option [ngValue]="_stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_MYMOTORCYCLE">{{_stringConstant.STRING_PRODUCT_MYMOTORCYCLE}}</option>
								</ng-container>
								<ng-container *ngIf="_modelProductGeneralReportRequest.Channel === _stringUndefined || _modelProductGeneralReportRequest.Channel === _stringConstant.STRING_CHANNEL_BRINSMOBILE">
									<option [ngValue]="" hidden></option>
									<option [ngValue]="_stringUndefined">All</option>
									<option [ngValue]="_stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_ASRI">{{_stringConstant.STRING_PRODUCT_ASRI}}</option>
									<option [ngValue]="_stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_OTO">{{_stringConstant.STRING_PRODUCT_OTO}}</option>
									<option [ngValue]="_stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_BICYCLE">{{_stringConstant.STRING_PRODUCT_BICYCLE}}</option>
									<option [ngValue]="_stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_PERSONALACCIDENT">{{_stringConstant.STRING_PRODUCT_PERSONALACCIDENT}}</option>
									<option [ngValue]="_stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_DAMAGEOFBUSINESSPLACE">{{_stringConstant.STRING_PRODUCT_DAMAGEOFBUSINESSPLACE}}</option>
									<option [ngValue]="_stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_MYHOUSE">{{_stringConstant.STRING_PRODUCT_MYHOUSE}}</option>
									<option [ngValue]="_stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_MYMOTORCYCLE">{{_stringConstant.STRING_PRODUCT_MYMOTORCYCLE}}</option>
									<option [ngValue]="_stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_ASRISYARIAH">{{_stringConstant.STRING_PRODUCT_SYARIAH_ASRISYARIAH}}</option>
									<option [ngValue]="_stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_OTOSYARIAH">{{_stringConstant.STRING_PRODUCT_SYARIAH_OTOSYARIAH}}</option>
									<option [ngValue]="_stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_PERSONALACCIDENTSYARIAH">{{_stringConstant.STRING_PRODUCT_SYARIAH_PERSONALACCIDENTSYARIAH}}</option>
									<option [ngValue]="_stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_DAMAGEOFBUSINESSPLACESYARIAH">{{_stringConstant.STRING_PRODUCT_SYARIAH_DAMAGEOFBUSINESSPLACESYARIAH}}</option>
									<option [ngValue]="_stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_MYHOUSESYARIAH">{{_stringConstant.STRING_PRODUCT_SYARIAH_MYHOUSESYARIAH}}</option>
								</ng-container>
								<ng-container *ngIf="_modelProductGeneralReportRequest.Channel === _stringConstant.STRING_CHANNEL_BRIMO">
									<option [ngValue]="" hidden></option>
									<option [ngValue]="_stringUndefined">All</option>
									<option [ngValue]="_stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_ASRI">{{_stringConstant.STRING_PRODUCT_ASRI}}</option>
									<option [ngValue]="_stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_OTO">{{_stringConstant.STRING_PRODUCT_OTO}}</option>
									<option [ngValue]="_stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_BICYCLE">{{_stringConstant.STRING_PRODUCT_BICYCLE}}</option>
								</ng-container>
								<ng-container *ngIf="_modelProductGeneralReportRequest.Channel === _stringConstant.STRING_CHANNEL_NDSUB">
									<option [ngValue]="" hidden></option>
									<option [ngValue]="_stringUndefined">All</option>
									<option [ngValue]="_stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_ASRI">{{_stringConstant.STRING_PRODUCT_ASRI}}</option>
									<option [ngValue]="_stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_OTO">{{_stringConstant.STRING_PRODUCT_OTO}}</option>
									<option [ngValue]="_stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_BICYCLE">{{_stringConstant.STRING_PRODUCT_BICYCLE}}</option>
									<option [ngValue]="_stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_DAMAGEOFBUSINESSPLACE">{{_stringConstant.STRING_PRODUCT_DAMAGEOFBUSINESSPLACE}}</option>
									<option [ngValue]="_stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_MYHOUSE">{{_stringConstant.STRING_PRODUCT_MYHOUSE}}</option>
									<option [ngValue]="_stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_MYMOTORCYCLE">{{_stringConstant.STRING_PRODUCT_MYMOTORCYCLE}}</option>
								</ng-container>
								<ng-container *ngIf="_modelProductGeneralReportRequest.Channel === _stringConstant.STRING_CHANNEL_SELFSERVICEMONITORING">
									<option [ngValue]="" hidden></option>
									<option [ngValue]="_stringUndefined">All</option>
									<option [ngValue]="_stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_ASRI">{{_stringConstant.STRING_PRODUCT_ASRI}}</option>
									<option [ngValue]="_stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_OTO">{{_stringConstant.STRING_PRODUCT_OTO}}</option>
									<option [ngValue]="_stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_PERSONALACCIDENT">{{_stringConstant.STRING_PRODUCT_PERSONALACCIDENT}}</option>
									<option [ngValue]="_stringConstant.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_BICYCLE">{{_stringConstant.STRING_PRODUCT_BICYCLE}}</option>
								</ng-container>
							</select>

							<label for="selectBranch">Cabang</label>
							<select type="text" id="selectBranch" name="_modelProductGeneralReportSummaryRequestBranchName" [(ngModel)]="_modelProductGeneralReportRequest.BranchName" required>
								<option [ngValue]="_stringUndefined">All</option>
								<option [ngValue]="modelBranch.Name" *ngFor="let modelBranch of _arrayModelBranch;">{{ modelBranch.Name }}</option>
							</select>
						</div>
					</div>
				</fieldset>
			</div>
			<div class="DivForm2ColumnButton">
				<input type="button" name="buttonExportExcel" value="Expor Excel" (click)="callSelectPolicyGeneralReportRatingByAttributes(this)">
				<input type="button" name="buttonSearch" value="Cari" (click)="callSelectProductGeneralReportByAttributes(this, true)">
			</div>
		</form>

		<div id="divMenuAllProductListContainer">
			<div class="DivTableContainerHorizontal">
				<div class="DivTableContainer MarginLeft0 MarginBottom20">
					<ng-container>
						<app-tablecontrolinclude [_modelTableForm]="_modelTable" (_eventEmitterSelect)="setEventEmitterSelect($event);"></app-tablecontrolinclude>
						<table class="TableListReportMonitoring">
							<thead>
								<tr>
									<th>Channel</th>
									<th>Produk</th>
									<th>Tanggal Pemesanan</th>
									<th>Nomor Referensi</th>
									<th>Nomor Polis</th>
									<th>Cabang</th>
									<th>Periode Awal</th>
									<th>Periode Akhir</th>
									<th>Nama Pemesan</th>
									<!-- <th>NIK Pemesan</th> -->
									<th>No. Telp Pemesan</th>
									<th>Premi</th>
									<th>Biaya Admin</th>
									<th>Diskon</th>
									<th>Kode Promo</th>
									<th>Total Pembayaran</th>
									<th>Tanggal Pembayaran</th>
									<th>Nomor BRIVA</th>
									<th>Nama Pemilik Referral</th>
									<!-- <th>NIK Referral</th> -->
									<th>No. Telp Referral</th>
								</tr>
							</thead>
	
							<tbody>
								<tr *ngFor="let modelProductGeneralReport of _arrayModelProductGeneralReport; let numberIndex = index">
									<td>{{modelProductGeneralReport.Channel}}</td>
									<td>{{modelProductGeneralReport.ProductName}}</td>
									<td>{{modelProductGeneralReport.OrderDate}}</td>
									<td>{{modelProductGeneralReport.ReferenceNumber}}</td>
									<td>{{modelProductGeneralReport.PolicyNumber}}</td>
									<td>{{modelProductGeneralReport.BranchName}}</td>
									<td>{{modelProductGeneralReport.PolicyStartDate}}</td>
									<td>{{modelProductGeneralReport.PolicyEndDate}}</td>
									<td>{{modelProductGeneralReport.PolicyholderFullName}}</td>
									<!-- <td>{{modelProductGeneralReport.PolicyholderIdentificationNumber}}</td> -->
									<td>{{modelProductGeneralReport.PolicyholderPhoneNumber}}</td>
									<td>{{modelProductGeneralReport.PremiumAmount | currencyValue}}</td>
									<td>{{modelProductGeneralReport.AdministrationAmount | currencyValue}}</td>
									<td>{{modelProductGeneralReport.DiscountAmount | currencyValue}}</td>
									<td>{{modelProductGeneralReport.PromoCode}}</td>
									<td>{{modelProductGeneralReport.PremiumTotalAmount | currencyValue}}</td>
									<td>{{modelProductGeneralReport.PaymentDate}}</td>
									<td>{{modelProductGeneralReport.BRIVANumber}}</td>
									<td>{{modelProductGeneralReport.ReferralOwnerFullName}}</td>
									<!-- <td>{{modelProductGeneralReport.ReferralOwnerIdentificationNumber}}</td> -->
									<td>{{modelProductGeneralReport.ReferralOwnerPhoneNumber}}</td>
								</tr>
							</tbody>
						</table>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</div>

